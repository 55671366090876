import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { I18n } from "aws-amplify";
import { NavLink, useLocation } from "react-router-dom";
import { AUTH_API } from "../../../../../actions/auth/actions";
import logo from "../../../../../image/logoWOtextb.svg";
import icoMenu from "../../../../../image/ico-menu-white.svg";
import icoHome from "../../../../../image/ico-home.svg";
import icoExit from "../../../../../image/ico-exit.svg";
import icoProfile from "../../../../../image/ico-profile-menu.svg";
import * as S from "./styles";


export const Logged = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [drawerMenu, setDrawerMenu] = useState({ visible: false });

  const logout = () => {
    dispatch(AUTH_API.logout());
  };

  return (
    <>
      <S.Wrapper>
        <S.Header>
          <div className="container">
            <S.HeaderContent>
              <div>
                <S.ButtonHeader
                  onClick={() => setDrawerMenu({ visible: true })}
                >
                  <img src={icoMenu} alt="icone menu" />
                </S.ButtonHeader>
              </div>
              <div>
                <span>
                  <img
                    src={logo}
                    alt="Ollo"
                    class="logo"
                  />
                </span>
              </div>
              <div>
                <S.ButtonHeader onClick={() => logout()}>
                  <span>{I18n.get("Sair")}</span>
                </S.ButtonHeader>
              </div>
            </S.HeaderContent>
          </div>
        </S.Header>
        <S.Content>{children}</S.Content>
        <S.Footer>
          <S.ContentFooter>
            <div className="container">
            <span>{`${new Date().getFullYear()} © Ollo`}</span>
            </div>
          </S.ContentFooter>
        </S.Footer>
      </S.Wrapper>

      <S.Drawer
        title={
          <a href="/#/fornecedor/inicio">
            <img src={logo} alt="Ollo" className="logo" />
          </a>
        }
        placement="left"
        closable={true}
        onClose={() => setDrawerMenu({ visible: false })}
        visible={drawerMenu?.visible}
        width={500}
      >
        <S.Menu>
          <li>
            {location?.pathname === "/fornecedor/inicio" ? (
              <button
                onClick={() => setDrawerMenu({ visible: false })}
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoHome} />
                <span>{I18n.get("Início")}</span>
              </button>
            ) : (
              <NavLink
                to="/fornecedor/inicio"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoHome} />
                <span>{I18n.get("Início")}</span>
              </NavLink>
            )}
          </li>
          <li>
            <NavLink
              to="/fornecedor/perfil/dados-bancarios"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProfile} />
              <span>{I18n.get("Perfil")}</span>
            </NavLink>
          </li>
          <li>
            <button
              onClick={() => logout()}
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoExit} />
              <span>{I18n.get("Sair")}</span>
            </button>
          </li>
        </S.Menu>
      </S.Drawer>
    </>
  );
};
