import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import { Link } from "react-router-dom";
import UploadFile from "../../components/FileUpload";
import FileUploadList from "../../components/FileUploadButton";
import { CardBorder } from "../../components/Cards";

import constants from "../../../constants";
import MaskedInput from "antd-mask-input";

import { v4 as uuidv4 } from "uuid";
import FormSkeleton from "../../components/FormSkeleton";
import axios from "axios";

import { TextCounter } from "./counterText";

import {
  Row,
  Col,
  Button,
  Tooltip,
  Checkbox,
  notification,
  Icon,
  Input,
  Select,
  Form,
} from "antd";

const { TextArea, Search } = Input;
const { Option } = Select;

class FormCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      introImage: "",
      logoImage: "",
      headerImage: "",
      copy: false,
      fieldBlur: "",
      docNegativeCertificate: null,
      docCnpjCard: null,
      docSocialContract: null,
      docAccountStatement: null,
      loadingFiles: false,
      countrySeleted: "",
      commercialCountrySeleted: "",
      fieldsLoad: {},
    };
  }

  loadFields = () => {
    let fieldsForm = this.props.form.getFieldsValue();

    this.setState({ fieldsLoad: fieldsForm }, () =>
      this.props.action(this.sendDataApi())
    );
  };

  sendDataApi = () => {
    const dataForm = {
      ...this.props.partner.company,
      ...this.state.fieldsLoad,
      partnerId: this.props.partnerId,
      logoImage: this.state.logoImage,
      introImage: this.state.introImage,
      headerImage: this.state.headerImage,
      isSubmit: false,
      country:
        this.state.countrySeleted ||
        this.props.partner.company.country ||
        this.props.partner.company.country,

      commercialCountry: this.state.fieldsLoad.copyAddressToCommercialAddress
        ? this.state.countrySeleted
        : this.state.commercialCountrySeleted,

      commercialPostcode: this.state.fieldsLoad.copyAddressToCommercialAddress
        ? this.state.fieldsLoad.postcode
        : this.state.commercialPostcode ||
          this.state.fieldsLoad.commercialPostcode,

      isNewCompany:
        this.props.partner.company && this.props.partner.company.isNewCompany
          ? this.props.partner.company.isNewCompany
          : false,
      docNegativeCertificate: this.state.docNegativeCertificate,
      docCnpjCard: this.state.docCnpjCard,
      docSocialContract: this.state.docSocialContract,
      docAccountStatement: this.state.docAccountStatement,
    };

    return dataForm;
  };

  componentDidMount = () => {
    this.setState({
      loadingFiles: true,
    });
  };

  componentWillMount = () => {
    this.setState({
      loadingFiles: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.partner &&
      this.props.partner.company != prevProps.partner.company
    ) {
      this.setState({
        logoImage: this.props.partner.company.logoImage,
        introImage: this.props.partner.company.introImage,
        introVideo: this.props.partner.company.introVideo,
        headerImage: this.props.partner.company.headerImage,
        docNegativeCertificate:
          this.props.partner.company.docNegativeCertificate,
        docCnpjCard: this.props.partner.company.docCnpjCard,
        docSocialContract: this.props.partner.company.docSocialContract,
        docAccountStatement: this.props.partner.company.docAccountStatement,
      });
    }

    if (
      this.props.partner &&
      this.props.partner.company &&
      this.state.loadingFiles != prevState.loadingFiles
    ) {
      this.setState({
        logoImage: this.props.partner.company.logoImage,
        introImage: this.props.partner.company.introImage,
        introVideo: this.props.partner.company.introVideo,
        headerImage: this.props.partner.company.headerImage,
        docNegativeCertificate:
          this.props.partner.company.docNegativeCertificate,
        docCnpjCard: this.props.partner.company.docCnpjCard,
        docSocialContract: this.props.partner.company.docSocialContract,
        docAccountStatement: this.props.partner.company.docAccountStatement,
      });
    }

    if (
      this.props.errors != prevProps.errors &&
      Object.keys(this.props.errors).length > 0 &&
      this.props.errors[this.state.fieldBlur]
    ) {
      this.props.form.setFields({
        [this.state.fieldBlur]: {
          errors: [
            new Error(this.props.errors[this.state.fieldBlur].errorMessage),
          ],
        },
      });
    }
  }

  onChangeIsNew = (e) => {
    let data = {
      partnerId: this.props.partnerId,
      isNewCompany: e.target.checked,
    };

    this.setState({
      isNewCompany: e.target.checked,
    });

    this.props.isNewCompany(data);
  };

  onChangeAdress = (field) => {
    this.handleSubmit(field);
  };

  handleSubmit = (field) => {
    this.setState(
      {
        fieldBlur: field,
      },
      () => this.loadFields()
    );
  };

  handleSubmitSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.action(this.sendDataApi());
        this.loadFields();
      }
    });
  };

  onPostcodeSearchClick = (e, field) => {
    let value = e.target.value;

    if (value && value.length > 0) {
      const pattern = /^[0-9]{5}-[0-9]{3}$/;

      const code = value.replace("-", "");

      return axios
        .get(`https://viacep.com.br/ws/${code}/json/`)
        .then((response) => {
          if (field == "postcode") {
            this.props.form.setFieldsValue({
              addressLine1: response.data.logradouro,
            });
            this.props.form.setFieldsValue({ city: response.data.localidade });
            this.props.form.setFieldsValue({ state: response.data.uf });

            this.setState({
              addressLine1: response.data.logradouro,
              city: response.data.localidade,
              state: response.data.uf,
            });

            setTimeout(() => {
              this.handleSubmit("postcode");
            }, 100);
          } else {
            this.props.form.setFieldsValue({
              commercialAddressLine1: response.data.logradouro,
            });
            this.props.form.setFieldsValue({
              commercialCity: response.data.localidade,
            });
            this.props.form.setFieldsValue({
              commercialState: response.data.uf,
            });

            this.setState({
              commercialAddressLine1: response.data.logradouro,
              commercialCity: response.data.localidade,
              commercialState: response.data.uf,
            });

            setTimeout(() => {
              this.handleSubmit("commercialPostcode");
            }, 100);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  getDataUpload = (dataForm) => {
    const { field, url } = dataForm;

    switch (field) {
      case "introImage":
        this.setState({
          introImage: url,
        });
        break;

      case "logoImage":
        this.setState({
          logoImage: url,
        });
        break;
      case "headerImage":
        this.setState({
          headerImage: url,
        });
        break;

      default:
        break;
    }

    this.handleSubmit();
  };

  handleDocNegativeCertificate = (archive) => {
    let file = archive[0];

    if (archive.length > 0) {
      delete file["uid"];
    }

    const data = archive.length > 0 ? file : null;

    this.setState({
      docNegativeCertificate: data,
    });

    this.handleSubmit();
  };

  handleDocCnpjCard = (archive) => {
    let file = archive[0];

    if (archive.length > 0) {
      delete file["uid"];
    }

    const data = archive.length > 0 ? file : null;

    this.setState({
      docCnpjCard: data,
    });

    this.handleSubmit();
  };

  handleDocSocialContract = (archive) => {
    let file = archive[0];

    if (archive.length > 0) {
      delete file["uid"];
    }

    const data = archive.length > 0 ? file : null;

    this.setState({
      docSocialContract: data,
    });

    this.handleSubmit();
  };

  handleDocAccountStatement = (archive) => {
    let file = archive[0];

    if (archive.length > 0) {
      delete file["uid"];
    }

    const data = archive.length > 0 ? file : null;

    this.setState({
      docAccountStatement: data,
    });

    this.handleSubmit();
  };

  handleCountry = (e) => {
    let obj = this.props.countryCodes.find((i) => i.code == e);

    let country = {
      name: obj.name,
      code: obj.code,
      prefix: obj.prefix,
    };

    this.setState({
      prefix: obj.prefix,
      countrySeleted: country,
    });

    this.props.form.setFieldsValue({
      city: "",
      state: "",
      postcode: "",
      addressLine1: "",
      addressLine2: "",
      phoneNumber: "",
    });

    this.handleSubmit("country");
  };

  handleCommercialCountry = (e) => {
    let obj = this.props.countryCodes.find((i) => i.code == e);

    let country = {
      name: obj.name,
      code: obj.code,
      prefix: obj.prefix,
    };

    this.setState({
      prefix: obj.prefix,
      commercialCountrySeleted: country,
    });

    this.props.form.setFieldsValue({
      commercialCity: "",
      commercialState: "",
      commercialPostcode: "",
      commercialAddressLine1: "",
      commercialAddressLine2: "",
      commercialPhoneNumber: "",
    });

    this.handleSubmit("country");
  };

  getPrefix = (code) => {
    let prefixCode =
      this.props.countryCodes &&
      this.props.countryCodes.find((i) => i.code == code);

    if (prefixCode) {
      return prefixCode.prefix || "";
    } else {
      return "";
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    if (!this.props.partner || !this.props.partner.company)
      return <FormSkeleton />;

    const { partner } = this.props;

    return (
      <Form onSubmit={this.handleSubmitSave}>
        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Perfil da sua empresa")}
              </TitleMiddleLine>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Logo - 113 x 113 pixels")}>
                {getFieldDecorator("logoImage", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.logoImage,
                })(
                  <UploadFile
                    name="logoImage"
                    nameField="logoImage"
                    getDataUpload={this.getDataUpload}
                    idContext={this.props.partner.partnerId}
                    imgUrl={
                      this.props.partner && this.props.partner.company.logoImage
                    }
                  />
                )}

                <p className="helpText">
                  {I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}
                </p>
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Imagem Capa - 1280 x 720 pixels")}>
                {getFieldDecorator("introImage", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.introImage,
                })(
                  <UploadFile
                    name="introImage"
                    nameField="introImage"
                    getDataUpload={this.getDataUpload}
                    idContext={this.props.partner.partnerId}
                    imgUrl={
                      this.props.partner.company &&
                      this.props.partner.company.introImage
                    }
                    cover={true}
                    coverDescription="Formato ideal: 1280px X 770px"
                  />
                )}
                <p className="helpText">
                  {I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}
                </p>
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item
                label={I18n.get("Link Video Reel (Youtube, Vimeo ou Dropbox)")}
              >
                {getFieldDecorator("introVideo", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.introVideo
                      ? this.props.partner.company.introVideo
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("introVideo")}
                    onChange={() => this.handleSubmit("introVideo")}
                  />
                )}
                <p className="helpText">
                  {I18n.get("o video inserido aqui substitui a imagem de capa")}
                </p>
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Imagem Header - 1280 x 220 pixels")}>
                {getFieldDecorator("headerImage", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.headerImage,
                })(
                  <UploadFile
                    name="headerImage"
                    nameField="headerImage"
                    getDataUpload={this.getDataUpload}
                    idContext={this.props.partner.partnerId}
                    imgUrl={
                      this.props.partner.company &&
                      this.props.partner.company.headerImage
                    }
                    cover={true}
                    coverDescription="Formato ideal: 1280px X 220px"
                  />
                )}
                <p className="helpText">
                  {I18n.get("Enviar somente uma imagem do tipo JPG ou PNG.")}
                </p>
              </Form.Item>
            </Col>

            <Col sm={24}>
              <p className="descriptionPage">
                {I18n.get("Veja preview do seu perfil:")}{" "}
                <Link
                  target="_blank"
                  to={`/parceiro/cadastro/preview-card/${this.props.partnerId}`}
                >
                  <Button
                    type="primary"
                    shape="round"
                    style={{ float: "right" }}
                  >
                    <Icon type="eye" theme="filled" />
                    {I18n.get("Visualizar Perfil")}
                  </Button>
                </Link>
              </p>
              <p></p>
            </Col>
          </Row>
        </CardBorder>
        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>{I18n.get("Dados da empresa")}</TitleMiddleLine>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Nome fantasia")}>
                {getFieldDecorator("name", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.name
                      ? this.props.partner.company.name
                      : null,
                })(<Input onBlur={() => this.handleSubmit("name")} />)}
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Razão Social")}>
                {getFieldDecorator("registrationName", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.registrationName
                      ? this.props.partner.company.registrationName
                      : null,
                })(
                  <Input onBlur={() => this.handleSubmit("registrationName")} />
                )}
              </Form.Item>
            </Col>

            <Col sm={12}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("CNPJ")}>
                  {getFieldDecorator("registrationId", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.registrationId
                        ? this.props.partner.company.registrationId
                        : null,
                  })(
                    <Input onBlur={() => this.handleSubmit("registrationId")} />
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("CNPJ")}>
                  {getFieldDecorator("registrationId", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.registrationId
                        ? this.props.partner.company.registrationId
                        : null,
                  })(
                    <MaskedInput
                      mask="00.000.000/0000-00"
                      size="14"
                      onBlur={() => this.handleSubmit("registrationId")}
                    />
                    // <Input
                    //     onBlur={() => this.handleSubmit()}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>
            {/*
                        <Col sm={8}>
                            <Form.Item label="Minha empresa tem menos de 6 meses">
                                {getFieldDecorator('isNewCompany', {
                                    rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    initialValue: this.props.partner.company && this.props.partner.company.isNewCompany ? true : false,
                                    valuePropName: 'checked'

                                })(
                                    <Checkbox
                                        onChange={e => this.onChangeIsNew(e)}
                                    >
                                        Sim, minha empresa é nova.
                                    </Checkbox>
                                )}
                            </Form.Item>
                        </Col> */}

            <Col sm={12}>
              <Form.Item label={I18n.get("Site")}>
                {getFieldDecorator("website", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.website
                      ? this.props.partner.company.website
                      : null,
                })(<Input onBlur={() => this.handleSubmit("website")} />)}
              </Form.Item>
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Linkedin Corporativo")}>
                {getFieldDecorator("linkedin", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.linkedin
                      ? this.props.partner.company.linkedin
                      : null,
                })(<Input onBlur={() => this.handleSubmit("linkedin")} />)}
              </Form.Item>
            </Col>

            <Col sm={24}>
              <Form.Item
                label={I18n.get(
                  "Selecione até 3 palavras-chave que reforcem o perfil da sua empresa:"
                )}
              >
                {getFieldDecorator("tags", {
                  rules: [
                    { required: false, message: "Campo obrigatório!" },
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          if (value.length > 3) {
                            const tagsValues =
                              this.props.form.getFieldValue("tags");
                            const arrayTags = [
                              tagsValues[0],
                              tagsValues[1],
                              tagsValues[2],
                            ];
                            this.props.form.setFieldsValue({
                              tags: arrayTags,
                            });
                          } else if (value.length <= 3) {
                            callback();
                          }
                        }
                        return;
                      },
                    },
                  ],
                  initialValue:
                    (this.props.partner &&
                      this.props.partner.company &&
                      this.props.partner.company.tags) ||
                    [],
                })(
                  <Select
                    showSearch={true}
                    mode="tags"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    onBlur={() => this.onChangeAdress("tags")}
                    onSelect={() => this.onChangeAdress("tags")}
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={24}>
              <Form.Item
                label={
                  <span>
                    {I18n.get("Conte um pouco da sua empresa")}
                    <Tooltip
                      title={I18n.get(
                        "Uma pequena apresentação em poucas palavras que venda seu trabalho"
                      )}
                    >
                      <Icon
                        className="iconLabel"
                        type="question-circle"
                        theme="filled"
                      />
                    </Tooltip>
                  </span>
                }
                className="textAreaCounter"
              >
                {getFieldDecorator("description", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    (this.props.partner.company &&
                      this.props.partner.company.description) ||
                    "",
                })(
                  <TextArea
                    onBlur={() => this.handleSubmit("description")}
                    rows={4}
                    maxLength={500}
                  />
                )}
              </Form.Item>
              <TextCounter
                counter={getFieldValue("description").length}
                limit={500}
                min={100}
              />
            </Col>
          </Row>
        </CardBorder>
        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Representatividade e diversidade")}
              </TitleMiddleLine>
            </Col>

            <Col sm={24}>
              <p>
                {I18n.get(
                  "Gostaríamos de entender como é composta a sua equipe. Cite abaixo a presença de pessoas negras, indígenas, portadores de deficiência, homens e mulheres trans, homossexuais e mulheres cis em cargos de liderança e no time de colaboradores."
                )}
              </p>
              <br />
            </Col>

            <Fragment>
              <Col sm={24}>
                <Form.Item label={I18n.get("Sócios_register")}>
                  {getFieldDecorator("diversityShareHolders", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props?.partner?.company?.diversityShareHolders || [],
                  })(
                    <Select
                      showSearch={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      disabled={
                        this.props.partner.company &&
                        this.props.partner.company.diversityPreferNotDeclare
                      }
                      onSelect={() =>
                        this.handleSubmit(
                          "company.diversityShareHolders",
                          "diversityShareHolders"
                        )
                      }
                      onBlur={() =>
                        this.handleSubmit(
                          "company.diversityShareHolders",
                          "diversityShareHolders"
                        )
                      }
                    >
                      {this.props.diversity &&
                        this.props.diversity.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item label={I18n.get("Em cargos de liderança")}>
                  {getFieldDecorator("diversityLeadership", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.diversityLeadership
                        ? this.props.partner.company.diversityLeadership
                        : [],
                  })(
                    <Select
                      showSearch={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      disabled={
                        this.props.partner.company &&
                        this.props.partner.company.diversityPreferNotDeclare
                      }
                      onBlur={() => this.handleSubmit("diversityLeadership")}
                      onDeselect={() =>
                        this.handleSubmit("diversityLeadership")
                      }
                    >
                      {this.props.diversity &&
                        this.props.diversity.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col sm={24}>
                <Form.Item label={I18n.get("No time de colaboradores")}>
                  {getFieldDecorator("diversityOwned", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.diversityOwned
                        ? this.props.partner.company.diversityOwned
                        : [],
                  })(
                    <Select
                      showSearch={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      tokenSeparators={[","]}
                      disabled={
                        this.props.partner.company &&
                        this.props.partner.company.diversityPreferNotDeclare
                      }
                      onBlur={() => this.handleSubmit("diversityOwned")}
                      onDeselect={() =>
                        this.handleSubmit("diversityLeadership")
                      }
                    >
                      {this.props.diversity &&
                        this.props.diversity.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Fragment>

            <Col sm={12}>
              <Form.Item>
                {getFieldDecorator("diversityPreferNotDeclare", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.diversityPreferNotDeclare &&
                    this.props.partner.company.diversityPreferNotDeclare
                      ? true
                      : false,
                  valuePropName: "checked",
                })(
                  <Checkbox
                    onChange={() =>
                      this.handleSubmit("diversityPreferNotDeclare")
                    }
                  >
                    <b>{I18n.get("Prefiro não declarar")}</b>{" "}
                    <Tooltip
                      title={I18n.get(
                        "As informações acima ficarão disponíveis no seu perfil, clique aqui se preferir não declarar"
                      )}
                    >
                      <Icon type="question-circle" theme="filled" />
                    </Tooltip>
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>{I18n.get("Endereço do CNPJ")}</TitleMiddleLine>
            </Col>

            <Col sm={6}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("country", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.country
                      ? this.props.partner.company.country.code
                      : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    onSelect={(e) => this.handleCountry(e)}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();
                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={6}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("postcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.postcode
                        ? this.props.partner.company.postcode
                        : null,
                  })(<Input onBlur={(e) => this.handleSubmit("postcode")} />)}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("postcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.postcode
                        ? this.props.partner.company.postcode
                        : null,
                  })(
                    <MaskedInput
                      mask="00000-000"
                      onBlur={(e) => this.onPostcodeSearchClick(e, "postcode")}
                    />
                  )}
                </Form.Item>
              )}
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Endereço")}>
                {getFieldDecorator("addressLine1", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.addressLine1
                      ? this.props.partner.company.addressLine1
                      : null,
                })(<Input onBlur={() => this.handleSubmit("addressLine1")} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Form.Item label={I18n.get("Complemento")}>
                {getFieldDecorator("addressLine2", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.addressLine2
                      ? this.props.partner.company.addressLine2
                      : null,
                })(<Input onBlur={() => this.handleSubmit("addressLine2")} />)}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Cidade")}>
                {getFieldDecorator("city", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.city
                      ? this.props.partner.company.city
                      : null,
                })(<Input onBlur={() => this.handleSubmit("city")} />)}
              </Form.Item>
            </Col>

            <Col sm={8}>
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("state", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.state
                        ? this.props.partner.company.state
                        : null,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onChange={() => this.handleSubmit("state")}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {constants.STATES &&
                        constants.STATES.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("state", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.state
                        ? this.props.partner.company.state
                        : null,
                  })(<Input onBlur={() => this.handleSubmit("state")} />)}
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.phoneNumber
                        ? this.props.partner.company.phoneNumber
                        : null,
                  })(
                    <Input
                      addonBefore={
                        this.props.partner &&
                        this.props.partner.company &&
                        this.props.partner.company.country &&
                        this.props.partner.company.country.prefix
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.phoneNumber
                        ? this.props.partner.company.phoneNumber
                        : null,
                  })(
                    <Input
                      addonBefore={
                        this.props.partner &&
                        this.props.partner.company &&
                        this.props.partner.company.country &&
                        this.props.partner.company.country.prefix
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                    // <MaskedInput
                    //     addonBefore={this.props.partner && this.props.partner.company && this.props.partner.company.country && this.props.partner.company.country.prefix}
                    //     mask="(11) 1111-1111"
                    //     size="14"
                    //     onBlur={() => this.handleSubmit('phoneNumber')}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Endereço do comercial")}
              </TitleMiddleLine>
            </Col>

            <Col sm={24}>
              <Form.Item label={I18n.get("O mesmo da empresa")}>
                {getFieldDecorator("copyAddressToCommercialAddress", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.copyAddressToCommercialAddress
                      ? true
                      : false,
                  valuePropName: "checked",
                })(
                  <Checkbox
                    onChange={(e) =>
                      this.onChangeAdress("copyAddressToCommercialAddress")
                    }
                  >
                    {I18n.get("Sim, é o mesmo endereço do CNPJ")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>

            <Col sm={6}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("commercialCountry", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.commercialCountry
                      ? this.props.partner.company.commercialCountry.code
                      : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    onSelect={(e) => this.handleCommercialCountry(e)}
                    showSearch={true}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();
                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={6}>
              {getFieldValue("commercialCountry") != "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("commercialPostcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.commercialPostcode
                        ? this.props.partner.company.commercialPostcode
                        : null,
                  })(
                    <Input
                      onBlur={(e) => this.handleSubmit("commercialPostcode")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("commercialCountry") == "BRA" && (
                <Form.Item label={I18n.get("CEP")}>
                  {getFieldDecorator("commercialPostcode", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.commercialPostcode
                        ? this.props.partner.company.commercialPostcode
                        : null,
                  })(
                    <MaskedInput
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      mask="00000-000"
                      onBlur={(e) =>
                        this.onPostcodeSearchClick(e, "commercialPostcode")
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Col>
            {/* <Col sm={4}>
                            <Form.Item className="labelClean" label="&nbsp;">
                                <Button
                                    type="primary"
                                    shape="round"
                                    disabled={getFieldValue('copyAddressToCommercialAddress')}
                                >Buscar</Button>
                            </Form.Item>
                        </Col> */}
            <Col sm={12}>
              <Form.Item label={I18n.get("Endereço")}>
                {getFieldDecorator("commercialAddressLine1", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.state.commercialAddressLine1 ||
                    (this.props.partner.company &&
                      this.props.partner.company.commercialAddressLine1)
                      ? this.props.partner.company.commercialAddressLine1
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("commercialAddressLine1")}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <Form.Item label={I18n.get("Complemento")}>
                {getFieldDecorator("commercialAddressLine2", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.state.commercialAddressLine2 ||
                    (this.props.partner.company &&
                      this.props.partner.company.commercialAddressLine2)
                      ? this.props.partner.company.commercialAddressLine2
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("commercialAddressLine2")}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Cidade")}>
                {getFieldDecorator("commercialCity", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.state.commercialCity ||
                    (this.props.partner.company &&
                      this.props.partner.company.commercialCity)
                      ? this.props.partner.company.commercialCity
                      : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("commercialCity")}
                    disabled={getFieldValue("copyAddressToCommercialAddress")}
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              {getFieldValue("commercialCountry") == "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("commercialState", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.state.commercialState ||
                      (this.props.partner.company &&
                        this.props.partner.company.commercialState)
                        ? this.props.partner.company.commercialState
                        : null,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onChange={() => this.handleSubmit("commercialState")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {constants.STATES &&
                        constants.STATES.map((item) => {
                          return (
                            <Option value={item.code} key={item.code}>
                              {item.label}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              )}
              {getFieldValue("commercialCountry") != "BRA" && (
                <Form.Item label={I18n.get("Estado")}>
                  {getFieldDecorator("commercialState", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.state.commercialState ||
                      (this.props.partner.company &&
                        this.props.partner.company.commercialState)
                        ? this.props.partner.company.commercialState
                        : null,
                  })(
                    <Input
                      onBlur={() => this.handleSubmit("commercialState")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                    />
                  )}
                </Form.Item>
              )}
            </Col>

            <Col sm={8}>
              {getFieldValue("commercialCountry") != "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("commercialPhoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.commercialPhoneNumber
                        ? this.props.partner.company.commercialPhoneNumber
                        : null,
                  })(
                    <Input
                      addonBefore={
                        this.props.partner.company &&
                        this.props.partner.company.commercialCountry &&
                        this.props.partner.company.commercialCountry.prefix
                          ? this.props.partner.company.commercialCountry.prefix
                          : ""
                      }
                      onBlur={() => this.handleSubmit("commercialPhoneNumber")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("commercialCountry") == "BRA" && (
                <Form.Item label={I18n.get("Telefone")}>
                  {getFieldDecorator("commercialPhoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.partner.company &&
                      this.props.partner.company.commercialPhoneNumber
                        ? this.props.partner.company.commercialPhoneNumber
                        : null,
                  })(
                    <Input
                      addonBefore={
                        this.props.partner.company &&
                        this.props.partner.company.commercialCountry &&
                        this.props.partner.company.commercialCountry.prefix
                          ? this.props.partner.company.commercialCountry.prefix
                          : ""
                      }
                      onBlur={() => this.handleSubmit("commercialPhoneNumber")}
                      disabled={getFieldValue("copyAddressToCommercialAddress")}
                    />
                    // <MaskedInput
                    //     addonBefore={this.props.partner.company && this.props.partner.company.commercialCountry && this.props.partner.company.commercialCountry.prefix ? this.props.partner.company.commercialCountry.prefix : ""}
                    //     mask="(11) 1111-1111"
                    //     size="14"
                    //     disabled={getFieldValue('copyAddressToCommercialAddress')}
                    //     onBlur={() => this.handleSubmit('commercialPhoneNumber')}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
        </CardBorder>

        <CardBorder>
          <Col sm={24}>
            <TitleMiddleLine>
              {I18n.get("Documentos essenciais")}
            </TitleMiddleLine>
          </Col>
          <Row>
            <Col sm={6}>
              <Form.Item
                label={
                  <span>
                    {I18n.get("Cartão CNPJ")}{" "}
                    {this.props.partner.signupLocale == "es" && (
                      <Tooltip
                        title={I18n.get(
                          "Carta del banco que acredite que la empresa no tiene pendientes ficales/Informe de antecedentes comerciales del Nosis/etc"
                        )}
                      >
                        <Icon
                          className="iconLabel"
                          type="question-circle"
                          theme="filled"
                        />
                      </Tooltip>
                    )}
                  </span>
                }
              >
                {getFieldDecorator("docCnpjCard", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                })(
                  <FileUploadList
                    getDataUpload={this.handleDocCnpjCard}
                    name="docCnpjCard"
                    uploadId={this.props.partner.partnerId}
                    get2={this.props.get2 || false}
                    fileListGet={
                      this.props.partner.company &&
                      this.props.partner.company.docCnpjCard
                        ? [
                            {
                              ...this.props.partner.company.docCnpjCard,
                              uid: this.props.partner.company.docCnpjCard.id,
                            },
                          ]
                        : []
                    }
                    limit={1}
                    output="object"
                  />
                )}
              </Form.Item>
            </Col>

            {/* ##### Somente Brasil valida todos os documentos ##### */}
            {this.props.partner.signupLocale == "pt" && (
              <>
                <Col sm={6}>
                  <Form.Item
                    label={
                      <span>
                        {I18n.get("Certidão negativa")}{" "}
                        <Tooltip
                          title={I18n.get(
                            "Para a consulta da CND pessoa jurídica, basta acessar o portal da receita e informar o seu CNPJ. Caso não haja pendências, será gerada a certidão negativa."
                          )}
                        >
                          <Icon
                            className="iconLabel"
                            type="question-circle"
                            theme="filled"
                          />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("docNegativeCertificate", {
                      rules: [
                        { required: false, message: "Campo obrigatório!" },
                      ],
                    })(
                      <FileUploadList
                        getDataUpload={this.handleDocNegativeCertificate}
                        name="docNegativeCertificate"
                        uploadId={this.props.partner.partnerId}
                        get2={this.props.get2 || false}
                        fileListGet={
                          this.props.partner.company &&
                          this.props.partner.company.docNegativeCertificate
                            ? [
                                {
                                  ...this.props.partner.company
                                    .docNegativeCertificate,
                                  uid: this.props.partner.company
                                    .docNegativeCertificate.id,
                                },
                              ]
                            : []
                        }
                        limit={1}
                        output="object"
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item label={I18n.get("Contrato social")}>
                    {getFieldDecorator("docSocialContract", {
                      rules: [
                        { required: false, message: "Campo obrigatório!" },
                      ],
                    })(
                      <FileUploadList
                        getDataUpload={this.handleDocSocialContract}
                        name="docSocialContract"
                        uploadId={this.props.partner.partnerId}
                        get2={this.props.get2 || false}
                        fileListGet={
                          this.props.partner.company &&
                          this.props.partner.company.docSocialContract
                            ? [
                                {
                                  ...this.props.partner.company
                                    .docSocialContract,
                                  uid: this.props.partner.company
                                    .docSocialContract.id,
                                },
                              ]
                            : []
                        }
                        limit={1}
                        output="object"
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item
                    label={
                      <span>
                        {I18n.get("Declaração correntista")}{" "}
                        <Tooltip
                          title={I18n.get(
                            "Use um documento da conta PJ da empresa. Pode ser um extrato ou uma carta do gerente do banco."
                          )}
                        >
                          <Icon
                            className="iconLabel"
                            type="question-circle"
                            theme="filled"
                          />
                        </Tooltip>
                      </span>
                    }
                  >
                    {getFieldDecorator("docAccountStatement", {
                      rules: [
                        { required: false, message: "Campo obrigatório!" },
                      ],
                    })(
                      <FileUploadList
                        getDataUpload={this.handleDocAccountStatement}
                        name="docAccountStatement"
                        uploadId={this.props.partner.partnerId}
                        get2={this.props.get2 || false}
                        fileListGet={
                          this.props.partner.company &&
                          this.props.partner.company.docAccountStatement
                            ? [
                                {
                                  ...this.props.partner.company
                                    .docAccountStatement,
                                  uid: this.props.partner.company
                                    .docAccountStatement.id,
                                },
                              ]
                            : []
                        }
                        limit={1}
                        output="object"
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </CardBorder>

        <CardBorder>
          <Row>
            <Col sm={8}>
              <Form.Item
                label={
                  <span>
                    {I18n.get("Faixa gerencial anual")}
                    <Tooltip
                      title={I18n.get(
                        "Precisamos saber se a sua empresa gerencia a verba de algum cliente. Esse dado é confidêncial e só."
                      )}
                    >
                      <Icon
                        className="iconLabel"
                        type="question-circle"
                        theme="filled"
                      />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("managerYearlyRange", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.managerYearlyRange
                      ? this.props.partner.company.managerYearlyRange
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    // onChange={() => this.handleSubmit("managerYearlyRange")}
                    onSelect={() => this.handleSubmit("managerYearlyRange")}
                    showSearch={true}
                  >
                    <Option value="" key=""></Option>
                    {this.props.partnerManagerRanges &&
                      this.props.partnerManagerRanges.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Faixa tamanho do time")}>
                {getFieldDecorator("teamSizeRange", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.teamSizeRange
                      ? this.props.partner.company.teamSizeRange
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    // onChange={() => this.handleSubmit("teamSizeRange")}
                    onSelect={() => this.handleSubmit("teamSizeRange")}
                    showSearch={true}
                  >
                    <Option value="" key=""></Option>
                    {this.props.partnerTeamSizeRanges &&
                      this.props.partnerTeamSizeRanges.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("Faturamento anual")}>
                {getFieldDecorator("revenueRange", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.revenueRange
                      ? this.props.partner.company.revenueRange
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    // onChange={() => this.handleSubmit("revenueRange")}
                    onSelect={() => this.handleSubmit("revenueRange")}
                    showSearch={true}
                  >
                    <Option value="" key=""></Option>
                    {this.props.partnerRevenueRanges &&
                      this.props.partnerRevenueRanges.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={24}>
              <br />
              <Form.Item label="">
                {getFieldDecorator("isFulltime", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.isFulltime
                      ? true
                      : false,
                  valuePropName: "checked",
                })(
                  <Checkbox onChange={() => this.onChangeAdress("isFulltime")}>
                    <b className="boldBlack">
                      {I18n.get(
                        "Confirmo que a boutique se dedica integralmente a projetos de seus próprios clientes."
                      )}
                    </b>
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>
        </CardBorder>

        <Row className="RowSection">
          <Col sm={8}></Col>
        </Row>
      </Form>
    );
  }
}

const MainFormCompany = Form.create({})(FormCompany);

export default MainFormCompany;
