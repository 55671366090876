
import React from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TooltipField from '../form/tooltip-field';
import { ClientProjectStatus } from '../../model';


const mapStateToProps = state => {
    return state;
};
const mapDispatchToProps = dispatch => {
    return {}
};

class Steps extends React.Component {
    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {

        const { project = {}, hidden = [] } = this.props;

        const { projectId } = project;

        const items = [
            {
                index: 1,
                title: I18n.get("Resumo"),
                color: "#F0E2A1",
                link: `/projetos/${projectId}/resumo`
            },
            {
                index: 2,
                title: I18n.get("Briefing"),
                color: "#FFC5B9",
                link: `/projetos/${projectId}/briefing`
            },
            {
                index: 3,
                title: I18n.get("Arquivos"),
                color: "#BAB1D1",
                link: `/projetos/${projectId}/arquivos`
            },
            {
                index: 4,
                title: I18n.get("Proposta"),
                color: "#C7E5F2",
                link: `/projetos/${projectId}/proposta`
            },
            {
                index: 5,
                title: I18n.get("#SPA"),
                color: "#858585",
                link: `/projetos/${projectId}/faturamento`
            },
            {
                index: 6,
                title: I18n.get("Extras"),
                color: "#F0E2A1",
                link: `/projetos/${projectId}/extras`
            },
            {
                index: 7,
                title: I18n.get("Avaliação"),
                color: "#BAB1D1",
                link: `/projetos/${projectId}/avaliacao`
            }
        ];

        const { active } = this.props





        if (this.props.status !== ClientProjectStatus.Finished && this.props.project.status !== ClientProjectStatus.Finished) {
            items.pop();
        }



        let partnerName = '';

        if (this.props.reference && this.props.reference.partnerName) {

            partnerName = this.props.reference.partnerName;
        }

        let txt = '';

        return (
            <div>
                <ul id="partner-signup-steps" className="reference-signup-steps">
                    {items.map(function (item) {

                        if (hidden.indexOf(item.index) > -1) return null;

                        const lineStyle = {
                            background: item.color,
                        };
                        return (<li key={item.index} className={(active && item.index === active && "progress-bar--item is-active") || "progress-bar--item"}>
                            <h3>
                                <b className="yes-mobile">{item.index}</b>
                            </h3>
                            <Link to={item.link} className="progress-bar--menu-item" disabled={!projectId}>
                                <span style={lineStyle}></span>
                                <strong className="is-not-mobile">{item.title}</strong>
                            </Link>
                        </li>)
                    })}
                </ul>
            </div>)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
