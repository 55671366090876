import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import * as S from "./styles";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";


import { ButtonBpool } from "../ButtonBpool";

import Config from "../../../../config";
import IcoNuvem from "../../../../svg/nuvem.svg";

import { notification } from "antd";

export const BoxUpload = ({ sendUpload, limit, uploadId, loadingFiles, disabled }) => {
  const [itemUploadFile, setItemUpload] = useState({});
  const [loading, setLoading] = useState(false);
  const [progress, setProgrees] = useState(0);

  const get2 = useSelector((state) => state.get2);
  const authUser = useSelector((state) => state.auth);

  const onDrop = async ({ onSuccess, onError, file }) => {
    //EndPoint para pegar os dados de autorização
    const EndpointGetAuthToUpload = get2
      ? Config.UPLOAD_URL2
      : Config.UPLOAD_URL;

    let fileSplit = file.name.split(".");
    let fileSafe = file.name.replace(/[^a-z0-9]/gi, "-");
    let fileNew = `${fileSafe}_${uuidv4()}`;
    if (fileSplit.length > 1) {
      fileNew = fileNew + "." + fileSplit[fileSplit.length - 1];
    }

    const isLt2M = file.size / 1024 / 1024 < 100;

    if (!isLt2M) {
      notification.error({
        message: I18n.get("ops! Algo deu errado."),
        description: I18n.get("Tamanha máximo permitido 100mb"),
        duration: 3,
      });
      // return isLt2M;
    } else {
      setLoading(true);

      let id = uploadId; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

      let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

      //Access Token vindo do Cognito
      let authHeader = {
        headers: { Authorization: "Bearer " + authUser?.jwtToken },
      };

      //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
      let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${get2 ? "&t=pleader" : ""
        }`;

      //Pega autorização para upload
      axios
        .get(preSignUrl, authHeader)
        .then((resp) => {
          let { url, fields } = resp.data;

          let formData = new FormData();

          //Dados recebidos para autorizar o upload do arquivo
          Object.keys(fields).forEach((key) => {
            formData.append(key, fields[key]);
          });

          //arquivo

          formData.append("file", file);

          let percentCompleted = "";

          var config = {
            headers: { "content-type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              setProgrees(percentCompleted);

              return percentCompleted;
            },
          };

          //tendo sucesso, fazer POST do arquivo com os dados de autorização
          axios
            .post(url, formData, config)
            .then(async (response) => {
              let data = new Date();

              let urlClean =
                "https://" + resp.data.url.split("s3.sa-east-1.amazonaws.com/")[1] + "/";

              let itemUpload = {
                id: uuidv4(),
                uid: uuidv4(),
                name: file.name,
                url: urlClean + resp.data.fields.key,
                size: file.size,
                type: file.type,
                uploadedByUsername: authUser.username,
                uploadedByName: authUser.name,
                uploadedDate: data,
              };

              setLoading(false);
              setProgrees("0");

              sendUpload(itemUpload);

              onSuccess(null, file);
            })
            .catch((error) => {

              notification.error({
                message: I18n.get("ops! Algo deu errado."),
                description: I18n.get("Tente novamente por favor."),
                duration: 3,
              });

              console.log("error", error);

              setLoading(false);
              setProgrees("0");
            });
        })
        .catch((error) => {
          //Erro Pegar Autorizaçõ

          if (error.response.status === 401) {

            notification.error({
              message: I18n.get("ops! Algo deu errado."),
              description: I18n.get("Tente novamente por favor."),
              duration: 3,
            });
          } else {

            notification.error({
              message: I18n.get("ops! Algo deu errado."),
              description: I18n.get("Tente novamente por favor."),
              duration: 3,
            });
          }

          setProgrees("0");
          setLoading(false);
        });
    }
  };

  return (
    <S.DraggerFiles disabled={disabled} fileList={[]} customRequest={(e) => onDrop(e)}>
      <p>
        <img src={IcoNuvem} />
      </p>
      <S.LabelText>{I18n.get("Arraste e solte seu arquivo ou:")}</S.LabelText>

      <S.Buttons>
        <p>
          <ButtonBpool
            text={I18n.get("Meu Computador")}
            theme="primary"
            loading={loading || loadingFiles}
            full={true}
            disabled={disabled}
          />
        </p>
        {/* <p>
          <Button
            text={I18n.get("BPool Drive")}
            theme="primary"
            loading={loading || loadingFiles}
            outline={true}
            full={true}
          />
        </p> */}
      </S.Buttons>
    </S.DraggerFiles>
  );
};
