
const Config = {
    WEBSOCKET: '',

    AWS_COGNITO_REGION      : "sa-east-1",
    AWS_COGNITO_USERPOOL_ID : "sa-east-1_IwbBWCPnc",
    AWS_COGNITO_CLIENT_ID   : "3iqcrghpeuoul246838ulhmlci",

    API_BASE_URL: "https://2a97kzpsnd.execute-api.sa-east-1.amazonaws.com/Prod/api",

  // APP  https://2a97kzpsnd.execute-api.sa-east-1.amazonaws.com/Prod/api
  // DEV1 https://clc1hbc4pe.execute-api.sa-east-1.amazonaws.com/Prod/api

  UPLOAD_URL  : "https://hy9ozmm0yh.execute-api.sa-east-1.amazonaws.com/prod/geturl",
  UPLOAD_URL2 : "https://hy9ozmm0yh.execute-api.sa-east-1.amazonaws.com/prod/geturl2",

    URL_PLATFORM: "https://ollo.bpool.co",

    applyClientDiscount: false,

    moduloEvento: true,
    moduloCriacao: true,
    moduloFaturamento: true,
    moduloDahs2: true,
    moduloBPitch: false,
    moduloChangeLanguage: true,

    moduloEventoLatam: false,
    moduloBPitchLatam: false,
    moduloDashEventLatam: false,

    moduloWhatif: false,
    moduloMasterSenior: false,
    moduloSuggestProject: true,
    moduloSuggestProjectProposal: true,
    moduloSuggestAsset: true,
    moduloDashEvent: false,
    moduloNewRegister: true,
    moduloNewRegisterClient: true,
    moduloFaqs: false,

    showChat: true,
    showMeet: true,

    moduloNewBpay: true,
  };

  export default Config;
