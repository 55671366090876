import React from "react";
import { I18n } from "aws-amplify";
import { Collapse } from "antd";
import { Info } from "./Info";
import constants from "../../../../../../../../../constants";
import { DownloadNF } from "../../../../DownloadNF";
import * as S from "./styles";

const { Panel } = Collapse;

export const InfosBillingAccount = ({ data, loading }) => {
  const whatBank = (bankId) => {
    const bank = constants?.BANK_IDS?.find((bank) => bank?.code === bankId);
    if (bank) {
      return bank?.label;
    } else {
      return bankId;
    }
  };

  const renderTitle = (paymentMethod) => {
    switch (paymentMethod) {
      case "PIX":
        return (
          <strong>{`${I18n.get("Forma de recebimento")} - ${I18n.get(
            "PIX"
          )}`}</strong>
        );
        break;
      case "TRANSFER":
        return (
          <strong>{`${I18n.get("Forma de recebimento")} - ${I18n.get(
            "Transferência"
          )}`}</strong>
        );
        break;
      case "SWIFT":
        return (
          <strong>{`${I18n.get("Forma de recebimento")} - ${I18n.get(
            "SWIFT"
          )}`}</strong>
        );
        break;
      case "SLIP":
        return (
          <strong>{`${I18n.get("Forma de recebimento")} - ${I18n.get(
            "Boleto"
          )}`}</strong>
        );
        break;
      default:
        break;
    }
  };
  const renderInfosByPaymentMethod = ({ item }) => {
    switch (item?.paymentMethod) {
      case "PIX":
        return (
          <S.Infos>
            <Info
              label={I18n.get("Chave Pix")}
              info={item?.pix}
              loading={loading}
            />
            <Info
              label={I18n.get("Nome do Beneficiário/Razão Social")}
              info={item?.beneficiaryName}
              loading={loading}
            />
            <Info
              label={I18n.get("Documento do Beneficiário/CNPJ")}
              info={item?.beneficiaryId}
              loading={loading}
            />
          </S.Infos>
        );
        break;
      case "TRANSFER":
        return (
          <S.Infos>
            <Info
              label={I18n.get("Banco")}
              info={whatBank(item?.bankId)}
              loading={loading}
            />
            <Info
              label={I18n.get("Agência")}
              info={item?.bankBranch}
              loading={loading}
            />
            <Info
              label={I18n.get("Conta")}
              info={item?.bankAccount}
              loading={loading}
            />
            <Info
              label={I18n.get("Nome do Beneficiário/Razão Social")}
              info={item?.beneficiaryName}
              loading={loading}
            />
            <Info
              label={I18n.get("Documento do Beneficiário/CNPJ")}
              info={item?.beneficiaryId}
              loading={loading}
            />
          </S.Infos>
        );
        break;
      case "SWIFT":
        return (
          <>
            <div>
              <strong style={{ fontSize: 16 }}>
                {I18n.get("Beneficiário")}
              </strong>
            </div>
            <S.Infos>
              <Info
                label={I18n.get("Nome do Beneficiário/Razão Social")}
                info={item?.beneficiaryName}
                loading={loading}
              />
              <Info
                label={I18n.get("Documento do Beneficiário/CNPJ")}
                info={item?.beneficiaryId}
                loading={loading}
              />
              <Info
                label={I18n.get("Dirección de Beneficiário")}
                info={item?.beneficiaryDirectionBeneficiary}
                loading={loading}
              />
              <Info
                label={I18n.get("Número de cuenta")}
                info={item?.beneficiaryAccountNumber}
                loading={loading}
              />
              <Info
                label={I18n.get("Clave interbancaria")}
                info={item?.beneficiaryInterbankKey}
                loading={loading}
              />
              <Info
                label={I18n.get("SWIFT/ABA/BIC/IBAN")}
                info={item?.beneficiarySWIFTABABICIBAN}
                loading={loading}
              />
              <Info
                label={I18n.get("Nombre de Banco")}
                info={item?.beneficiaryBank}
                loading={loading}
              />
              <Info
                label={I18n.get("Dirección del Banco")}
                info={item?.beneficiaryBankDirection}
                loading={loading}
              />
            </S.Infos>
            <div>
              <strong style={{ marginTop: 16, fontSize: 16 }}>
                {I18n.get("Intermediário")}
              </strong>
            </div>
            <S.Infos>
              <Info
                label={I18n.get("Dirección de Intermediário")}
                info={item?.intermediateDirectionBeneficiary}
                loading={loading}
              />
              <Info
                label={I18n.get("Número de cuenta")}
                info={item?.intermediateAccountNumber}
                loading={loading}
              />
              <Info
                label={I18n.get("Clave interbancaria")}
                info={item?.intermediateInterbankKey}
                loading={loading}
              />
              <Info
                label={I18n.get("SWIFT/ABA/BIC/IBAN")}
                info={item?.intermediateSWIFTABABICIBAN}
                loading={loading}
              />
              <Info
                label={I18n.get("Nombre de Banco")}
                info={item?.intermediateBank}
                loading={loading}
              />
              <Info
                label={I18n.get("Dirección del Banco")}
                info={item?.intermediateBankDirection}
                loading={loading}
              />
            </S.Infos>
          </>
        );
        break;
      case "SLIP":
        return (
          <>
            <S.Infos>
              <Info
                label={I18n.get("Nome do Beneficiário/Razão Social")}
                info={item?.beneficiaryName}
                loading={loading}
              />
              <Info
                label={I18n.get("Documento do Beneficiário/CNPJ")}
                info={item?.beneficiaryId}
                loading={loading}
              />
            </S.Infos>
            <DownloadNF file={item?.slip} title={I18n.get("Baixar Boleto")} />
          </>
        );
        break;
      default:
        break;
    }
  };

  if (data?.paymentMethod) {
    return (
      <S.Collapse>
        <Panel header={renderTitle(data?.paymentMethod)} key="1">
          {renderInfosByPaymentMethod({ item: data })}
        </Panel>
      </S.Collapse>
    );
  } else {
    return <></>;
  }
};
