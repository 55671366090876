import React from "react";
import { I18n } from "aws-amplify";
import { NavLink } from "react-router-dom";

import ExtrasContainer from "../../containers/extras-container";

import StepsCreative from "../../components/client-project-definition/steps-creative";

import FillForm from "../page-templates/fillForm-logged";

import EditForm from "./extras/_edit-form";

import Steps from "./steps";

class ExtraEditPage extends React.Component {
  constructor(props) {
    super();
    this.state = {
      task: {},
    };
  }

  componentDidMount() {}

  submitForm(e) {}

  render() {
    const { auth = {} } = this.props;

    const displayPO = auth.is_master || auth.is_client_or_client_user;

    const endExtraEnabled =
      auth.is_client_or_client_user &&
      this.props.quotation &&
      this.props.quotation.isApproved != null;

    return (
      <FillForm>
        <div className="hero-body has-background-white">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column is-4">
                <p className="title">
                  {this.props?.project && this.props?.project?.status != 6
                    ? I18n.get("Projeto em Andamento")
                    : I18n.get("Definições do Projeto")}
                  <br />
                  <span className="tag is-warning">
                    {this.props.project && this.props.project.projectName}
                  </span>
                </p>
              </div>
              <div className="column is-8">
                {this.props?.project && this.props?.project?.status == 6 && (
                  <>
                    <StepsCreative
                      active={5}
                      projectId={this.props.match.params.projectId}
                      terms={this.props.clientTermsUpdatedOn}
                    />
                  </>
                )}

                {this.props?.project && this.props?.project?.status != 6 && (
                  <Steps
                    active={6}
                    project={this.props.project}
                    hidden={this.props.auth.is_partner ? [5] : []}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="client-step client-step-1">
          <div
            className="container client-container-1"
            style={{ paddingTop: "210px" }}
          >
            <div className="columns" style={{ margin: 0 }}>
              <div className="column is-3">
                <div className="card">
                  <div className="card-content">
                    <h1 className="title-bpool medium no-bottom">
                      {I18n.get("Extra")}
                    </h1>
                    <span className="tag tagNameExtra">
                      {this.props.extraName}
                    </span>

                    <aside className="menu">
                      <ul className="menu-list">
                        <li style={{ fontWeight: "bold" }}>
                          <NavLink
                            to={`/projetos/${this.props.match.params.projectId}/extras/${this.props.match.params.extraId}/detalhes`}
                            className="is-black"
                          >
                            {I18n.get("Detalhes")}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={`/projetos/${this.props.match.params.projectId}/extras/${this.props.match.params.extraId}/orcamento`}
                            className="is-black"
                          >
                            {I18n.get("Orçamento")}
                          </NavLink>
                        </li>
                        {displayPO && (
                          <>
                            <li>
                              <NavLink
                                to={`/projetos/${this.props.match.params.projectId}/extras/${this.props.match.params.extraId}/po`}
                                className="is-black"
                              >
                                SPA
                              </NavLink>
                            </li>
                            {endExtraEnabled && (
                              <li>
                                <NavLink
                                  to={`/projetos/${this.props.match.params.projectId}/extras/${this.props.match.params.extraId}/finalizar`}
                                  className="is-black"
                                >
                                  {I18n.get("Finalizar")}
                                </NavLink>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </aside>
                  </div>
                </div>
              </div>

              <div className="column is-6 is-offset-1">
                <EditForm {...this.props} />
              </div>
            </div>
          </div>
        </div>
      </FillForm>
    );
  }
}

export default ExtrasContainer(ExtraEditPage);
