import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import SubMenu from "./_sub-menu";

import "../../App.css";
import "../../Site.css";

import logo from "../../image/logoWOtext.svg";

import TopMenu from "./_top-menu";

import Loading from "../pages/loading";

import { AUTH_API } from "../../actions/auth/actions";
import ChatIcon from "../../image/ico-chat-white.svg";
import Footer from "./footer";

import ChatNavBarItem from "../shared/chat-navbar-item";

import PartnerTermsUpdateModal from "../partner/terms-update-modal";

import LanguageNavBarItem from "../shared/language-navbar-item";

import { MenuAdmin } from "../../v2/components/UI/MenuAdmin";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auth: state.auth,
    chat: state.chat,
    client: state.client,
    partner: state.partner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AUTH_API.logout());
    },
  };
};

class FillFormLogged extends Component {
  constructor() {
    super();
    this.state = {};
  }

  onLogoutClick(e) {
    e.preventDefault();

    this.props.logout();
  }

  render() {
    const name =
      (this.props.auth && this.props.auth.name) || this.props.auth.email;

    var firstName = "";

    const isInit = !this.props.auth || this.props.auth.init;

    if (isInit) {
      return <Loading />;
    }
    if (!isInit && name) {
      if (name.indexOf("@") > -1) firstName = name.split("@")[0].toUpperCase();
      else firstName = name.split(" ")[0].toUpperCase();
    }

    const isNotLogged = this.props.auth && !this.props.auth.logged_user;
    const IsLogout = this.props.auth && this.props.auth.is_logout;

    if (
      (this.props?.auth?.init === false && isNotLogged) ||
      (this.props?.auth?.init === true && isNotLogged)
    ) {
      const login = {
        pathname: "/login",
        state: {
          returnUrl: IsLogout ? "" : this.props.location.pathname,
        },
      };

      return <Redirect to={login}></Redirect>;
    }

    // if (this.props.client && this.props.client.customization && this.props.client.customization.cssUrl) {

    //     require('../../custom-css/' + this.props.client.customization.cssUrl);
    // }

    return (
      <>
        <div>
          <section
            className={`hero logged-page ${
              this.props.noFullHeight ? "" : "is-fullheight"
            }`}
          >
            <div
              className={`hero-head ${this.props.noFixed ? "" : "is-fixed"}`}
            >
              <nav className="navbar is-main navbar-main">
                <div className="container">
                  <div
                    id="navbarMenu"
                    className="navbar-menu navbar-menu-new is-active"
                  >
                    <div className="navbar-start">
                      <TopMenu {...this.props} />
                    </div>
                    <div className="navbar-logo">
                      <div className="navbar-item">
                        <a href="/">
                          <img src={logo} alt="Ollo" className="logo" />
                        </a>
                      </div>
                    </div>

                    <div className="navbar-end" style={{ zIndex: 999 }}>
                      <LanguageNavBarItem {...this.props} />

                      <div
                        className="navbar-name"
                        style={{ marginLeft: "20px" }}
                      >
                        <span className="navbar-link-name-not-mobile">
                          {firstName}
                        </span>
                      </div>

                      {/* <ChatNavBarItem {...this.props} /> */}
                    </div>
                  </div>
                </div>
              </nav>
              {/* <SubMenu {...this.props} /> */}
              <MenuAdmin />
            </div>
            <div style={{ padding: "120px 50px" }}>{this.props.children}</div>
          </section>
        </div>

        {this.props.client && this.props.client.id && (
          <a
            className="button bt-bpool fixedChat"
            onClick={this.props.actionBtn}
            href={`${
              window.location.href.split("/#/")[0]
            }/#/cliente/projetos?tab=2`}
          >
            <img src={ChatIcon} /> Chat{" "}
          </a>
        )}
        {this.props.partner && this.props.partner.id && (
          <a
            className="button bt-bpool fixedChat"
            onClick={this.props.actionBtn}
            href={`${
              window.location.href.split("/#/")[0]
            }/#/parceiro/projetos?tab=3`}
          >
            <img src={ChatIcon} /> Chat{" "}
          </a>
        )}
        <Footer {...this.props} />

        {this.props.partner &&
          this.props.partner.id &&
          !this.props.partner.terms && (
            <PartnerTermsUpdateModal {...this.props} />
          )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FillFormLogged));
