import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useLocation, NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { Row, Col } from "antd";
import { useBpayAdmin } from "../../../../../hooks/useBpay/useBpayAdmin.hook";
import LoggedPage from "../../../../../../components/page-templates/loggedAdmin";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import Main from "../../../../../components/UI/Main";
import { Input } from "../../../../../components/UI/Input";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { NotificationOnScreen } from "../../../../../components/UI/NotificationOnScreen";
import { BPaymentsData } from "./BPaymentsData";
import { converData as BPaymentsDataData } from "./BPaymentsData/convertData";
import { InformationsDetails } from "./InformationsDetails";
import { converData as InformationsDetailsData } from "./InformationsDetails/convertData";
import { FiltersBPayments } from "../../components/FiltersBPayments";
import { TableBPayments } from "../../components/TableBPayments";
import * as S from "./styles";

export const AdminBpayDetails = () => {
  let params = useParams();
  let location = useLocation();
  const { getBpayById, updateNFSe } = useBpayAdmin();
  const [nfseValue, setNfseValue] = useState("");
  const [isSendingNFSe, setIsSendingNFSe] = useState(false);
  const [nFSeAlert, setNFSeAlert] = useState({ visible: false, msg: "" });
  const [selectedsBpays, setSelectedsBpays] = useState([]);

  const { data, isLoading, error, refetch } = useQuery(
    `bpayByIdAdmin-${params?.bpayId}`,
    () =>
      getBpayById({
        bpayId: params?.bpayId,
      }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {
        setNfseValue(data?.data?.billing?.bPoolNFSe);
      },
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os dados do BPay."),
          duration: 3,
        });
      },
    }
  );

  const handleUpdateNFSe = async () => {
    try {
      setIsSendingNFSe(true);
      const objSend = {
        id: params?.bpayId,
        nfSe: nfseValue,
      };
      await updateNFSe(objSend);
      setNFSeAlert({
        visible: true,
        msg: I18n.get("NFSe atualizada com sucesso"),
      });
      refetch();
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao atualizar a NFSe."),
        duration: 3,
      });
    } finally {
      setIsSendingNFSe(false);
    }
  };

  const handleSelectedsBpays = (selecteds) => {
    setSelectedsBpays(selecteds);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LoggedPage>
      <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <li>
            <NavLink to="/admin" className="is-black">
              {I18n.get("Admin")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/bpool/bpay" className="is-black">
              {I18n.get("Ollo Pay")}
            </NavLink>
          </li>
          <li className="is-active">
            <NavLink
              to={`/bpool/bpay/detalhes/${params?.bpayId}`}
              className="is-black"
            >
              {I18n.get("Solicitação de Ollo Pay")}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="container">
        <Main bgColor="#fff" padding="30px" style={{ marginTop: 52 }}>
          <Row>
            <Col xs={24}>
              <S.Title>
                <h3>{`${I18n.get("Solicitação de Ollo Pay")} - ${I18n.get(
                  "SPA Nº:"
                )} ${data?.data?.billing?.poNumber || "---"}`}</h3>
              </S.Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <BPaymentsData
                data={BPaymentsDataData(data?.data)}
                isLoading={isLoading}
              />
            </Col>
          </Row>
          <Row style={{ borderBottom: "1px solid #e8e8e8" }}>
            <Col xs={24}>
              <S.NfseWrapper>
                <S.Title>
                  <h3>{I18n.get("NFSe")}</h3>
                </S.Title>
                <S.NfseContent style={{ marginTop: 24 }}>
                  <div style={{ width: 280 }}>
                    <Input
                      placeholder={I18n.get("NFSe")}
                      value={nfseValue}
                      onChange={(value) => setNfseValue(value)}
                      error=""
                      loading={isLoading}
                    />
                  </div>
                  <ButtonBpool
                    text={I18n.get("Atualizar")}
                    theme="tertiary"
                    loading={isSendingNFSe}
                    onClick={handleUpdateNFSe}
                  />
                </S.NfseContent>
                <NotificationOnScreen
                  visible={nFSeAlert.visible}
                  msg={nFSeAlert.msg}
                />
              </S.NfseWrapper>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <InformationsDetails
                data={InformationsDetailsData(data?.data)}
                isLoading={isLoading}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <FiltersBPayments handleSelectedsBpays={selectedsBpays} />
            </Col>
          </Row>
          <Row style={{ marginTop: 32 }}>
            <Col xs={24}>
              <TableBPayments handleSelectedsBpays={handleSelectedsBpays} />
            </Col>
          </Row>
        </Main>
      </div>
    </LoggedPage>
  );
};
