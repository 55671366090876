import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Upload, Icon, Modal, Button, message, notification } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// import { Auth } from 'aws-amplify';

import Config from '../../config';

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {};
}

const { Dragger } = Upload;


let UploadBox = styled(Dragger)`
    position: relative;

    .progressLine {
        background: #ccc;
        display: block;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 3s ease;
    }

}`

class FileUploadButton extends React.Component {
    state = {
        fileList: [],
        itemUpload: {},
        loading: false,
        progress: 0
    };

    handleCancel = () => this.setState({ previewVisible: false });

    // handlePreview = async file => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }

    //     this.setState({
    //         previewImage: file.url || file.preview,
    //         previewVisible: true,
    //     });
    // };

    handleChange = (e) => {
        // this.setState({ fileList });

    };


    sendImages = () => {

        let cleanFile = this.state.itemUpload;

        delete cleanFile['uid'];

        let files = [
            ...this.state.fileList,
            cleanFile
        ]

        if (this.props.limit && files.length > this.props.limit) {
            files = files.slice(- this.props.limit);
        }

        this.props.getDataUpload(files)

    }

    removeSendImages = () => {
        let files = [
            ...this.state.fileList,
        ]

        this.props.getDataUpload(files)

    }

    onDrop = ({ onSuccess, onError, file }) => {
        //EndPoint para pegar os dados de autorização
        const EndpointGetAuthToUpload = this.props.get2 ? Config.UPLOAD_URL2 : Config.UPLOAD_URL;



        let fileSplit = file.name.split('.');
        let fileSafe = file.name.replace(/[^a-z0-9]/gi, '-');
        let fileNew = `${fileSafe}_${uuidv4()}`;
        if (fileSplit.length > 1) {
            fileNew = fileNew + '.' + fileSplit[fileSplit.length - 1];
        }

        const isLt2M = file.size / 1024 / 1024 < 100;

        if (!isLt2M) {
            notification.error({
                message: I18n.get('ops! Algo deu errado.'),
                description: I18n.get('Tamanha máximo permitido 100mb'),
                duration: 3
            });
            // return isLt2M;
        } else {


            this.setState({
                loading: true
            });

            let id = this.props.uploadId ? this.props.uploadId : "bpool"; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

            let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

            //Access Token vindo do Cognito
            let authHeader = { headers: { Authorization: 'Bearer ' + this.props.auth.jwtToken } };

            //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
            let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${this.props.get2 ? "&t=pleader" : ""}`;

            //Pega autorização para upload
            axios.get(preSignUrl, authHeader).then((resp) => {

                let { url, fields } = resp.data;

                let formData = new FormData();

                //Dados recebidos para autorizar o upload do arquivo
                Object.keys(fields).forEach(key => {
                    formData.append(key, fields[key])
                });

                //arquivo

                formData.append('file', file);

                let percentCompleted = "";

                var config = {
                    headers: { 'content-type': 'multipart/form-data' },
                    onUploadProgress: (progressEvent) => {
                        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                        this.setState({
                            progress: percentCompleted,
                        })

                        return percentCompleted
                    }
                };

                //tendo sucesso, fazer POST do arquivo com os dados de autorização
                axios.post(url, formData, config).then((response) => {

                    let data = new Date();

                    let urlClean = "https://" + resp.data.url.split("s3.sa-east-1.amazonaws.com/")[1] + "/"

                    let itemUpload = {
                        id: uuidv4(),
                        uid: uuidv4(),
                        name: file.name,
                        url: urlClean + resp.data.fields.key,
                        size: file.size,
                        type: file.type,
                        uploadedByUsername: this.props.auth.username,
                        uploadedByName: this.props.auth.name,
                        uploadedDate: data,
                    }


                    this.setState({
                        statusCode: response.status,
                        imgPreview: resp.data.url + "/" + resp.data.fields.key
                    });


                    setTimeout(() => {
                        this.setState({
                            loading: false,
                            progress: "0"
                        })
                    }, 1000);

                    this.setState({
                        itemUpload: {
                            ...itemUpload
                        }
                    })


                    setTimeout(() => {
                        this.sendImages();
                    }, 1);

                    onSuccess(null, file);


                }).catch(error => { //Erro Upload



                });

            }).catch(error => { //Erro Pegar Autorizaçõ

                if (error.response.status === 401) {


                } else {

                }

                setTimeout(() => {
                    this.setState({
                        loading: false,
                        progress: "0"
                    })
                }, 1000);

            });
        }


    };


    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.fileListGet != prevProps.fileListGet) {
            this.setState({
                fileList: this.props.fileListGet
            });
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;

        let listFileCheck = [];

        if (this.props.direct) {
            listFileCheck = this.props.fileListGet;
        } else {
            listFileCheck = fileList;
        }



        return (
            <Upload
                className="pb-bt"
                fileList={this.props.disabledList ? [] : listFileCheck}
                customRequest={(e) => this.onDrop(e)}
                onRemove={(file) => {
                    this.setState(state => {
                        const index = state.fileList.indexOf(file);
                        const newFileList = state.fileList.slice();
                        newFileList.splice(index, 1);
                        return {
                            fileListRemove: newFileList,
                        };
                    });

                    setTimeout(() => {
                        this.props.getDataUpload(this.state.fileListRemove)
                    }, 1);
                }}>
                <Button type="primary" block>
                    {this.state.loading &&
                        <span><Icon type="loading" /> &nbsp; {this.state.progress + "%"}</span>
                    }
                    {!this.state.loading &&
                        <span><Icon type="upload" /> Upload</span>
                    }
                </Button>
                {/* <p className="ant-upload-drag-icon">
                    {this.state.loading &&
                        <i className="numberP" style={{ fontSize: "16px", margin: "10px 0", display: "block" }}>{this.state.progress + "%"}</i>
                    }
                    {!this.state.loading &&
                        <span><Icon type="cloud-upload" /> <b>Upload</b></span>
                    }
                </p> */}
            </Upload>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadButton);
