import React, { Component, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Redirect, Link } from "react-router-dom";

import {
    Form,
    Button,
    Input,
    Checkbox,
    Row,
    Col,
    DatePicker,
    Avatar
} from 'antd';

import WhatifWrapper from '../components/WhatifWrapper';

import t1 from '../../../image/thumb-1.jpg';

import SubHeader from '../components/SubHeader';
import ContainerPage from '../components/ContainerPage';
import { TitleBorder, TitleMedium } from '../components/Titles';
import { CardBorder } from '../components/Cards';
import ResumeTable from '../components/ResumeTable';

import { MENU_CLIENT_WHATIF } from '../constants';

import { IDEA_WHATIF_API } from '../../../actions/idea-whatif/actions';


import Loading from '../../../components/pages/loading';


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        get_idea_detail: data => {
            dispatch(IDEA_WHATIF_API.get_idea_detail(data));
        },
        add_po: data => {
            dispatch(IDEA_WHATIF_API.add_po(data));
        }
    };
};




const Main = styled.div`
  max-width: 820px;
  margin: 0 auto 100px;
  padding-top: 80px;
  width: 100%;
  box-sizing: border-box;

  .NameIdea {
        color: #3d3d3d;
        font-size: 25px;
        font-weight: 700;
        line-height: 21px;
        margin-bottom: 10px;
  }

  .tagYellow {
        background: #fff291;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        padding: 2px 7px;
    }

    .RowBottom {
        padding-bottom: 20px;
    }

  .Redes {
    margin-bottom: 0px;
    li {
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        line-height: 37px;
        padding-right: 30px;
        position: relative;
        color: #353535;

        &:last-child  {
            &::after {
                display: none;
            }
        }

        &::after {
            content: "/";
            position: absolute;
            right: 12px;
            top: 0;
        }
    }
}
`;

const ThumbClient = styled.div`
    margin-top: 80px;
    .TitMarca {
        font-size: 23px;
        font-weight: 600;
        line-height: 37px;
        color: #353535;
        margin-left: 20px;
    }
`;




class RegisterPo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            jobOffer: "",
            jobValorIdea: 0,
            jobValorProduction: 0
        };
    }

    componentDidMount = () => {
        const data = {
            id: this.props.match.params.whatIfIdeaId,
            prefix: "ClientWhatIf"
        }
        this.props.get_idea_detail(data);

    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {


                const dataForm = {
                    ...values,
                    whatIfIdeaId: this.props.match.params.whatIfIdeaId
                }

                this.props.add_po(dataForm);
            }
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        if (!this.props.ideaWhatIf.ideaDetail) {
            return (<Loading />);
        }

        const {
            ideaWhatIf: {
                ideaDetail,
                ideaDetail: {
                    whatIfIdeaId,
                    partner,
                    ideaProposalSocial,
                    name,
                    brandName,
                    productName,
                    partner: {
                        imageLogo,
                        imageIntro
                    },
                    status,
                    offers
                }
            }
        } = this.props;

        const activeOffer = offers.filter(offer => offer.activeOffer == true);
        const approvedOfferGet = offers.filter(offer => offer.activeOffer == true && offer.approvedOn);
        const declineOfferGet = offers.filter(offer => offer.activeOffer == false && offer.declinedOn && offer.activeOfferFrom != "P");

        const approvedOffer = approvedOfferGet.length > 0 ? approvedOfferGet[0] : null;
        const declineOffer = declineOfferGet.length > 0 ? declineOfferGet[0] : null;



        if (this.props.ideaWhatIf.ideaDetail.po && this.props.ideaWhatIf.ideaDetail.poAddedOn) {
            return (<Redirect to={`/cliente/ideias/recebida/cadastrar-po/${this.props.match.params.whatIfIdeaId}/sucesso`} />);


        }

        return (
            <Fragment>
                <WhatifWrapper>
                    <SubHeader itensMenu={MENU_CLIENT_WHATIF} activeItem={0} />
                    <ContainerPage>
                        <Main>
                            <CardBorder
                                Bcolor="#fff"
                            >
                                <TitleBorder>Cadastrar SPA</TitleBorder>

                                <ThumbClient>
                                    {/* <Avatar src={t1} size={80} />  */}<span className="TitMarca">{brandName} / {productName}</span>
                                </ThumbClient>
                            </CardBorder>
                            <CardBorder
                                Bcolor="#fff291"
                            >
                                <Row>
                                    <Col sm={24}>
                                        <TitleMedium>Ideia</TitleMedium>
                                    </Col>
                                </Row>
                                <Row className="RowBottom">
                                    <Col sm={24}>
                                        <ul className="Redes">
                                            {ideaProposalSocial && ideaProposalSocial.map((item, index) => {
                                                return <li key={index}>{item}</li>
                                            })}
                                        </ul>

                                        <h2 className="NameIdea">{name}</h2>
                                        <p className="tagYellow">IDEIA: Pode ser usada em qualquer momento</p>
                                    </Col>
                                </Row>

                            </CardBorder>
                            <Form onSubmit={this.handleSubmit}>
                                <CardBorder
                                    Bcolor="#cbede0"
                                >
                                    <Row>
                                        <Col sm={24}>
                                            <TitleMedium>Valores</TitleMedium>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <ResumeTable
                                                valorIdeia={approvedOffer ? approvedOffer.jobOfferPriceIdea : offers[0].jobOfferPriceIdea}
                                                valorProducao={approvedOffer ? approvedOffer.jobOfferPriceProduction : offers[0].jobOfferPriceProduction}
                                                scroll={{ y: 595 }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBorder>
                                <CardBorder
                                    Bcolor="#c7e5f2"
                                >

                                    <Row>
                                        <Col sm={24}>
                                            <Form.Item label="Cadastrar SPA">
                                                {getFieldDecorator('po', {
                                                    rules: [{ required: true, message: 'Campo obrigatório!' }],
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24}>
                                            <Button htmlType="submit" className="BtnAprovar" type="primary" shape="round">CADASTRAR SPA</Button>
                                        </Col>
                                    </Row>
                                </CardBorder>
                            </Form>
                        </Main>
                    </ContainerPage>

                </WhatifWrapper>
            </Fragment>
        );
    }
}

const RegisterPoForm = Form.create({})(RegisterPo);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPoForm);
