import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../ui/format-currency";

class Heading extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    const { customEvent = {}, currency = {}, quotation = {} } = this.props;

    return (
      <div className="heading-mobile">
        <p className="textInvite">
          {I18n.get(
            "Sua empresa foi indicada para participar de um processo de proposta. Verifique o briefing, datas e escopo. Caso não queira ou não possa participar, faça o opt-out utilizando o botāo no final da tela."
          )}
        </p>

        <p className="textInvite">
          {I18n.get(
            "Ah, para acessar as informações do projeto, você precisa concordar com o NDA antes. Vamos lá?"
          )}
        </p>

        <br />
        <br />

        <h1 className="title">{I18n.get("Briefing")}</h1>

        <br />
        <br />

        <p>
          <strong>{I18n.get("Nome do Projeto:")}</strong> &nbsp;{" "}
          {this.props.projectName}
        </p>
        <p>
          <strong>{I18n.get("Marca / Categoria:")}</strong> &nbsp;{" "}
          {this.props.brandName} - {this.props.categoryName}
        </p>

        <p>
          <strong> {I18n.get("Budget máximo autorizado pelo cliente")}:</strong>{" "}
          <FormatCurrency
            amount={customEvent?.budgetTotal}
            currency={currency}
          />
        </p>

        {quotation?.workingBudgetMax && (
          <>
            <p>
              <strong>{I18n.get("Retenção máxima Ollo")}:</strong>{" "}
              <FormatCurrency
                amount={
                  quotation?.bpCommission && quotation?.bpCommission?.maxAmount
                }
                currency={currency}
              />
            </p>

            <p>
              <strong>
                {" "}
                {I18n.get("Budget máximo disponível para orçamento")}:
              </strong>{" "}
              <FormatCurrency
                amount={quotation?.workingBudgetMax}
                currency={currency}
              />
            </p>

            <p>
              <strong>{I18n.get("Prazo pagamento:")}</strong>{" "}
              {this.props.paymentTermDays} {I18n.get("dias")}
            </p>
          </>
        )}

        <br />
        <br />
      </div>
    );
  }
}

export default Heading;
