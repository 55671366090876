import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import { useExtras } from "../../../hooks/useExtras/useExtras.hook";
import { useProjects } from "../../../hooks/useProjects/useProjects.hook";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../components/UI/Titles";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { TableExtras } from "./components/TableExtras";
import Loading from "../../../components/UI/Loading";
import { TabsProject } from "../../../components/UI/TabsProject";
import { RequestProjectCompletion } from "../components/RequestProjectCompletion";

import * as S from "./styles";

const tableColumnsClient = [
  "Name",
  "Type",
  "Budget",
  "#SPA",
  "Status",
  "Created on",
  "PDF",
  "",
  "",
];

const tableColumnsPartner = [
  "Name",
  "Type",
  "Budget",
  "#SPA",
  "Status",
  "Created on",
  "PDF",
  "",
  "",
];

export const ExtrasInProgress = () => {
  let params = useParams();
  let history = useHistory();
  let location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { getExtras } = useExtras();
  const { getProjectById } = useProjects();
  const [dataExtras, setDataExtras] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);

  const loadInit = async () => {
    setIsLoading(true);
    try {
      const response = await getExtras({ projectId: params.projectId });
      setDataExtras(response?.data);
      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });

      setProject(responseProject?.data);
    } catch (error) {
      console.log("error?.response", error?.response);
    }
    setIsLoading(false);
  };

  const handleNewExtra = () => {
    const pathname = location?.pathname;

    history.push(`${pathname}/new`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              {isLoading ? (
                <Loading
                  text={I18n.get("Loading...")}
                  sizeIcon={18}
                  sizeText={14}
                  align="left"
                />
              ) : (
                <H2Border>
                  {I18n.get(
                    project?.status === 6
                      ? "Definições do Projeto"
                      : "Projeto em andamento"
                  )}
                </H2Border>
              )}
            </Col>
            <Col sm={12}>
              <RequestProjectCompletion reloadPage={() => true} />
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={
                  project?.status === 6
                    ? "definitions"
                    : project?.status === 7
                    ? "inProgress"
                    : "finalized"
                }
                page="extras"
                externalLoading={isLoading}
                projectStatus={project?.status}
                partnerHasSubmittedDefinitions={
                  project?.partnerHasSubmittedDefinitions
                }
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Extras")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24}>
                    <S.Text>
                      {I18n.get(
                        "Request an Extra! If you require any increase in scope, production or any other item for your project, use the fields below to request a partner's proposal."
                      )}
                    </S.Text>
                  </Col>
                  <Col xs={24} sm={24}>
                    <S.ContentButtonNew>
                      <ButtonBpool
                        theme="primary"
                        onClick={handleNewExtra}
                        text={I18n.get("New Extra")}
                      />
                    </S.ContentButtonNew>
                  </Col>
                  <Col xs={24} sm={24}>
                    <S.ContentText>
                      <S.Text>
                        {I18n.get(
                          "In the list below you will find the extra items."
                        )}
                      </S.Text>
                    </S.ContentText>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24}>
                    <TableExtras
                      isLoading={isLoading}
                      columns={
                        auth?.is_client_or_client_user
                          ? tableColumnsClient
                          : tableColumnsPartner
                      }
                      data={dataExtras}
                      isClient={auth?.is_client_or_client_user ? true : false}
                      loadInit={loadInit}
                      isInProgress={project?.status === 7}
                    />
                  </Col>
                </Row>
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
