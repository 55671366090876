import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";

import ChatNavBarItem from '../shared/chat-navbar-item';

import '../../App.css';
//import '../../Site.css';

import logo from '../../image/logoWOtext.svg';
import Footer from './footer';
import TopMenu from './_top-menu';
import SubMenu from './_sub-menu';

import WallPage from '../pages/wall';
import Loading from '../pages/loading';

import { PartnerStatus } from '../../model';
import { ClientStatus } from '../../model';

import PartnerTermsUpdateModal from '../partner/terms-update-modal';

import LanguageNavBarItem from '../shared/language-navbar-item';


class LoggedPage extends Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {

        const name = (this.props.auth
            && this.props.auth.name) || this.props.auth.email;

        var firstName = "";

        const isInit = (!this.props.auth || this.props.auth.init);

        if (isInit) {
            return (<Loading />);
        }
        if (!isInit && name) {
            if (name.indexOf("@") > -1)
                firstName = name.split('@')[0].toUpperCase();
            else
                firstName = name.split(' ')[0].toUpperCase();
        }

        const isNotLogged = (this.props.auth && !this.props.auth.logged_user);
        const IsLogout = (this.props.auth && this.props.auth.is_logout);

        if (isNotLogged) {

            const login = {
                pathname: '/login',
                state: {
                    returnUrl: (IsLogout ? '' : this.props.location.pathname),
                }
            }

            return (<Redirect to={login}></Redirect>);
        }

        return (<div>
            <section className="hero is-fullheight logged-page preview-page-container">
                <div className="hero-head">
                    <nav className="navbar is-main navbar-main">
                            <div className="container">

                                <div id="navbarMenu" className="navbar-menu navbar-menu-new is-active">
                                    <div className="navbar-start">
                                        <TopMenu {...this.props} />
                                    </div>
                                    <div className="navbar-logo">
                                        <div className="navbar-item">
                                            <a href="/"><img src={logo} alt="Ollo" className="logo"/></a>
                                        </div>
                                    </div>

                                    <div className="navbar-end" style={{ zIndex: 999 }}>

                                        {/* <LanguageNavBarItem {...this.props} /> */}
                                        <div className="navbar-name">
                                            <span className="navbar-link-name-not-mobile">{firstName}</span>
                                        </div>

                                        {/* <ChatNavBarItem {...this.props} /> */}

                                    </div>
                                </div>
                            </div>
                        </nav>
                    <SubMenu {...this.props} />
                </div>
                <div className="hero-body">
                    <div className="container">

                        {this.props.children}

                    </div>
                </div>

            </section>

            <Footer {...this.props} />

            {this.props.partner && this.props.partner.id && !this.props.partner.terms &&
                <PartnerTermsUpdateModal {...this.props} />
            }

        </div>

        );
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LoggedPage));
