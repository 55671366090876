import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import { PROJECT_API } from "../../actions/project/actions";

import { ClientProjectStatus } from "../../model";

import { Row, Col, Icon } from "antd";

import ReviewStep1 from "../_project-review-1";
import AssetBundle from "../_project-asset-bundle";
import ReviewStep4 from "../_project-review-4";
import ReviewStep5 from "../_project-review-5";
import ReviewStep5Client from "../_project-review-5-client";

import ViewMacros from "../../modules/components/ViewMacrosResume";

import DownloadButton from "../form/download-button-field";

const mapStateToProps = (state) => {
  return Object.assign({}, state, { ...state.project });
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_project: (data) => {
      dispatch(PROJECT_API.get(data));
    },
    save: (data) => {
      dispatch(PROJECT_API.update_definitions(data));
    },
    getPartnerResponsible: (data) => {
      return dispatch(PROJECT_API.get_partner_responsible(data));
    },
  };
};

class ProjectDetailsForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      form: {},
      task: {},
    };
  }

  componentDidMount() {
    if (this.props.match.params.projectId) {
      var data = {
        projectId: this.props.match.params.projectId,
      };

      var task = new Task();

      task.start();

      this.setState({ task: task }, function () {
        this.props.get_project(data);
      });
    }

    if (this.props.partner && this.props.partnerId && !this.state.loading) {
      let data = {
        partnerId: this.props.partnerId,
        projectId: this.props.projectId,
      };
      this.props.getPartnerResponsible(data);

      this.setState({
        loading: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.form).forEach((item) => {
        if (FormUtils.isFormField(nextState.form[item])) {
          nextState.form[item].error = this.props.errors[item];
          nextState.form[item].value = this.props[item] || "";
        } else if (typeof nextState.form !== "undefined") {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }

    if (
      this.props.partner != prevProps.partner &&
      this.props.partner.partnerId &&
      !this.state.loading
    ) {
      let data = {
        partnerId: this.props.partnerId,
        projectId: this.props.projectId,
      };
      this.props.getPartnerResponsible(data);

      this.setState({
        loading: true,
      });
    }
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop].value = value;

      return nextState;
    });
  }

  getData() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (
        typeof this.state.form[item] === "object" &&
        "value" in this.state.form[item]
      ) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  getSelected() {
    return {
      bundleId: this.props.bundleId,
      variationId: this.props.variationId,
    };
  }

  render() {
    return (
      <div className="project-ongoing-review container-content">
        <Row>
          <Col sm={12}>
            <h1 className="title title-1">{I18n.get("Resumo")}</h1>
            {this.props.project.status == ClientProjectStatus.Canceled && (
              <div className="is-pulled-right">
                <span class="tag is-warning is-medium">
                  {I18n.get("Projeto Cancelado")}
                </span>
              </div>
            )}
          </Col>
          <Col sm={12} className="btns-inline">
            {this.props.pdfProject && this.props.pdfProject.length > 0 && (
              <DownloadButton LinkToDownload={this.props.pdfBundle} />
            )}
            {this.props.project.status == ClientProjectStatus.Started &&
              this.props.auth.is_client_or_client_user && (
                <div className="field is-narrow">
                  {" "}
                  <NavLink
                    to={`/projetos/${this.props.match.params.projectId}/finalizar`}
                    className="button bt-bpool black"
                  >
                    {I18n.get("Finalizar Projeto")}
                  </NavLink>
                </div>
              )}
          </Col>
        </Row>
        <br />
        <br />

        {this.props.clientTermsUpdatedOn && (
          <>
            <Row>
              <Col sm={24}>
                <div
                  style={{
                    display: "flex",
                    boxShadow:
                      "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
                    backgroundColor: "#fff",
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{
                      flexBasis: "2.5rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      type="check"
                      style={{ fontSize: "150%", color: "#000" }}
                    />
                  </div>

                  <div style={{ flex: "1", fontSize: "90%" }}>
                    {I18n.get(
                      "Veja abaixo os detalhes do projeto aprovado por"
                    )}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.clientTermsUpdatedByEmail}
                    </span>{" "}
                    {I18n.get("em")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.clientTermsUpdatedOn}
                    </span>{" "}
                    {I18n.get("com o parceiro")}{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.props.partnerName}
                    </span>
                    .{" "}
                    {I18n.get(
                      "De agora em diante, qualquer adição de escopo será através dos “Extras” ou “Novo Projeto”."
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <br />
          </>
        )}

        {!this.props.clientTermsUpdatedOn && (
          <>
            <Row>
              <Col sm={24}>
                <div className="alert-bpool">
                  <p>
                    {I18n.get(
                      "Confira abaixo os detalhes do escopo final do projeto aprovado entre você e seu parceiro. Nesta etapa não é possível alterar itens e valores do projeto base."
                    )}{" "}
                    {/* A inclusão de novos itens deve ser feita por meio do menu “Extensão de Projeto”.  */}{" "}
                    {I18n.get(
                      "A inclusao de novos itens deve ser feita por meio da contratacao de um novo pacote ou de assets individuais clicando no meno Novo Projeto. Lembre-se que o cronograma pode ser alterado pelo parceiro ao longo do projeto somente com a sua aprovação. A última versão ficará disponível nesta área."
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <br />
            <br />
          </>
        )}

        <Row>
          <Col sm={12}>
            <h1 className="title-bpool medium">{I18n.get("Informações")}</h1>

            <ReviewStep1 {...this.props} />

            <br />
            <br />

            {this.props.auth.is_client_or_client_user && (
              <>
                <h1 className="title-bpool medium">
                  {I18n.get("Sobre o Parceiro")}
                </h1>

                <ReviewStep5 {...this.props} />
              </>
            )}

            {!this.props.auth.is_client_or_client_user && (
              <>
                <h1 className="title-bpool medium">
                  {I18n.get("Sobre o Cliente")}
                </h1>

                <ReviewStep5Client {...this.props} />
              </>
            )}

            <br />
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Escopo Criativo")}
            </h1>

            <div className="reviewed-media uplift-review listBundle">
              <AssetBundle
                {...this.props.bundle}
                {...this.props}
                bp={this.props.bp}
                selected={this.getSelected()}
                isExpanded={true}
                IsOnGoing={true}
                IsClient={this.props.auth.is_client_or_client_user == true}
                PricePartner={this.props.pricePartner}
                PricePartnerTax={this.props.pricePartnerTax}
                PricePartnerOtherTax={this.props.pricePartnerOtherTax}
                PriceClientWithDiscount={this.props.priceClientWithDiscount}
                PriceClientWithDiscountTax={
                  this.props.priceClientWithDiscountTax
                }
                PriceClientWithDiscountOtherTax={
                  this.props.priceClientWithDiscountOtherTax
                }
                PriceBPool={this.props.priceBPoolComission}
                PriceBPoolTax={this.props.priceBPoolComissionTax}
                PriceBPoolOtherTax={this.props.priceBPoolComissionOtherTax}
                isFull={true}
                currency={this.props.currency}
                projectStatus={this.props.status}
                assetTax={this.props.assetTax || 0}
                otherTax={this.props.otherTax || 0}
                projectAssets={this.props.project?.bundle?.variations}
              />
            </div>

            <br />
            <br />
          </Col>

          <Col sm={12}>
            <h1 className="title-bpool medium">{I18n.get("Detalhes")}</h1>

            <div className="uplift">
              <ReviewStep4 {...this.props} />
            </div>

            <br />
            <br />

            <br />
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Observações importantes do Parceiro")}
            </h1>

            <div
              className={`card ${this.props.partnerNotes ? "" : "no-content"}`}
            >
              <div className="card-content">
                <div className="content">
                  <p>{this.props.partnerNotes}</p>
                </div>
              </div>
            </div>

            <br />
            <br />

            <h1 className="title-bpool medium">
              {I18n.get("Observações importantes do Cliente")}
            </h1>

            <div
              className={`card ${this.props.clientNotes ? "" : "no-content"}`}
            >
              <div className="card-content">
                <div className="content">
                  <pre style={{ backgroundColor: "#ffffff", padding: "0px" }}>
                    {this.props.clientNotes}
                  </pre>
                </div>
              </div>
            </div>

            <br />
            <br />

            <h1 className="title-bpool medium">{I18n.get("Macro entregas")}</h1>

            <div className="card project-ongoing-review-card">
              <div className="card-content" style={{ padding: 0 }}>
                <div className="content">
                  <ViewMacros
                    project={this.props.project}
                    create={this.props.create_milistones}
                    delete={this.props.delete_milistones}
                    update={this.props.update_milestones_mensal}
                    isPartner={this.props.auth.is_partner}
                    errors={this.props.errors && this.props.errors}
                    isReview={true}
                  />
                </div>
              </div>
            </div>

            <br />
            <br />
          </Col>
        </Row>

        {/* {this.props.auth.is_client_or_client_user && <>
                    <h1 className="title-bpool medium">
                        <span>8.</span> SPA
                    </h1>

                    <Billing {...this.props} />
                </>} */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsForm);
