import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import "../../App.css";
import "../../Site.css";

import logo from "../../image/logoWOtext.svg";

import TopMenu from "./_top-menu";

import Loading from "../pages/loading";

import { AUTH_API } from "../../actions/auth/actions";
import Footer from "./footer";

import ChatNavBarItem from "../shared/chat-navbar-item";

import PartnerTermsUpdateModal from "../partner/terms-update-modal";

import { ModalBp } from "../../v2/components/UI/ModalBp/ModalBp";

import Chat from "../../v2/components/UI/Chat";
import ChatNotification from "../../v2/components/UI/ChatNotification";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auth: state.auth,
    chat: state.chat,
    client: state.client,
    partner: state.partner,
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AUTH_API.logout());
    },
  };
};

class FillFormLogged extends Component {
  constructor() {
    super();
    this.state = {
      counterChatNotifications: 0,
      dateupdate: true,
    };
  }

  onLogoutClick(e) {
    e.preventDefault();

    this.props.logout();
  }

  redirectToDiversity = () => {
    const dataClose = new Date();
    localStorage.setItem("@BPOOL-closemodal", dataClose);
    this.props.history.push("/partner/edit-profile/diversity-and-inclusion");
  };

  handleSetClose = () => {
    const dataClose = new Date();
    localStorage.setItem("@BPOOL-closemodal", dataClose);
    this.setState({ dateupdate: dataClose });

    this.props.logout();
  };

  hasPassed24Hours = () => {
    const closeModalTime = localStorage.getItem("@BPOOL-closemodal");

    if (!closeModalTime) {
      return true;
    }

    // const twentyFourHoursInMillis = 1 * 60 * 1000;
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const closeModalTimestamp = new Date(closeModalTime).getTime();

    return currentTime - closeModalTimestamp > twentyFourHoursInMillis;
  };

  render() {
    const name =
      (this.props.auth && this.props.auth.name) || this.props.auth.email;

    var firstName = "";

    const isInit = !this.props.auth || this.props.auth.init;

    if (isInit) {
      return <Loading />;
    }
    if (!isInit && name) {
      if (name.indexOf("@") > -1) firstName = name.split("@")[0].toUpperCase();
      else firstName = name.split(" ")[0].toUpperCase();
    }

    const isNotLogged = this.props.auth && !this.props.auth.logged_user;
    const IsLogout = this.props.auth && this.props.auth.is_logout;

    if (isNotLogged) {
      if (!IsLogout) {
        const urlAtual = window.location.href;

        localStorage.setItem("@BPOOL-redirect-url-after-login", urlAtual);
      }

      const login = {
        pathname: "/login",
      };

      return <Redirect to={login}></Redirect>;
    }

    if (this.props.client && this.props.client.customization && this.props.client.customization.cssUrl) {

        require('../../custom-css/' + this.props.client.customization.cssUrl);
    }

    const isDiversityRoute =
      this.props.location.pathname ===
      "/partner/edit-profile/diversity-and-inclusion";


    return (
      <>
        <div>
          <section
            className={`hero logged-page ${
              this.props.noFullHeight ? "" : "is-fullheight"
            }`}
          >
            <div
              className={`hero-head ${this.props.noFixed ? "" : "is-fixed"}`}
            >
              <nav className="navbar is-main navbar-main">
                <div className="container">
                  <div
                    id="navbarMenu"
                    className="navbar-menu navbar-menu-new is-active"
                  >
                    <div className="navbar-start">
                      <TopMenu {...this.props} />
                    </div>
                    <div className="navbar-logo">
                      <div className="navbar-item">
                        <a href="/">
                          <img
                            src={logo}
                            alt="Ollo"
                            className="logo"
                          />
                        </a>
                      </div>
                    </div>

                    <div
                      className="navbar-end"
                      style={{
                        zIndex: 999,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <LanguageNavBarItem {...this.props} /> */}
                      <div className="navbar-name">
                        <span className="navbar-link-name-not-mobile">
                          {firstName}
                        </span>
                      </div>

                      <ChatNotification />

                      <ChatNavBarItem {...this.props} />
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            <div>{this.props.children}</div>
          </section>
        </div>

        {/* {this.props.client && this.props.client.id && (
          // <a
          //   className="button bt-bpool fixedChat"
          //   onClick={this.props.actionBtn}
          //   href={`${
          //     window.location.href.split('/#/')[0]
          //   }/#/cliente/projetos?tab=2`}
          // >
          //   <img src={ChatIcon} /> Chat{' '}
          // </a>
          <Chat typeUser="client" />
        )} */}

        {!isDiversityRoute &&
          !this.props.partner?.company?.diversityPreferNotDeclare &&
          !this.props.partner?.company?.partnerHasDiversity &&
          this.props.partner &&
          this.props.partner.id && (
            <ModalBp
              visible={
                this.props.partner &&
                this.props.partner.id &&  !this.props.partner?.company?.diversityPreferNotDeclare &&
                !this.props.partner?.company?.hasAnyDiversity
              }
              // visible={this.hasPassed24Hours()}
              bodyText={I18n.get("Aviso")}
              okText={I18n.get("Atualizar agora")}
              handleClickOk={() => this.redirectToDiversity()}
              onCancel={() => this.handleSetClose()}
              cancelText={I18n.get("Sair")}
              typeModal="isConfirm"
              // isDanger
              isAlert
            >
              <div className="mainModalmsg">

                {/* TODO: Mudar depois do 15 dias */}
                <p>
                  {I18n.get("Identificamos que a declaração de D&I no cadastro da sua empresa não está preechida.")}

                </p>
                <p>
                  {I18n.get("Este campo passou a ser de preenchimento obrigatorio em 1 de setembro de 2023. Para seguir usando a BPool, favor atualize o campo de D&I do cadastro da sua empresa.")}
                </p>
                <p>

                </p>
              </div>
            </ModalBp>
          )}

        {this.props.partner && this.props.partner.id && (
          // <a className="button bt-bpool fixedChat" onClick={this.props.actionBtn} href={`${window.location.href.split("/#/")[0]}/#/parceiro/projetos?tab=3`}><img src={ChatIcon}/> Chat </a>
          <Chat typeUser="partner" />
        )}
        <Footer {...this.props} />

        {this.props.partner &&
          this.props.partner.id &&
          !this.props.partner.terms && (
            <PartnerTermsUpdateModal {...this.props} />
          )}
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FillFormLogged));
