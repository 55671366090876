import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Row, Col, Card, Icon, Table, Tag, DatePicker, Drawer, Checkbox, Skeleton, Button } from 'antd';
import { Redirect, Link } from 'react-router-dom';

import { I18n } from 'aws-amplify';

import DataSet from "@antv/data-set";
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Legend,
    Guide,

} from "bizcharts";

import { formatCurrency, formatMoneyGraph } from '../../../utils/helpers'

import { REPORT_API } from '../../../actions/report/actions';

import styled from "styled-components";

import { Task } from '../../../utils/task';

const { RangePicker } = DatePicker;

const TableCard = styled(Table)`
margin-top: 14px;

table {
    border: solid 1px #ccc !important;

    tr {
        cursor: pointer;
    }

    tr:nth-child(odd) td {
        background: #f2f2f2;
    }
}

.ant-table-tbody > tr > td {
        padding: 6px 16px;
    }
`


const DrawerBp = styled(Drawer)`
    top: 60px;

    h2 {
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;
        position: relative;

        &::after {
            width: 60px;
            content: "";
            border-bottom: solid 5px #000;
            display: block;
            position: absolute;
            bottom: -12px;
            left: 0;
        }
    }

    .description {
        font-size: 13px;
        line-height: 20px;
    }

    .total {
        margin: 30px 0;
        small {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .boxtotal {
            font-size: 22px;
            color: #fff;
            background: #000;
            padding: 5px 10px;
            strong {
                color: #fff;
            }
        }
    }

    .TableBox {
        margin-top: 50px;
    }
`
const RowFlex = styled(Row)`
    margin-bottom: 30px;

    .cardCol {
        margin-bottom: 30px;
    }
`
const CardDisplay = styled(Card)`
    margin-bottom: 20px;
    height: 100%;

    .DescriptionPage {
        font-size: 14px;
    }

    .LabelPeriodo {
        font-size: 12px;
        font-weight: bold;
    }

    &.TableBox {
        min-height: 335px;
    }

    .GraphCard {
        opacity: 1;
        transition: opacity 2s ease;
    }

    .hidden {
        opacity: 0;
    }

    .show {
        opacity: 1;
    }

    .LabelCard {
        text-transform: uppercase;
        font-size: 10px;
        margin-bottom: -10px;
    }

    h2 {
         font-size: 14px;
         text-transform: uppercase;
         margin-bottom: 20px;
         padding-left: 15px;
         border-left: solid 5px #000;
         span {
             display: block;
             color: #999;
             font-size: 11px;
         }

         &.title-span {
             line-height: 18px;
         }
    }

    .NumberCard {
        display: block;
        text-align: center;
        font-size: 50px;
        line-height: 60px;
    }

    .DescriptionCard {
        span {font-weight: bold;}
        border-top: solid 1px #b5b5b5;
        padding-top: 10px;
        margin-top: 15px;
        font-size: 18px;
    }

    .BtnCard {
        border-top: solid 1px #ccc;
        padding-top: 15px;
        margin-top: 10px;
        display: none;
    }

    .LoadingCard {
        text-align: center;
        margin: 36px 0 33px;

        .noDate {
            display: block;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
        }

        i {
            font-size: 50px;
            opacity: .4;
        }

        &.LabelG {
            position: absolute;
            top: 38%;
            left: 0;
            width: 100%;

            &.noDateView {
                top: 32%;
            }

        }
    }
`;

const Miolo = styled.div`
    text-align: center;
    position: absolute;
    top: 50%;
    margin-top: -15px;
    width: calc(100% - 48px);
    font-size: 20px;

    h3 {
        font-size: 18px;
    }

    .
`


const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        api_get_status_dash: data => {
            dispatch(REPORT_API.get(data));
        },
        api_get_projects_dash: data => {
            dispatch(REPORT_API.get_projects(data));
        },
        api_get_project_dash: data => {
            dispatch(REPORT_API.get_project(data));
        },
        api_get_specs_dash: data => {
            dispatch(REPORT_API.get_specs(data));
        },
        api_get_spec_dash: data => {
            dispatch(REPORT_API.get_spec(data));
        },
        api_get_assets_dash: data => {
            dispatch(REPORT_API.get_assets(data));
        },
        api_get_asset_dash: data => {
            dispatch(REPORT_API.get_asset(data));
        },
        api_get_briefings_dash: data => {
            dispatch(REPORT_API.get_briefings(data));
        },
        api_get_briefing_dash: data => {
            dispatch(REPORT_API.get_briefing(data));
        },
        api_get_warning_dash: data => {
            dispatch(REPORT_API.get_warning(data));
        },
        api_get_brands_dash: data => {
            dispatch(REPORT_API.get_brands(data));
        },
        api_get_brand_dash: data => {
            dispatch(REPORT_API.get_brand(data));
        },
        api_get_historic_dash: data => {
            dispatch(REPORT_API.get_historic(data));
        },
        api_get_historic_item_dash: data => {
            dispatch(REPORT_API.get_historic_item(data));
        },
        api_get_historic_month_dash: data => {
            dispatch(REPORT_API.get_historic_month(data));
        },
        api_get_partners_dash: data => {
            dispatch(REPORT_API.get_partners(data));
        },
        api_get_partner_dash: data => {
            dispatch(REPORT_API.get_partner(data));
        },
        delete_itens_dash: data => {
            dispatch(REPORT_API.delete_itens());
        },
        reset_itens_dash: data => {
            dispatch(REPORT_API.reset_itens());
        },
        api_get_savings_dash: data => {
            dispatch(REPORT_API.get_savings(data));
        },
        api_get_clients_dash: data => {
            dispatch(REPORT_API.get_clients(data));
        },
        api_get_saving_dash: data => {
            dispatch(REPORT_API.get_saving(data));
        },
    };

};

const visitData = [];
const beginDay = new Date().getTime();
for (let i = 0; i < 20; i += 1) {
    visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: Math.floor(Math.random() * 100) + 10,
    });
}


const columns = [
    {
        title: I18n.get("Mensagem"),
        dataIndex: 'msg',
        key: 'msg'
    },
    {
        title: I18n.get("Status"),
        key: 'status',
        dataIndex: 'status',
        render: status => <Tag color={status && status === true ? "green" : "red"}>{status === true ? I18n.get("Resolvido") : I18n.get("Em andamento")}</Tag>
    },
    {
        title: I18n.get("Data"),
        dataIndex: 'data',
        key: 'data'
    },
];

const columnsModal = [
    {
        title: I18n.get("Marca"),
        dataIndex: 'marca',
        key: 'marca'
    },
    {
        title: I18n.get("Nome"),
        dataIndex: 'nome',
        key: 'nome'
    },
    {
        title: 'SPA',
        dataIndex: 'po',
        key: 'po'
    },
    {
        title: I18n.get("Preço"),
        dataIndex: 'preco',
        key: 'preco',
        render: preco => formatCurrency(preco)
    },
    {
        title: I18n.get("Inicio"),
        dataIndex: 'inicio',
        key: 'inicio'
    },
    {
        title: I18n.get("Fim"),
        dataIndex: 'fim',
        key: 'fim'
    },
    {
        title: I18n.get("Status"),
        key: 'status',
        dataIndex: 'status',
        render: status => {

            let text = ""
            let color = ""

            switch (status) {
                case 7:
                    text = "Em Andamento";
                    color = "blue";
                    break;
                case 20:
                    text = "Finalizado";
                    color = "green";
                    break;
                case 90:
                    text = "Cancelado";
                    color = "orange";
            }

            return <Tag color={color}>{text}</Tag>

        }

    }
];


const columnsModalSaving = [
    {
        title: I18n.get('Marca'),
        dataIndex: 'marca',
        key: 'marca'
    },
    {
        title: I18n.get('Nome'),
        dataIndex: 'nome',
        key: 'nome'
    },
    {
        title: I18n.get('Valor interno'),
        dataIndex: 'vi',
        key: 'vi',
        render: vi => formatCurrency(vi)
    },
    {
        title: I18n.get('Valor externo'),
        dataIndex: 've',
        key: 've',
        render: ve => formatCurrency(ve)
    },
    {
        title: I18n.get('Status'),
        key: 'status',
        dataIndex: 'status',
        render: status => {

            let text = ""
            let color = ""

            switch (status) {
                case 7:
                    text = "Em Andamento";
                    color = "blue";
                    break;
                case 20:
                    text = "Finalizado";
                    color = "green";
                    break;
                case 90:
                    text = "Cancelado";
                    color = "orange";
            }

            return <Tag color={color}>{text}</Tag>

        }

    },
    {
        title: '',
        key: 'item',
        dataIndex: 'item',
        render: record => {
            return (<Link to={`/projetos/${record.projectId}/resumo`}><Icon type="eye" /></Link>);
        }
    }
];


const DataRanger = new Date;
const currentDate = moment(DataRanger).format("YYYY-MM-DD");
const dayDate = moment(DataRanger).add(-2, 'M').format("YYYY-MM-DD");


class ContainerClientEvent extends React.Component {

    constructor(props) {

        super(props);

        this.partner_chart = React.createRef();

        this.state = {
            fromDate: dayDate,
            toDate: currentDate,
            visible: false,
            typeDrawer: "",
            redirectProject: false,
            redirectId: "",
            url: "",
            savingItem: {},
            checkedValues: "7"
        };
    }

    showDrawer = (infos, dataFrom, dataTo, type) => {
        const { _origin: { code, year, period, month, brandId, partnerId, itemSelected } } = infos;

        // let year =
        let data = {
            fromDate: dataFrom,
            toDate: dataTo,
            prefix: this.props.prefix,
            idGet: this.props.idGet,
            code: code,
            period: period,
            year: year,
            month: month,
            brandId: brandId,
            partnerId: partnerId,
            ids: itemSelected
        }

        switch (type) {
            case "spec":
                this.props.api_get_spec_dash(data);
                this.setState({ typeDrawer: "spec" })
                break;
            case "asset":
                this.props.api_get_asset_dash(data);
                this.setState({ typeDrawer: "asset" })
                break;
            case "briefing":
                this.props.api_get_briefing_dash(data);
                this.setState({ typeDrawer: "briefing" })
                break;
            case "historicY":
                this.props.api_get_historic_item_dash(data);
                this.setState({ typeDrawer: "historicY" })
                break;
            case "brand":
                this.props.api_get_brand_dash(data);
                this.setState({ typeDrawer: "brand" })
                break;
            case "partner":
                this.props.api_get_partner_dash(data);
                this.setState({ typeDrawer: "partner" })
                break;
            case "client":
                this.props.api_get_client_dash(data);
                this.setState({ typeDrawer: "client" })
                break;
            case "saving":
                this.props.api_get_saving_dash(data);
                this.setState({ typeDrawer: "saving" })
                break;
            default:
                break;
        }




        this.setState({
            visible: true,
        });

    };

    onClose = () => {
        this.setState({
            visible: false,
            savingItem: {}
        });

        this.props.delete_itens_dash();
    };


    onCancel = () => {

    }

    getApi(data) {
        this.props.api_get_status_dash(data);
        this.props.api_get_projects_dash(data);
        this.props.api_get_specs_dash(data);
        this.props.api_get_assets_dash(data);
        this.props.api_get_warning_dash(data);
        this.props.api_get_historic_dash(data);
        this.props.api_get_historic_month_dash(data);
        this.props.api_get_brands_dash(data);
        this.props.api_get_briefings_dash(data);

        if (this.props.prefix == "client") {
            this.props.api_get_savings_dash(data);
            this.props.api_get_partners_dash(data);
        }

        if (this.props.prefix == "partner") {
            this.props.api_get_clients_dash(data);
        }


    }

    getApiFilter(data) {
        this.props.api_get_status_dash(data);
        this.props.api_get_projects_dash(data);
        this.props.api_get_specs_dash(data);
        this.props.api_get_assets_dash(data);
        this.props.api_get_warning_dash(data);
        this.props.api_get_historic_dash(data);
        this.props.api_get_historic_month_dash(data);
        this.props.api_get_brands_dash(data);
        this.props.api_get_briefings_dash(data);
        if (this.props.prefix == "client") {
            this.props.api_get_savings_dash(data);
            this.props.api_get_partners_dash(data);
        }

        if (this.props.prefix == "partner") {
            this.props.api_get_clients_dash(data);
        }
    }


    componentDidMount() {
        let data = {
            prefix: this.props.prefix,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            idGet: this.props.idGet,
            projectType: "2",
            status: "7"
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.getApi(data);
        });

    }


    componentWillUnmount() {
        this.props.reset_itens_dash();
    }

    onFilter() {
        let dataChange = {
            prefix: this.props.prefix,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            idGet: this.props.idGet,
            projectType: "2",
            status: this.state.checkedValues
        }

        var task = new Task();

        task.start();

        this.setState({ task: task }, function () {
            this.getApiFilter(dataChange);
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevState) return;
        if (!prevProps) return;

        if (this.props.report && this.props.report.task != prevState.task) {
            this.setState({ task: this.props.report.task });
        }
    }


    onChange(dateStrings) {

        let dataFrom = moment(dateStrings[0]).format('YYYY-MM-DD');
        let dataTo = moment(dateStrings[1]).format('YYYY-MM-DD');

        this.setState({
            fromDate: dataFrom,
            toDate: dataTo,
        });

        let dataChange = {
            prefix: this.props.prefix,
            fromDate: dataFrom,
            toDate: dataTo,
            idGet: this.props.idGet,
            projectType: "2"
        }

        this.getApi(dataChange);
    }


    onChangeStatus(checkedValues) {
        this.setState({
            checkedValues: checkedValues
        });
    }

    renderDataTable(obj) {

        let newObj = [];

        if (this.state.typeDrawer == "saving") {
            newObj = obj.map(i => {
                return ({
                    key: i.projectId,
                    marca: i.brand,
                    nome: i.name,
                    vi: i.totalInterSavedAmount,
                    ve: i.totalExternSavedAmount,
                    status: i.statusId,
                    item: i
                })
            })
        } else {
            newObj = obj.projects.map(i => {
                return ({
                    key: i.projectId,
                    marca: i.brand,
                    nome: i.name,
                    po: i.po,
                    preco: i.price,
                    inicio: i.start,
                    fim: i.end ? i.end : "--",
                    status: i.statusId
                })
            })
        }
        return newObj;
    }

    renderCallBack(arr) {

        if (arr == undefined) {
            return (
                <p className="LoadingCard LabelG">
                    <Icon type="loading" />
                </p>
            )
        } else if (arr && arr.length == 0) {
            return (
                <p className="LoadingCard LabelG noDateView">
                    <Icon type="inbox" />
                    <span className="noDate">Sem informações</span>
                </p>
            )
        }

    }


    render() {

        const {
            status,
            projects,
            specs,
            assets,
            briefings,
            briefing,
            warning,
            historicYear,
            historicMonth,
            brands,
            brand,
            project,
            partners,
            clients,
            partnerItem,
            spec,
            asset,
            savings,
            saving,
            historicItem } = this.props.report;
        const { typeDrawer, redirectProject, redirectId, url } = this.state;

        const { DataView } = DataSet;

        let valor = 0;

        let dataTable = null;
        let DrawerInfos = null;




        if (typeDrawer !== "") {
            switch (typeDrawer) {
                case 'spec':
                    dataTable = spec && this.renderDataTable(spec);
                    DrawerInfos = spec && spec;
                    break;
                case 'asset':
                    dataTable = asset && this.renderDataTable(asset);
                    DrawerInfos = asset && asset;
                case "briefing":
                    dataTable = briefing && this.renderDataTable(briefing);
                    DrawerInfos = briefing && briefing;
                    break;
                case 'historicY':
                    dataTable = historicItem && this.renderDataTable(historicItem);
                    DrawerInfos = historicItem && historicItem;
                    break;
                case 'brand':
                    dataTable = brand && this.renderDataTable(brand);
                    DrawerInfos = brand && brand;
                    break;
                case 'partner':
                    dataTable = partnerItem && this.renderDataTable(partnerItem);
                    DrawerInfos = partnerItem && partnerItem;
                    break;
                case 'saving':
                    dataTable = saving && this.renderDataTable(saving);
                    DrawerInfos = this.state.savingItem && this.state.savingItem;
                    break;

                default:
                    break;
            }
        }



        const dataHistoric = historicYear && historicYear.map(i => {
            return ({
                name: i.year.toString(),
                total: i.total,
                year: "i.year",
                period: i.type
            })
        })


        const dataHistoricMonth = historicMonth && historicMonth.map(i => {
            return ({
                name: i.monthName,
                total: i.total,
                year: i.year,
                period: i.type,
                month: i.month
            })
        })

        const dataAssets = assets && assets.map(i => {
            return ({
                item: i.name,
                count: i.total,
                code: i.assetCode
            })
        })

        const dataProjectAssets = project && project.assets.map(i => {
            return ({
                item: i.name,
                total: i.total
            })
        })

        const data = projects && projects.map(i => {
            valor = valor + i.price;

            return ({
                item: i.name,
                count: i.price,
                projectId: i.projectId
            })
        })

        const dv = new DataView();

        if (data) {
            dv.source(data).transform({
                type: "percent",
                field: "count",
                dimension: "item",
                as: "percent"
            });

        }


        const cols = {
            percent: {
                formatter: val => {
                    val = val * 100 + "%";
                    return val;
                }
            }
        };
        const dv2 = new DataView();
        const dv3 = new DataView();

        if (dataAssets) {
            dv2.source(dataAssets).transform({
                type: "percent",
                field: "count",
                dimension: "item",
                as: "percent"
            });
        }

        if (dataProjectAssets) {
            dv3.source(dataProjectAssets).transform({
                type: "percent",
                field: "total",
                dimension: "item",
                as: "percent"
            });
        }

        const dataWarning = warning && warning.filter(i => i.name && i.warningId).map(i => {
            return ({
                key: i.warningId,
                msg: i.message,
                data: i.createdOn,
                status: i.solved,
                url: i.url
            })
        })

        const dataBrand = brands && brands.filter(i => i.name && i.brandId).map(i => {
            return ({
                name: i.name,
                total: i.total,
                brandId: i.brandId
            })
        })

        const dataBriefings = briefings && briefings.filter(i => i.name && i.code).map(i => {
            return ({
                name: i.name,
                total: i.total,
                code: i.code
            })
        })

        const dataPartners = partners && partners.filter(i => i.name && i.partnerId).map(i => {
            return ({
                name: i.name,
                total: i.total,
                partnerId: i.partnerId,
            })
        })

        const dataClients = clients && clients.filter(i => i.name && i.clientId).map(i => {
            return ({
                name: i.name,
                total: i.total,
                clientId: i.clientId,
            })
        })

        const dataSavingsds = new DataSet();
        let dataSavingsdv = []


        if (savings) {
            dataSavingsdv = dataSavingsds.createView().source(savings && savings.data);

            dataSavingsdv.transform({
                type: "fold",
                fields: ["ABR / 2020", "FEV / 2020", "MAR / 2020"],
                key: "mes",
                value: "total"
            });
        }


        if (redirectProject) {
            if (url) {
                return <Redirect to={url} />
            }
            return <Redirect to={`/projetos/${redirectId}/resumo`} />
        }

        const status1 = status && status.filter(obj => { return obj.statusId == 1; })[0];
        const status2 = status && status.filter(obj => { return obj.statusId == 2; })[0];
        const status3 = status && status.filter(obj => { return obj.statusId == 3; })[0];
        const status4 = status && status.filter(obj => { return obj.statusId == 4; })[0];
        const status5 = status && status.filter(obj => { return obj.statusId == 5; })[0];
        const status6 = status && status.filter(obj => { return obj.statusId == 6; })[0];
        const status7 = status && status.filter(obj => { return obj.statusId == 7; })[0];
        const status20 = status && status.filter(obj => { return obj.statusId == 20; })[0];
        const status90 = status && status.filter(obj => { return obj.statusId == 90; })[0];


        const rascunho = status1;

        const matching = {
            totalAmount: status2 && status3 && status4 && status2.totalAmount + status3.totalAmount + status4.totalAmount,
            totalProjects: status2 && status3 && status4 && status2.totalProjects + status3.totalProjects + status4.totalProjects
        };

        const analise = {
            totalAmount: status5 && status6 && status5.totalAmount + status6.totalAmount,
            totalProjects: status5 && status6 && status5.totalProjects + status6.totalProjects
        };

        const tepPo = status7 && status7;

        const finalizado = status20 && status20;
        const cancelado = status90 && status90;

        return (
            <Fragment>
                <Row>
                    <Col md={24}>
                        <CardDisplay>
                            <Row>
                                <Col md={4}>
                                    <p className="LabelPeriodo">&nbsp;</p>
                                    <h1>{I18n.get("Filtrar")}</h1>
                                    {/* <p className="DescriptionPage">{I18n.get("Informações dos seus projetos e marcas")}</p> */}
                                </Col>

                                <Col md={11}>
                                    <p className="LabelPeriodo">&nbsp;</p>
                                    <Checkbox.Group style={{ width: '100%' }} defaultValue={['7']} onChange={(e) => this.onChangeStatus(e)}>
                                        <Row>
                                            <Col span={8}>
                                                <Checkbox value="1">{I18n.get("Rascunho")}</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="5,6">{I18n.get("Em análise")}</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="20">{I18n.get("Finalizado")}</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="2,3,4">{I18n.get("Matching")}</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="7">{I18n.get("Andamento")}</Checkbox>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox value="90">{I18n.get("Cancelado")}</Checkbox>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Col>

                                <Col md={6}>
                                    <p className="LabelPeriodo">{I18n.get("Selecione o período")}</p>
                                    <RangePicker
                                        defaultValue={[moment(dayDate), moment(currentDate)]}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        }}
                                        format="DD/MM/YYYY"
                                    />
                                </Col>
                                <Col md={3}>
                                    <p className="LabelPeriodo">&nbsp;</p>
                                    <Button
                                        block
                                        disabled={!this.state.checkedValues || this.state.checkedValues.length == 0}
                                        onClick={() => this.onFilter()}
                                        type="primary"
                                        loading={this.state.task && this.state.task.busy}
                                    >
                                        {this.state.task && !this.state.task.busy && I18n.get("Filtrar")}

                                    </Button>
                                </Col>
                            </Row>


                        </CardDisplay>
                    </Col>
                </Row>


                <Row type="flex" style={{ marginBottom: "40px" }}>
                    <Col md={4}>
                        <CardDisplay>
                            <h2 className="title-span">
                                {/* <span>Projetos em</span> */}{I18n.get("Rascunho")}</h2>

                            {rascunho &&
                                <Fragment>
                                    <p className="NumberCard">
                                        {rascunho.totalProjects ? rascunho.totalProjects : 0}
                                    </p>

                                    <p className="DescriptionCard">
                                        &nbsp;
                                    </p>
                                </Fragment>
                            }
                            {!rascunho &&
                                <p className="LoadingCard">
                                    <Icon type="loading" />
                                </p>
                            }
                        </CardDisplay>
                    </Col>

                    <Col md={4}>
                        <CardDisplay>
                            <h2 className="title-span">
                                {/* <span>Projetos em</span> */}{I18n.get("Matching")}</h2>

                            {matching.totalProjects >= 0 &&
                                <Fragment>
                                    <p className="NumberCard">
                                        {matching.totalProjects}
                                    </p>

                                    <p className="DescriptionCard">
                                        <span>R$</span> {matching.totalAmount ? formatCurrency(matching.totalAmount) : 0}
                                    </p>
                                </Fragment>
                            }
                            {!matching.totalProjects && matching.totalProjects != 0 &&
                                <p className="LoadingCard">
                                    <Icon type="loading" />
                                </p>
                            }
                        </CardDisplay>
                    </Col>

                    <Col md={4}>
                        <CardDisplay>
                            <h2 className="title-span">
                                {/* <span>Projetos em</span> */}{I18n.get("Em análise")}</h2>

                            {analise.totalProjects >= 0 &&
                                <Fragment>
                                    <p className="NumberCard">
                                        {analise.totalProjects}
                                    </p>

                                    <p className="DescriptionCard">
                                        <span>R$</span> {analise.totalAmount ? formatCurrency(analise.totalAmount) : 0}
                                    </p>
                                </Fragment>
                            }
                            {!analise.totalProjects && analise.totalProjects != 0 &&
                                <p className="LoadingCard">
                                    <Icon type="loading" />
                                </p>
                            }
                        </CardDisplay>
                    </Col>

                    <Col md={4}>
                        <CardDisplay>
                            <h2 className="title-span">
                                {/* <span>Projetos em</span> */}{I18n.get("Andamento")}</h2>
                            {tepPo &&
                                <Fragment>
                                    <p className="NumberCard">
                                        {tepPo.totalProjects ? tepPo.totalProjects : 0}
                                    </p>

                                    <p className="DescriptionCard">
                                        <span>R$</span> {tepPo.totalAmount ? formatCurrency(tepPo.totalAmount) : 0}
                                    </p>
                                </Fragment>
                            }
                            {!tepPo &&
                                <p className="LoadingCard">
                                    <Icon type="loading" />
                                </p>
                            }
                        </CardDisplay>
                    </Col>

                    <Col md={4}>
                        <CardDisplay>
                            <h2 className="title-span">
                                {/* <span>Projetos em</span> */}{I18n.get("Finalizado")}</h2>
                            {finalizado &&
                                <Fragment>
                                    <p className="NumberCard">
                                        {finalizado.totalProjects ? finalizado.totalProjects : 0}
                                    </p>

                                    <p className="DescriptionCard">
                                        <span>R$</span> {finalizado.totalAmount ? formatCurrency(finalizado.totalAmount) : 0}
                                    </p>
                                </Fragment>
                            }
                            {!finalizado &&
                                <p className="LoadingCard">
                                    <Icon type="loading" />
                                </p>
                            }
                        </CardDisplay>
                    </Col>

                    <Col md={4}>
                        <CardDisplay>
                            <h2 className="title-span">
                                {/* <span>Projetos em</span> */}{I18n.get("Cancelado")}</h2>
                            {cancelado &&
                                <Fragment>
                                    <p className="NumberCard">
                                        {cancelado.totalProjects ? cancelado.totalProjects : 0}
                                    </p>

                                    <p className="DescriptionCard">
                                        <span>R$</span> {cancelado.totalAmount ? formatCurrency(cancelado.totalAmount) : 0}
                                    </p>
                                </Fragment>
                            }
                            {!cancelado &&
                                <p className="LoadingCard">
                                    <Icon type="loading" />
                                </p>
                            }
                        </CardDisplay>
                    </Col>
                </Row>


                <Row>
                    <Col md={14}>
                        <CardDisplay>
                            <h2>{I18n.get("Histórico / Mês")}</h2>
                            <Chart
                                height={240}
                                data={dataHistoricMonth}
                                scale={cols}
                                forceFit
                                className={`GraphCard ${dataHistoricMonth && dataHistoricMonth.length > 0 ? "show" : "hidden"}`}
                                onPlotClick={ev => {
                                    if (ev.data) {
                                        const { data } = ev;

                                        this.showDrawer(data, this.state.fromDate, this.state.toDate, "historicY");
                                    }

                                }}
                            >
                                <Axis name="name" />
                                <Axis
                                    name="total"
                                    label={{ formatter: val => `${formatMoneyGraph(val)}` }}
                                />
                                <Tooltip
                                    crosshairs={{
                                        type: "y"
                                    }}
                                />
                                <Geom
                                    type="interval"
                                    position="name*total"
                                    tooltip={['name*total', (name, total) => {
                                        return {
                                            // Custom tooltip on the title display and so on.
                                            name: 'Total:',
                                            title: name,
                                            value: 'R$ ' + formatMoneyGraph(total)
                                        };
                                    }]}

                                />
                            </Chart>

                            {this.renderCallBack(dataHistoricMonth)}
                        </CardDisplay>
                    </Col>
                    <Col md={10}>
                        <CardDisplay>
                            <h2>{I18n.get("Histórico / Ano")}</h2>
                            <Chart
                                height={240}
                                data={dataHistoric}
                                scale={cols}
                                forceFit
                                className={`GraphCard ${dataHistoric && dataHistoric.length > 0 ? "show" : "hidden"}`}
                                onPlotClick={ev => {
                                    if (ev.data) {
                                        const { data } = ev;

                                        this.showDrawer(data, this.state.fromDate, this.state.toDate, "historicY");
                                    }

                                }}
                            >
                                <Axis name="name" />
                                <Axis
                                    name="total"
                                    label={{ formatter: val => `${formatMoneyGraph(val)}` }}
                                />
                                <Tooltip
                                    crosshairs={{
                                        type: "y"
                                    }}
                                />
                                <Geom
                                    type="interval"
                                    position="name*total"
                                    tooltip={['name*total', (name, total) => {
                                        return {
                                            // Custom tooltip on the title display and so on.
                                            name: 'Total:',
                                            title: name,
                                            value: 'R$ ' + formatMoneyGraph(total)
                                        };
                                    }]}

                                />
                            </Chart>

                            {this.renderCallBack(dataHistoric)}

                        </CardDisplay>
                    </Col>
                </Row>

                <RowFlex type="flex">
                    <Col className="cardCol" md={8}>
                        <CardDisplay>
                            <h2>{I18n.get("Projetos")}</h2>
                            <Chart
                                height={240}
                                data={dv}
                                scale={cols}
                                padding={[0, 0, 0, 0]}
                                margin={[0, 0, 0, 0]}
                                forceFit
                                className={`GraphCard ${data && data.length > 0 ? "show" : "hidden"}`}
                                onPlotClick={ev => {
                                    if (ev.data) {
                                        const { data: { _origin: { projectId } } } = ev;
                                        const link = `/projetos/${projectId}/resumo`
                                        this.setState({
                                            redirectProject: true,
                                            url: link
                                        })
                                    }

                                }}



                            >
                                <Coord type={"theta"} radius={0.75} innerRadius={0.6} />
                                <Axis name="percent" />
                                <Tooltip
                                    showTitle={false}
                                    itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
                                />
                                <Guide>
                                    <Miolo>
                                        <p>{I18n.get("Total")}</p>
                                        <h3>{formatMoneyGraph(valor)}</h3>
                                    </Miolo>
                                </Guide>
                                <Geom
                                    type="intervalStack"
                                    position="percent"
                                    color="item"
                                    tooltip={[
                                        "item*count",
                                        (item, count) => {
                                            return {
                                                name: item,
                                                value: 'R$ ' + formatMoneyGraph(count)
                                            };
                                        }
                                    ]}
                                    style={{
                                        lineWidth: 1,
                                        stroke: "#fff"
                                    }}

                                >

                                </Geom>
                            </Chart>

                            {this.renderCallBack(data)}
                        </CardDisplay>
                    </Col>
                    {this.props.prefix != "partner" &&
                        <Col className="cardCol" md={16}>
                            <CardDisplay>
                                <h2></h2>
                                <h2>{I18n.get("Savings")}</h2>

                                <Chart
                                    height={400}
                                    data={dataSavingsdv}
                                    forceFit
                                    onPlotClick={ev => {
                                        if (ev.data) {

                                            const itemSelected = savings.raw.find(i => i.monthName == ev.data._origin.mes);

                                            if (itemSelected.projectIds.length > 0) {

                                                this.setState({
                                                    savingItem: {
                                                        name: "Interno X Externo",
                                                        total: ev.data._origin.mes,
                                                    }
                                                })

                                                const data = {
                                                    _origin: {
                                                        itemSelected: itemSelected.projectIds
                                                    }
                                                };

                                                this.showDrawer(data, this.state.fromDate, this.state.toDate, "saving");
                                            }
                                        }

                                    }}
                                >
                                    <Axis name="Externo" />
                                    <Axis name="Interno" />
                                    <Legend />
                                    <Tooltip
                                        crosshairs={{
                                            type: "y"
                                        }}
                                    />
                                    <Geom
                                        type="interval"
                                        position="mes*total"
                                        color={"name"}
                                        adjust={[
                                            {
                                                type: "dodge",
                                                marginRatio: 1 / 32
                                            }
                                        ]}
                                    />
                                </Chart>

                                {this.renderCallBack(savings)}
                            </CardDisplay>
                        </Col>
                    }
                    <Col className="cardCol" md={8}>
                        <CardDisplay>
                            <h2>{I18n.get("Briefings")}</h2>


                            <Chart
                                height={240}
                                data={dataBriefings}
                                padding="auto"
                                forceFit
                                className={`GraphCard ${dataBriefings && dataBriefings.length > 0 ? "show" : "hidden"}`}
                                onPlotClick={ev => {
                                    if (ev.data) {
                                        const { data } = ev;

                                        this.showDrawer(data, this.state.fromDate, this.state.toDate, "briefing");
                                    }

                                }}
                            >
                                <Coord type="polar" />
                                <Tooltip />

                                <Geom
                                    type="interval"
                                    color="name"
                                    position="name*total"
                                    tooltip={['name*total', (name, total) => {
                                        return {
                                            // Custom tooltip on the title display and so on.
                                            name: 'Total:',
                                            title: name,
                                            value: 'R$ ' + formatMoneyGraph(total)
                                        };
                                    }]}
                                    style={{
                                        lineWidth: 1,
                                        stroke: "#fff"
                                    }}
                                />
                            </Chart>

                            {this.renderCallBack(dataBriefings)}
                        </CardDisplay>
                    </Col>
                    <Col className="cardCol" md={8}>
                        <CardDisplay>
                            <h2>{I18n.get("Marcas")}</h2>


                            <Chart
                                height={240}
                                data={dataBrand}
                                padding="auto"
                                forceFit
                                className={`GraphCard ${dataBrand && dataBrand.length > 0 ? "show" : "hidden"}`}
                                onPlotClick={ev => {
                                    if (ev.data) {
                                        const { data } = ev;

                                        this.showDrawer(data, this.state.fromDate, this.state.toDate, "brand");
                                    }

                                }}
                            >
                                <Coord type="polar" />
                                <Tooltip />

                                <Geom
                                    type="interval"
                                    color="name"
                                    position="name*total"
                                    tooltip={['name*total', (name, total) => {
                                        return {
                                            // Custom tooltip on the title display and so on.
                                            name: 'Total:',
                                            title: name,
                                            value: 'R$ ' + formatMoneyGraph(total)
                                        };
                                    }]}
                                    style={{
                                        lineWidth: 1,
                                        stroke: "#fff"
                                    }}
                                />
                            </Chart>

                            {this.renderCallBack(dataBrand)}
                        </CardDisplay>
                    </Col>
                    {this.props.prefix == "client" &&
                        <Col className="cardCol" md={8}>
                            <CardDisplay>
                                <h2>{I18n.get("Parceiros")}</h2>

                                <Chart
                                    height={240}
                                    data={dataPartners || []}
                                    padding="auto"
                                    forceFit
                                    className={`GraphCard ${dataPartners && dataPartners.length > 0 ? "show" : "hidden"}`}
                                    onPlotClick={ev => {
                                        if (ev.data) {
                                            const { data } = ev;

                                            this.showDrawer(data, this.state.fromDate, this.state.toDate, "partner");
                                        }

                                    }}
                                >
                                    <Coord type="polar" />
                                    <Tooltip />
                                    {/* <Legend
                                    position="left"
                                    offsetY={-280 / 2 + 180}
                                    offsetX={10}
                                /> */}
                                    <Geom
                                        type="interval"
                                        color="name"
                                        position="name*total"
                                        tooltip={['name*total', (name, total) => {
                                            return {
                                                // Custom tooltip on the title display and so on.
                                                name: 'Total:',
                                                title: name && name,
                                                value: 'R$ ' + formatMoneyGraph(total && total)
                                            };
                                        }]}
                                        style={{
                                            lineWidth: 1,
                                            stroke: "#fff"
                                        }}
                                    />
                                </Chart>

                                {this.renderCallBack(dataPartners && dataPartners)}
                            </CardDisplay>
                        </Col>
                    }

                    {this.props.prefix == "partner" &&
                        <Col className="cardCol" md={8}>
                            <CardDisplay>
                                <h2>{I18n.get("Clientes")}</h2>
                                <Chart
                                    height={240}
                                    data={dataClients}
                                    padding="auto"
                                    forceFit
                                    className={`GraphCard ${dataClients ? "show" : "hidden"}`}
                                    onPlotClick={ev => {
                                        if (ev.data) {
                                            const { data } = ev;

                                            this.showDrawer(data, this.state.fromDate, this.state.toDate, "partner");
                                        }

                                    }}
                                >
                                    <Coord type="polar" />
                                    <Tooltip />
                                    {/* <Legend
                                    position="left"
                                    offsetY={-280 / 2 + 180}
                                    offsetX={10}
                                /> */}
                                    <Geom
                                        type="interval"
                                        color="name"
                                        position="name*total"
                                        tooltip={['name*total', (name, total) => {
                                            return {
                                                // Custom tooltip on the title display and so on.
                                                name: 'Total:',
                                                title: name,
                                                value: 'R$ ' + formatMoneyGraph(total)
                                            };
                                        }]}
                                        style={{
                                            lineWidth: 1,
                                            stroke: "#fff"
                                        }}
                                    />
                                </Chart>

                                {this.renderCallBack(dataClients)}
                            </CardDisplay>
                        </Col>
                    }
                </RowFlex>


                <DrawerBp
                    title=""
                    width={1024}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    className="DrawerBp"
                    destroyOnClose={true}
                >

                    {DrawerInfos && DrawerInfos &&
                        <Fragment>
                            <h2>{DrawerInfos.name}</h2>
                            <p className="description">{DrawerInfos.description}</p>
                            <p className="total">
                                <small>{this.state.savingItem ? "Mês" : "Total"}:</small>
                                <span className="boxtotal">
                                    {!this.state.savingItem &&
                                        <Fragment><strong>R$</strong> {formatCurrency(DrawerInfos.total)}</Fragment>
                                    }
                                    {this.state.savingItem &&
                                        DrawerInfos.total
                                    }
                                </span>
                            </p>




                            <TableCard
                                columns={this.state.savingItem ? columnsModalSaving : columnsModal}
                                dataSource={dataTable}
                                pagination={{ defaultPageSize: 10 }}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            this.setState({
                                                redirectProject: true,
                                                redirectId: record.key
                                            })
                                        },
                                    }
                                }}
                            />
                        </Fragment>
                    }
                    {!DrawerInfos &&
                        <Fragment>
                            <Skeleton active />
                        </Fragment>
                    }

                </DrawerBp>
            </Fragment >
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContainerClientEvent);
