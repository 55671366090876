import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import UploadFile from "../../components/FileUploadCrop";
import { CardBorder } from "../../components/Cards";
import styled from "styled-components";
import constants from "../../../constants";
import MaskedInput from "antd-mask-input";

import { TextCounter } from "./counterText";

import FormSkeleton from "../../components/FormSkeleton";

import {
  Row,
  Col,
  Button,
  Checkbox,
  Tooltip,
  Icon,
  Input,
  Select,
  Form,
} from "antd";

const { TextArea, Search } = Input;
const { Option } = Select;

const FieldCustom = styled.div``;

const BoxAddFild = styled(Search)`
  button {
    position: relative;

    i {
      display: none;
    }
  }
`;

class FormResponsible extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioUrls: [],
      socialUrls: [],
      countrySeleted: "",
      fieldBlur: "",
      profilePicture: "",
    };
  }

  getDataUpload = (dataForm) => {
    const { field, url } = dataForm;

    switch (field) {
      case "profilePicture":
        this.setState({
          profilePicture: url,
        });
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 1);
  };

  removeUrl = (str, url) => {
    if (str == "socialUrls") {
      const arr = this.state.socialUrls.filter((e) => e !== url);

      this.setState({
        socialUrls: arr,
      });
    } else {
      const arr = this.state.portfolioUrls.filter((e) => e !== url);

      this.setState({
        portfolioUrls: arr,
      });
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 10);
  };

  addUrl = (arrStr) => {
    switch (arrStr) {
      case "socialUrls":
        const valueInputSocial = this.props.form.getFieldValue("socialURLs");
        if (valueInputSocial) {
          this.setState({
            socialUrls: [...this.state.socialUrls, valueInputSocial],
          });

          this.props.form.resetFields("socialURLs");
        }
        break;

      case "portfolioUrls":
        const valueInputPortfolio =
          this.props.form.getFieldValue("portfolioURLs");
        if (valueInputPortfolio) {
          this.setState({
            portfolioUrls: [...this.state.portfolioUrls, valueInputPortfolio],
          });

          this.props.form.resetFields("portfolioURLs");
        }
        break;

      default:
        break;
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 500);
  };

  sendDataApi = () => {
    const fieldsForm = this.props.form.getFieldsValue();

    const dataForm = {
      ...fieldsForm,
      socialURLs: this.state.socialUrls,
      country: this.state.countrySeleted || this.props.partner.country,
      profilePicture:
        this.state.profilePicture || this.props.partner.profilePicture,
      portfolioURLs: this.state.portfolioUrls,
    };

    if (this.props.isLeader) {
      dataForm.Id = this.props.partnerId;
    } else {
      dataForm.partnerId = this.props.partnerId;
    }

    return dataForm;
  };

  handleSubmit = (field) => {
    if (field) {
      this.setState({
        fieldBlur: field,
      });
    }

    let data = {
      PartnerLeader: this.sendDataApi(),
      locale: this.props.locale,
    };

    this.props.action(data);
  };

  handleSubmitSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.action(this.sendDataApi());
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.isLeader &&
      this.props.partner &&
      this.props.partner.socialURLs != prevProps.partner.socialURLs
    ) {
      this.setState({
        socialUrls: this.props.partner.socialURLs,
        profilePicture: this.props.partner.profilePicture,
      });
    }

    if (
      (!this.props.isLeader &&
        this.props.partner &&
        this.props.partner.socialURLs != prevProps.partner.socialURLs) ||
      this.props.partner.portfolioURLs != prevProps.partner.portfolioURLs
    ) {
      this.setState({
        socialUrls: this.props.partner.socialURLs,
        portfolioUrls: this.props.partner.portfolioURLs,
        profilePicture: this.props.partner.profilePicture,
      });
    }

    if (
      this.props.errors != prevProps.errors &&
      Object.keys(this.props.errors).length > 0 &&
      this.props.errors[this.state.fieldBlur]
    ) {
      this.props.form.setFields({
        [this.state.fieldBlur]: {
          errors: [
            new Error(this.props.errors[this.state.fieldBlur].errorMessage),
          ],
        },
      });
    }

    // if (this.props.errors != prevProps.errors && Object.keys(this.props.errors).length > 0 && !this.props.errors["linkedin"]) {
    //     this.props.form.setFields({
    //         linkedin: {
    //             errors: null,
    //         },
    //     });
    // }
  }

  handleCountry = (e) => {
    if (e) {
      let obj = this.props.countryCodes.find((i) => i.code == e);

      let country = {
        name: obj.name,
        code: obj.code,
        prefix: obj.prefix,
      };

      this.setState({
        prefix: obj.prefix,
        countrySeleted: country,
      });
    }

    // this.props.form.resetFields('companyCity');

    // this.props.form.setFieldsValue({
    //     companyCity: '',
    //     companyState: '',
    // });

    setTimeout(() => {
      this.handleSubmit("country");
    }, 10);
  };

  getPrefix = (code) => {
    let prefixCode =
      this.props.countryCodes &&
      this.props.countryCodes.find((i) => i.code == code);

    if (prefixCode) {
      return prefixCode.prefix || "";
    } else {
      return "";
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    if (
      !this.props.isLeader &&
      (!this.props.partner || !this.props.partner?.partnerId)
    )
      return <FormSkeleton />;

    const { partner } = this.props;

    return (
      <Form onSubmit={this.handleSubmitSave}>
        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Informações Gerais")}
              </TitleMiddleLine>
            </Col>
            <Col sm={12}>
              <Form.Item
                label={
                  <span>
                    {I18n.get("Nome completo do responsável")}
                    <Tooltip
                      title={I18n.get(
                        "Pedimos o líder responsável aqui, nos passos a frente você poderá cadastrar todos os outros líderes"
                      )}
                    >
                      <Icon
                        className="iconLabel"
                        type="question-circle"
                        theme="filled"
                      />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("name", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: partner.name ? partner.name : null,
                })(<Input onBlur={() => this.handleSubmit("name")} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("Como você quer ser chamado?")}>
                {getFieldDecorator("nameAlias", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: partner.nameAlias ? partner.nameAlias : null,
                })(<Input onBlur={() => this.handleSubmit("nameAlias")} />)}
              </Form.Item>
            </Col>
            {/* <Col sm={12}>
                            <Form.Item label="Linkedin Pessoal">
                                {getFieldDecorator('linkedin', {
                                    rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    // initialValue: partner.linkedin ? partner.linkedin : null
                                })(
                                    <Input
                                        onBlur={() => this.handleSubmit()}
                                    />
                                )}
                            </Form.Item>
                        </Col> */}
            <Col sm={8}>
              <Form.Item label={I18n.get("E-mail")}>
                {getFieldDecorator("email", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: partner.email ? partner.email : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("email")}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("country", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    partner && partner.country && partner.country.code
                      ? partner.country.code
                      : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    // onSelect={(e) => this.handleCountry(e)}
                    onBlur={(e) => this.handleCountry(e)}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();

                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("Celular")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue: partner.phoneNumber
                      ? partner.phoneNumber
                      : null,
                  })(
                    <Input
                      // addonBefore={partner.company && partner.company.country && this.getPrefix(partner.company.country.prefix)}
                      addonBefore={
                        partner && partner.country && partner.country.prefix
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("Celular")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue: partner.phoneNumber
                      ? partner.phoneNumber
                      : null,
                  })(
                    <Input
                      // addonBefore={partner.company && partner.company.country && this.getPrefix(partner.company.country.prefix)}
                      addonBefore={
                        partner && partner.country && partner.country.prefix
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                    // <MaskedInput
                    //     addonBefore={partner && partner.country && partner.country.prefix}
                    //     mask="(11) 11111-1111" size="14"
                    //     onBlur={() => this.handleSubmit('phoneNumber')}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>

            <Col sm={12}>
              <Form.Item label={I18n.get("Linkedin Pessoal")}>
                {getFieldDecorator("linkedin", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: partner.linkedin ? partner.linkedin : null,
                })(<Input onBlur={() => this.handleSubmit("linkedin")} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                label={
                  <span>
                    {I18n.get("Outras redes sociais pessoais")}
                    <Tooltip
                      title={I18n.get(
                        "Aqui você pode colocar outras redes sociais que apresentam seu trabalho"
                      )}
                    >
                      <Icon
                        className="iconLabel"
                        type="question-circle"
                        theme="filled"
                      />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("socialURLs", {
                  rules: [{ required: false, message: "ops!" }],
                })(
                  <BoxAddFild
                    className="InputPlus"
                    placeholder=""
                    onSearch={() => this.addUrl("socialUrls")}
                    enterButton
                  />
                )}
              </Form.Item>

              <ul className="ListDinamic">
                {this.state.socialUrls &&
                  this.state.socialUrls.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item} target="_blank">
                          <Tooltip title={item}>
                            <Icon type="video-camera" />{" "}
                            <span className="txt">{item}</span>
                          </Tooltip>
                        </a>
                        <Icon
                          type="delete"
                          onClick={() => this.removeUrl("socialUrls", item)}
                          theme="filled"
                          className="iconList"
                        />
                      </li>
                    );
                  })}
              </ul>
            </Col>

            <Fragment>
              <Col sm={12}>
                <Form.Item
                  label={I18n.get("Breve currículo")}
                  className="textAreaCounter"
                >
                  {getFieldDecorator("resume", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue: (partner.resume && partner.resume) || "",
                  })(
                    <TextArea
                      onBlur={() => this.handleSubmit("resume")}
                      rows={5}
                      maxLength={500}
                    />
                  )}
                </Form.Item>
                <TextCounter
                  counter={getFieldValue("resume").length}
                  limit={500}
                  min={100}
                />
              </Col>
              <Col sm={12}>
                <Form.Item label={I18n.get("Foto de perfil")}>
                  {getFieldDecorator("profilePicture", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                  })(
                    <UploadFile
                      name="profilePicture"
                      nameField="profilePicture"
                      getDataUpload={this.getDataUpload}
                      idContext={this.props.partnerId}
                      imgUrl={
                        this.props.partner && this.props.partner.profilePicture
                      }
                      get2={this.props.get2 ? true : false}
                    />
                  )}
                </Form.Item>
              </Col>
            </Fragment>
          </Row>
        </CardBorder>

        <Row className="RowSection">
          <Col sm={8}></Col>
        </Row>
      </Form>
    );
  }
}

const MainFormResponsible = Form.create({})(FormResponsible);

export default MainFormResponsible;
