import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";

import '../../App.css';
import '../../Site.css';
import Footer from './footer';

import { AUTH_API } from '../../actions/auth/actions';

const mapStateToProps = state => {
    return {
        user: state.user,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(AUTH_API.logout());
        }
    };
};

class LandingPage extends Component {

    constructor() {
        super();
        this.state = {};
    }

    onLogoutClick(e) {
        e.preventDefault();

        this.props.logout();
    }

    render() {

        const isLogged = (this.props.auth && this.props.auth.logged_user);

        return (
            <div>
                <section>
                    <header className="is-inline-block">
                        <div className="top-header">
                            <div className="container">
                                <div className="row">
                                    <div className="logo-top-left">

                                        <a href="https://bpool.co" className="logo">
                                            <img className="logo-lg" src="/images/logo.1.svg" alt="Ollo" />
                                            <img className="logo-sm" src="/images/logo-mobile.svg" alt="Ollo" />
                                        </a>

                                    </div>
                                    <div className="top-right">
                                        <ul className="nav-link menuRight">

                                            { /*!isLogged && <li><a href="//www.bptest.xyz/cadastrar">CADASTRAR</a></li> */}
                                            {!isLogged && <li><NavLink to="/login" className="is-black">{I18n.get("ENTRAR")}</NavLink></li>}

                                            {isLogged && <li><a href="#" onClick={this.onLogoutClick.bind(this)} >{I18n.get("LOGOUT")}</a></li>}

                                        </ul>

                                        <span className="menu-open" >&#9776; </span>
                                        <nav className="nav">

                                            <div id="myNav" className="overlay">
                                                <a href="javascript:void(0)" className="closebtn" >&times;</a>
                                                <div className="overlay-content">
                                                    {/*<li className="has-text-white"><a href="//www.bptest.xyz">HOME</a></li>
                                            <li><a href="//www.bptest.xyz/boutique-pool">BPool</a></li>
                                            <li><a href="//www.bptest.xyz/#cliente">CLIENTES</a></li>
                                            <li><a href="//www.bptest.xyz/#parceiro">PARCEIROS CRIATIVOS </a></li>
                                            <li><a href="//www.bptest.xyz/nosso-time">TIME</a></li>
                                            <li><a href="//www.bptest.xyz/#contato">CONTATO</a></li>*/}
                                                </div>
                                            </div>

                                            <ul id="menu-bg">
                                                {/*<li><a href="//www.bptest.xyz">HOME</a></li>
                                        <li><a href="//www.bptest.xyz/boutique-pool">BPool</a></li>
                                        <li><a href="//www.bptest.xyz/#cliente">CLIENTES</a></li>
                                        <li><a href="//www.bptest.xyz/#parceiro">PARCEIROS CRIATIVOS </a></li>
                                        <li><a href="//www.bptest.xyz/nosso-time">TIME</a></li>
                                        <li><a href="//www.bptest.xyz/#contato">CONTATO</a></li>*/}
                                            </ul>

                                        </nav>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </header>
                    {this.props.children}
                </section>

                <Footer {...this.props} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPage);
