import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";

import '../../App.css';
//import '../../Site.css';

import logo from '../../image/logoWOtext.svg';
import Footer from './footer';
import TopMenu from './_top-menu';
import SubMenu from './_sub-menu';
import Loading from '../pages/loading';


import PartnerTermsUpdateModal from '../partner/terms-update-modal';

class LoggedPage extends Component {

    constructor(props) {
        super();
        this.state = {
        };
    }

    render() {

        const isInit = (!this.props.auth || this.props.auth.init);

        if (isInit) {
            return (<Loading />);
        }

        const isNotLogged = (this.props.auth && !this.props.auth.logged_user);
        const IsLogout = (this.props.auth && this.props.auth.is_logout);

        if (isNotLogged) {

            const login = {
                pathname: '/login',
                state: {
                    returnUrl: (IsLogout ? '' : this.props.location.pathname),
                }
            }

            return (<Redirect to={login}></Redirect>);
        }

        return (<div>
            <section className="is-fullheight logged-page preview-page-container">
                <div className="hero-head">
                    <nav className="navbar is-main navbar-main">
                        <div className="container">
                            <div className="navbar-brand is-expanded">
                                <a className="navbar-item" href="https://bpool.co">
                                    <img src={logo} alt="Ollo" />
                                </a>
                                <span className="navbar-burger burger" data-target="navbarMenu">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </div>
                            <div id="navbarMenu" className="navbar-menu is-active">
                                <div className="navbar-start">
                                    <div className="navbar-item">
                                    </div>
                                </div>

                                <div className="navbar-end" style={{ zIndex: 999 }}>

                                    {/* <LanguageNavBarItem {...this.props} /> */}


                                    <TopMenu {...this.props} />
                                </div>
                            </div>
                        </div>
                    </nav>
                    <SubMenu {...this.props} />
                </div>
                <div className="">


                    {this.props.children}

                </div>

            </section>

            <Footer {...this.props} />

            {this.props.partner && this.props.partner.id && !this.props.partner.terms &&
                <PartnerTermsUpdateModal {...this.props} />
            }

        </div>

        );
    }
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LoggedPage));
