import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { saveAs } from "file-saver";
import { PDFReader } from "reactjs-pdf-reader";
import { Modal } from "antd";

import * as S from "./styles";

export const ModalTerms = ({ openTerms, handleClickCancel }) => {
  let location = useLocation();
  const [localeUser, setLocaleUser] = useState("pt");

  useEffect(() => {
    const language = getParamsLocale();
    setLocaleUser(language);
  }, [location]);

  const getParamsLocale = () => {
    let language = "";
    if (location.search) {
      const locationSplit = location.search.split("=");
      language = locationSplit[1];
    } else {
      language = "pt";
    }
    return language;
  };

  const renderPdfByLocation = (language) => {
    if (language === "pt" || language === "br") {
      return "https://assets-ollo.bpool.co/terms/blank.pdf";
    } else if (language === "en") {
      return "https://assets-ollo.bpool.co/terms/blank.pdf";
    } else if (language === "es") {
      return "https://assets-ollo.bpool.co/terms/blank.pdf";
    }
  };

  const downloadTerms = () => {
    saveAs(renderPdfByLocation(localeUser), "BPool-terms.pdf");
  };

  return (
    <S.WrapperModal
      visible={openTerms}
      onOk={downloadTerms}
      okText={I18n.get("Download")}
      onCancel={handleClickCancel}
      cancelText="Fechar"
      width={1000}
    >
      <S.TermsWrapper>
        <PDFReader
          url={renderPdfByLocation(localeUser)}
          showAllPage
          width={800}
        />
      </S.TermsWrapper>
    </S.WrapperModal>
  );
};
