import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col, Table, Modal, Select, message } from "antd";
import { EditOutlined, DeleteOutlined, SendOutlined } from "@ant-design/icons";
import { useReferences } from "../../../hooks/useReferences/useReferences.hook";
import { Logged } from "../../RegistrationFlow/components/Logged";
import Main from "../../../components/UI/Main";
import { Input } from "../../../components/UI/Input";
import Loading from "../../../components/UI/Loading";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { SelectOne } from "../../../components/UI/SelectOne";

import * as S from "./styles";

const { Option } = Select;

const ADD_REFERENCE_SUCCESS = "Reference add successful.";
const SAVE_REFERENCE_SUCCESS = "Reference changed successful.";
const RESEND_EMAIL_SUCCESS = "Resend email successful";
const RESEND_EMAIL_ERROR = "There was an error resending the email.";
const ERROR_SEND_WITHOUT_LANGUAGE = "Select a language.";
const DELETE_REFERENCE_SUCCESS = "Reference deleted successful.";
const DELETE_REFERENCE_ERROR = "There was an error deleting the reference.";

export const ReferencesList = () => {
  const {
    getListReferences,
    postReference,
    updateReference,
    deleteReference,
    resendEmailReference,
  } = useReferences();
  const [data, setData] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    type: "",
    id: null,
  });
  const [isSending, setIsSending] = useState(false);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [partnerIdTemp, setPartnerIdTemp] = useState("");
  const [inputsForm, setInputsForm] = useState({
    name: "",
    email: "",
    culture: undefined,
    sendEmail: true,
  });
  const [errorLanguage, setErrorLanguage] = useState(false);
  const [errorEmailAddReference, setErrorEmailAddReference] = useState("");
  const [resendVisible, setResendVisible] = useState(false);
  const [createVisible, setCreateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [errors, setErrors] = useState(null);

  const renderStatus = (status) => {
    if (status === 1) {
      return (
        <S.StatusDiv>
          <S.CircleCreated />
          <S.StatusText>Created</S.StatusText>
        </S.StatusDiv>
      );
    }
    if (status === 2) {
      return (
        <S.StatusDiv>
          <S.CirclePending />
          <S.StatusText>Pending</S.StatusText>
        </S.StatusDiv>
      );
    }
    if (status === 3) {
      return (
        <S.StatusDiv>
          <S.CircleConcluded />
          <S.StatusText>Concluded</S.StatusText>
        </S.StatusDiv>
      );
    }
  };

  const handleOk = () => {
    setOpenModal({
      open: false,
      type: "",
      id: null,
    });
  };

  const handleCancel = () => {
    setOpenModal({ open: false, type: "", id: null });
    setInputsForm({
      name: "",
      email: "",
      culture: undefined,
    });
    setErrorLanguage(false);
    setErrors(null);
  };

  const handleEditModal = (id) => {
    console.log("handleEditModal id", id);
    setOpenModal({ open: true, id, type: "edit" });
    const finded = data.find((item) => item.id === id);

    if (finded) {
      setInputsForm({
        name: finded.name || "",
        email: finded.email || "",
        culture: finded.culture || undefined,
      });
    }
  };

  const resendEmail = async (id) => {
    setPartnerIdTemp(id);
    setIsResendingEmail(true);
    const response = await resendEmailReference(id);
    if (response.success) {
      setResendVisible(true);
    } else {
      message.error(I18n.get(RESEND_EMAIL_ERROR));
    }
    setIsResendingEmail(false);
    setPartnerIdTemp("");
  };

  const columns = [
    {
      title: "Reference",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => <div>{renderStatus(record.status)}</div>,
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <>
          {record.status != 3 && (
            <div>
              <S.ButtonIcoStatus onClick={() => handleEditModal(record.id)}>
                <EditOutlined />
              </S.ButtonIcoStatus>
              <S.ButtonIcoStatus
                style={{ marginLeft: 5 }}
                onClick={() =>
                  setOpenModal({ open: true, id: record.id, type: "del" })
                }
              >
                {isDeleting && partnerIdTemp === record.id ? (
                  <Loading sizeIcon={7.2} align="center" />
                ) : (
                  <DeleteOutlined />
                )}
              </S.ButtonIcoStatus>
              <S.ButtonIcoStatus
                style={{ marginLeft: 5 }}
                onClick={() => resendEmail(record.id)}
              >
                {isResendingEmail && partnerIdTemp === record.id ? (
                  <Loading sizeIcon={7.2} align="center" />
                ) : (
                  <SendOutlined />
                )}
              </S.ButtonIcoStatus>
            </div>
          )}
        </>
      ),
    },
  ];

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value,
    });
    if (id === "culture" && value !== undefined) {
      setErrorLanguage(false);
    }
  };

  const renderTitleModal = ({ type }) => {
    if (type === "add") {
      return I18n.get("Add Reference");
    }
    if (type === "edit") {
      return I18n.get("Edit Reference");
    }
    if (type === "del") {
      return I18n.get("Delete Reference");
    }
  };

  const renderTitleDelete = (id) => {
    const itemReference = data.find((i) => i.id == id);
    return `${I18n.get("Would you like to delete the")}: ${itemReference.email
      } ${I18n.get("reference?")}`;
  };

  const loadInit = async () => {
    setIsLoadingPage(true);
    const response = await getListReferences();

    if (response.success) {
      setData(response.data);
    }

    setIsLoadingPage(false);
  };

  const addReference = async () => {
    setIsSending(true);
    const response = await postReference(inputsForm);
    if (response.success) {
      setCreateVisible(true);
      handleCancel();
      await loadInit();
    } else {
      setErrors(response?.errors);

      // if (response?.errors?.EmailAlready) {
      //   setErrorEmailAddReference(
      //     response?.errors?.EmailAlready?.errorMessage
      //   );
      // }
    }
    setIsSending(false);
  };

  const saveReference = async () => {
    setIsSending(true);
    const send = {
      ...inputsForm,
      id: openModal.id,
    };
    const response = await updateReference(send);
    if (response.success) {
      setEditVisible(true);
      handleCancel();
      await loadInit();
    }
    setIsSending(false);
  };

  const handleDelete = async (id) => {
    setPartnerIdTemp(id);
    setIsDeleting(true);
    const response = await deleteReference(id);

    if (response.success) {
      setDeleteVisible(true);
      handleCancel();
      await loadInit();
    } else {
      message.error(I18n.get(DELETE_REFERENCE_ERROR));
      handleCancel();
    }
    setPartnerIdTemp("");
    setIsDeleting(false);
    await loadInit();
  };

  const languages = [
    { code: "pt", label: "Portuguese" },
    { code: "en", label: "English" },
    { code: "es", label: "Spanish" },
  ];

  const filterCompleted =
    data && data.length > 0 && data.filter((i) => i.status === 3);

  console.log("filterCompleted", filterCompleted.length);

  useEffect(() => {
    loadInit();
  }, []);

  return (
    <>
      <Logged showStepBar={false}>
        <div className="container">
          <Row>
            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>
                          {I18n.get(
                            "Great! You have completed the pre-register."
                          )}
                        </S.H4>
                      </S.TitleWithIcon>
                      <S.Paragraph>
                        {I18n.get("Thanks. Your application was submitted!")}
                      </S.Paragraph>
                    </S.RowCenter>
                  </Col>
                </Row>

                {filterCompleted.length >= 3 && (
                  <Row style={{ marginTop: 40 }}>
                    <Col xs={24} style={{ marginTop: 30 }}>
                      <S.TitleSectionForm
                        style={{
                          margin: "0",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {I18n.get(
                          "Recebemos as avaliações das suas referências e agora sua empresa passa a fazer parte de um banco de dados que é acessado conforme flutuação de demanda na BPool."
                        )}
                      </S.TitleSectionForm>
                    </Col>

                    <Col xs={24}>
                      <S.TitleSectionForm
                        style={{
                          margin: "0",
                          textAlign: "center",
                          width: "100%",
                          textDecoration: "underline"
                        }}
                      >
                        {I18n.get(
                          "Não há uma prazo determinado para a conclusão desta etapa."
                        )}
                      </S.TitleSectionForm>
                    </Col>

                    <Col xs={24} style={{ marginTop: 30 }}>
                      <S.TitleSectionForm
                        style={{
                          margin: "0",
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        {I18n.get(
                          "Obrigado pelo seu interesse em fazer parte da Ollo!"
                        )}
                      </S.TitleSectionForm>
                    </Col>
                  </Row>
                )}

                {filterCompleted.length < 3 && (
                  <Row style={{ marginTop: 40 }}>
                    <Col xs={24} style={{ marginTop: 30 }}>
                      <S.TitleSectionForm style={{ margin: "0" }}>
                        {I18n.get(
                          "Now, people indicated as professional references will receive an email requesting a quick assessment of your work or company."
                        )}
                      </S.TitleSectionForm>
                      <S.TitleSectionForm>
                        {I18n.get(
                          "You can follow the progress of the assessments through below, as well as add, change emails, or even resend the invitation again."
                        )}
                      </S.TitleSectionForm>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <ButtonBpool
                        text={I18n.get("Add Reference")}
                        theme="primary"
                        loading={false}
                        onClick={() =>
                          setOpenModal({ open: true, type: "add", id: null })
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24}>
                      <S.WrapperTable>
                        <Table
                          columns={columns}
                          dataSource={data}
                          pagination={false}
                          loading={isLoadingPage}
                        />
                      </S.WrapperTable>
                    </Col>
                  </Row>
                )}
              </Main>
            </Col>
          </Row>
        </div>
      </Logged>

      <Modal
        title={
          !openModal.type
            ? "Modal Reference"
            : renderTitleModal({ type: openModal.type })
        }
        visible={openModal.open && openModal.type != "del"}
        centered
        width={800}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <S.WrapperFooterModal>
            <ButtonBpool
              key="back"
              text={I18n.get("Cancel")}
              theme="secondary"
              loading={false}
              onClick={handleCancel}
            />
            {openModal.type === "del" ? (
              <ButtonBpool
                key="delete"
                text={I18n.get("Delete")}
                theme="danger"
                loading={isDeleting}
                onClick={() => handleDelete(openModal.id)}
              />
            ) : (
              <ButtonBpool
                key="add"
                text={
                  openModal.type === "edit" ? I18n.get("Save") : I18n.get("Add")
                }
                theme="primary"
                loading={isSending}
                onClick={
                  openModal.type === "edit"
                    ? () => saveReference()
                    : () => addReference()
                }
              />
            )}
          </S.WrapperFooterModal>,
        ]}
      >
        {openModal.type === "del" ? (
          <Row style={{ margin: "0 -15px" }}>
            <Col xs={24} sm={24}>
              {I18n.get("Do you want delete this reference?")}
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ margin: "0 -15px" }}>
              <Col xs={12} sm={12}>
                <Input
                  placeholder={I18n.get("Name")}
                  required
                  value={inputsForm.name}
                  onChange={(value) => onChangeInputForm("name", value)}
                  error={errors && errors?.Name?.errorMessage}
                />
              </Col>
              <Col xs={12} sm={12}>
                <Input
                  placeholder={I18n.get("Email")}
                  required
                  value={inputsForm.email}
                  onChange={(value) => onChangeInputForm("email", value)}
                  error={
                    (errors && errors?.EmailAlready?.errorMessage) ||
                    errors?.Email?.errorMessage
                  }
                />
              </Col>
            </Row>
            <Row style={{ margin: "0 -15px" }}>
              <Col xs={12} sm={12}>
                <S.SelectBox>
                  <SelectOne
                    options={languages}
                    value={inputsForm.culture}
                    onChange={(value) => onChangeInputForm("culture", value)}
                    required
                    placeholder={I18n.get("Idioma")}
                    error={errors && errors?.Culture?.errorMessage}
                  />
                </S.SelectBox>
              </Col>
            </Row>
          </>
        )}
      </Modal>

      <ModalBp
        visible={openModal.open && openModal.type == "del"}
        bodyText={
          openModal.open &&
          openModal.type == "del" &&
          renderTitleDelete(openModal.id)
        }
        // subBodyText={I18n.get("Do you want delete this reference?")}
        okText={I18n.get("Delete")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={() => handleDelete(openModal.id)}
        onCancel={handleCancel}
        typeModal="isConfirm"
      />

      <ModalBp
        visible={createVisible}
        bodyText={I18n.get("Reference added successfully")}
        // subBodyText={I18n.get("Deseja apagar a referência?")}
        okText={I18n.get("OK")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={() => setCreateVisible(false)}
        onCancel={() => { }}
      // typeModal="isConfirm"
      // isDanger={true}
      />

      <ModalBp
        visible={editVisible}
        bodyText={I18n.get("Reference edited successfully")}
        // subBodyText={I18n.get("Deseja apagar a referência?")}
        okText={I18n.get("OK")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={() => setEditVisible(false)}
        onCancel={() => { }}
        actionBp="ok-edited"
      />

      <ModalBp
        visible={deleteVisible}
        bodyText={I18n.get("Reference deleted successfully")}
        // subBodyText={I18n.get("Deseja apagar a referência?")}
        okText={I18n.get("OK")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={() => setDeleteVisible(false)}
        onCancel={() => { }}
        actionBp="ok-deleted"
      />

      <ModalBp
        visible={resendVisible}
        bodyText={I18n.get("We have successfully sent the invitation again")}
        // subBodyText={I18n.get("Deseja apagar a referência?")}
        okText={I18n.get("OK")}
        cancelText={I18n.get("Cancel")}
        handleClickOk={() => setResendVisible(false)}
        onCancel={() => { }}
        actionBp="ok-resend"
      // typeModal="isConfirm"
      // isDanger={true}
      />
    </>
  );
};
