import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams } from "react-router-dom";
import { Icon, Row, Col, Modal, message, notification } from "antd";
import { usePo } from "../../../../../../../hooks/usePo/usePo.hook";
import { ButtonBpool } from "../../../../../../../components/UI/ButtonBpool";
import { Input } from "../../../../../../../components/UI/Input";
import { FormatCurrency } from "../../../../../../../components/UI/FormatCurrency";

import { Attachments } from "./Attachments";
import * as S from "./styles";

export const PoBoxItem = ({ data, currency, refreshPos }) => {
  let params = useParams();
  const { updateCodePo } = usePo();
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [errorCodeMissing, setErrorCodeMissing] = useState("");
  const [inputCode, setInputCode] = useState({
    id: "",
    code: "",
    projectId: "",
  });

  const sendApi = async () => {
    if (inputCode?.code) {
      setIsSending(true);
      const response = await updateCodePo(inputCode);
      if (response?.status === 200) {
        notification.success({
          message: I18n.get("Code #SPA updated successfully"),
          duration: 3,
        });
        refreshPos();
      } else {
        notification.error({
          message: I18n.get("Error updating code #SPA"),
          duration: 3,
        });
      }
      setIsSending(false);
    } else {
      setErrorCodeMissing(I18n.get("Code is required"));
    }
  };

  const cancelEditCode = () => {
    setModalAddVisible(false);
    setInputCode({ id: "", code: "", projectId: "" });
  };

  const handleEditCodePo = (id) => {
    setModalAddVisible(true);
    setInputCode({ id, code: data?.code, projectId: params?.projectId });
  };

  const onChangeInputCodeForm = (id, value) => {
    setInputCode({
      ...inputCode,
      [id]: value ? value : null,
    });
  };

  const renderItem = ({ label, content }) => {
    return (
      <S.InfoItem>
        <S.LabelItem>{`${label}: `}</S.LabelItem>
        <S.ContentItem>{content}</S.ContentItem>
      </S.InfoItem>
    );
  };

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.RowInternal>
            <S.Code>
              <p>#SPA</p>
              <S.Divisor />
              <p>
                <span>{data?.projectPO}</span>
              </p>
            </S.Code>
            <S.Values>
              <S.ValuePo>
                <p>{`Valor:`}</p>
                <FormatCurrency amount={data?.total} currency={currency} />
              </S.ValuePo>
              <S.ValueFee>
                <p>{`Fee Ollo:`}</p>
                {data?.bpCommissionAmount ? (
                  <FormatCurrency
                    amount={data?.bpCommissionAmount}
                    currency={currency}
                  />
                ) : (
                  <span>---</span>
                )}
              </S.ValueFee>
            </S.Values>
          </S.RowInternal>
          <S.RowInternal style={{ marginTop: 15 }}>
            <S.InfosLeft>
              {renderItem({
                label: I18n.get("Emission"),
                content: `${data?.registrationName} - ${data?.registrationId}`,
              })}
              {renderItem({
                label: I18n.get("Address"),
                content: `${data?.addressLine1}, ${data?.addressLine2} - ${data?.city} - ${data?.state} - ${data?.postcode}`,
              })}
            </S.InfosLeft>
            <S.InfosRight>
              <S.InfoItem>
                <S.LabelItem>{I18n.get("Code")}</S.LabelItem>
                <S.ContentItem style={{ width: "100px" }}>
                  {data?.code}
                </S.ContentItem>
                <S.ActionCodeEdit>
                  <button onClick={() => handleEditCodePo(data?.id)}>
                    <Icon type="edit" />
                  </button>
                </S.ActionCodeEdit>
              </S.InfoItem>
              <Attachments data={data?.files || []} />
            </S.InfosRight>
          </S.RowInternal>
        </S.Content>
      </S.Wrapper>
      <Modal
        title={I18n.get("Edit code #SPA")}
        centered
        visible={modalAddVisible}
        onOk={sendApi}
        onCancel={cancelEditCode}
        width={600}
        footer={[
          <ButtonBpool
            text={"Save"}
            theme="primary"
            onClick={sendApi}
            loading={isSending}
          />,
        ]}
      >
        <Row>
          <Col sm={24}>
            <S.SubTitle>
              <p>{I18n.get("New code to #SPA")}</p>
              <span />
            </S.SubTitle>
          </Col>
          <Col sm={24}>
            <Input
              placeholder={I18n.get("Code")}
              required
              label
              error={errorCodeMissing}
              clearError={() => {
                setErrorCodeMissing("");
              }}
              value={inputCode?.code}
              onChange={(value) => onChangeInputCodeForm("code", value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
