import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { TitleMiddleLine } from '../../components/Titles';
import { CardBorder } from '../../components/Cards';
import styled from 'styled-components';
import MaskedInput from 'antd-mask-input';
import { Link, Redirect } from 'react-router-dom';

import {
    Row,
    Col,
    Tooltip,
    Icon,
    Select,
    Button,
    Input,
    Rate,
    Form
} from 'antd';


const { Search, TextArea } = Input;
const { Option } = Select;

const BoxAddFild = styled(Search)`
    button {
        position:relative;

        i {
            display: none;
        }
    }
`;

const RowStarts = styled(Row)`
    margin-top: 30px;
    i {
        font-size: 50px;
    }
`



class FormReference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratings: [],
            sendRegister: false,
            countrySeleted: "",
            formState: []
        }
    }

    sendDataApi = (isSubmit) => {
        const fieldsForm = this.props.form.getFieldsValue();

        const dataForm = {
            ...fieldsForm,
            country: this.state.countrySeleted,
            comment: fieldsForm.comment,
            ratings: this.state.ratings,
            token: this.props.reference.verificationToken,
            isSubmit: isSubmit,

        }

        return dataForm;
    }

    handleVotes = (vote) => {

        setTimeout(() => {
            const voteSelected = this.props.form.getFieldValue(`ratings[${vote}]`);



            this.setState((prevState) => {

                const current = Object.assign({}, prevState);

                current.ratings[vote] = this.state.ratings[vote];
                current.ratings[vote].value = voteSelected;

                return current;

            });
        }, 50);



    }

    // handleSubmit = (field) => {

    //     if (field) {
    //         this.setState({
    //             fieldBlur: field
    //         })
    //     }


    //     this.props.action(this.sendDataApi(false));
    // };

    handleSubmitSave = (e) => {
        e.preventDefault();

        this.setState({
            sendRegister: true
        });

        this.props.form.validateFields((err, values) => {
            this.setState({
                formState: values
            })
            if (!err) {

                let x = {
                    Reference: this.sendDataApi(true),
                    Locale: this.props.locale
                };

                this.props.action(x);
            }
        });

    };

    componentDidUpdate(prevProps, prevState) {

        if (!prevProps) return;
        if (!prevState) return;

        if (this.props.errors != prevProps.errors) {
            Object.keys(this.props.errors).map(field => {

                let fieldClean = field.split(".")[1];
                let fieldClean0 = field.split(".")[0];

                if (fieldClean) {
                    this.props.form.setFields({
                        [fieldClean0]: {
                            value: this.state.formState[field],
                            errors: [new Error(this.props.errors[field].errorMessage)],
                        },
                    });
                } else {
                    this.props.form.setFields({
                        [field]: {
                            value: this.state.formState[field],
                            errors: [new Error(this.props.errors[field].errorMessage)],
                        },
                    });
                }

            });
        };

        if (this.props.reference && this.props.reference.ratings != prevProps.reference.ratings) {
            this.setState({
                ratings: this.props.reference.ratings
            })
        }
    }

    handleCountry = (e) => {

        let obj = this.props.countryCodes.find(i => i.code == e);

        let country = {
            name: obj.name,
            code: obj.code,
            prefix: obj.prefix
        }

        this.setState({
            prefix: obj.prefix,
            fieldBlur: "country",
            countrySeleted: country
        });

    }

    getPrefix = (code) => {
        let prefixCode = this.props.countryCodes && this.props.countryCodes.find(i => i.code == code);

        if (prefixCode) {
            return prefixCode.prefix || "";
        } else {
            return "";
        }

    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const { reference } = this.props;

        return (
            <Form onSubmit={this.handleSubmitSave}>
                <CardBorder
                >
                    <Row>
                        <Col sm={24}>
                            <TitleMiddleLine>{I18n.get("Sobre você")}</TitleMiddleLine>
                        </Col>
                        <Col sm={12}>
                            <Form.Item
                                label={
                                    <span>{I18n.get("Nome")}<Tooltip title={I18n.get("Pedimos o líder responsável aqui, nos passos a frente você poderá cadastrar todos os outros líderes")}>
                                        <Icon className="iconLabel" type="question-circle" theme="filled" />
                                    </Tooltip>
                                    </span>
                                }
                            >
                                {getFieldDecorator('name', {
                                    rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    initialValue: reference.name ? reference.name : null
                                })(
                                    <Input
                                    // onBlur={() => this.handleSubmit('name')}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item label={I18n.get("E-mail")}>
                                {getFieldDecorator('email', {
                                    rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    initialValue: reference.email && reference.email
                                })(
                                    <Input
                                        disabled={this.props.isReference}
                                    // onBlur={() => this.handleSubmit('email')}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item label={I18n.get("País")}>
                                {getFieldDecorator('country', {
                                    rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    initialValue: reference && reference.country && reference.country.code ? reference.country.code : ""
                                })(
                                    <Select
                                        style={{ width: "100%" }}
                                        onSelect={(e) => this.handleCountry(e)}
                                        showSearch={true}
                                    // filterOption={(input, option) =>
                                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    // }
                                    >
                                        {this.props.countryCodes && this.props.countryCodes.map((item) => {

                                            let codeString = item.isO2.toLowerCase();

                                            return <Option
                                                value={item.code}
                                                key={item.code}
                                                prefix={item.prefix}
                                            >
                                                <img src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`} /> {item.name}
                                            </Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            {getFieldValue("country") != "BRA" &&
                                <Form.Item label={I18n.get("Celular")}>
                                    {getFieldDecorator('phoneNumber', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                        initialValue: reference.phoneNumber ? reference.phoneNumber : null
                                    })(
                                        <Input
                                            addonBefore={this.state.prefix}
                                        // onBlur={() => this.handleSubmit('phoneNumber')}
                                        />
                                    )}
                                </Form.Item>
                            }
                            {getFieldValue("country") == "BRA" &&
                                <Form.Item label={I18n.get("Celular")}>
                                    {getFieldDecorator('phoneNumber', {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                        initialValue: reference.phoneNumber ? reference.phoneNumber : null
                                    })(
                                        <Input
                                            addonBefore={this.state.prefix}
                                        // onBlur={() => this.handleSubmit('phoneNumber')}
                                        />
                                        // <MaskedInput mask="(11) 11111-1111" size="14"
                                        //     addonBefore={this.state.prefix}
                                        // // onBlur={() => this.handleSubmit('phoneNumber')}
                                        // />
                                    )}
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                </CardBorder>
                <CardBorder
                >
                    <RowStarts>
                        <Col sm={24}>
                            <TitleMiddleLine>{I18n.get("Avaliação")}</TitleMiddleLine>
                        </Col>

                        {this.props.reference && this.props.reference.ratings && this.props.reference.ratings.map((item, index) =>

                            <Col sm={12} key={index}>
                                <Form.Item
                                    label={item.label}
                                >
                                    {getFieldDecorator(`ratings[${index}]`, {
                                        rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    })(
                                        <Rate
                                            onChange={() => this.handleVotes(index)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        )}


                    </RowStarts>
                    <Row>
                        <Col sm={24}>
                            <Form.Item label={I18n.get("Quer deixar algum comentário?")} className="textAreaCounter">
                                {getFieldDecorator('comment', {
                                    rules: [{ required: false, message: 'Campo obrigatório!' }],
                                    initialValue: ""
                                })(
                                    <TextArea
                                        rows={4}
                                    />
                                )}
                            </Form.Item>
                            {/* <TextCounter
                                counter={getFieldValue('comment').length}
                            /> */}
                        </Col>
                    </Row>


                    {this.state.sendRegister && this.props.errors && Object.keys(this.props.errors).length > 0 && this.props.errors.phoneNumber &&
                        < div >
                            <Row className="RowSection">
                                <Col sm={8}>
                                </Col>
                            </Row>

                            <Row className="">
                                <Col sm={24}>
                                    <div className="ulErrors">
                                        <ul>
                                            {Object.keys(this.props.errors).map((item) => {

                                                const error = this.props.errors[item].errorMessage;

                                                if (item == "phoneNumber") {
                                                    return <li><Icon type="exclamation-circle" /> {error}</li>
                                                }
                                                if (item == "country") {
                                                    return <li><Icon type="exclamation-circle" /> {error}</li>
                                                }
                                            })}

                                        </ul>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    }
                    <Row className="RowSection">
                        <Col sm={8}>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3}>
                            {/* <Link to={`/referencia/cadastro/sobre-voce`}><Button shape="round">Voltar</Button></Link> */}
                        </Col>
                        <Col sm={13}>
                        </Col>
                        <Col sm={8}>
                            <Button style={{ float: "right" }} type="primary" shape="round" htmlType="submit">{I18n.get("FINALIZAR")}</Button>
                        </Col>
                    </Row>
                </CardBorder>

            </Form>
        );
    }
}

const MainFormReference = Form.create({})(FormReference);

export default MainFormReference;
