import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Modal, Checkbox, message, notification } from "antd";
import { usePo } from "../../../hooks/usePo/usePo.hook";
import { useUpload } from "../../../hooks/useUpload/useUpload.hook";
import Content from "../../../components/UI/Content";
import Main from "../../../components/UI/Main";
import LoggedPage from "../../../../components/page-templates/fillForm-logged";
import Loading from "../../../components/UI/Loading";
import { H2Border } from "../../../components/UI/Titles";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { Input } from "../../../components/UI/Input";
import { SelectOne } from "../../../components/UI/SelectOne";
import { InputMask } from "../../../components/UI/InputMask";
import { UploadInternal } from "../../../components/UI/UploadInternal";
import { PoBoxItem } from "./components/PoBoxItem";
import { ModalBp } from "../../../components/UI/ModalBp/ModalBp";
import { CurrencyInput } from "../../../components/UI/CurrencyInput";
import { TabsProject } from "../../../components/UI/TabsProject";
import {
  hasError,
  clearErrorInput,
} from "../../RegistrationFlow/utils/errorsInput";
import { RequestProjectCompletion } from "../components/RequestProjectCompletion";
import constants from "../../../../constants";

import * as S from "./styles";

export const POInProgress = () => {
  let params = useParams();
  const state = useSelector((state) => state);
  const auth = useSelector((state) => state?.auth);
  const { getPo, addNewPo, startProject } = usePo();
  const { uploadFile } = useUpload();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBillings, setIsLoadingBillings] = useState(false);
  const [listOfBillings, setListOfBillings] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [checkboxCompany, setCheckboxCompany] = useState(false);
  const [inputsForm, setInputsForm] = useState({});
  const [filePo, setFilePo] = useState({});
  const [isLoadingInput, setIsLoadingInput] = useState(false);
  const [addIsDisabled, setAddIsDisabled] = useState(true);
  const [infosPo, setInfosPo] = useState({});
  const [loadSend, setLoadSend] = useState(false);
  const [successStart, setSuccessStart] = useState(false);
  const [billingCurrency, setBillingCurrency] = useState({});
  const [statusProject, setStatusProject] = useState(null);
  const [errorsInputsForm, setErrorsInputsForm] = useState([]);
  const [paymentTermDays, setPaymentTermDays] = useState("");
  const [countryClient, setCountryClient] = useState("");

  const history = useHistory();

  const loadPos = async () => {
    const response = await getPo({ id: params?.projectId });
    if (response?.success) {
      console.log("respnse?.data #PO", response?.data);
      setInfosPo({
        billingTotalFmt: response?.data?.billingTotalFmt,
        billingPOBalance: response?.data?.billingPOBalance,
        billingPOBalanceFmt: response?.data?.billingPOBalanceFmt,
      });
      setListOfBillings(response?.data?.billings);
      setBillingCurrency(response?.data?.billingCurrency);
      setStatusProject(response?.data?.status);
      setPaymentTermDays(response?.data?.paymentTermDays);
    } else {
      message.error(I18n.get("Ops algo deu errado!"));
    }
  };

  const loadInit = async () => {
    setIsLoadingBillings(true);
    await loadPos();
    setIsLoadingBillings(false);
  };

  const populateInfosClientToForm = () => {
    const clientInfos = state?.client?.company;
    setInputsForm({
      registrationName: clientInfos?.registrationName || "",
      registrationId: clientInfos?.registrationId || "",
      postcode: clientInfos?.postcode || "",
      addressLine1: clientInfos?.addressLine1 || "",
      addressLine2: clientInfos?.addressLine2 || "",
      city: clientInfos?.city || "",
      state: clientInfos?.state || "",
      total: "",
      code: "",
      projectPO: "",
      billingDate: "",
    });
    setFilePo({});
  };

  const handleAddPo = () => {
    if (modalAddVisible) {
      setModalAddVisible(false);
      populateInfosClientToForm();
      setIsLoadingInput(true);
    } else {
      setModalAddVisible(true);
      setIsLoadingInput(false);
    }
  };

  const handleCloseAddPo = () => {
    setModalAddVisible(false);
    populateInfosClientToForm();
    setFilePo({});
  };

  const sendApi = async () => {
    setIsSending(true);
    let fileUploaded = null;

    if (filePo?.name) {
      fileUploaded = await uploadFile({
        file: filePo,
        idUser: "usertestingnow",
        fieldId: `${auth?.is_client_or_client_user ? "cliente" : "parceiro"}-${
          params?.projectId
        }-po`,
      });
    }

    const data = {
      ...inputsForm,
      authorize: checkboxCompany,
      projectId: params?.projectId,
      files: fileUploaded ? [fileUploaded] : null,
    };

    const response = await addNewPo(data);

    if (response?.success) {
      handleAddPo();
      setFilePo({});
      message.success(I18n.get("SPA successfully added!"));
      await loadPos();
    } else {
      const errors = [];
      Object.keys(response?.errors).forEach(function (key, index) {
        errors.push(response?.errors[key]);
      });
      setErrorsInputsForm(errors);
    }

    setIsSending(false);
  };

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const handleClickSub = async (check) => {
    setCheckboxCompany(check);

    setInputsForm({ ...inputsForm, billingDate: "", orderNumber: "" });
  };

  const handleStartProject = async () => {
    setLoadSend(true);
    const response = await startProject(params?.projectId);
    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 204
    ) {
      setSuccessStart(true);
    } else {
      message.error(I18n.get("Some problem happened, please try again."));
      setSuccessStart(false);
    }
    setLoadSend(false);
  };

  useEffect(() => {
    loadInit();
  }, []);

  useEffect(() => {
    if (
      state?.auth?.is_client_or_client_user &&
      !inputsForm?.registrationName
    ) {
      populateInfosClientToForm();
      setCountryClient(state?.client?.country?.code);
    }
  }, [state]);

  useEffect(() => {
    if (checkboxCompany) {
      if (
        inputsForm?.billingDate &&
        inputsForm?.total &&
        inputsForm?.code &&
        inputsForm?.registrationName &&
        inputsForm?.registrationId &&
        inputsForm?.postcode &&
        inputsForm?.addressLine1 &&
        inputsForm?.addressLine2 &&
        inputsForm?.city &&
        inputsForm?.state
      ) {
        setAddIsDisabled(false);
      } else {
        setAddIsDisabled(true);
      }
    } else {
      if (
        inputsForm?.projectPO &&
        inputsForm?.total &&
        inputsForm?.code &&
        inputsForm?.registrationName &&
        inputsForm?.registrationId &&
        inputsForm?.postcode &&
        inputsForm?.addressLine1 &&
        inputsForm?.addressLine2 &&
        inputsForm?.city &&
        inputsForm?.state
      ) {
        setAddIsDisabled(false);
      } else {
        setAddIsDisabled(true);
      }
    }
  }, [inputsForm, checkboxCompany]);

  const handleFilePo = (item) => {
    setFilePo(item);

    if (item) {
      const errorsArr = clearErrorInput({
        field: "files[0].url",
        arr: errorsInputsForm,
      });
      setErrorsInputsForm(errorsArr);
    }
  };

  return (
    <>
      <LoggedPage>
        <Content>
          <div className="container">
            <Row>
              <Col sm={12}>
                <H2Border>{I18n.get("Projeto em andamento")}</H2Border>
              </Col>
              <Col sm={12}>
                <RequestProjectCompletion reloadPage={() => true} />
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <TabsProject
                  context={
                    statusProject === null
                      ? "inProgress"
                      : statusProject === 7
                      ? "inProgress"
                      : "finalized"
                  }
                  page="po"
                  externalLoading={isLoadingBillings}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={24}>
                <Main
                  bgColor="#fff"
                  padding="30px"
                  style={{ marginBottom: "30px" }}
                >
                  <Row>
                    <Col xs={24} sm={12} offset={6}>
                      <S.RowCenter>
                        <S.TitleWithIcon>
                          <S.H4>{I18n.get("#SPA")}</S.H4>
                        </S.TitleWithIcon>
                      </S.RowCenter>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Loading
                      text={I18n.get("Loading...")}
                      sizeText={14}
                      sizeIcon={16}
                      color="#000000"
                      align="left"
                    />
                  ) : (
                    <>
                      <Row>
                        <Col xs={16}>
                          <S.TotalPo>
                            {isLoadingBillings ? (
                              <p>
                                {`${I18n.get("Total")}: `}
                                <Loading text="" sizeIcon={16} align="left" />
                              </p>
                            ) : (
                              <p>{`${I18n.get("Total")}: ${
                                infosPo?.billingTotalFmt
                              }`}</p>
                            )}
                            {isLoadingBillings ? (
                              <p>
                                {`${I18n.get("Balance")}: `}
                                <Loading text="" sizeIcon={16} align="left" />
                              </p>
                            ) : (
                              <p>{`${I18n.get("Balance")}: ${
                                infosPo?.billingPOBalanceFmt
                              }`}</p>
                            )}
                          </S.TotalPo>
                          <S.Text>
                            <p>
                              {I18n.get(
                                "Below are the #SPA ​​registered for the project."
                              )}
                            </p>
                          </S.Text>
                        </Col>
                        {auth?.is_client_or_client_user &&
                        infosPo?.billingPOBalance > 0 ? (
                          <Col xs={8}>
                            <S.ButtonAddPo>
                              <ButtonBpool
                                text={I18n.get("Add #SPA")}
                                theme="primary"
                                onClick={handleAddPo}
                              />
                            </S.ButtonAddPo>
                          </Col>
                        ) : null}
                      </Row>

                      <Row>
                        {isLoadingBillings ? (
                          <Loading
                            text={I18n.get("Loading...")}
                            sizeText={14}
                            sizeIcon={16}
                            color="#000000"
                            align="left"
                            style={{ marginTop: 30 }}
                          />
                        ) : (
                          <>
                            {listOfBillings?.length === 0 ? (
                              <Col xs={24}>
                                <S.BillingsEmpty>
                                  <p>{I18n.get("No #SPA ​​registered yet.")}</p>
                                </S.BillingsEmpty>
                              </Col>
                            ) : (
                              listOfBillings?.map((item, index) => (
                                <Col xs={24} key={index}>
                                  <PoBoxItem
                                    data={item}
                                    refreshPos={loadInit}
                                    billingCurrency={billingCurrency}
                                  />
                                </Col>
                              ))
                            )}
                          </>
                        )}
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col sm={24}>
                      <S.RowButtonsActions>
                        <Link
                          to={`/${
                            auth?.is_partner ? "parceiro" : "cliente"
                          }/projetos/v2/${
                            params?.projectId
                          }/in-progress/briefing`}
                        >
                          <ButtonBpool
                            text={I18n.get("Back")}
                            theme="secondary"
                            onClick={() => true}
                          />
                        </Link>

                        <Link
                          to={`/${
                            auth?.is_partner ? "parceiro" : "cliente"
                          }/projetos/v2/${
                            params?.projectId
                          }/in-progress/extras`}
                          style={{ marginLeft: 20 }}
                        >
                          <ButtonBpool
                            text={I18n.get("Next")}
                            theme="primary"
                            onClick={() => true}
                          />
                        </Link>
                      </S.RowButtonsActions>
                    </Col>
                  </Row>
                </Main>
              </Col>
            </Row>
          </div>
        </Content>
      </LoggedPage>

      <Modal
        title={I18n.get("Add #SPA")}
        centered
        visible={modalAddVisible}
        onOk={handleAddPo}
        onCancel={handleAddPo}
        width={1200}
        footer={[
          <S.ButtonsModalFooter>
            <ButtonBpool
              text={I18n.get("Close")}
              theme="secondary"
              onClick={handleCloseAddPo}
            />
            <ButtonBpool
              text={I18n.get("Add")}
              theme="primary"
              onClick={sendApi}
              loading={isSending}
              isDisabled={addIsDisabled}
            />
          </S.ButtonsModalFooter>,
        ]}
      >
        <Row>
          <Col sm={24}>
            <S.SubTitle>
              <p>{I18n.get("Billing Data")}</p>
              <span />
            </S.SubTitle>
          </Col>
          <Col sm={24}>
            <S.ContentCheckboxCompany>
              <Checkbox
                checked={checkboxCompany}
                onChange={(e) => handleClickSub(e.target.checked)}
              />
              <span className="text">
                {I18n.get(
                  "My company does not require SPA, I authorize billing for payment on the date and amount below."
                )}
              </span>
            </S.ContentCheckboxCompany>
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {!checkboxCompany ? (
              <Input
                placeholder={I18n.get("Order number or purchase order")}
                required
                label
                value={inputsForm?.projectPO}
                onChange={(value) => onChangeInputForm("projectPO", value)}
                error={hasError({ arr: errorsInputsForm, field: "projectPO" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "projectPO",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            ) : (
              <InputMask
                id="billingDate"
                label={I18n.get("Data Pagamento")}
                defaultValue={inputsForm?.billingDate}
                onChange={(value) =>
                  onChangeInputForm("billingDate", value?.formattedValue)
                }
                mask="##/##/####"
                required
                error={hasError({
                  arr: errorsInputsForm,
                  field: "billingDate",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "billingDate",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            )}
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <CurrencyInput
              placeholder={I18n.get("Value")}
              required
              value={inputsForm?.total}
              onChange={(value) => onChangeInputForm("total", value)}
              currency={billingCurrency}
              error={hasError({ arr: errorsInputsForm, field: "total" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "total",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Code")}
              label
              value={inputsForm?.code}
              onChange={(value) => onChangeInputForm("code", value)}
              error={hasError({ arr: errorsInputsForm, field: "code" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "code",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={24}>
            <UploadInternal
              multiple={false}
              cbFiles={(item) => handleFilePo(item)}
              filesDefault={filePo}
            />
            {hasError({ arr: errorsInputsForm, field: "files[0].url" }) ? (
              <S.ErrorSendImageRequired>
                {hasError({ arr: errorsInputsForm, field: "files[0].url" })}
              </S.ErrorSendImageRequired>
            ) : null}
          </Col>
          <Col sm={24}>
            <S.PaymentosInstructions>
              <strong>{I18n.get("Condição Pagamento:")}</strong>{" "}
              {paymentTermDays + " "}
              {paymentTermDays ? I18n.get("dias corridos") : ""}
            </S.PaymentosInstructions>
          </Col>
          <Col sm={24}>
            <S.SubTitle>
              <p>{I18n.get("Issue Data")}</p>
              <span />
            </S.SubTitle>
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Razão social")}
              required
              label
              value={inputsForm?.registrationName}
              onChange={(value) => onChangeInputForm("registrationName", value)}
              error={hasError({
                arr: errorsInputsForm,
                field: "registrationName",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "registrationName",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {isLoadingInput ? (
              <div />
            ) : (
              <>
                {countryClient === "BRA" ? (
                  <InputMask
                    id="registrationId"
                    label={I18n.get("Cnpj")}
                    defaultValue={inputsForm?.registrationId}
                    onChange={(value) =>
                      onChangeInputForm("registrationId", value?.value)
                    }
                    mask="##.###.###/####-##"
                    required
                    error={hasError({
                      arr: errorsInputsForm,
                      field: "registrationId",
                    })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "registrationId",
                        arr: errorsInputsForm,
                      });
                      setErrorsInputsForm(errorsArr);
                    }}
                  />
                ) : (
                  <Input
                    placeholder={I18n.get("Registration ID")}
                    required
                    label
                    value={inputsForm?.registrationId}
                    onChange={(value) =>
                      onChangeInputForm("registrationId", value)
                    }
                    error={hasError({
                      arr: errorsInputsForm,
                      field: "registrationId",
                    })}
                    clearError={() => {
                      const errorsArr = clearErrorInput({
                        field: "registrationId",
                        arr: errorsInputsForm,
                      });
                      setErrorsInputsForm(errorsArr);
                    }}
                  />
                )}
              </>
            )}
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {isLoadingInput ? (
              <div />
            ) : (
              <InputMask
                id="postcode"
                label={I18n.get("Cep")}
                defaultValue={inputsForm?.postcode}
                onChange={(value) =>
                  onChangeInputForm("postcode", value?.value)
                }
                mask="##.###-###"
                required
                error={hasError({ arr: errorsInputsForm, field: "postcode" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "postcode",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            )}
          </Col>
          <Col sm={16} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Address")}
              required
              label
              value={inputsForm?.addressLine1}
              onChange={(value) => onChangeInputForm("addressLine1", value)}
              error={hasError({ arr: errorsInputsForm, field: "addressLine1" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "addressLine1",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("Complement")}
              label
              value={inputsForm?.addressLine2}
              onChange={(value) => onChangeInputForm("addressLine2", value)}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get("City")}
              required
              label
              value={inputsForm?.city}
              onChange={(value) => onChangeInputForm("city", value)}
              error={hasError({ arr: errorsInputsForm, field: "city" })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "city",
                  arr: errorsInputsForm,
                });
                setErrorsInputsForm(errorsArr);
              }}
            />
          </Col>
          <Col sm={8} style={{ height: 80 }}>
            {countryClient === "BRA" ? (
              <SelectOne
                options={constants.STATES}
                value={inputsForm?.state}
                defaultValue={inputsForm?.state}
                onChange={(value) => onChangeInputForm("state", value)}
                required
                placeholder={I18n.get("State")}
                error={hasError({ arr: errorsInputsForm, field: "state" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            ) : (
              <Input
                placeholder={I18n.get("State")}
                required
                label
                value={inputsForm?.state}
                onChange={(value) => onChangeInputForm("state", value)}
                error={hasError({ arr: errorsInputsForm, field: "state" })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "state",
                    arr: errorsInputsForm,
                  });
                  setErrorsInputsForm(errorsArr);
                }}
              />
            )}
          </Col>
          <Col sm={8} style={{ height: "50px" }}>
            <S.Balance>{`Saldo total: ${infosPo?.billingPOBalanceFmt}`}</S.Balance>
          </Col>
        </Row>
      </Modal>

      <ModalBp
        visible={successStart}
        bodyText={I18n.get("Projeto iniciado com sucesso")}
        subBodyText={I18n.get(
          "Agora a BPool e o parceiro irão iniciar o processo de faturamento. Qualquer dúvida entre em contato com a gente pelo e-mail suporte.cliente@bpool.co e teremos prazer em te ajudar."
        )}
        okText={I18n.get("Ir projetos")}
        handleClickOk={() => history.push(`/parceiro/projetos/v2`)}
      />
    </>
  );
};
