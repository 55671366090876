import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useSupplier } from "../../../../hooks/useSupplier/useSupplier.hook";
import { NotificationBPool } from "../../../../components/UI/NotificationBPool";
import Main from "../../../../components/UI/Main";
import { Unlogged } from "../../../../components/UI/Unlogged";
import { TitlePage } from "../../components/TitlePage";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { Dialog } from "../../../../components/UI/Dialog";
import { FormNF } from "../../components/FormNF";
import { parseQueryString } from "../Initial/Filters/utils/resolvers";

import * as S from "./styles";

export const BpayRegisterSupplier = () => {
  let params = useParams();
  let history = useHistory();
  let location = useLocation();
  const auth = useSelector((state) => state.auth);
  const {
    getInfosFormSupplier,
    createUserSupplier,
    createUserSupplierRegister,
    getTimezones,
    getSupplierAlreadyRegister,
  } = useSupplier();
  const [isLoadingGet, setIsLoadingGet] = useState(false);
  const [dataGetForm, setDataGetForm] = useState({
    emailSupplier: "",
    supplierId: "",
  });
  const [errorsInputs, setErrorsInputs] = useState([]);
  const [termsAccept, setTermsAccept] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [dialogOk, setDialogOk] = useState({ visible: false });
  const [formData, setFormData] = useState({});
  const [isDisabledButtonSend, setIsDisabledButtonSend] = useState(true);
  const [timezones, setTimezones] = useState([]);
  const [culture, setCulture] = useState("");

  const handleClickOkDialog = () => {
    localStorage.setItem("@BPOOL-supplier-bPaymentId", params?.bpaymentId);
    history.push("/login");
    setDialogOk({ visible: false });
  };

  const whatPageContext = (url) => {
    const parts = url.split("/");
    const status = parts[3]; // "finalized" ou "details"
    return status;
  };

  const postNewUserSupplier = async () => {
    let objSendRegisterSupplier = {
      passWord: formData?.passWord,
      confirmPassWord: formData?.confirmPassWord,
      timeZone: formData?.timeZone,
      termsAccepted: termsAccept,
      registrationId: formData?.registrationId,
      registrationName: formData?.registrationName,
      zipCode: formData?.zipCode,
      addressLine1: formData?.addressLine1,
      addressLine2: formData?.addressLine2,
      city: formData?.city,
      state: formData?.state,
      countryCode: formData?.country?.code,
    };

    const pageContext = whatPageContext(location?.pathname);
    if (pageContext === "cadastro") {
      objSendRegisterSupplier = {
        ...objSendRegisterSupplier,
        supplierId: dataGetForm?.supplierId || params?.supplierId,
      };

      const response = await createUserSupplierRegister(
        objSendRegisterSupplier
      );
      return response;
    } else {
      objSendRegisterSupplier = {
        ...objSendRegisterSupplier,
        email: dataGetForm?.emailSupplier,
        supplierId: dataGetForm?.supplierId || params?.supplierId,
      };

      const response = await createUserSupplier(objSendRegisterSupplier);
      return response;
    }
  };

  const handleSend = async () => {
    try {
      setIsSending(true);

      const responseNewUser = await postNewUserSupplier();
      setDataGetForm({ ...dataGetForm, supplierId: responseNewUser?.id });

      const pageContext = whatPageContext(location?.pathname);
      if (pageContext === "cadastro") {
        history.push(
          `/fornecedor/bpay/invoices/sucesso?login=true${
            culture && `&culture=${culture}`
          }`
        );
      } else {
        history.push(
          `/fornecedor/bpay/invoices/sucesso?login=true&bPaymentId=${
            params?.bpaymentId
          }${culture && `&culture=${culture}`}`
        );
      }
    } catch (error) {
      setErrorsInputs(error?.response?.data?.errors);
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao cadastrar"),
        duration: 3,
      });
    } finally {
      setIsSending(false);
    }
  };

  const formatTimezone = async ({ data }) => {
    return data?.map((item) => {
      return {
        code: item?.value,
        label: `${item?.text} - ${item?.value}`,
        selected: item.selected,
      };
    });
  };

  const loadInitCadastro = async () => {
    const responseTimezones = await getTimezones();
    const listTimezones = await formatTimezone({
      data: responseTimezones?.data,
    });
    setTimezones(listTimezones);
  };

  const loadInit = async (pageContext) => {
    try {
      setIsLoadingGet(true);
      const objInfos = {
        bpayId: params?.bpayId,
        bpaymentId: params?.bpaymentId,
      };

      const responseAlreadyRegister = await getSupplierAlreadyRegister({
        bPaymentId: params?.bpaymentId,
      });
      if (responseAlreadyRegister?.data?.alreadyRegistered) {
        setDialogOk({
          visible: true,
          email: responseAlreadyRegister?.data?.email,
        });
      } else {
        const responseTimezones = await getTimezones();
        const listTimezones = await formatTimezone({
          data: responseTimezones?.data,
        });
        setTimezones(listTimezones);

        if (pageContext === "cadastro") {
          setDataGetForm({
            supplierId: params?.supplierId,
          });
        } else {
          const response = await getInfosFormSupplier(
            objInfos,
            auth?.is_partner
          );
          setDataGetForm({
            emailSupplier: response?.data?.email,
            supplierId: response?.data?.supplierId,
          });
        }
      }
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar as informações"),
        duration: 3,
      });
    } finally {
      setIsLoadingGet(false);
    }
  };

  const handleChangeForm = (dataForm) => {
    setFormData(dataForm);
  };

  const handleChangeErrors = (errors) => {
    setErrorsInputs(errors);
  };

  const handleChangetermsAndPassword = ({ termsAccept, passwordIsValid }) => {
    setTermsAccept(termsAccept);
    setPasswordIsValid(passwordIsValid);
  };

  useEffect(() => {
    const parameters = parseQueryString(location?.search);
    if (parameters?.logged === "True" || parameters?.logged === "true") {
      if (params?.bpaymentId) {
        localStorage.setItem("@BPOOL-supplier-bPaymentId", params?.bpaymentId);
      }
      history.push("/login");
    } else {
      const pageContext = whatPageContext(location?.pathname);
      if (params?.bpaymentId) {
        loadInit(pageContext);
      }
      loadInitCadastro();
    }

    if (parameters?.culture) {
      I18n.setLanguage(parameters?.culture);
      setCulture(parameters?.culture);
    }
  }, [location]);

  useEffect(() => {
    if (passwordIsValid && termsAccept) {
      setIsDisabledButtonSend(false);
    } else {
      setIsDisabledButtonSend(true);
    }
  }, [passwordIsValid, termsAccept, dataGetForm]);

  return (
    <>
      <Unlogged>
        <div className="container">
          <Main bgColor="#fff" padding="30px" style={{ marginBottom: 0 }}>
            <Row>
              <Col xs={24}>
                <TitlePage
                  title={I18n.get("Faturamento Ollo Pay")}
                  subtitle={
                    <>
                      {I18n.get(
                        "Favor preencher os campos abaixo. Após o envio, nosso time financeiro irá entrar em contato."
                      )}
                      <br />
                      {I18n.get("Qualquer dúvida, favor enviar um e-mail para")}
                      <strong>
                        {" "}
                        {I18n.get("suporte.financeiro@ollo.is")}
                      </strong>
                      .
                    </>
                  }
                />
              </Col>
              <Col xs={24}>
                <FormNF
                  modules={["DadosDaEmpresa", "Endereco", "Cadastro"]}
                  onChangeForm={handleChangeForm}
                  formError={errorsInputs}
                  onChangeErrors={handleChangeErrors}
                  isLoadingGet={isLoadingGet}
                  onChangetermsAndPassword={handleChangetermsAndPassword}
                  listTimezone={timezones}
                  bPaymentId={params?.bpaymentId}
                />
              </Col>
            </Row>
          </Main>
          <Row>
            <Col xs={24} md={24}>
              <S.Actions>
                <ButtonBpool
                  text={I18n.get("Enviar")}
                  theme="primary"
                  loading={isSending}
                  onClick={handleSend}
                  disabled={isDisabledButtonSend}
                />
              </S.Actions>
            </Col>
          </Row>
        </div>
      </Unlogged>

      <Dialog
        visible={dialogOk?.visible}
        text={I18n.get("Este link já foi usado")}
        subText={`${I18n.get(
          `Usuário já cadastrado por esse link. E-mail usado foi:`
        )} ${dialogOk?.email}`}
        buttons={{
          ok: {
            text: I18n.get("Realizar login"),
            func: () => handleClickOkDialog(),
          },
          cancel: {},
        }}
        theme="white"
      />
    </>
  );
};
