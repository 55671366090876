import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import constants from "../../../constants";
import MaskedInput from "antd-mask-input";

import { TextCounter } from "./counterText";

import { Row, Col, Icon, Checkbox, Tooltip, Input, Select, Form } from "antd";

const { TextArea } = Input;
const { Option } = Select;

class FormAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNewCompany:
        this.props.partner &&
        this.props.partner.company &&
        this.props.partner.company.isNewCompany,
      fieldBlur: "",
      fieldBlurError: "",
      prefix: "",
      countrySeleted:
        (this.props.partner.country && this.props.partner.company.country) ||
        "",
    };
  }

  sendDataApi = () => {
    const fieldsForm = this.props.form.getFieldsValue();

    const dataForm = {
      ...fieldsForm,
      name: fieldsForm.name,
      linkedin: fieldsForm.linkedin,
      email: fieldsForm.email,
      partnerId: this.props.partnerId,
      company: {
        name: fieldsForm.companyName,
        registrationId: fieldsForm.companyRegistrationId,
        linkedin: fieldsForm.companyLinkedin,
        description: fieldsForm.companyDescription,
        city:
          fieldsForm.companyCity || this.props.partner?.company?.companyCity,
        country:
          this.state.countrySeleted || this.props.partner.company.country,
        state:
          fieldsForm.companyState || this.props.partner?.company?.companyState,
        phoneNumber: fieldsForm.phoneNumber,
        website: fieldsForm.website,
        isNewCompany: fieldsForm.isNewCompany,
        diversityPreferNotDeclare: fieldsForm.diversityPreferNotDeclare,
        diversityShareHolders:
          fieldsForm.diversityPreferNotDeclare ||
          !fieldsForm.diversityShareHolders
            ? []
            : fieldsForm.diversityShareHolders,
        diversityLeadership:
          fieldsForm.diversityPreferNotDeclare ||
          !fieldsForm.diversityLeadership
            ? []
            : fieldsForm.diversityLeadership,
        diversityOwned:
          fieldsForm.diversityPreferNotDeclare ||
          !fieldsForm.diversityOwned
            ? []
            : fieldsForm.diversityOwned,
      },
    };

    return dataForm;
  };

  // componentWillUnmount() {
  //     this.sendDataApi();
  // }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.partner &&
      this.props.partner.company != prevProps.partner.company
    ) {
      this.setState({
        isNewCompany: this.props.partner.company.isNewCompany,
        countrySeleted:
          (this.props.partner.company.country &&
            this.props.partner.company.country) ||
          "",
      });
    }

    if (
      this.props.errors != prevProps.errors &&
      Object.keys(this.props.errors).length > 0 &&
      this.props.errors[this.state.fieldBlur]
    ) {
      this.props.form.setFields({
        [this.state.fieldBlurError]: {
          errors: [
            new Error(this.props.errors[this.state.fieldBlur].errorMessage),
          ],
        },
      });
    }

    // if (this.props.form != prevProps.form) {
    //     this.handleOnChange();
    // }
  }

  onChangeIsNew = (e) => {
    let data = {
      partnerId: this.props.partnerId,
      isNewCompany: e.target.checked,
    };

    this.setState({
      isNewCompany: e.target.checked,
    });

    setTimeout(() => {
      this.props.isNewCompany(data);
    }, 1);
  };

  handleSubmit = (field, fieldError) => {
    this.setState({
      fieldBlur: field,
      fieldBlurError: fieldError,
    });

    setTimeout(() => {
      this.props.action(this.sendDataApi());
    }, 1);
  };

  handleSubmitSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.action(this.sendDataApi());
      }
    });
  };

  handleCountry = (e) => {
    let obj = this.props.countryCodes.find((i) => i.code == e);

    let country = {
      name: obj.name,
      code: obj.code,
      prefix: obj.prefix,
    };

    this.setState({
      prefix: obj.prefix,
      countrySeleted: country,
    });

    this.props.form.setFieldsValue({
      companyCity: "",
      companyState: "",
    });

    setTimeout(() => {
      this.handleSubmit("company.country", "companyCountry");
    }, 10);
  };

  getPrefix = (code) => {
    let prefixCode =
      this.props.countryCodes &&
      this.props.countryCodes.find((i) => i.code == code);

    if (prefixCode) {
      return prefixCode.prefix || "";
    } else {
      return "";
    }
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    if (!this.props.partner || !this.props.partner.partnerId) return null;

    const { partner } = this.props;

    return (
      <Form onSubmit={this.handleSubmitSave}>
        <Row className="RowSection">
          <Col sm={24}>
            <TitleMiddleLine>{I18n.get("Líder_pre")}</TitleMiddleLine>
          </Col>
          <Col sm={8}>
            <Form.Item label={I18n.get("Nome do líder")}>
              {getFieldDecorator("name", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: partner.name ? partner.name : null,
              })(<Input onBlur={() => this.handleSubmit("name", "name")} />)}
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item label={I18n.get("Linkedin Pessoal")}>
              {getFieldDecorator("linkedin", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: partner.linkedin ? partner.linkedin : null,
              })(
                <Input
                  onBlur={() => this.handleSubmit("linkedin", "linkedin")}
                />
              )}
            </Form.Item>
          </Col>
          <Col sm={8}>
            <Form.Item label={I18n.get("E-mail")}>
              {getFieldDecorator("email", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue: partner.email ? partner.email : null,
              })(
                <Input
                  onBlur={() => this.handleSubmit("email", "email")}
                  disabled={true}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="RowSection">
          <Col sm={24}>
            <TitleMiddleLine>
              {I18n.get("Conte um pouco da sua empresa")}
            </TitleMiddleLine>
          </Col>

          <Col sm={9}>
            <Form.Item label={I18n.get("Nome fantasia")}>
              {getFieldDecorator("companyName", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.partner.company && this.props.partner.company.name
                    ? this.props.partner.company.name
                    : null,
              })(
                <Input
                  onBlur={() =>
                    this.handleSubmit("company.name", "companyName")
                  }
                />
              )}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Minha empresa tem menos de 6 meses")}>
              {getFieldDecorator("isNewCompany", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.partner.company &&
                  this.props.partner.company.isNewCompany
                    ? true
                    : false,
                valuePropName: "checked",
              })(
                <Checkbox onChange={(e) => this.onChangeIsNew(e)}>
                  {I18n.get("Sim, minha empresa é nova.")}
                </Checkbox>
              )}
            </Form.Item>
          </Col>

          <Col sm={7}>
            {this.props.countryCodes && (
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("companyCountry", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner &&
                    this.props.partner.company &&
                    this.props.partner.company.country
                      ? this.props.partner.company.country.code
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    onSelect={(e) => this.handleCountry(e)}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();

                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            )}
          </Col>

          {/* <Col sm={12}>
                        <Form.Item label="Nome fantasia">
                            {getFieldDecorator('companyName', {
                                rules: [{ required: false, message: 'Campo obrigatório!' }],
                                initialValue: this.props.partner.company && this.props.partner.company.name ? this.props.partner.company.name : null
                            })(
                                <Input

                                onBlur={() => this.handleSubmit()}
                                />
                            )}
                        </Form.Item>
                    </Col> */}

          <Col sm={12}>
            {getFieldValue("companyCountry") == "BRA" && (
              <Form.Item label={I18n.get("CNPJ")}>
                {getFieldDecorator("companyRegistrationId", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.registrationId
                      ? this.props.partner.company.registrationId
                      : null,
                })(
                  <MaskedInput
                    mask="00.000.000/0000-00"
                    size="14"
                    onBlur={() =>
                      this.handleSubmit(
                        "company.registrationId",
                        "companyRegistrationId"
                      )
                    }
                  />
                )}
              </Form.Item>
            )}
            {getFieldValue("companyCountry") != "BRA" && (
              <Form.Item label={I18n.get("CNPJ")}>
                {getFieldDecorator("companyRegistrationId", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.registrationId
                      ? this.props.partner.company.registrationId
                      : null,
                })(
                  <Input
                    onBlur={() =>
                      this.handleSubmit(
                        "company.registrationId",
                        "companyRegistrationId"
                      )
                    }
                  />
                )}
              </Form.Item>
            )}
          </Col>
          <Col sm={12}>
            <Form.Item label={I18n.get("Linkedin Corporativo")}>
              {getFieldDecorator("companyLinkedin", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.partner.company &&
                  this.props.partner.company.linkedin
                    ? this.props.partner.company.linkedin
                    : null,
              })(
                <Input
                  onBlur={() =>
                    this.handleSubmit("company.linkedin", "companyLinkedin")
                  }
                />
              )}
            </Form.Item>
          </Col>

          <Col sm={24}>
            <Form.Item
              label={I18n.get("Resumo e histórico")}
              className="textAreaCounter"
            >
              {getFieldDecorator("companyDescription", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  (this.props.partner.company &&
                    this.props.partner.company.description) ||
                  "",
              })(
                <TextArea
                  onBlur={() =>
                    this.handleSubmit(
                      "company.description",
                      "companyDescription"
                    )
                  }
                  rows={4}
                  maxLength={500}
                />
              )}
            </Form.Item>
            <TextCounter
              counter={getFieldValue("companyDescription").length}
              limit={500}
              min={100}
            />
          </Col>
        </Row>
        <Row className="RowSection">
          <Col sm={24}>
            <TitleMiddleLine>
              {I18n.get("Representatividade e diversidade")}
            </TitleMiddleLine>
          </Col>

          <Col sm={24}>
            <p>
              {I18n.get(
                "Gostaríamos de entender como é composta a sua equipe. Cite abaixo a presença de pessoas negras, indígenas, portadores de deficiência, homens e mulheres trans, homossexuais e mulheres cis em cargos de liderança e no time de colaboradores."
              )}
            </p>
            <br />
          </Col>

          <Fragment>
            <Col sm={24}>
              <Form.Item label={I18n.get("Sócios_register")}>
                {getFieldDecorator("diversityShareHolders", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props?.partner?.company?.diversityShareHolders || [],
                })(
                  <Select
                    showSearch={true}
                    mode="multiple"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    disabled={
                      this.props.partner.company &&
                      this.props.partner.company.diversityPreferNotDeclare
                    }
                    onSelect={() =>
                      this.handleSubmit(
                        "company.diversityShareHolders",
                        "diversityShareHolders"
                      )
                    }
                    onBlur={() =>
                      this.handleSubmit(
                        "company.diversityShareHolders",
                        "diversityShareHolders"
                      )
                    }
                  >
                    {this.props.diversity &&
                      this.props.diversity.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={24}>
              <Form.Item label={I18n.get("Em cargos de liderança")}>
                {getFieldDecorator("diversityLeadership", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.diversityLeadership
                      ? this.props.partner.company.diversityLeadership
                      : [],
                })(
                  <Select
                    showSearch={true}
                    mode="multiple"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    disabled={
                      this.props.partner.company &&
                      this.props.partner.company.diversityPreferNotDeclare
                    }
                    onSelect={() =>
                      this.handleSubmit(
                        "company.diversityLeadership",
                        "diversityLeadership"
                      )
                    }
                    onBlur={() =>
                      this.handleSubmit(
                        "company.diversityLeadership",
                        "diversityLeadership"
                      )
                    }
                  >
                    {this.props.diversity &&
                      this.props.diversity.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={24}>
              <Form.Item label={I18n.get("No time de colaboradores")}>
                {getFieldDecorator("diversityOwned", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.diversityOwned
                      ? this.props.partner.company.diversityOwned
                      : [],
                })(
                  <Select
                    showSearch={true}
                    mode="multiple"
                    style={{ width: "100%" }}
                    tokenSeparators={[","]}
                    disabled={
                      this.props.partner.company &&
                      this.props.partner.company.diversityPreferNotDeclare
                    }
                    onSelect={() =>
                      this.handleSubmit(
                        "company.diversityOwned",
                        "diversityOwned"
                      )
                    }
                    onBlur={() =>
                      this.handleSubmit(
                        "company.diversityOwned",
                        "diversityOwned"
                      )
                    }
                  >
                    {this.props.diversity &&
                      this.props.diversity.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Fragment>

          <Col sm={12}>
            <Form.Item>
              {getFieldDecorator("diversityPreferNotDeclare", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.partner.company &&
                  this.props.partner.company.diversityPreferNotDeclare &&
                  this.props.partner.company.diversityPreferNotDeclare
                    ? true
                    : false,
                valuePropName: "checked",
              })(
                <Checkbox
                  onChange={() =>
                    this.handleSubmit(
                      "company.diversityPreferNotDeclare",
                      "diversityPreferNotDeclare"
                    )
                  }
                >
                  <b>{I18n.get("Prefiro não declarar")}</b>{" "}
                  <Tooltip
                    title={I18n.get(
                      "As informações acima ficarão disponíveis no seu perfil, clique aqui se preferir não declarar"
                    )}
                  >
                    <Icon type="question-circle" theme="filled" />
                  </Tooltip>
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row className="RowSection">
          <Col sm={24}>
            <TitleMiddleLine>{I18n.get("Onde estamos")}</TitleMiddleLine>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Cidade")}>
              {getFieldDecorator("companyCity", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.partner.company && this.props.partner.company.city
                    ? this.props.partner.company.city
                    : null,
              })(
                <Input
                  onBlur={() =>
                    this.handleSubmit("company.city", "companyCity")
                  }
                />
              )}
            </Form.Item>
          </Col>

          <Col sm={8}>
            {getFieldValue("companyCountry") == "BRA" && (
              <Form.Item label={I18n.get("Estado")}>
                {getFieldDecorator("companyState", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.state
                      ? this.props.partner.company.state
                      : null,
                })(
                  <Select
                    style={{ width: "100%" }}
                    onSelect={() =>
                      this.handleSubmit("company.state", "companyState")
                    }
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {constants.STATES &&
                      constants.STATES.map((item) => {
                        return (
                          <Option value={item.code} key={item.code}>
                            {item.label}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            )}
            {getFieldValue("companyCountry") != "BRA" && (
              <Form.Item label={I18n.get("Estado")}>
                {getFieldDecorator("companyState", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.state
                      ? this.props.partner.company.state
                      : null,
                })(
                  <Input
                    onBlur={() =>
                      this.handleSubmit("company.state", "companyState")
                    }
                  />
                )}
              </Form.Item>
            )}
          </Col>
        </Row>

        <Row className="RowSection">
          <Col sm={24}>
            <TitleMiddleLine>{I18n.get("Contato")}</TitleMiddleLine>
          </Col>
          <Col sm={12}>
            {getFieldValue("companyCountry") != "BRA" && (
              <Form.Item label={I18n.get("Telefone")}>
                {getFieldDecorator("phoneNumber", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.phoneNumber
                      ? this.props.partner.company.phoneNumber
                      : null,
                })(
                  <Input
                    addonBefore={
                      this.state.prefix ||
                      (this.props.partner.company &&
                        this.props.countryCodes &&
                        this.getPrefix(this.props.partner.company.countryCode))
                    }
                    onBlur={() =>
                      this.handleSubmit("company.phoneNumber", "phoneNumber")
                    }
                  />
                )}
              </Form.Item>
            )}
            {getFieldValue("companyCountry") == "BRA" && (
              <Form.Item label={I18n.get("Telefone")}>
                {getFieldDecorator("phoneNumber", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.partner.company &&
                    this.props.partner.company.phoneNumber
                      ? this.props.partner.company.phoneNumber
                      : null,
                })(
                  <Input
                    addonBefore={
                      this.state.prefix ||
                      (this.props.partner.company &&
                        this.props.countryCodes &&
                        this.getPrefix(this.props.partner.company.countryCode))
                    }
                    onBlur={() =>
                      this.handleSubmit("company.phoneNumber", "phoneNumber")
                    }
                  />
                  // <MaskedInput
                  //     addonBefore={this.state.prefix || this.props.partner.company && this.props.countryCodes && this.getPrefix(this.props.partner.company.countryCode)}
                  //     mask="(11) 1111-1111"
                  //     size="14"
                  //     onBlur={() => this.handleSubmit('company.phoneNumber', 'phoneNumber')}
                  // />
                )}
              </Form.Item>
            )}
          </Col>

          <Col sm={12}>
            <Form.Item label={I18n.get("Site")}>
              {getFieldDecorator("website", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.partner.company &&
                  this.props.partner.company.website
                    ? this.props.partner.company.website
                    : null,
              })(
                <Input
                  onBlur={() => this.handleSubmit("company.website", "website")}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row className="RowSection">
          <Col sm={8}></Col>
        </Row>
      </Form>
    );
  }
}

const AboutBoutiqueForm = Form.create({})(FormAbout);

export default AboutBoutiqueForm;
