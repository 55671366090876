import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Icon, Tooltip, Popconfirm } from "antd";
import { useBpayAdmin } from "../../../../../hooks/useBpay/useBpayAdmin.hook";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Drawer } from "../../../../../components/UI/Drawer";
import { Dialog } from "../../../../../components/UI/Dialog";
import { createQueryString, parseQueryString } from "./utils/paramsResolvers";
import * as S from "./styles";
import { colorStatus, colorBgStatus } from "./utils/colorsStatus";
import IconEnvelope from "../../svgs/fi-rr-envelope.svg";
import IconEye from "../../svgs/fi-rr-eye.svg";
import IconTrash from "../../svgs/fi-rr-trash.svg";
import { InfosDrawer } from "../InfosDrawer";

export const Table = ({ handleSelectedsBpays }) => {
  let history = useHistory();
  let location = useLocation();
  const {
    getBpaysAdmin,
    getBpayDataToDrawer,
    sendWarningUploadInvoice,
    deleteBPayAdmin,
  } = useBpayAdmin();
  const [isLoadingInternal, setIsLoadingInternal] = useState("");
  const [openDrawer, setOpenDrawer] = useState({ open: false });
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});
  const [isSendingMails, setIsSendingMails] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [dialogErrorDeleteBPay, setDialogErrorDeleteBPay] = useState({
    visible: false,
  });

  const renderText = (text) => {
    if (text) {
      return text;
    } else if (text === 0) {
      return text;
    } else {
      return "---";
    }
  };

  const { data, isLoading, error, refetch } = useQuery(
    ["bpaysAdmin", createQueryString(parseQueryString(location?.search))],
    () => getBpaysAdmin(createQueryString(parseQueryString(location?.search))),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar a listagem"),
          duration: 3,
        });
      },
    }
  );

  const handleDeleteBPay = async () => {
    try {
      setIsLoadingInternal(dialog?.id);
      const response = await deleteBPayAdmin(dialog?.id);
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Ollo Pay deletado com sucesso"),
        duration: 3,
      });
      setDialog({ visible: false });
      refetch();
    } catch (error) {
      setDialog({ visible: false });
      setDialogErrorDeleteBPay({
        visible: true,
        text: I18n.get("Erro ao deletar este Ollo Pay"),
        subText: error?.response?.data?.errors[0]?.errorMessage,
        error: true,
      });
    } finally {
      setIsLoadingInternal(null);
    }
  };

  const linkTo = ({ bpayId }) => {
    return (
      <Link to={`/bpool/bpay/detalhes/${bpayId}`}>
        <S.ButtonIcon>
          <img src={IconEye} alt="Go to" />
        </S.ButtonIcon>
      </Link>
    );
  };

  const columns = [
    {
      title: I18n.get("Cliente"),
      dataIndex: "clientName",
      width: 160,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.clientName}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={160}>
            <S.RowElipsis>{renderText(record?.clientName)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("SPA"),
      dataIndex: "poNumber",
      width: 100,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.poNumber}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={100}>
            <S.RowElipsis>{renderText(record?.poNumber)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Projeto"),
      dataIndex: "name",
      width: 160,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.name}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={160}>
            <S.RowElipsis>{renderText(record?.name)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("SKU"),
      dataIndex: "sku",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.sku}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.sku)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor Total"),
      dataIndex: "totalFmt",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.totalFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.totalFmt)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor Fee"),
      dataIndex: "totalFeeFmt",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.totalFeeFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.totalFeeFmt)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor Parceiro"),
      dataIndex: "budgetFmt",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.budgetFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.budgetFmt)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Status"),
      dataIndex: "statusFmt",
      width: 160,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${I18n.get("Solicitação está em")} ${record?.statusFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.Status style={{ ...colorBgStatus(record?.status) }}>
            <span style={{ ...colorStatus(record?.status) }}>
              {renderText(record?.statusFmt)}
            </span>
          </S.Status>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("NFSe"),
      dataIndex: "bPoolNFSe",
      width: 100,
      render: (type, record) =>
        record?.bPoolNFSe ? (
          <Tooltip
            placement="topRight"
            title={`${record?.bPoolNFSe}`}
            overlayClassName="tooltip-status-bpay"
          >
            <S.RowTab widthRow={100}>
              <S.RowElipsis>{renderText(record?.bPoolNFSe)}</S.RowElipsis>
            </S.RowTab>
          </Tooltip>
        ) : (
          <S.RowTab widthRow={100}>
            <S.RowElipsis>{renderText(record?.bPoolNFSe)}</S.RowElipsis>
          </S.RowTab>
        ),
    },
    {
      title: I18n.get("Ações"),
      dataIndex: "id",
      width: 150,
      render: (type, record) => (
        <S.RowTab>
          {linkTo({ bpayId: record.id })}
          <S.ButtonIcon
            style={{ marginLeft: 5 }}
            onClick={() => handleOpenDrawer(record.id)}
          >
            <img src={IconEnvelope} alt="send email" />
          </S.ButtonIcon>
          {record?.status === 5 ? null : isLoadingInternal === record.id ? (
            <S.LoadingIcon>
              <Icon type="loading" />
            </S.LoadingIcon>
          ) : (
            <S.ButtonIcon
              style={{ marginLeft: 5 }}
              onClick={() =>
                setDialog({
                  type: "delete",
                  visible: true,
                  id: record.id,
                  text: I18n.get("Deseja realmente excluir essa BPay?"),
                  subText: I18n.get(
                    "Se você prosseguir com essa ação, todos os dados serão deletado e não poderão ser resgatados posteriormente."
                  ),
                })
              }
            >
              <img src={IconTrash} alt="send email" />
            </S.ButtonIcon>
          )}
        </S.RowTab>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleSelectedsBpays(selectedRows);
    },
  };

  const handlePagination = (pageNumber) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!pageNumber || pageNumber === 1) {
      delete objQueries.pageNumber;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, pageNumber });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleOpenDrawer = async (bpayId) => {
    setOpenDrawer({ open: true, bpayId });
    try {
      setIsLoadingDrawer(true);
      const response = await getBpayDataToDrawer(bpayId);
      setDataDrawer(response?.data);
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar as informações"),
        duration: 3,
      });
    } finally {
      setIsLoadingDrawer(false);
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer({ open: false });
    refetch();
  };

  const sendEmails = async () => {
    try {
      setIsSendingMails(true);
      const response = await sendWarningUploadInvoice(openDrawer?.bpayId);
      setDialog({
        visible: true,
        text: I18n.get("E-mail enviado com sucesso"),
      });
      setOpenDrawer({ open: false });
    } catch (error) {
      const errors = error?.response?.data?.errors;
      setDialog({
        visible: true,
        error: true,
        text: I18n.get("Erro ao enviar e-mail"),
        subText: errors
          ? errors[0]?.errorMessage || ""
          : I18n.get("Erro ao tentar enviar, entre em contato conosco."),
      });
    } finally {
      setIsSendingMails(false);
    }
  };

  return (
    <>
      <S.Table
        columns={columns}
        dataSource={data?.list}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 10,
          total: data?.total,
          defaultCurrent: parseQueryString(location?.search)?.pageNumber
            ? Number(parseQueryString(location?.search)?.pageNumber)
            : 1,
          onChange: (page) => {
            handlePagination(page);
          },
        }}
        loading={isLoading}
        locale={{ emptyText: I18n.get("Nenhum BPay cadastrado até o momento") }}
        scroll={{ x: 1300 }}
      />

      <Drawer
        open={openDrawer.open}
        handleClose={handleCloseDrawer}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Não, voltar")}
              onClick={handleCloseDrawer}
              theme="tertiary"
            />
            <div style={{ marginLeft: 16 }}>
              <ButtonBpool
                text={I18n.get("Sim, enviar")}
                onClick={sendEmails}
                theme="primary"
                loading={isSendingMails}
              />
            </div>
          </>
        }
      >
        <InfosDrawer data={dataDrawer} loading={isLoadingDrawer} />
      </Drawer>

      <Dialog
        visible={dialog.visible}
        text={dialog.text}
        subText={dialog.subText}
        loading={dialog?.type === "delete" ? isLoadingInternal : false}
        theme="white"
        error={dialog?.error}
        buttons={{
          ok:
            dialog?.type === "delete"
              ? {
                  text: I18n.get("Sim, deletar"),
                  func: () => handleDeleteBPay(),
                }
              : {
                  text: I18n.get("Fechar"),
                  func: () => setDialog({ visible: false }),
                },
          cancel:
            dialog?.type === "delete"
              ? {
                  text: I18n.get("Não, fechar"),
                  func: () => setDialog({ visible: false }),
                }
              : {},
        }}
      />

      <Dialog
        visible={dialogErrorDeleteBPay.visible}
        text={dialogErrorDeleteBPay.text}
        subText={dialogErrorDeleteBPay.subText}
        loading={false}
        theme="white"
        error={dialogErrorDeleteBPay?.error}
        buttons={{
          ok: {
            text: I18n.get("Fechar"),
            func: () => setDialogErrorDeleteBPay({ visible: false }),
          },
          cancel: {},
        }}
      />
    </>
  );
};
