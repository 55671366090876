import React from "react";
import logo from '../../../../image/logoWOtext.svg';

import * as S from "./styles";

export const Unlogged = ({ children }) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderContent>
          <span>
            <img
              src={logo}
              alt="Ollo"
              class="logo"
            />
          </span>
        </S.HeaderContent>
      </S.Header>
      <S.Content>{children}</S.Content>
      <S.Footer>
        <S.ContentFooter>
          <div className="container">
          <span>{`${new Date().getFullYear()} © Ollo`}</span>
          </div>
        </S.ContentFooter>
      </S.Footer>
    </S.Wrapper>
  );
};
