import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { NavLink } from "react-router-dom";
import LoggedPage from "../../../../../../components/page-templates/loggedAdmin";
import Main from "../../../../../components/UI/Main";
import { FiltersBPaymentsAll } from "../../components/FiltersBPaymentsAll";
import { TableBPaymentsAll } from "../../components/TableBPaymentsAll";

import * as S from "./styles";

export const AdminBPayments = () => {
  const [selectedsBpays, setSelectedsBpays] = useState([]);

  const handleSelectedsBpays = (selecteds) => {
    setSelectedsBpays(selecteds);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LoggedPage>
      <nav className="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <li>
            <NavLink to="/admin" className="is-black">
              {I18n.get("Admin")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/bpool/bpay" className="is-black">
              {I18n.get("Ollo Pay")}
            </NavLink>
          </li>
          <li className="is-active">
            <NavLink to="/bpool/bpay/bpayments" className="is-black">
              {I18n.get("BPayments")}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="container" style={{ width: "100%", maxWidth: "100%" }}>
        <Main bgColor="#fff" padding="30px">
          <Row>
            <Col xs={24}>
              <S.Title>
                <h3>{I18n.get("BPayments")}</h3>
              </S.Title>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <FiltersBPaymentsAll handleSelectedsBpays={selectedsBpays} />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <TableBPaymentsAll handleSelectedsBpays={handleSelectedsBpays} />
            </Col>
          </Row>
        </Main>
      </div>
    </LoggedPage>
  );
};
