import React from 'react';

import { I18n } from 'aws-amplify';

import FormatCurrency from '../ui/format-currency';

require('./quotation-budget.css');

class QuotationBudget extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        const { quotation = {}, currency = {}, customEvent = {} } = this.props;

        if (customEvent.budgetType == 'NOTSET'){

            return (
                <div className="quotation-budget">
                    <table>
                        <tbody>
                            <tr>
                                <td className="item">
                                    {I18n.get('Budget máximo autorizado pelo cliente')}:
                                </td>
                                <td className="amount">
                                    {I18n.get('Não informado')}
                                </td>
                            </tr>
                            <tr>
                                <td className="item">
                                    {I18n.get('Retenção máxima Ollo')}:
                                </td>
                                <td className="amount">
                                    -
                                </td>
                            </tr>
                            <tr>
                                <td className="item">
                                    {I18n.get('Budget máximo disponível para orçamento')}:
                                </td>
                                <td className="amount">
                                    -
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );

        }
        else {
            return (
                <div className="quotation-budget">
                    <table>
                        <tbody>
                            <tr>
                                <td className="item">
                                    {I18n.get('Budget máximo autorizado pelo cliente')}:
                                </td>
                                <td className="amount">
                                    <FormatCurrency amount={quotation.budget} currency={currency} />
                                </td>
                            </tr>
                            <tr>
                                <td className="item">
                                    {I18n.get('Retenção máxima Ollo')}:
                                </td>
                                <td className="amount">
                                    <FormatCurrency amount={quotation.bpCommission && quotation.bpCommission.maxAmount} currency={currency} />
                                </td>
                            </tr>
                            <tr>
                                <td className="item">
                                    {I18n.get('Budget máximo disponível para orçamento')}:
                                </td>
                                <td className="amount">
                                    <FormatCurrency amount={quotation.workingBudgetMax} currency={currency} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
    }
}

export default QuotationBudget;
