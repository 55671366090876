import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Link, Redirect } from "react-router-dom";

import NumberFormat from 'react-number-format';

import { formatMoney2, formatMoneyInput, formatMoneyGraph } from '../../../utils/helpers'
import {
    Table,
    Popconfirm,
    Tooltip,
    Icon
} from 'antd';

import { ProjectTypeNames, ProjectExtraTypeNames } from '../../../model';

import FormatCurrency from '../../../components/ui/format-currency';


const Btns = styled.p`
width: 100px;
display: block;
    a {
        margin-left: 10px;
    }
`

const FooterT = styled.div`
    font-weight: bold;
    text-align: right;
`

class tableProjectsAudio extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return '';

        return this.props.bp.clientProjectStatusesMap[value] && this.props.bp.clientProjectStatusesMap[value].label;
    }


    render() {


        const columns = [
            {
                title: I18n.get("País"),
                dataIndex: 'country',
                key: 'country',
                width: 70,
                render: (country) => {
                    if (country) {
                        return <img src={`https://assets-ollo.bpool.co/country-flags/png24px/${country}.png`} />
                    } else {
                        return "";
                    }
                }

            },


            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                width: 250,
                key: 'name',
                render: (name, record) => <Link to={`/bpool/projetos/audio/${record.item.id}`}>{name}</Link>,
            },
            {
                title: I18n.get("Status"),
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: I18n.get("Cliente"),
                dataIndex: 'client',
                key: 'client',
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'createdByName',
                key: 'createdByName',
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'createdByEmail',
                key: 'createdByEmail',
            },
            {
                title: I18n.get("Data"),
                dataIndex: 'date',
                width: 100,
                key: 'date',
            },

            // {
            //     title: '',
            //     dataIndex: 'actions',
            //     key: 'item',
            //     width: 100,
            //     render: (text, record) => {
            //         let data = {
            //             id: record.item.id,
            //             partnerId: this.props.partnerId,
            //         }

            //         if (!this.props.isView) {

            //             // this.props.delete_project(item.projectId)
            //             return (
            //                 <Btns>
            //                     <Popconfirm
            //                         title={I18n.get("Deseja cancelar esse projeto?")}
            //                         icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            //                         onConfirm={() => this.props.cancel(record.item.projectId)}
            //                         okText={I18n.get("Sim")}
            //                         cancelText={I18n.get("Não")}
            //                     >
            //                         <a>
            //                             <Tooltip title={I18n.get("Cancelar projeto")}>
            //                                 <Icon type="stop" theme="filled" />
            //                             </Tooltip>
            //                         </a>
            //                     </Popconfirm>
            //                     <Popconfirm
            //                         title={I18n.get("Deseja deletar esse projeto?")}
            //                         icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            //                         onConfirm={() => this.props.delete(record.item.projectId)}
            //                         okText={I18n.get("Sim")}
            //                         cancelText={I18n.get("Não")}
            //                     >
            //                         <a>
            //                             <Tooltip title={I18n.get("Deletar projeto")}>
            //                                 <Icon type="delete" theme="filled" />
            //                             </Tooltip>
            //                         </a>
            //                     </Popconfirm>

            //                 </Btns>
            //             )
            //         }

            //     }
            //     // render: (record) => this.props.delMarketExpertise(record.item.id),
            // },
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {

            return {
                key: index,
                country: item.isO2.toLowerCase(),
                name: item.name,
                client: item.clientName,
                createdByName: item.createdByName,
                createdByEmail: item.createdByEmail,
                status: item.statusDescription,
                date: item.date,
                item: item,
            }
        });


        return (
            <Table
                pagination={this.props.pagination ? true : false}
                columns={columns}
                dataSource={dataTable}
                locale={{ emptyText: I18n.get('Sem informação') }}
                scroll={{ x: 1200 }}
                // Nao é possível somar moedas diferentes
                // footer={() => {
                //     return (<FooterT>
                //         <h3>{I18n.get("Total:")}  <FormatCurrency  amount={this.props.totals.client} /></h3>
                //     </FooterT>)
                // }
                // }
            />
        );
    }
}

export default tableProjectsAudio;
