import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Link, NavLink } from "react-router-dom";

import NumberFormat from 'react-number-format';

import { formatMoney2, formatMoneyInput, formatMoneyGraph } from '../../../utils/helpers'
import {
    Table,
    Checkbox,
    notification
} from 'antd';


const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`

const FooterT = styled.div`
    font-weight: bold;
    text-align: right;
`

class TableClient extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getRegion(region) {

        if (region === 'en')
            return 'USA'
        else if (region === 'es')
            return 'LATAM'
        else
            return 'BRASIL'
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }


    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientStatusesMap) return '';

        return this.props.bp.clientStatusesMap[value].label;
    }

    setTrained(id,trained) {

        const data = {
            clientId: id,
            trained: trained
        }

        this.props.api_update_trained(data).then(response => {

            if(response?.success) {
                this.props.getUpdate();
            } else {
                notification.error({
                    message: I18n.get('Algo deu errado!'),
                    description: I18n.get('Tente novamente mais tarde'),
                });
            }
        });
    }


    render() {
        const columns = [
            {
                title: I18n.get("Região"),
                dataIndex: 'region',
                key: 'region',
                width: 110,
            },
            {
                title: I18n.get("País"),
                dataIndex: 'country',
                key: 'country',
                width: 70,
                render: (country) => {
                    if (country) {
                        return <img src={`https://assets-ollo.bpool.co/country-flags/png24px/${country}.png`} />
                    } else {
                        return "";
                    }
                }

            },
            {
                title: I18n.get("Tier"),
                dataIndex: 'tier',
                key: 'tier',
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'registrationName',
                key: 'registrationName',
                render: (registrationName, record) => <div>
                    <Link to={`/bpool/clientes/${record.id}`}>{registrationName || `S/N`} </Link>
                </div>
            },
            {
                title: I18n.get("Nome Fantasia"),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: I18n.get("E-mail"),
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: I18n.get("Cadastro"),
                dataIndex: 'date',
                width: 120,
                key: 'date',
            },
            {
                title: I18n.get("Status"),
                dataIndex: 'status',
                width: 120,
                key: 'status',
            },
            {
                title: I18n.get("Configuração"),
                dataIndex: 'hasSettings',
                width: 100,
                key: 'hasSettings',
                align: 'center',
                render: (hasSettings, record) => {
                    return (<span>{hasSettings ? I18n.get('Sim') : I18n.get('Não')}</span>)
                }
            }

        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {
            return {
                key: item.id,
                id: item.id,
                region: this.getRegion(item.signupLocale),
                tier: item.company?.tc,
                clientId: item.clientId,
                country: item.company && item.company.country && item.company.country.isO2 && item.company.country.isO2.toLowerCase(),
                date: item.createdOnFmt,
                responsible: item.name,
                email: item.email,
                state: item.company && item.company.state,
                registrationName: item.company && item.company.registrationName,
                city: item.company && item.company.city,
                name: item.company && item.company.name,
                status: this.getStatus(item.status),
                hasSettings: item.hasSettings,
                trainedOnUtcFmt: item.trainedOnUtcFmt,
                trained: item.trainedOnUtcFmt?.length > 0
            }
        });


        return (
            <Table
                pagination={this.props.pagination ? true : false}
                columns={columns}
                dataSource={dataTable}
                locale={{ emptyText: I18n.get('Sem informação') }}
                scroll={{ x: 1200 }}
            />
        );
    }
}

export default TableClient;
