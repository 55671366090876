import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import icoRepoFiles from "../../image/ico-repo-files.svg";

import { AUTH_API } from "../../actions/auth/actions";

import icoBpay from "../../image/ico-bpay.svg";

import Config from "../../config";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AUTH_API.logout());
    },
  };
};

class TopMenuUser extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isActive: null,
    };

    this.activeMenu = this.activeMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isActive: false,
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logout();
  }

  activeMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: true,
    });
  }

  render() {
    //PARTNER

    const name =
      (this.props.auth && this.props.auth.name) || this.props.auth.email;

      const containsPartnerAndSupplier = this.props.auth?.profileGroups.includes('PartnerAndSupplier');

    var firstName = "";

    if (name.indexOf("@") > -1) firstName = name.split("@")[0].toUpperCase();
    else firstName = name.split(" ")[0].toUpperCase();

    const { moduloFaturamento } = Config;

    const urlDash = "/parceiro/dashboard";

    return (
      <div
        ref={this.setWrapperRef}
        onClick={(event) => this.activeMenu(event)}
        className={`navbar-item has-dropdown ${
          this.state.isActive ? "is-active" : ""
        }`}
      >
        <a className="navbar-link has-text-white">
          <span className="navbar-link-name-not-mobile">{firstName} | </span>
          {I18n.get("MENU")}
        </a>

        <div className="navbar-dropdown">
          <div className="columns">
            <div className="column is-6 is-mobile navbar-dropdown-left">
              {I18n.get("Olá,")} {firstName}!
            </div>
            <div className="column is-6 is-mobile navbar-dropdown-right">
              {(this.props.auth.is_partner_operation ||
                this.props.auth.is_partner) && (
                <NavLink
                  to={urlDash}
                  className="navbar-item"
                  activeClassName="is-active"
                >
                  {I18n.get("Dashboard")}
                </NavLink>
              )}

              <NavLink
                to="/parceiro/contratos"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Contratos")}
              </NavLink>

              {(this.props.auth.is_partner_operation ||
                this.props.auth.is_partner) && (
                <>
                  <NavLink
                    to="/parceiro/projetos"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    {I18n.get("Projetos")}
                    {Config.moduloNewBpay && containsPartnerAndSupplier && (
                      <NavLink
                        to="/partners/inicio"
                        className="navbar-item"
                        activeClassName="is-active"
                      >
                        <img src={icoBpay} />
                        {I18n.get("Ollo Pay")}
                      </NavLink>
                    )}
                  </NavLink>
                </>
              )}

              <NavLink
                to="/parceiro/repositorio-de-arquivos"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoRepoFiles} />
                {I18n.get("Repositório de Arquivos")}
              </NavLink>

              <NavLink
                to="/parceiro/extras"
                className="navbar-item"
                activeClassName="is-active"
              >
                {I18n.get("Extras")}
              </NavLink>

              {Config.moduloSuggestProject && (
                <>
                  {this.props.partner &&
                    this.props.partner.statistics &&
                    this.props.partner.statistics.clientWorkedWith.length > 0 &&
                    (this.props.auth.is_partner_operation ||
                      this.props.auth.is_partner) && (
                      <NavLink
                        to="/parceiro/criar-projeto"
                        className="navbar-item"
                        activeClassName="is-active"
                      >
                        {I18n.get("Criar Projeto")}
                      </NavLink>
                    )}
                </>
              )}

              {/* {(Config.moduloSuggestAsset
                                && (this.props.auth.is_partner_operation || this.props.auth.is_partner)) &&
                                <NavLink to="/parceiro/assets/criar" className="navbar-item" activeClassName="is-active">{I18n.get("Criar Asset")}</NavLink>
                            } */}

              {moduloFaturamento &&
                (this.props.auth.is_partner_financial ||
                  this.props.auth.is_partner) && (
                  <NavLink
                    to="/parceiro/faturamento"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    {I18n.get("Faturamento")}
                  </NavLink>
                )}

              <a
                onClick={this.onLogoutClick.bind(this)}
                className="navbar-item navbar-last-item"
              >
                {I18n.get("Sair")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuUser);
