import React, { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row, Col, Rate, message, notification } from "antd";
import { useExtras } from "../../../../../hooks/useExtras/useExtras.hook";
import { useProjects } from "../../../../../hooks/useProjects/useProjects.hook";
import Content from "../../../../../components/UI/Content";
import Main from "../../../../../components/UI/Main";
import LoggedPage from "../../../../../../components/page-templates/fillForm-logged";
import { H2Border } from "../../../../../components/UI/Titles";
import { TabsProject } from "../../../../../components/UI/TabsProject";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import Loading from "../../../../../components/UI/Loading";
import { Tabs } from "../../components/Tabs";
import { ClientProjectStatus } from "../../../../../../model";

import TabsItemsClient from "../../../urls/urlsClient.json";
import TabsItemsClientDefinition from "../../../urls/urlsClientDefinition.json";
import TabsItemsPartner from "../../../urls/urlsPartner.json";
import * as S from "./styles";

export const NewExtraFinalize = () => {
  let params = useParams();
  let history = useHistory();
  const auth = useSelector((state) => state.auth);
  const { getProjectById } = useProjects();
  const { putFinalizeExtra, getExtraById } = useExtras();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [listOfRatings, setListOfRatings] = useState([]);
  const [project, setProject] = useState(null);
  const [infosFinalize, setInfosFinalize] = useState({ status: null, ratings: [] });

  const handleChangeRate = ({ ratingCode, ratingLabel, ratingValue }) => {
    const existingRatingIndex = ratings.findIndex(
      (rating) => rating.code === ratingCode
    );

    if (existingRatingIndex === -1) {
      // Se o rating não existe no array, adiciona o novo objeto
      setRatings((prevRatings) => [
        ...prevRatings,
        {
          code: ratingCode,
          label: ratingLabel,
          value: ratingValue,
        },
      ]);
    } else {
      // Se o rating já existe no array, atualiza o valor do rating no objeto existente
      setRatings((prevRatings) =>
        prevRatings.map((rating, index) =>
          index === existingRatingIndex
            ? { ...rating, value: ratingValue }
            : rating
        )
      );
    }
  };

  const handleBack = () => {
    const urlPartner = `/parceiro/projetos/v2/${params?.projectId}/in-progress/${params?.extraId}/extras`;
    const urlClient = `/cliente/projetos/v2/${params?.projectId}/in-progress/${params?.extraId}/extras`;

    history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
  };

  const send = async () => {
    setIsSending(true);

    try {
      const objSend = {
        projectId: params?.projectId,
        extraId: params?.extraId,
        ratings,
      };
      await putFinalizeExtra(objSend);
      notification.success({
        message: I18n.get(I18n.get('Sucesso!')),
        description: I18n.get("Extra finalizado com sucesso!"),
        duration: 3
      });

      const urlPartner = `/parceiro/projetos/v2/${params?.projectId}/in-progress/extras`;
      const urlClient = `/cliente/projetos/v2/${params?.projectId}/in-progress/extras`;

      history.push(auth?.is_client_or_client_user ? urlClient : urlPartner);
    } catch (error) {
      console.log("error", error?.response);
    }

    setIsSending(false);
  };

  const loadInit = async () => {
    setIsLoading(true);
    try {
      const response = await getExtraById({
        projectId: params?.projectId,
        extraId: params?.extraId,
      });

      setInfosFinalize({ status: response?.data?.status, ratings: response?.data?.ratings })

      const responseProject = await getProjectById({
        projectId: params?.projectId,
      });
      if (responseProject?.success) {
        setProject(responseProject?.data);
      }

      const mockArrayRatings = [
        {
          code: "ATENDIMENTO",
          label: I18n.get("Atendimento do Parceiro"),
        },
        {
          code: "QUALIDADE",
          label: I18n.get("Qualidade da Entrega do Job"),
        },
        {
          code: "PRAZO",
          label: I18n.get("Cumprimento dos Prazos"),
        },
      ];
      setListOfRatings(mockArrayRatings);
    } catch (error) {
      console.log("error", error?.response);
    }
    setIsLoading(false);
  };

  const whatValueRating = (code) => {
    const finded = ratings?.find((item) => item?.code === code);

    if (finded) {
      return finded?.value;
    } else {
      return null;
    }
  };

  const whatValueRatingFinalized = (code) => {
    const finded = infosFinalize?.ratings?.find((item) => item?.code === code);

    if (finded) {
      return finded?.value;
    } else {
      return null;
    }
  };

  useEffect(() => {
    loadInit();
  }, []);

  let tabs =
    project?.status === 7 ? TabsItemsClient : TabsItemsClientDefinition;

  let tabActive = project?.status === 7 ? 5 : 4;

  let disableTabs = [];

  if (!project?.partnerHasSubmittedDefinitions) {
    disableTabs = [3, 4];
  } else if (project?.partnerHasSubmittedDefinitions && project?.status < 6) {
    disableTabs = [3];
  } else {
    disableTabs = [];
  }

  const shouldDisableFinalize = () => {
    // Dica: Para fins de debug, descomente os comentários para ver o que está acontecendo.

    let shouldBeDisabled = true;

    if (project?.status < ClientProjectStatus.Started) {
      // console.log(
      //   'Status Finalização: Ação de "Finalizar" bloqueado, pois o status do projeto precisa ter informado SPA#.'
      // );
      return true;
    } else {
      //console.log("Status Finalização: Ação de \"Finalizar\" liberado por ser status maior ou igual a \"Started\".");
      shouldBeDisabled = false;
    }

    // Verifica se ratings existe e tem pelo menos os 3 itens avaliados.
    if (ratings?.length >= 3) {
      const hasAtLeastOneNotFilled = ratings.every((item) => item.value > 0);

      if (!hasAtLeastOneNotFilled) {
        // console.log(
        //   'Status Finalização: Ação de "Finalizar" bloqueado, pois precisa ter selecionado avaliação em 3 parceiros.'
        // );
        return true;
      } else {
        //console.log("Status Finalização: Ação de \"Finalizar\" liberado por ter selecionado avaliação em 3 parceiros.");
        shouldBeDisabled = false;
      }
    } else {
      // console.log(
      //   'Status Finalização: Ação de "Finalizar" bloqueado por não ter selecionado avaliações dos parceiros.'
      // );
      return true;
    }

    const amountExtras = project?.extras?.length;

    if (project?.extras) {
      let billingsCount = 0;

      // Calcula a quantidade de Extras que possui SPA preenchido (Billing).
      for (const extra of project.extras) {
        if (extra.Billings) {
          billingsCount++;
        }
      }

      if (amountExtras === billingsCount) {
        //console.log("Status Finalização: Ação de \"Finalizar\" liberado devido os Extras terem POs preenchidos.");
        shouldBeDisabled = false;
      } else {
        // console.log(
        //   'Status Finalização: Ação de "Finalizar" bloqueado por ter Extras sem SPA preenchido.'
        // );
        return true;
      }
    } else {
      //console.log("Status Finalização: Ação de \"Finalizar\" liberado por não haver Extras.");
      shouldBeDisabled = false;
    }

    return shouldBeDisabled;
  };

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>
                {project?.status === 7
                  ? I18n.get("Projeto em andamento")
                  : I18n.get("Definições do Projeto")}
              </H2Border>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <TabsProject
                context={
                  project?.status === 6
                    ? "definitions"
                    : project?.status === 7
                      ? "inProgress"
                      : "finalized"
                }
                page="extras"
                externalLoading={isLoading}
                projectStatus={project?.status}
                partnerHasSubmittedDefinitions={
                  project?.partnerHasSubmittedDefinitions
                }
              />
            </Col>
          </Row>

          <Row>
            <Col sm={24}>
              <Main bgColor="#fff" padding="30px">
                <Row>
                  <Col xs={24} sm={24}>
                    <S.RowCenter>
                      <S.TitleWithIcon>
                        <S.H4>{I18n.get("Extras")}</S.H4>
                      </S.TitleWithIcon>
                    </S.RowCenter>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24}>
                    <Tabs extraId={params?.extraId} />
                  </Col>
                </Row>

                <Row>
                  {isLoading ? (
                    <Col xs={24} sm={24}>
                      <S.ContentIsLoading>
                        <Loading
                          text={I18n.get("Loading informations...")}
                          sizeText={14}
                          sizeIcon={16}
                        />
                      </S.ContentIsLoading>
                    </Col>
                  ) : (
                    listOfRatings?.map((item, index) => (
                      <Col key={index} xs={8} sm={8}>
                        <S.Content>
                          <S.Text>{item?.label}</S.Text>
                          <S.ContentRate>
                            <Rate
                              disabled={infosFinalize?.status === 20 ? true : false}
                              value={infosFinalize?.status === 20 ? whatValueRatingFinalized(item?.code) : whatValueRating(item?.code)}
                              onChange={(value) =>
                                handleChangeRate({
                                  ratingCode: item?.code,
                                  ratingLabel: item?.label,
                                  ratingValue: value,
                                })
                              }
                            />{" "}
                          </S.ContentRate>
                        </S.Content>
                      </Col>
                    ))
                  )}
                </Row>

                {isLoading ? (
                  <div />
                ) : (
                  <Row>
                    <Col xs={24} sm={24}>
                      <S.ButtonsFooter>
                        <ButtonBpool
                          text={I18n.get("Back")}
                          theme="secondary"
                          onClick={handleBack}
                        />
                        {infosFinalize?.status !== 20 ? (
                          <ButtonBpool
                            text={I18n.get("Finalize")}
                            theme="primary"
                            onClick={send}
                            disabled={shouldDisableFinalize()}
                            loading={isSending}
                          />
                        ) : (
                          <S.ExtraFinalized>
                            <p>{I18n.get("Extra já finalizado.")}</p>
                          </S.ExtraFinalized>
                        )}
                      </S.ButtonsFooter>
                    </Col>
                  </Row>
                )}
              </Main>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
