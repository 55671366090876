import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { DatePicker } from "antd";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";
import Checkbox from "../../../../../components/UI/Checkbox";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";

import { createQueryString, parseQueryString } from "./utils/resolvers";
import localePt from "./localesDates/pt.json";
import IconPlus from "../../../svgs/plus.svg";
import * as S from "./styles";

const { RangePicker } = DatePicker;
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const listStatus = [
  {
    label: I18n.get("Todos"),
    code: 0,
  },
  {
    label: I18n.get("Rascunho"),
    code: 1,
  },
  {
    label: I18n.get("Cadastro de fornecedor"),
    code: 2,
  },
  {
    label: I18n.get("Aguardando SPA"),
    code: 3,
  },
  {
    label: I18n.get("Em andamento"),
    code: 4,
  },
  {
    label: I18n.get("Finalizado"),
    code: 5,
  },
];

export const Filters = () => {
  let history = useHistory();
  let location = useLocation();
  const { exportBPays } = useBpay();
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({});
  const [datesDefault, setDatesDefault] = useState({
    dateFormattedStart: null,
    dateFormattedEnd: null,
  });
  const [loadingDate, setLoadingDate] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [searchValueDebounced] = useDebounce(searchTerm, 1000);

  const handleChangeRangePicker = (date, dateString) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!date[0]) {
      delete objQueries.createdStart;
      delete objQueries.createdEnd;
      queryString = createQueryString({ ...objQueries });
    } else {
      const dateCreatedStart = date[0].format("YYYY-MM-DD");
      const dateCreatedEnd = date[1].format("YYYY-MM-DD");

      queryString = createQueryString({
        ...objQueries,
        createdStart: dateCreatedStart,
        createdEnd: dateCreatedEnd,
      });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleChangeStatus = (status) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (status === 0) {
      delete objQueries.status;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, status });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleChangeProjects = (e) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (e.target.checked === false) {
      delete objQueries.onlyMyBPays;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({
        ...objQueries,
        onlyMyBPays: e.target.checked,
      });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearch = (search) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!search) {
      delete objQueries.search;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, search });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchValueDebounced]);

  const datepicker = async (objQueries) => {
    setDatesDefault({
      dateFormattedStart: objQueries?.createdStart,
      dateFormattedEnd: objQueries?.createdEnd,
    });
  };

  const datepickerInit = async (objQueries) => {
    setLoadingDate(true);
    await datepicker(objQueries);
    setLoadingDate(false);
  };

  const handleExportBPays = async () => {
    try {
      setIsExporting(true);
      const query = location?.search;
      const response = await exportBPays({
        query,
      });

      const pdfUrl = response?.data?.urlXlsx;
      if (pdfUrl) {
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        throw new Error();
      }
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao exportar os BPays"),
        duration: 3,
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    const objQueries = parseQueryString(location?.search);

    if (objQueries?.createdStart) {
      datepickerInit(objQueries);
    }

    setFilters(objQueries);
  }, [location]);

  return (
    <S.Wrapper>
      <S.WrapperRangePicker>
        {loadingDate ? (
          <div />
        ) : (
          <RangePicker
            onChange={handleChangeRangePicker}
            separator="-"
            locale={localePt}
            format={dateFormatList}
            defaultValue={
              datesDefault?.dateFormattedStart
                ? [
                    moment(datesDefault?.dateFormattedStart, "YYYY-MM-DD"),
                    moment(datesDefault?.dateFormattedEnd, "YYYY-MM-DD"),
                  ]
                : null
            }
          />
        )}
      </S.WrapperRangePicker>
      <div style={{}}>
        <SelectOne
          placeholder={I18n.get("Status")}
          options={listStatus}
          value={filters?.status ? Number(filters?.status) : 0}
          onChange={(value) => handleChangeStatus(value)}
          error=""
        />
      </div>
      <div style={{}}>
        <Input
          placeholder={I18n.get("Pesquisar")}
          label
          value={searchTerm}
          onChange={(value) => setSearchTerm(value)}
          searchInput
        />
      </div>
      <S.CheckboxProjects>
        <strong>{I18n.get("Projetos")}</strong>
        <div className="checkbox-my-projects">
          <Checkbox
            onChangeCb={(e, id) => handleChangeProjects(e)}
            checked={filters?.onlyMyBPays ? true : false}
            checkboxSize={18}
          />
          <p>{I18n.get("Apenas os meus")}</p>
        </div>
      </S.CheckboxProjects>
      <div>
        <ButtonBpool
          text={I18n.get("Gerar relatório")}
          theme="tertiary"
          icon={IconPlus}
          loading={isExporting}
          full
          onClick={handleExportBPays}
        />
      </div>
    </S.Wrapper>
  );
};
