import React, { useState, useEffect } from "react";
import COUNTRY_CODES from "./countryCodes.json";
import {
  FormDadosDaEmpresa,
  FormEndereco,
  FormDadosDaNota,
  FormFormDeRecebimento,
  FormCarregarNF,
  FormCadastro,
} from "./Forms";

import * as S from "./styles";

export const FormNF = ({
  modules,
  modulesDisableds,
  onChangeForm,
  formError,
  onChangeErrors,
  isLoadingGet,
  nfValue,
  bPaymentId,
  onChangetermsAndPassword,
  defaultValues,
  isDrawer,
  listTimezone,
  paymentBeneficiaries,
}) => {
  const [inputsForm, setInputsForm] = useState({
    passWord: "",
    passwordIsValid: "",
    confirmPassWord: "",
  });
  const [fileNF, setFileNF] = useState({});
  const [slipFile, setSlipFile] = useState({});
  const [termsAccept, setTermsAccept] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [defaultZipCode, setDefaultZipCode] = useState({});

  const onChangeInputForm = (id, value, isCountry) => {
    if (isCountry) {
      const obj = COUNTRY_CODES.find((i) => i.code == value);
      setInputsForm({
        ...inputsForm,
        [id]: value ? obj : null,
      });
    } else {
      setInputsForm({
        ...inputsForm,
        [id]: value ? value : "",
      });
    }
  };

  React.useEffect(() => {
    console.log("inputsForm", inputsForm);
  }, [inputsForm]);

  const handleChangeInputs = (inputsByCep) => {
    setInputsForm(inputsByCep);
  };

  const handleUploadNF = (file) => {
    setFileNF(file);
  };

  const handleUploadSlip = (file) => {
    setSlipFile(file);
  };

  const handleDeleteUploadNF = async (fileOrId) => {
    if (fileOrId?.name) {
      setFileNF({});
    }
  };

  const handleDeleteUploadSlip = async (fileOrId) => {
    if (fileOrId?.name) {
      setSlipFile({});
    }
  };

  const handleChangeErrors = (errors) => {
    onChangeErrors(errors);
  };

  const handleChangeTerms = (e) => {
    setTermsAccept(e.target.checked);
  };

  const handleChangePasswordIsValid = (isValid) => {
    setPasswordIsValid(isValid);
    setTermsAccept(isValid);
  };

  useEffect(() => {
    if (onChangeForm) {
      onChangeForm({ ...inputsForm, file: fileNF, slip: slipFile });
    }
  }, [inputsForm, fileNF, slipFile]);

  useEffect(() => {
    if (onChangetermsAndPassword) {
      onChangetermsAndPassword({ termsAccept, passwordIsValid });
    }
  }, [termsAccept, passwordIsValid]);

  useEffect(() => {
    if (defaultValues) {
      setInputsForm({ ...inputsForm, ...defaultValues });

      if (defaultValues?.zipCode) {
        setDefaultZipCode({
          zipCode: defaultValues?.zipCode,
          city: defaultValues?.city,
          addressLine1: defaultValues?.addressLine1,
          state: defaultValues?.state,
          country: defaultValues?.country,
        });
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    if (listTimezone?.length) {
      setInputsForm({
        ...inputsForm,
        timeZone: "America/Sao_Paulo",
      });
    }
  }, [listTimezone]);

  const mock = [
    {
      label: "Pix 123",
      code: "123",
    },
    {
      label: "Pix 456",
      code: "456",
    },
  ];

  return (
    <S.Form>
      {modules?.includes("DadosDaEmpresa") ? (
        <FormDadosDaEmpresa
          inputsForm={inputsForm}
          handleChangeInputForm={onChangeInputForm}
          errorsInputs={formError}
          handleSetErrorsInputs={handleChangeErrors}
          isLoadingGet={isLoadingGet}
          inputsDisableds={
            modulesDisableds?.includes("DadosDaEmpresa") ? true : false
          }
        />
      ) : null}
      {modules?.includes("Endereco") ? (
        <FormEndereco
          inputsForm={inputsForm}
          handleChangeInputForm={onChangeInputForm}
          handleChangeInputsAddressByCep={handleChangeInputs}
          errorsInputs={formError}
          handleSetErrorsInputs={handleChangeErrors}
          isLoadingGet={isLoadingGet}
          defaultValues={defaultZipCode}
          inputsDisableds={
            modulesDisableds?.includes("Endereco") ? true : false
          }
          listTimezone={listTimezone}
        />
      ) : null}
      {modules?.includes("DadosDaNota") ? (
        <FormDadosDaNota
          inputsForm={inputsForm}
          handleChangeInputForm={onChangeInputForm}
          errorsInputs={formError}
          handleSetErrorsInputs={handleChangeErrors}
          nfValue={nfValue}
          isLoadingGet={isLoadingGet}
          inputsDisableds={
            modulesDisableds?.includes("DadosDaNota") ? true : false
          }
        />
      ) : null}
      {modules?.includes("FormaDeRecebimento") ? (
        <FormFormDeRecebimento
          inputsForm={inputsForm}
          handleChangeInputForm={onChangeInputForm}
          handleChangeInputs={handleChangeInputs}
          errorsInputs={formError}
          handleSetErrorsInputs={handleChangeErrors}
          isLoadingGet={isLoadingGet}
          inputsDisableds={
            modulesDisableds?.includes("FormaDeRecebimento") ? true : false
          }
          isDrawer={isDrawer}
          paymentBeneficiaries={paymentBeneficiaries}
          bPaymentId={bPaymentId}
          handleDeleteUploadSlip={handleDeleteUploadSlip}
          handleUploadSlip={handleUploadSlip}
        />
      ) : null}
      {modules?.includes("CarregarNF") ? (
        <FormCarregarNF
          inputsForm={inputsForm}
          handleUploadNF={handleUploadNF}
          handleDeleteUploadNF={handleDeleteUploadNF}
          errorsInputs={formError}
          handleSetErrorsInputs={handleChangeErrors}
          isLoadingGet={isLoadingGet}
          inputsDisableds={
            modulesDisableds?.includes("CarregarNF") ? true : false
          }
        />
      ) : null}
      {modules?.includes("Cadastro") ? (
        <FormCadastro
          inputsForm={inputsForm}
          handleChangeInputForm={onChangeInputForm}
          termsAccept={true}
          handleChangeTerms={handleChangeTerms}
          isLoadingGet={isLoadingGet}
          handleChangePasswordIsValid={handleChangePasswordIsValid}
          inputsDisableds={
            modulesDisableds?.includes("Cadastro") ? true : false
          }
        />
      ) : null}
    </S.Form>
  );
};
