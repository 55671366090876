import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { PARTNER_API } from "../../../../../actions/partner/actions";

import LoggedPage from "../../../../../components/page-templates/fillForm-logged";

import * as S from "./styles";

const mapStateToProps = (state) => {
  return Object.assign({}, state, state.partner);
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_partner: () => {
      dispatch(PARTNER_API.get());
    },
    get_project: (data) => {
      dispatch(PARTNER_API.get_project(data));
    },
    create_billing_invoice: (data) => {
      return dispatch(PARTNER_API.create_billing_invoice(data));
    },
    upload_billing_invoice: (data) => {
      return PARTNER_API.upload_billing_invoice(data);
    },
  };
};

class InvoiceInstructions extends React.Component {
  constructor(props) {
    super();

    this.state = {};
  }

  componentDidMount() {
    var data = {
      projectId: this.props.match.params.projectId,
    };

    this.props.get_project(data);
  }

  render() {
    const { project = {} } = this.props;

    const { billingId, projectId } = this.props.match.params;

    const { billings = [] } = project;

    const billing = billings.find((item) => item.id == billingId);

    let bPoolOffice = project.bPoolOffice;
    let invoiceTo = project.invoiceTo;

    let invoiceToName;
    if (!invoiceTo || invoiceTo.length == 0 || invoiceTo.toUpperCase() == "CLIENT") {
      invoiceToName = `${billing?.registrationName} (${billing?.registrationId})`
    } else {
      invoiceToName = `${bPoolOffice?.registrationName} (${bPoolOffice?.registrationId})`
    }

    console.log("project", project);

    return (
      <LoggedPage {...this.props}>
        <div className="uplift partner-projects client-project-list">
          <S.WrapperPage>
            <S.SubHeader>
              <div className="container" style={{ textAlign: "right" }}>
                <Link
                  to={`/parceiro/faturamento/${projectId}/po/${billingId}`}
                  className="button bt-bpool"
                >
                  {I18n.get("Voltar")}
                </Link>
              </div>
            </S.SubHeader>
            <div className="container" style={{ paddingBottom: 70 }}>
              <h1>
                <span>{I18n.get("Invoice Instructions")}</span>
              </h1>

              <S.WrapperTable>
                <S.ItemList>
                  {I18n.get("Type of Invoice")}:{" "}
                  <strong>{billing?.invoiceType}</strong>
                </S.ItemList>
                <S.ItemList>
                  {I18n.get("Invoice to")}:{" "}
                  <strong>{invoiceToName}</strong>
                </S.ItemList>

                <S.ItemList>
                  <div className="invoiceResume">
                    <div className="topResume">
                      <br />
                      <p>
                        <strong>
                          {I18n.get("Informaçoes para faturamento")}
                        </strong>
                      </p>
                      <p>
                        <b>{I18n.get("Client SPA number")}:</b>{" "}
                        {billing?.projectPO}
                      </p>
                      <p>
                        <b>{I18n.get("Brand/Project")}:</b> {project?.brandName}{" "}
                        / {project?.projectName}
                      </p>
                    </div>

                    {/* Boutique Pool */}
                    {bPoolOffice.id == "bp_intermediacao" && (
                      <div className="resume">
                        <p>
                          <strong>
                            {I18n.get("Detalhes de pagamento e inserçao de NF")}
                          </strong>
                        </p>

                        <p>{I18n.get("Your bank account details")}</p>
                        <p>- {I18n.get("Name of Bank")}</p>
                        <p>- {I18n.get("Type and Account number , CNPJ")}</p>
                      </div>
                    )}

                    {/* GLOBALISTA */}
                    {bPoolOffice.id === "bp_inter_sas" && (
                      <div className="resume">
                        <p>
                          <strong>
                            {I18n.get("Detalhes de pagamento e inserçao de NF")}
                          </strong>
                        </p>

                        <p>{I18n.get("Your Colombian bank account details")}</p>
                        <p>
                          {I18n.get(
                            "Taxes: you will apply and inform taxes in the same way you usually do for your clients. In other words, please follow your normal invoicing model. "
                          )}
                        </p>
                      </div>
                    )}

                    {/* Terandes SA */}
                    {bPoolOffice.id === "bp_terandes" && (
                      <div className="resume">
                        <p>
                          <strong>
                            {I18n.get("Detalhes de pagamento e inserçao de NF")}
                          </strong>
                        </p>

                        <p>
                          {I18n.get(
                            "Your international bank account details - please strictily follow this instructions to avoid delays or payment issues. "
                          )}
                        </p>
                        <p>- {I18n.get("Name of Bank")}</p>
                        <p>- {I18n.get("Bank account ")}</p>
                        <p>- Swift</p>
                        <p>
                          -{" "}
                          {I18n.get(
                            "Full address of bank HQ (with ZIP CODE) used for international payment - usually your branch - please get this vital information with your manager or bank website"
                          )}
                        </p>
                        <p>
                          -{" "}
                          {I18n.get(
                            "If your bank uses an intermediary bank please provide: name of bank, ABA code, full adress with zip code. "
                          )}
                        </p>
                        <p>
                          -{" "}
                          {I18n.get(
                            "Full beneficiary name (in case of 2 beneficiaries, please inform main beneficiary) "
                          )}
                        </p>

                        <br />

                        <p>
                          -{" "}
                          {I18n.get(
                            "Taxes: do not add any IVA in your price since in Costa Rica our clients will make a self assessment of this taxes and pay themselves. "
                          )}
                        </p>
                      </div>
                    )}

                    {/* Fiduciaria BPOOL  */}
                    {bPoolOffice.id === "bpool_fideicomiso " && (
                      <div className="resume">
                        <p>
                          <strong>
                            {I18n.get("Detalhes de pagamento e inserçao de NF")}
                          </strong>
                        </p>

                        <p>
                          {I18n.get("Your Equatorian bank account details")}:
                        </p>

                        <p>
                          {I18n.get(
                            "taxes: you will apply and inform taxes in the same way you usually do for your clients. In other words, please follow  your normal invoicing model. "
                          )}
                        </p>
                      </div>
                    )}

                    {/* Clan Colectora SA */}
                    {bPoolOffice.id === "bpool_clan " && (
                      <div className="resume">
                        <p>
                          <strong>
                            {I18n.get("Detalhes de pagamento e inserçao de NF")}
                          </strong>
                        </p>

                        <p>
                          {I18n.get("Your Equatorian bank account details")}:
                        </p>

                        <p>
                          {I18n.get(
                            "taxes: you will apply and inform taxes in the same way you usually do for your clients. In other words, please follow  your normal invoicing model. "
                          )}
                        </p>

                        <p>- {I18n.get("Name of Bank")}</p>

                        <br />
                        <p>
                          {I18n.get(
                            "Type and Account number - CBU (22 digits)  + Codigo ALIAS (optional). Taxes:  CUIT (Codigo Unico de Identificacion Tributaria).    Para Partners Responsables Inscripto en IVA, emitir Factura A con IVA discriminado.    Para Partners Responsables Monotributo, emitir Factura C sin agregar IVA."
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </S.ItemList>
              </S.WrapperTable>

              <S.DescriptionInfo>
                {/* <h2>{I18n.get("How to handle your taxes")}</h2> */}

                <section>
                  <div className="payment-box">
                    <h3>{I18n.get("Payment instructions")}</h3>

                    <div className="invoiceResume">
                      {/* Boutique Pool */}

                      {bPoolOffice.id === "bp_mex" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "Please strictily follow this instructions to avoid delays or payment issues."
                            )}
                          </p>

                          <p>
                            {I18n.get(
                              "On the description body of your invoice please inform your complete international bank account details"
                            )}
                          </p>

                          <p>
                            -{" "}
                            {I18n.get(
                              "Complete name of Bank (ie Citibanamex name is Banco Nacional de Mexico SA)"
                            )}
                          </p>

                          <p> -{I18n.get("Bank account")}</p>
                          <p>
                            -{" "}
                            {I18n.get(
                              "Full address of bank head quarters used for international payment (with ZIP CODE) - It might not be your branch adress - please get this vital information with your manager or bank website"
                            )}
                          </p>

                          <p>
                            -{" "}
                            {I18n.get(
                              "If your bank uses an intermediary bank please provide: name of bank, ABA code, full adress with zip code."
                            )}
                          </p>
                          <p>
                            -{" "}
                            {I18n.get(
                              "Full beneficiary name (in case of 2 beneficiaries, please inform main beneficiary) "
                            )}
                          </p>
                          <br />
                          <p>
                            -{" "}
                            {I18n.get(
                              "Please notice that informing only bank account and swift code will lead to delays and payment issues. "
                            )}
                          </p>
                        </div>
                      )}

                      {/* Boutique Pool */}
                      {bPoolOffice.id === "bp_intermediacao" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "please inform your Brazilian bank account details on your invoice body description text. Name of Bank Type and Account number, CNPJ"
                            )}{" "}
                          </p>
                        </div>
                      )}

                      {/* GLOBALISTA */}
                      {bPoolOffice.id === "bp_inter_sas" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "Please inform your Colombian bank account details on your invoice body description text"
                            )}
                          </p>
                        </div>
                      )}

                      {/* Terandes SA */}
                      {bPoolOffice.id === "bp_terandes" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "Please strictily follow this instructions to avoid delays or payment issues."
                            )}
                          </p>

                          <p>
                            -{" "}
                            {I18n.get(
                              "On the description body of your invoice please inform your complete international bank account details"
                            )}
                          </p>

                          <p>
                            -{" "}
                            {I18n.get(
                              "Complete name of Bank (ie Citibanamex name is Banco Nacional de Mexico SA)"
                            )}
                          </p>

                          <p>- {I18n.get("Bank account")}</p>
                          <p>- Swift</p>
                          <p>
                            -{" "}
                            {I18n.get(
                              "Full address of bank head quarters used for international payment (with ZIP CODE) - It might not be your branch adress - please get this vital information with your manager or bank website."
                            )}
                          </p>
                          <p>
                            -{" "}
                            {I18n.get(
                              "If your bank uses an intermediary bank please provide: name of bank, ABA code, full adress with zip code."
                            )}
                          </p>
                          <p>
                            -{" "}
                            {I18n.get(
                              "Full beneficiary name (in case of 2 beneficiaries, please inform main beneficiary)"
                            )}
                          </p>

                          <p>
                            {I18n.get(
                              "Please notice that informing only bank account and swift code will lead to delays and payment issues."
                            )}
                          </p>
                        </div>
                      )}

                      {/* Fiduciaria BPOOL  */}
                      {bPoolOffice.id === "bpool_fideicomiso" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "Please inform your bank account details on your invoice body description text"
                            )}
                            :
                          </p>
                        </div>
                      )}

                      {/* Clan Colectora SA */}
                      {bPoolOffice.id === "bpool_clan" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "Please inform your Argentinian bank account details on your invoice body description text. Name of Bank Type and Account number - CBU (22 digits)  + Codigo ALIAS (optional). Taxes:  CUIT (Codigo Unico de Identificacion Tributaria). "
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="report-box">
                    <h3>{I18n.get("Payment fluxe")}</h3>

                    <div className="invoiceResume">
                      {/* Boutique Pool */}

                      {bPoolOffice.id === "bp_mex" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "1) Your company will invoice BPOOL Mexico"
                            )}{" "}
                          </p>
                          <p>
                            {I18n.get(
                              "2) BPOOL invoices client in México ( Total invoice)"
                            )}
                          </p>
                          <p>
                            {I18n.get(
                              "3) Upon client's payment to BPOOL account in the MEX according to client's pre-agreed standard payment terms, BPOOL will transfer the resources to your informed bank account within 72 hours"
                            )}
                          </p>
                        </div>
                      )}

                      {/* Boutique Pool */}
                      {bPoolOffice.id === "bp_intermediacao" && (
                        <div className="resume">
                          <p>
                            {I18n.get("1) Your company will invoice BPOOL")}{" "}
                          </p>
                          <p>{I18n.get("2) BPOOL invoices client ")}</p>
                          <p>
                            {I18n.get(
                              "3) Upon client's payment to BPOOL accounts , according to client's pre-agreed standard payment terms, BPOOL will transfer the resources to your informed bank account within 72 hours "
                            )}
                          </p>
                        </div>
                      )}

                      {/* GLOBALISTA */}
                      {bPoolOffice.id === "bp_inter_sas" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "1) Your company will invoice BPOOL LATAM / TERANDES SA (URUGUAY) "
                            )}
                          </p>
                          <p>
                            {I18n.get("2) BPOOL invoices client in Colombia")}
                          </p>
                          <p>
                            {I18n.get(
                              "3) Upon client's payment to BPOOL accounts, according to client's pre-agreed standard payment terms, BPOOL will transfer the resources to your informed bank account within 72 hours "
                            )}
                          </p>
                        </div>
                      )}

                      {/* Terandes SA */}
                      {bPoolOffice.id === "bp_terandes" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "1) Your company will invoice BPOOL LATAM (Terandes SA @ Uruguay) "
                            )}
                          </p>
                          <p>
                            {I18n.get("2) BPOOL invoices client in Costa Rica")}
                          </p>
                          <p>
                            {I18n.get(
                              "3) Upon client's payment to BPOOL account in the USA, according to client's pre-agreed standard payment terms, BPOOL will transfer the resources to your informed bank account within 72 hours"
                            )}
                          </p>
                        </div>
                      )}

                      {/* Fiduciaria BPOOL  */}
                      {bPoolOffice.id === "bpool_fideicomiso" && (
                        <div className="resume">
                          <p>
                            <strong>
                              {I18n.get(
                                "1) Your company will invoice BPOOL ECUADOR (Fideicomiso BPOOL @ Ecuador)  "
                              )}
                            </strong>
                          </p>

                          <p>
                            {I18n.get("2) BPOOL invoices client in Ecuador")}:
                          </p>

                          <p>
                            {I18n.get(
                              "3) Upon client's payment to BPOOL bank account @Ecuador, according to client's pre-agreed standard payment terms, BPOOL will transfer the resources to your informed bank account within 72 hours "
                            )}
                          </p>
                        </div>
                      )}

                      {/* Clan Colectora SA */}
                      {bPoolOffice.id === "bpool_clan" && (
                        <div className="resume">
                          <p>
                            {I18n.get(
                              "1) Your company will invoice BPOOL Argentina ( CLAN COLECTORA SA @ Argentina)"
                            )}
                          </p>
                          <p>
                            {I18n.get("2) BPOOL invoices client in Argentine")}
                          </p>
                          <p>
                            {I18n.get(
                              "3) Upon client's payment to BPOOL accounts @Argentina, according to client's pre-agreed standard payment terms, BPOOL will transfer the resources to your informed bank account within 72 hours"
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </S.DescriptionInfo>
            </div>
          </S.WrapperPage>
        </div>
      </LoggedPage>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceInstructions);
