import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import icoClose from "../../image/ico-close.svg";
import icoMenu from "../../image/ico-menu-white.svg";
import bLogo from "../../image/b-logo.svg";
import icoDash from "../../image/ico-dash.svg";
import icoContract from "../../image/ico-contract.svg";
import icoProjects from "../../image/ico-projects.svg";
import icoExit from "../../image/ico-exit.svg";
import icoHome from "../../image/ico-home.svg";
import icoNews from "../../image/ico-news.svg";
import icoProfile from "../../image/ico-profile-menu.svg";
import icoRepoFiles from "../../image/ico-repo-files.svg";
import icoBpay from "../../image/ico-bpay.svg";

import Config from "../../config";

import { AUTH_API } from "../../actions/auth/actions";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AUTH_API.logout());
    },
  };
};

class TopMenu extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isActive: null,
    };

    this.activeMenu = this.activeMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isActive: false,
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logout();
  }

  activeMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: true,
    });
  }

  deactivateMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: false,
    });
  }

  render() {
    //CLIENT USER

    // const name = (this.props.auth
    //     && this.props.auth.name) || this.props.auth.email;
    // var firstName = "";
    // if (name.indexOf("@") > -1)
    //     firstName = name.split('@')[0].toUpperCase();
    // else
    //     firstName = name.split(' ')[0].toUpperCase();
    // const urlDash = '/cliente/dashboard';

    return (
      <div
        ref={this.setWrapperRef}
        onClick={(event) => this.activeMenu(event)}
        className={`navbar-item has-dropdown ${
          this.state.isActive ? "is-active" : ""
        }`}
      >
        <a className="navbar-link has-text-white">
          <img src={icoMenu} alt="icone menu" />
        </a>

        {/* <div className="navbar-dropdown">
                    <div className="columns">
                        <div className="column is-6 is-mobile navbar-dropdown-left">{I18n.get("Olá,")} {firstName}!
                        </div>
                        <div className="column is-6 is-mobile navbar-dropdown-right">

                            <NavLink to={urlDash} className="navbar-item" activeClassName="is-active">{I18n.get("Dashboard")}</NavLink>
                            <NavLink to="/cliente/contratos" className="navbar-item" activeClassName="is-active">{I18n.get("Contratos")}</NavLink>
                            <NavLink to="/cliente/projetos" className="navbar-item" activeClassName="is-active">{I18n.get("Projetos")}</NavLink>
                            <NavLink to="/cliente/extras" className="navbar-item" activeClassName="is-active">{I18n.get("Extras")}</NavLink>

                            <a onClick={this.onLogoutClick.bind(this)} className="navbar-item navbar-last-item">{I18n.get("Sair")}</a>

                        </div>
                    </div>
                </div> */}

        <div className="navbar-menu-left">
          <div className="menu-left-header">
            <img src={bLogo} />
            <img
              src={icoClose}
              onClick={(event) => this.deactivateMenu(event)}
            />
          </div>
          <div>
            <NavLink to="/" className="navbar-item" activeClassName="is-active">
              <img src={icoHome} />
              {I18n.get("Home")}
            </NavLink>
            {/* <NavLink to="/cliente/news" className="navbar-item" activeClassName="is-active"><img src={icoNews}/>{I18n.get("News")}</NavLink>  */}
            <NavLink
              to="/cliente/dashboard"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoDash} />
              {I18n.get("Central de Pendências e Dashboard")}
            </NavLink>
            <NavLink
              to="/cliente/user"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProfile} />
              {I18n.get("Perfil")}
            </NavLink>
            <NavLink
              to="/cliente/contratos"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoContract} />
              {I18n.get("Contratos")}
            </NavLink>
            <NavLink
              to="/cliente/projetos/v2"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProjects} />
              {I18n.get("Projetos")}
            </NavLink>
            {Config.moduloNewBpay && (
              <NavLink
                to="/cliente/bpay"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoBpay} />
                {I18n.get("Ollo Pay")}
              </NavLink>
            )}
            <NavLink
              to="/cliente/repositorio-de-arquivos"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoRepoFiles} />
              {I18n.get("Repositório de Arquivos")}
            </NavLink>

            <a
              onClick={this.onLogoutClick.bind(this)}
              className="navbar-item navbar-last-item"
            >
              <img src={icoExit} />
              {I18n.get("Sair")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
