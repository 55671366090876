import { CHAT_PING } from './actions';

const api_call = () => {
  return (dispatch, getState) => {
    const data = JSON.stringify({ action: 'chat-topics' });

    //window.sendWsMessage(data);
  };
};

export default api_call;
