import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Tooltip } from "antd";
import { useBpay } from "../../../../../hooks/useBpay/useBpay.hook";
import { Drawer } from "../../../../../components/UI/Drawer";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { InfosDrawer } from "./InfosDrawer";
import * as S from "./styles";
import { colorStatus, colorBgStatus } from "../../../utils/colorsStatus";
import {
  createQueryString,
  parseQueryString,
} from "../../../utils/paramsResolvers";

export const TableBPayments = () => {
  let params = useParams();
  let location = useLocation();
  let history = useHistory();
  const { getBPaymentDataStatus, bpayDetailsInProgressList } = useBpay();
  const [drawer, setDrawer] = useState({ visible: false });
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);

  const { data, isLoading, error, refetch } = useQuery(
    [
      `bpayDetail-id${params?.bpayId}-list`,
      createQueryString(parseQueryString(location?.search)),
    ],
    () =>
      bpayDetailsInProgressList({
        bpayId: params?.bpayId,
        query: createQueryString(parseQueryString(location?.search)),
      }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (data) => {},
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os dados do Ollo Pay"),
          duration: 3,
        });
      },
    }
  );

  const renderText = (text) => {
    if (text || text === "0" || text === 0) {
      return text;
    } else {
      return "---";
    }
  };

  const handleCloseDrawer = () => {
    setDrawer({ visible: false });
  };

  const handleOpenDrawer = async ({ record }) => {
    setDrawer({
      visible: true,
      data: {
        name: record?.name,
        emailPrimary: record?.emailPrimary,
        totalFmt: record?.totalFmt,
        status: null,
      },
    });
    try {
      setIsLoadingDrawer(true);
      const response = await getBPaymentDataStatus({
        bPayId: params?.bpayId,
        bPaymentId: record?.id,
      });
      setDrawer({
        visible: true,
        data: {
          name: record?.name,
          emailPrimary: record?.emailPrimary,
          totalFmt: record?.totalFmt,
          status: response?.data,
        },
      });
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao carregar os dados do BPayment"),
        duration: 3,
      });
    } finally {
      setIsLoadingDrawer(false);
    }
  };

  const handlePagination = (page) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!page || page === 1) {
      delete objQueries.page;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, page });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const columns = [
    {
      title: I18n.get("SKU"),
      dataIndex: "sku",
      width: 170,
      // sorter: true,
      render: (type, record) => <S.RowTab>{renderText(record?.sku)}</S.RowTab>,
    },
    {
      title: I18n.get("Nome do Fornecedor"),
      dataIndex: "name",
      width: 325,
      ellipsis: true,
      render: (type, record) => (
        <S.RowTab>
          <S.RowElipsis>{renderText(record?.name)}</S.RowElipsis>
        </S.RowTab>
      ),
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "totalFmt",
      render: (type, record) => (
        <S.RowTab>{renderText(record?.totalFmt)}</S.RowTab>
      ),
    },
    {
      title: I18n.get("Status"),
      dataIndex: "statusDescription",
      width: 270,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${I18n.get("Solicitação está em")} ${
            record?.statusDescription
          }`}
          overlayClassName="tooltip-status-bpay"
        >
          {record?.status != 3 && (
            <S.Status style={{ ...colorBgStatus(record?.status) }}>
              <span style={{ ...colorStatus(record?.status) }}>
                {renderText(record?.statusDescription)}
              </span>
            </S.Status>
          )}
          {record?.status === 3 && (
            <S.Status style={{ background: "#C6F1DA" }}>
              <span style={{ color: "#36694E" }}>
                {I18n.get("Pagamento confirmado")}
              </span>
            </S.Status>
          )}
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Ações"),
      dataIndex: "id",
      width: 150,
      render: (type, record) => (
        <S.RowTab>
          <S.BTdrawer onClick={() => handleOpenDrawer({ record })}>
            {I18n.get("Ver detalhes")}
          </S.BTdrawer>
        </S.RowTab>
      ),
    },
  ];

  return (
    <>
      <S.Table
        columns={columns}
        dataSource={data?.data?.data}
        pagination={{
          pageSize: 10,
          total: data?.data?.metadata[0]?.TotalItems,
          defaultCurrent: parseQueryString(location?.search)?.page
            ? Number(parseQueryString(location?.search)?.page)
            : 1,
          onChange: (page) => {
            handlePagination(page);
          },
        }}
        loading={isLoading}
        locale={{
          emptyText: I18n.get("Nenhum BPayment cadastrado até o momento"),
        }}
      />

      <Drawer
        open={drawer.visible}
        width={500}
        handleClose={handleCloseDrawer}
        buttons={
          <>
            <ButtonBpool
              text={I18n.get("Fechar")}
              onClick={handleCloseDrawer}
              theme="tertiary"
            />
          </>
        }
      >
        <InfosDrawer data={drawer?.data} isLoading={isLoadingDrawer} />
      </Drawer>
    </>
  );
};
