import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { useSelector } from "react-redux";
import { useProjects } from "../../../../hooks/useProjects/useProjects.hook";

import { PROJECTS_API } from "../../../../store/actions/projects";

import Content from "../../../../components/UI/Content";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { H2Border } from "../../../../components/UI/Titles";
import { SelectOne } from "../../../../components/UI/SelectOne";
import { Input } from "../../../../components/UI/Input";
import Loading from "../../../../components/UI/Loading";
import StepsCreateProject from "../components/StepsCreateProject";
import { ReviewProject } from "../components/ReviewProject";
import Main from "../components/Main";
import TopInformation from "../components/TopInformation";

import * as S from "./styles";

import {
  mockDates,
  mockDatesEN,
  monthsDates,
  monthsDatesEN,
  weeksDatesEN,
} from "./mocks";

export const CreateProjectStep5 = () => {
  const { updateProjectStep5 } = useProjects();
  const authUser = useSelector((state) => state.auth);
  const [projectDetails, setProjectDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [inputsForm, setInputsForm] = useState({
    estimatedDecisionInWeeks: "",
    estimatedDurationInMonths: "",
    estimatedDurationInWeeks: "",
    nonCompeteInDays: "",
  });
  const [startDate, setStartDate] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  let params = useParams();
  // const { handleSubmit, control, reset } = useForm();

  const removeNullString = (val) => {
    if (val === null || val === undefined) {
      return "";
    } else {
      return val;
    }
  };

  const getStep = async () => {
    setIsLoading(true);
    const data = {
      projectId: params.projectId,
    };
    await PROJECTS_API.get_project_step5(data).then((response) => {
      setProjectDetails(response?.payload?.project?.data);

      setInputsForm({
        ...inputsForm,
        estimatedDecisionInWeeks: removeNullString(
          response?.payload?.project?.data?.estimatedDecisionInWeeks
        ),
        estimatedDurationInMonths: removeNullString(
          response?.payload?.project?.data?.estimatedDurationInMonths
        ),
        estimatedDurationInWeeks: removeNullString(
          response?.payload?.project?.data?.estimatedDurationInWeeks
        ),
        nonCompeteInDays: removeNullString(
          response?.payload?.project?.data?.nonCompeteInDays
        ),
      });
      setStartDate(
        removeNullString(response?.payload?.project?.data?.estimatedStartDate)
      );
    });
    setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStep();
    // calcTotal();
  }, []);

  const sendApi = async () => {
    setIsSending(true);
    const dataForm = {
      id: params.projectId,
      estimatedDecisionInWeeks: !projectDetails?.selectPartner
        ? inputsForm.estimatedDecisionInWeeks
        : null,
      estimatedStartDate: startDate,
      estimatedDurationInMonths: inputsForm.estimatedDurationInMonths,
      estimatedDurationInWeeks: inputsForm.estimatedDurationInWeeks,
      nonCompeteInDays: inputsForm.nonCompeteInDays,
      selectPartner: projectDetails.selectPartner,
    };

    const response = await updateProjectStep5(dataForm);
    if (response?.success) {
      history.push(`/cliente/projetos/v2/review/${params.projectId}`);
    } else {
      setErrors(response?.errors);
    }
    setIsSending(false);
  };

  const handleRemoveError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleChangeInput = ({ key, value }) => {
    setInputsForm({
      ...inputsForm,
      [key]: value || value === 0 ? value : null,
    });
  };

  const handleChangeInputNonCompete = ({ key, value }) => {
    setInputsForm({
      ...inputsForm,
      [key]: value,
    });
  };

  const handleChangeInputStartDate = (value) => {
    const dateFormatted = value.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");

    if (value.length === 8) {
      setStartDate(dateFormatted ? dateFormatted : null);
    } else {
      setStartDate(value ? value : null);
    }
  };

  const weeksDatesPT = [
    { code: 1, label: I18n.get("Até 1 semana") },
    { code: 2, label: I18n.get("2 semanas") },
    { code: 3, label: I18n.get("3 semanas") },
    { code: 4, label: I18n.get("4 semanas") },
    { code: 5, label: I18n.get("5 semanas") },
    { code: 6, label: I18n.get("6 semanas") },
  ];

  const mockNonCompete = [
    {
      code: -1,
      label: I18n.get("Nenhum"),
    },
    {
      code: 0,
      label: I18n.get("During the project"),
    },
    {
      code: 30,
      label: I18n.get("During the project + 30 days"),
    },
    {
      code: 60,
      label: I18n.get("During the project + 60 days"),
    },
    {
      code: 90,
      label: I18n.get("During the project + 90 days"),
    },
  ];

  return (
    <LoggedPage>
      <Content>
        <div className="container">
          <Row>
            <Col sm={12}>
              <H2Border>{I18n.get("Novo Projeto")}</H2Border>
            </Col>
            <Col sm={12} justify="end"></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <S.StyleMain>
                <StepsCreateProject
                  active={5}
                  projectId={params.projectId || ""}
                />
              </S.StyleMain>

              <div>
                <Row>
                  <Col xs={24} sm={17}>
                    <Main bgColor="#fff" padding="30px">
                      <Row>
                        <Col xs={24} sm={24}>
                          <TopInformation>
                            <>
                              <h3>{I18n.get("Estimativas de tempo")}</h3>

                              <p>
                                <strong>
                                  {I18n.get("Prazo de pagamento do cliente")}
                                </strong>
                              </p>
                              <p>
                                {I18n.get(
                                  "C90 dias após a cobrança do Projeto."
                                )}
                              </p>
                            </>
                          </TopInformation>
                          <S.StyleForm>
                            {!projectDetails?.selectPartner ? (
                              <S.ContainerSelects>
                                <S.ContentSelect>
                                  <S.SelectsTitle>
                                    {I18n.get("Datas")}
                                  </S.SelectsTitle>
                                  <p>
                                    <strong>
                                      {I18n.get(
                                        "Prazo para a seleção do parceiro"
                                      )}
                                    </strong>
                                  </p>
                                  <p>
                                    {I18n.get(
                                      "Lembre-se que te apresentaremos 3 parceiros e entendemos que muitas vezes você vai precisar envolver mais gente no processo de decisão. Indique aqui quanto tempo estima que este processo vai demorar."
                                    )}
                                  </p>
                                  <div style={{ marginTop: 30 }}>
                                    <SelectOne
                                      options={weeksDatesPT}
                                      value={
                                        inputsForm?.estimatedDecisionInWeeks
                                      }
                                      onChange={(e) =>
                                        handleChangeInput({
                                          key: "estimatedDecisionInWeeks",
                                          value: e,
                                        })
                                      }
                                      placeholder={I18n.get(
                                        "Selecione a(s) semana(s)"
                                      )}
                                      error={
                                        errors?.estimatedDecisionInWeeks
                                          ?.errorMessage
                                      }
                                      clearError={() =>
                                        handleRemoveError(
                                          "estimatedDecisionInWeeks"
                                        )
                                      }
                                      required
                                    />
                                  </div>
                                </S.ContentSelect>
                              </S.ContainerSelects>
                            ) : null}

                            <S.ContainerSelects>
                              <S.ContentSelect>
                                <p>
                                  <strong>
                                    {I18n.get("Previsão de início do projeto")}
                                  </strong>
                                </p>
                                <p>
                                  {I18n.get(
                                    "O projeto só poderá começar depois da emissão da SPA (número de pedido)"
                                  )}
                                </p>
                                <div style={{ marginTop: 30 }}>
                                  {isLoading ? (
                                    <Loading
                                      sizeIcon={18}
                                      text={I18n.get("Carregando...")}
                                      sizeText={14}
                                      align="left"
                                    />
                                  ) : (
                                    <Input
                                      mask={"00/00/0000"}
                                      placeholder={I18n.get(
                                        "Data estimada de início"
                                      )}
                                      error={
                                        errors?.estimatedStartDate?.errorMessage
                                      }
                                      clearError={() =>
                                        handleRemoveError("estimatedStartDate")
                                      }
                                      value={startDate}
                                      onChange={(value) =>
                                        handleChangeInputStartDate(value)
                                      }
                                      required
                                    />
                                  )}
                                </div>
                              </S.ContentSelect>
                            </S.ContainerSelects>

                            <S.ContainerSelects>
                              <p>
                                <strong>
                                  {I18n.get("Previsão para término do projeto")}
                                </strong>
                              </p>

                              <S.ContainerDuble>
                                <S.ItemDuble>
                                  <S.ContentSelect style={{ marginTop: 25 }}>
                                    <p>
                                      <strong>{I18n.get("Meses")}</strong>
                                    </p>

                                    <div style={{ marginTop: 30 }}>
                                      <SelectOne
                                        options={
                                          authUser?.locale === "en"
                                            ? monthsDatesEN
                                            : monthsDates
                                        }
                                        value={
                                          inputsForm?.estimatedDurationInMonths
                                        }
                                        onChange={(e) =>
                                          handleChangeInput({
                                            key: "estimatedDurationInMonths",
                                            value: e,
                                          })
                                        }
                                        placeholder={I18n.get(
                                          "Selecione o(s) mes(es)"
                                        )}
                                        error={
                                          errors?.estimatedDurationInMonths
                                            ?.errorMessage
                                        }
                                        clearError={() =>
                                          handleRemoveError(
                                            "estimatedDurationInMonths"
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </S.ContentSelect>
                                </S.ItemDuble>
                                <S.ItemDuble>
                                  <S.ContentSelect style={{ marginTop: 25 }}>
                                    <p>
                                      <strong>{I18n.get("Semana(s)")}</strong>
                                    </p>

                                    <div style={{ marginTop: 30 }}>
                                      <SelectOne
                                        options={
                                          authUser?.locale === "en"
                                            ? mockDatesEN
                                            : mockDates
                                        }
                                        value={
                                          inputsForm?.estimatedDurationInWeeks
                                        }
                                        onChange={(e) =>
                                          handleChangeInput({
                                            key: "estimatedDurationInWeeks",
                                            value: e,
                                          })
                                        }
                                        placeholder={I18n.get(
                                          "Selecione a(s) semana(s)"
                                        )}
                                        error={
                                          errors?.estimatedDurationInWeeks
                                            ?.errorMessage
                                        }
                                        clearError={() =>
                                          handleRemoveError(
                                            "estimatedDurationInWeeks"
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </S.ContentSelect>
                                </S.ItemDuble>
                              </S.ContainerDuble>
                            </S.ContainerSelects>

                            <S.ContainerSelects>
                              <S.ContentSelect>
                                <S.SelectsTitle>
                                  {I18n.get("Non-Compete")}
                                </S.SelectsTitle>
                                <p>
                                  {I18n.get(
                                    "Precisa incluir cláusula de non-compete para este projeto? Por qual periodo (a partir do início do projeto)?"
                                  )}
                                </p>
                                <div style={{ marginTop: 30 }}>
                                  <SelectOne
                                    options={mockNonCompete}
                                    value={inputsForm?.nonCompeteInDays}
                                    onChange={(e) =>
                                      handleChangeInputNonCompete({
                                        key: "nonCompeteInDays",
                                        value: e,
                                      })
                                    }
                                    placeholder={I18n.get(
                                      "Selecione o Non-Compete em dias"
                                    )}
                                    error={
                                      errors?.nonCompeteInDays?.errorMessage
                                    }
                                    clearError={() =>
                                      handleRemoveError("nonCompeteInDays")
                                    }
                                    required
                                  />
                                </div>
                              </S.ContentSelect>
                            </S.ContainerSelects>
                          </S.StyleForm>

                          <S.DisplayErrors>
                            <Row>
                              <Col lg={24}>
                                {Object.keys(errors).length > 0 && (
                                  <>
                                    <h3>
                                      {I18n.get(
                                        "Ops... Alguns campos precisam ser preenchidos."
                                      )}
                                    </h3>
                                    <br />
                                  </>
                                )}

                                {Object.keys(errors).map((item, index) => {
                                  if (errors[item].errorMessage) {
                                    return (
                                      <p>
                                        {" "}
                                        <span class="icon has-text-danger">
                                          <i class="fas fa-exclamation"></i>
                                        </span>
                                        {errors[item].errorMessage}
                                      </p>
                                    );
                                  }
                                })}

                                {Object.keys(errors).length > 0 && (
                                  <div>
                                    <p>
                                      <Link
                                        to={`/cliente/projetos/v2/review/${params.projectId}`}
                                      >
                                        <ButtonBpool
                                          text={I18n.get(
                                            "Continue e informe depois"
                                          )}
                                          className=""
                                          theme="primary"
                                        />
                                      </Link>
                                    </p>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </S.DisplayErrors>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg={24}>
                          <S.FooterButtons className="buttonsContainer">
                            <ButtonBpool
                              text={I18n.get("Voltar")}
                              theme="secondary"
                              onClick={() =>
                                history.push(
                                  `/cliente/projetos/v2/parceiros/${params.projectId}`
                                )
                              }
                            />
                            <ButtonBpool
                              text={I18n.get("Próximo")}
                              className=""
                              theme="primary"
                              onClick={sendApi}
                              loading={isSending}
                            />
                          </S.FooterButtons>
                        </Col>
                      </Row>
                    </Main>
                  </Col>
                  <Col xs={24} sm={7}>
                    <Main
                      padding="30px"
                      style={{ background: "#fafafa", padding: "0" }}
                    >
                      <ReviewProject project={projectDetails} />
                    </Main>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </LoggedPage>
  );
};
