import React, { PureComponent, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";

import NumberFormat from "react-number-format";

import {
  formatMoney2,
  formatMoneyInput,
  formatMoneyGraph,
} from "../../../utils/helpers";
import { Table, Popconfirm, Tooltip, Icon } from "antd";

const Btns = styled.p`
  width: 50px;
  displa: block;
  a {
    margin-left: 10px;
  }
`;

const FooterT = styled.div`
  font-weight: bold;
  text-align: right;
`;

class TableClient extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSector = (sectorId) => {
    const items =
      this.props.sectorsFlat &&
      this.props.sectorsFlat.filter((item) => item.code == sectorId);

    if (items && items.length) {
      return items[0].label;
    } else {
      return {};
    }
  };

  getStatus(value) {
    if (!this.props.bp || !this.props.bp.partnerStatusesMap) return "";

    return this.props.bp.partnerStatusesMap[value].label;
  }

  getRegion(region) {
    if (region === "en") return "USA";
    else if (region === "es") return "LATAM";
    else return "BRASIL";
  }

  render() {
    const columns = [
      {
        title: I18n.get("Região"),
        dataIndex: "region",
        width: 110,
        key: "region",
      },
      {
        title: I18n.get("País"),
        dataIndex: "country",
        key: "country",
        width: 70,
        render: (country) => {
          if (country) {
            return (
              <img
                src={`https://assets-ollo.bpool.co/country-flags/png24px/${country}.png`}
              />
            );
          } else {
            return "";
          }
        },
      },

      {
        title: I18n.get("Asset Market"),
        dataIndex: "tMarket",
        key: "tMarket",
        render: (tmarket) => {
          return tmarket && tmarket.join();
        },
      },
      {
        title: I18n.get("Nome"),
        dataIndex: "name",
        width: 400,
        key: "name",
        render: (name, record) => {
          return (
            <Link to={`/bpool/partner/v2/${record.item.id}`}>
              {name || `S/N (${record.item.name} - ${record.item.email})`}{" "}
              {record.liveMkt && (
                <span class="tag is-info is-rounded tagLive">
                  {I18n.get("Live Mkt")}
                </span>
              )}
            </Link>
          );
        },
      },
      {
        title: I18n.get("Cadastro"),
        dataIndex: "date",
        width: 120,
        key: "date",
      },
      {
        title: I18n.get("Status"),
        dataIndex: "status",
        key: "status",
      },
      {
        title: I18n.get("CV. Total"),
        dataIndex: "cvtotal",
        width: 120,
        key: "cvtotal",
      },
      // {
      //     title: I18n.get("CV. Aguard."),
      //     dataIndex: 'cvwaiting',
      //     key: 'cvwaiting',
      // },
      // {
      //     title: I18n.get("CV. Aceito"),
      //     dataIndex: 'cvaccepted',
      //     key: 'cvaccepted',
      // },
      // {
      //     title: I18n.get("CV. Negado"),
      //     dataIndex: 'cvdeclined',
      //     key: 'cvdeclined',
      // },
      // {
      //     title: I18n.get("CV. Exp."),
      //     dataIndex: 'cvexpired',
      //     key: 'cvexpired',
      // }
    ];

    let dataTable =
      this.props.data &&
      this.props.data.map((item, index) => {
        let specs = item.specialisms || [];
        let hasLiveMkt = specs.includes("LIVE_MKT");

        // console.log(item.signupLocale)

        return {
          key: index,
          region: this.getRegion(item.signupLocale),
          country:
            item.company &&
            item.company.country &&
            item.company.country.isO2 &&
            item.company.country.isO2.toLowerCase(),
          date: item.createdOnFmt,
          tMarket: item.company?.tMarket,
          responsible: item.name,
          state: item.company && item.company.state,
          registrationName: item.company && item.company.registrationName,
          city: item.company && item.company.city,
          name: item.company && item.company.name,
          status: this.getStatus(item.status),
          cvtotal:
            item.statistics &&
            item.statistics.projectInvite &&
            item.statistics.projectInvite.total,
          cvwaiting:
            item.statistics &&
            item.statistics.projectInvite &&
            item.statistics.projectInvite.waiting,
          cvaccepted:
            item.statistics &&
            item.statistics.projectInvite &&
            item.statistics.projectInvite.accepted,
          cvdeclined:
            item.statistics &&
            item.statistics.projectInvite &&
            item.statistics.projectInvite.declined,
          cvexpired:
            item.statistics &&
            item.statistics.projectInvite &&
            item.statistics.projectInvite.expired,
          liveMkt: hasLiveMkt,
          item: item,
        };
      });

    return (
      <Table
        pagination={this.props.pagination ? true : false}
        columns={columns}
        dataSource={dataTable}
        locale={{ emptyText: I18n.get("Sem informação") }}
        scroll={{ x: 1200 }}
      />
    );
  }
}

export default TableClient;
