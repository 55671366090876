import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { Upload, Icon, message, notification } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// import { Auth } from 'aws-amplify';

import Config from '../../config';


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);


}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Somente arquivos JPG e PNG são suportados.');
    }
    // const isLt2M = file.size / 1024 / 1024 < 4;
    // if (!isLt2M) {
    //     message.error('Tamanho máximo suportado é de 4MB');
    // }
    // return isJpgOrPng && isLt2M;
    return isJpgOrPng;
}

const mapStateToProps = state => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {};
}

const UploadBtn = styled(Upload)`
    .ant-upload.ant-upload-select-picture-card {

        ${({ cover }) => cover && `
            max-width: 450px;
            width: 100%;
        `}
    }
`

const SpanLoading = styled.span`
    position: relative;
    display: block;

    .iconAnimate {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 30px;
            color: #fff;
        }
    }
`

class FileUpload extends Component {
    state = {
        loading: false,
        imageUrl: "",
        imgPreview: ""
    };

    onDrop = ({ onSuccess, onError, file }) => {
        const EndpointGetAuthToUpload = this.props.get2 ? Config.UPLOAD_URL2 : Config.UPLOAD_URL;

        console.log("FILE", file);

        let fileSplit = file.name.split('.');
        let fileSafe = file.name.replace(/[^a-z0-9]/gi, '-');
        let fileNew = `${fileSafe}_${uuidv4()}`;
        if (fileSplit.length > 1) {
            fileNew = fileNew + '.' + fileSplit[fileSplit.length - 1];
        }

        let id = this.props.idContext; //Dependenddo do contexto, será ClientId ou PartnerId ou ProjectId

        let key = `${id}/${fileNew}`; //Caminho final do arquivo - contexto/nome-unico-arquivo.txt

        //Access Token vindo do Cognito
        let authHeader = { headers: { Authorization: 'Bearer ' + this.props.auth.jwtToken } };

        //EndPoint para pegar autorização para subir o arquivo. passar a chave (key) na queryString
        let preSignUrl = `${EndpointGetAuthToUpload}?k=${key}${this.props.get2 ? "&t=pleader" : ""}`;

        //Pega autorização para upload
        axios.get(preSignUrl, authHeader).then((resp) => {

            console.log("RESP", resp);

            let { url, fields } = resp.data;

            let formData = new FormData();

            //Dados recebidos para autorizar o upload do arquivo
            Object.keys(fields).forEach(key => {
                formData.append(key, fields[key])
            });

            //arquivo

            formData.append('file', file);


            var config = {
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }
            };

            //tendo sucesso, fazer POST do arquivo com os dados de autorização
            axios.post(url, formData, config).then((response) => {

                console.log("RESPONSE", response);

                this.setState({
                    statusCode: response.status,
                    imgPreview: resp.data.url + "/" + resp.data.fields.key
                });

                // const dataForm = {
                //     field: this.props.name,
                //     url: response.data.url + "/" + response.data.fields
                // }

                // this.props.getDataUpload(dataForm);

                //Atualiza o item com o arquivo
                // let urlUpdate = '';
                // axios.put(urlUpdate, config).then((response) => {

                //     //Mensagem de OK

                // }).catch(error => { // Erro Atualização

                //     console.error("Erro Atualziar Objeto. Erro: " + error.response.status +'-'+ error.response.data.message)

                // });

                setTimeout(() => {
                    this.setState({
                        loading: false,
                    })
                }, 2000);

                let urlClean = "https://" + resp.data.url.split("s3.sa-east-1.amazonaws.com/")[1] + "/"

                let dataForm = {
                    field: this.props.nameField || this.props.name,
                    url: urlClean + resp.data.fields.key,
                }
                // this.setState({
                //     file: file,
                //     imageUrl
                // })

                // setTimeout(() => {
                //     this.setState({
                //         loading: false,
                //     })
                // }, 2000);

                this.props.getDataUpload(dataForm);

                onSuccess(null, file);


            }).catch(error => {



            });

        }).catch(error => {



        });


    };

    handleChange = info => {


        if (info.file.status === 'uploading') {
            this.setState({ loading: true });

        }

    };

    render() {

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{I18n.get("Upload")}</div>
                {(this.props.cover && this.props.coverDescription) &&
                    <small>{this.props.coverDescription}</small>
                }
            </div>
        );

        const { imgUrl } = this.props;

        return (
            <UploadBtn
                {...this.props}
                className="avatar-uploader"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
                customRequest={this.onDrop}
            >
                {imgUrl || this.state.imgPreview ? <SpanLoading>{this.state.loading ? <span className="iconAnimate"><Icon type="loading" /></span> : ""} <img src={imgUrl || this.state.imgPreview} alt="avatar" style={{ width: '100%', maxHeight: "200px" }} /></SpanLoading> : uploadButton}
            </UploadBtn>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
// export default FileUpload;
