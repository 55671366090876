import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../../utils/task";

import TextField from "../../form/text-field";
import TextAreaField from "../../form/textarea-field";
import MoneyField from "../../form/money-field";
import PercentageField from "../../form/percentage-field";
import SelectField from "../../form/select-field";
import RadioMultiField from "../../form/radio-multi-field";
import CheckboxMultiField from "../../form/checkbox-multi-field";
import SubmitButtonField from "../../form/submit-button-field";

import {
  BPCommissionType,
  BPCommissionTypeNames,
  ProjectType,
  ProjectTypeNames,
  ProjectExtraType,
  ProjectExtraTypeNames,
} from "../../../model";

class CustomBpayForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        commissionType: {
          label: I18n.get("Tipo de Comissão"),
          value: true,
          onChange: this.onChangeForm.bind(this, "commissionType"),
          successIcon: false,
          visited: true,
          isHorizontal: true,
        },
        commissionValue: {
          successIcon: false,
          onChange: this.onChangeForm.bind(this, "commissionValue"),
          visited: true,
        },

        sku: {
          label: I18n.get("Aplicar ao Projeto Número/SKU"),
          successIcon: false,
          onChange: this.onChangeForm.bind(this, "sku"),
          visited: true,
        },
      },
      form: {
        commissionType: BPCommissionType.Perc,

      },
      errors: {},
      task: {},
    };
  }

  onChangeForm(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = value;
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormRadio(prop, e) {
    const value = e.target.value;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      nextState.form[prop] = [value];
      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onChangeFormCheckboxMulti(prop, e) {
    const value = e.target.value;
    const checked = e.target.checked;

    this.setState((prevState) => {
      var nextState = Object.assign({}, prevState);

      if (checked) {
        nextState.form[prop] = [...nextState.form[prop], value];
      } else {
        nextState.form[prop] = nextState.form[prop].filter((v) => v !== value);
      }

      nextState.errors[prop] = null;

      return nextState;
    });
  }

  onSubmitForm(e) {
    e.preventDefault();

    var submit_task = new Task();

    submit_task.start();

    this.setState({ task: submit_task });

    var data = {
      clientId: this.props.match.params.clientId,
      ...this.state.form,
    };


    this.props
      .create_client_settings_bpay_rule(data)
      .then(({ task, errors }) => {
        const form = {
          commissionType: BPCommissionType.Perc,
          applyTo: "T",
          extraTypes: [],
        };

        if (task.result) {
          this.setState({ task: task, errors: errors, form: form });

          this.props.closeModal();
        } else {
          this.setState({ task: task, errors: errors });
        }
      });
  }

  render() {
    const commissionTypeValues = [
      BPCommissionType.Perc,
      BPCommissionType.Flat,
    ].map((commissionType) => ({
      code: commissionType,
      label: I18n.get(BPCommissionTypeNames[commissionType]),
    }));

    const extraTypesValues = [
      ProjectExtraType.InternalCosts,
      ProjectExtraType.ExternalCosts,
      ProjectExtraType.Finance,
      ProjectExtraType.Media,
      ProjectExtraType.Tools,
    ].map((extraType) => ({
      code: `${extraType}`,
      label: I18n.get(ProjectExtraTypeNames[extraType]),
    }));

    return (
      <form
        style={{
          textAlign: "left",
          backgroundColor: "#f5f5f5",
          padding: "2rem",
          margin: "-0.5rem",
        }}
      >
        <p className="title-bpool medium">{I18n.get("Nova Regra de Ollo Pay")}</p>

        <br />
        <br />

        <RadioMultiField
          {...this.state.fields.commissionType}
          value={this.state.form.commissionType}
          error={this.state.errors.commissionType}
          values={commissionTypeValues}
        />

        <div style={{ width: "50%" }}>
          {this.state.form.commissionType == BPCommissionType.Perc && (
            <>
              <PercentageField
                {...this.state.fields.commissionValue}
                label={I18n.get("Percentual")}
                value={this.state.form.commissionValue}
                error={this.state.errors.commissionValue}
              />
            </>
          )}
          {this.state.form.commissionType == BPCommissionType.Flat && (
            <>
              <MoneyField
                {...this.state.fields.commissionValue}
                label={I18n.get("Valor Fixo")}
                value={this.state.form.commissionValue}
                error={this.state.errors.commissionValue}
              />
            </>
          )}

          <br />

          <TextField
            {...this.state.fields.sku}
            value={this.state.form.sku}
            error={this.state.errors.sku}
          />
        </div>

        <br />
        <br />

        <SubmitButtonField
          label={I18n.get("Salvar")}
          onClick={this.onSubmitForm.bind(this)}
          className="button bt-bpool"
          task={this.state.task}
        />
      </form>
    );
  }
}

export default CustomBpayForm;
