import React from "react";
import { I18n } from "aws-amplify";
import { Icon, Checkbox } from "antd";
import { Info } from "./Info";
import { Dialog } from "../../../UI/Dialog";
import { NotificationBPool } from "../../../UI/NotificationBPool";
import * as S from "./styles";

export const Swift = ({
  data,
  isLoading,
  handleOpenEditDrawer,
  handleRefresh,
  isAdmin,
  updateBillingData,
  deleteBillingData,
}) => {
  const [dialog, setDialog] = React.useState({
    visible: false,
    text: "",
    subText: "",
    id: "",
  });
  const [checkbox, setCheckbox] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);

  const onChangeCheckboxToApi = async (checkboxIsMain) => {
    try {
      setIsSending(true);
      const objSend = {
        ...data,
        isMain: checkboxIsMain,
      };
      await updateBillingData({
        admin: isAdmin,
        form: { ...objSend },
      });
      await handleRefresh();
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsSending(false);
    }
  };

  const onChangeCheckbox = async (e) => {
    setCheckbox(e?.target?.checked);
    await onChangeCheckboxToApi(e?.target?.checked);
  };

  const handleDeleteDialog = async ({ id }) => {
    setDialog({
      visible: true,
      text: I18n.get("Deseja deletar essa conta?"),
      subText: I18n.get(
        "Ao confirmar essa ação, nós deletaremos essa informação e você não poderá voltar atrás."
      ),
      id,
    });
  };

  const handleDeleteAcc = async ({ id }) => {
    try {
      setIsDeleting(true);
      const response = await deleteBillingData({ admin: isAdmin, id });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Conta deletada com sucesso."),
        duration: 3,
      });
      await handleRefresh();
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao deletar a conta."),
        duration: 3,
      });
    } finally {
      setIsDeleting(false);
      setDialog({
        visible: false,
        text: "",
        subText: "",
        id: "",
      });
    }
  };

  React.useEffect(() => {
    setCheckbox(data?.isMain);
  }, [data]);

  return (
    <>
      <S.Wrapper>
        <S.Row gridColumns="1fr 200px">
          <S.Col>
            <h3>{I18n.get("SWIFT")}</h3>
          </S.Col>
          <S.Col actions>
            <S.ButtonAction onClick={() => handleOpenEditDrawer(data)}>
              <Icon type="edit" />
              <span>{I18n.get("Editar")}</span>
            </S.ButtonAction>
            {data?.isMain === false ? (
              <div style={{ marginLeft: 32 }}>
                <S.ButtonAction
                  onClick={() => handleDeleteDialog({ id: data?.id })}
                >
                  <Icon type="delete" />
                  <span>{I18n.get("Deletar")}</span>
                </S.ButtonAction>
              </div>
            ) : null}
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr">
          <S.Col>
            <h3 style={{ fontSize: 16 }}>{I18n.get("Beneficiário")}</h3>
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Nome do Beneficiário/Razão Social")}
              info={data?.beneficiaryName}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("Documento do Beneficiário/CNPJ")}
              info={data?.beneficiaryId}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Dirección de Beneficiário")}
              info={data?.beneficiaryDirectionBeneficiary}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("Número de cuenta")}
              info={data?.beneficiaryAccountNumber}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Clave interbancaria")}
              info={data?.beneficiaryInterbankKey}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("SWIFT/ABA/BIC/IBAN")}
              info={data?.beneficiarySWIFTABABICIBAN}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Nombre de Banco")}
              info={data?.beneficiaryBank}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("Dirección del Banco")}
              info={data?.beneficiaryBankDirection}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr">
          <S.Col style={{ borderTop: "1px solid #e8e8e8" }}>
            <h3 style={{ marginTop: 16, fontSize: 16 }}>
              {I18n.get("Intermediário")}
            </h3>
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Dirección de Intermediário")}
              info={data?.intermediateDirectionBeneficiary}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("Número de cuenta")}
              info={data?.intermediateAccountNumber}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Clave interbancaria")}
              info={data?.intermediateInterbankKey}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("SWIFT/ABA/BIC/IBAN")}
              info={data?.intermediateSWIFTABABICIBAN}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr 1fr">
          <S.Col>
            <Info
              label={I18n.get("Nombre de Banco")}
              info={data?.intermediateBank}
              loading={isLoading}
            />
          </S.Col>
          <S.Col>
            <Info
              label={I18n.get("Dirección del Banco")}
              info={data?.intermediateBankDirection}
              loading={isLoading}
            />
          </S.Col>
        </S.Row>
        <S.Row gridColumns="1fr">
          <S.Col>
            <S.CheckboxItem>
              {isSending ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon type="loading" />
                  <p style={{ marginLeft: 16 }}>{I18n.get("Carregando...")}</p>
                </div>
              ) : (
                <Checkbox
                  checked={checkbox}
                  onChange={
                    data?.isMain ? () => true : (e) => onChangeCheckbox(e)
                  }
                >
                  <p>{I18n.get("Essa é minha conta principal")}</p>
                </Checkbox>
              )}
            </S.CheckboxItem>
          </S.Col>
        </S.Row>
      </S.Wrapper>

      <Dialog
        visible={dialog.visible}
        text={dialog.text}
        subText={dialog.subText}
        loading={isDeleting}
        theme="white"
        buttons={{
          ok: {
            text: I18n.get("Confirmar"),
            func: () => handleDeleteAcc({ id: dialog?.id }),
          },
          cancel: {
            text: I18n.get("Não, voltar."),
            func: () =>
              setDialog({
                visible: false,
                text: "",
                subText: "",
                id: "",
              }),
          },
        }}
      />
    </>
  );
};
