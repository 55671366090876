import React, { Component, Fragment } from "react";
import { I18n } from "aws-amplify";
import styled from "styled-components";
import { Task } from "../../../utils/task";
import { useRegistrationFlowLeader } from "../../../v2/hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook";

import { Row, Col, Button, Tooltip, Icon, Input, Select, Form } from "antd";

const { Option } = Select;

const { getTimezones } = useRegistrationFlowLeader();

const RowSubmitModal = styled(Row)`
  border-top: solid 1px #ccc;
  margin-top: 30px;
  padding-top: 20px;
  button {
    margin-right: 20px;
  }
`;

class FormUserClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formState: [],
      countrySeleted: "",
      task: {},
      code: props.item?.country?.code,
      prefix: props.item?.country?.prefix,
      timezones: [],
    };
  }

  handleSubmit = (e) => {
    // if (e) {
    // }
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          formState: values,
        });

        let data = {
          ...values,
          country: this.state.countrySeleted || this.props.item.country,
          clientId: this.props.idContext,
          token: this.props.token,
          isSubmit: true,
        };

        var task = new Task();

        task.start();

        this.setState(
          {
            task: task,
          },
          function () {
            if(this.props.isUser && this.props.edit ) {
              this.props.update(data).then(response => {
                if(response){
                  this.setState({task: {}})
                }
              });
            } else {
              this.props.update(data);
            }
          }
        );

        // if (this.props.isUser) {
        //     data.leaderId = this.props.idContext;
        //     data.isSubmit = true;
        // } else {
        //     data.partnerId = this.props.idContext;
        // }

        // if (this.props.item) {
        //     data.id = this.props.item.id;
        //     this.props.update(data);

        // } else {
        //     this.props.action(data);
        // }
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (this.props.errors != prevProps.errors && this.props.errors) {
      this.setState({
        task: {},
      });
      Object.keys(this.props.errors).map((field) => {
        this.props.form.setFields({
          [field]: {
            value: this.state.formState[field],
            errors: [new Error(this.props.errors[field].errorMessage)],
          },
        });
      });
    }
  }

  handleCountry = (e) => {
    let obj = this.props.countryCodes.find((i) => i.code == e);

    let country = {
      ...obj
    };


    this.setState({
      prefix: obj.prefix,
      fieldBlur: "country",
      countrySeleted: country,
      code: country.code,
    });

    // this.props.form.resetFields('companyCity');

    // this.props.form.setFieldsValue({
    //     companyCity: '',
    //     companyState: '',
    // });

    // setTimeout(() => {
    //     this.handleSubmit('country');
    // }, 10);
  };

  getPrefix = (code) => {
    let prefixCode =
      this.props.countryCodes &&
      this.props.countryCodes.find((i) => i.code == code);

    if (prefixCode) {
      return prefixCode.prefix || "";
    } else {
      return "";
    }
  };

  componentDidMount() {
    getTimezones().then((res) => {
      this.setState({
        timezones: res.data,
      });
    });
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="RowSection" style={{ marginBottom: 0 }}>
          <Col sm={this.props.isUser ? 12 : 12}>
            <Form.Item label={I18n.get("Nome")}>
              {getFieldDecorator("name", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.name
                    ? this.props.item.name
                    : null,
              })(<Input />)}
            </Form.Item>
          </Col>

          {this.props.isUser && (
            <Col sm={12}>
              <Form.Item label={I18n.get("Como quer ser chamado?")}>
                {getFieldDecorator("nameAlias", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    this.props.item && this.props.item.nameAlias
                      ? this.props.item.nameAlias
                      : null,
                })(<Input />)}
              </Form.Item>
            </Col>
          )}
          <Col sm={this.props.isUser ? 12 : 12}>
            <Form.Item label={I18n.get("E-mail")}>
              {getFieldDecorator("email", {
                rules: [{ required: false, message: "Campo obrigatório!" }],
                initialValue:
                  this.props.item && this.props.item.email
                    ? this.props.item.email
                    : null,
              })(<Input disabled={this.props.isUser} />)}
            </Form.Item>
          </Col>

          {this.props.isUser && (
            <Fragment>
              <Col sm={12}>
                <Form.Item label={I18n.get("País")}>
                  {getFieldDecorator("country", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item &&
                      this.props.item.country &&
                      this.props.item.country.code
                        ? this.props.item.country.code
                        : "",
                  })(
                    <Select
                      style={{ width: "100%" }}
                      onSelect={(e) => this.handleCountry(e)}
                      showSearch={true}
                      // filterOption={(input, option) =>
                      //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {this.props.countryCodes &&
                        this.props.countryCodes.map((item) => {
                          let codeString = item.isO2.toLowerCase();

                          return (
                            <Option
                              value={item.code}
                              key={item.code}
                              prefix={item.prefix}
                            >
                              <img
                                src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                              />{" "}
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Col sm={12}>
                {this.state.code == "BRA" && (
                  <Form.Item label={I18n.get("Celular")}>
                    {getFieldDecorator("phoneNumber", {
                      rules: [
                        { required: false, message: "Campo obrigatório!" },
                      ],
                      initialValue:
                        this.props.item && this.props.item.phoneNumber
                          ? this.props.item.phoneNumber
                          : null,
                    })(
                      // <MaskedInput addonBefore={this.state.prefix} mask="(11) 11111-1111" size="13" />
                      <Input addonBefore={this.state.prefix} />
                    )}
                  </Form.Item>
                )}

                {this.state.code != "BRA" && (
                  <Form.Item label={I18n.get("Celular")}>
                    {getFieldDecorator("phoneNumber", {
                      rules: [
                        { required: false, message: "Campo obrigatório!" },
                      ],
                      initialValue:
                        this.props.item && this.props.item.phoneNumber
                          ? this.props.item.phoneNumber
                          : null,
                    })(<Input addonBefore={this.state.prefix} />)}
                  </Form.Item>
                )}
              </Col>
              <Col sm={12}>
                <Form.Item label={I18n.get("Linkedin")}>
                  {getFieldDecorator("linkedin", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item && this.props.item.linkedin
                        ? this.props.item.linkedin
                        : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col sm={24}>
                <Form.Item label={I18n.get("Fuso horário")}>
                  {getFieldDecorator("timeZone", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue:
                      this.props.item && this.props.item.timeZone
                        ? this.props.item.timeZone
                        : null,
                  })(
                    <Select
                      style={{ width: "100%" }}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.props.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.timezones &&
                        this.state.timezones.length > 0 &&
                        this.state.timezones.map((item) => {
                          return (
                            <Option
                              value={item.value}
                              label={item?.text}
                              key={item.value}
                            >
                              {item?.text} - {item.value}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Fragment>
          )}
        </Row>

        {this.props.isUser && !this.props.edit && (
          <Row className="">
            <Fragment>
              <Col sm={12}>
                <Form.Item
                  label={
                    <span>
                      {I18n.get("Senha")}
                      <Tooltip
                        title={I18n.get(
                          "Use oito ou mais caracteres com uma combinação de letras (maiúsculas e minúsculas), números e símbolos (!@#$%&=?*.:,<>-_+)"
                        )}
                      >
                        <Icon
                          className="iconLabel"
                          type="question-circle"
                          theme="filled"
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("password", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                  })(<Input.Password />)}
                </Form.Item>
              </Col>
              <Col sm={12}>
                <Form.Item label={I18n.get("Confirme a senha")}>
                  {getFieldDecorator("password2", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                  })(<Input.Password />)}
                </Form.Item>
              </Col>
            </Fragment>
          </Row>
        )}

        <RowSubmitModal>
          <Col sm={24}>
            {!this.props.isUser && (
              <Fragment>
                <Button shape="round" onClick={() => this.props.cancel()}>
                  {I18n.get("Cancelar")}
                </Button>
                <Button type="primary" shape="round" htmlType="submit">
                  {this.props.item
                    ? I18n.get("Atualizar")
                    : I18n.get("Adicionar")}
                </Button>
              </Fragment>
            )}

            {this.props.isUser && (
              <Button
                loading={this.state.task.busy}
                type="primary"
                shape="round"
                style={{ float: "right" }}
                htmlType="submit"
              >
                {I18n.get("Salvar")}
              </Button>
            )}
          </Col>
        </RowSubmitModal>
      </Form>
    );
  }
}

const FormUserForm = Form.create({})(FormUserClient);

export default FormUserForm;
