import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  Row,
  Col,
  Form,
  Input,
  Radio,
  Button,
  Result,
  Select,
  InputNumber,
} from "antd";
import NumberFormat from 'react-number-format';

import { MaskedInput } from "antd-mask-input";

import styled from "styled-components";

import { Task } from "../../utils/task";

import FileUploadList from "../../modules/components/FileUploadList";

import COUNTRY_CODES from "./countryCodes.json";

import constants from "../../constants";

const FormBox = styled(Form)``;

const { Option } = Select;

const MessageBox = styled.div`
  padding: 2rem;
`;

const SuccessPane = ({ onReset }) => {
  const BoxSuccess = styled.div`
    padding: 2rem;
  `;

  return (
    <BoxSuccess>
      <Result
        status="success"
        title={I18n.get("Faturamento enviado com sucesso!")}
        subTitle={I18n.get("Nosso time financeiro irá entrar em contato.")}
      />
    </BoxSuccess>
  );
};

const SuccessPaneBankAccountUpdated = ({ onReset }) => {
  const BoxSuccess = styled.div`
    padding: 2rem;
  `;

  return (
    <BoxSuccess>
      <Result
        status="success"
        title={I18n.get("Conta Bancária atualizada com sucesso!")}
        subTitle={I18n.get("Nosso time financeiro irá entrar em contato.")}
      />
    </BoxSuccess>
  );
};

class FormSupplierBilling extends Component {

  constructor(props) {
    super();

    this.state = {
      file: {},
      task: {},
      valor: null,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        formState: values,
      });

      const countryObg = COUNTRY_CODES.find(
        (item) => item.code === values.country
      );

      var updateTask = new Task();

      this.setState({ task: updateTask.start() });

      const data = {
        publicKey: this.props.match.params.id,
        token: this.props.token,
        ...values,
        file: this.state.file.id ? this.state.file : null,
        country: countryObg,
        supplierLocale: this.props.supplierLocale,
      };

      this.props.action(data).then(({ task, errors }) => {
        this.setState({ task: task, errors: errors });

        if (errors) {
          Object.keys(errors).map((field) => {
            this.props.form.setFields({
              [field]: {
                value: values.field,
                errors: [new Error(errors[field].errorMessage)],
              },
            });
          });
        }
      });
    });
  };

  getDataUploadMult = (files) => {
    if (files.length > 0) {
      const { id, name, size, type, url } = files[0];

      this.setState({
        file: {
          id,
          name,
          size,
          type,
          url,
          uid: uuidv4(),
        },
      });
    } else {
      this.setState({
        file: {},
      });
    }
  };

  formatarNumero(string) {
    string = string.replace(/[.,]/g, "");

    const tamanho = string.length;
    if (tamanho <= 2) {
      string = "0," + string.padStart(2, "0");
    } else {
      const parteInteira = string.slice(0, tamanho - 2);
      const parteDecimal = string.slice(tamanho - 2);
      string = parteInteira + "," + parteDecimal;
    }

    return string;
  }

  handleInputChange = (valor) => {
    this.setState({ valor: this.formatarNumero(valor.toString()) });
  };

  updateValor = () => {
    this.props.form.setFieldsValue({
      invoiceValue: this.state.valor,
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    const thousandSeparator = ".";   //currency?.thousandSeparator || ".";
    const decimalSeparator = ",";    // currency?.decimalSeparator || ",";

    // TODO: Considerar outras currencies também.
    const symbolOnly = "";//"R$";        // currency?.symbolOnly

    const {
      item = {},
      supplierBilling,
      mustAbleOnlyBankAccountFields,
    } = this.props;

    let disableFieldsExceptBankAccount = false;
    let initialValueBeneficiaryName;
    let initialValueBeneficiaryId;
    let initialValueCountry;

    let initialValueAddressLine1;
    let initialValueCity;
    let initialValueState;
    let initialValuePostcode;
    let initialValueInvoiceValue = "";
    let initialValueInvoiceNumber = "";

    // Quando a Conta Bancária estava errada, deve-se habilitar apenas os campos de conta bancária para preenchimento,
    // carregando como leitura (readonly) os demais campos.
    if (supplierBilling && mustAbleOnlyBankAccountFields) {
      disableFieldsExceptBankAccount = true;

      initialValueBeneficiaryName =
        supplierBilling?.invoice?.beneficiaryName || "";
      initialValueBeneficiaryId = supplierBilling?.invoice?.beneficiaryId || "";
      initialValueCountry = supplierBilling?.countryCode || "BRA";

      initialValueInvoiceValue = supplierBilling?.invoice?.invoiceValue || "";
      initialValueInvoiceNumber = supplierBilling?.invoice?.number || "";

      initialValueState = supplierBilling?.invoice?.state || "";
      initialValueCity = supplierBilling?.invoice?.city || "";
      initialValueAddressLine1 = supplierBilling?.invoice?.addressLine1 || "";
      initialValuePostcode = supplierBilling?.invoice?.postcode || "";
    } else {
      initialValueBeneficiaryName = item?.beneficiaryName;
      initialValueBeneficiaryId = item?.beneficiaryId || "";
      initialValueCountry = item?.country || "BRA";

      initialValueState = item.state || "";
      initialValueCity = item.city || "";
      initialValueAddressLine1 = item.addressLine1 || "";
      initialValuePostcode = item.postcode || "";
    }

    const { task } = this.state;

    let filesNormalize = [];

    if (this.state.file && Object.keys(this.state.file).length > 0) {
      filesNormalize = [
        {
          ...this.state.file,
        },
      ];
    }

    if (task.result) {
      if (mustAbleOnlyBankAccountFields) {
        // Quando apenas dados de Conta Bancária foram atualizados.
        return (
          <SuccessPaneBankAccountUpdated
            onReset={(e) => this.setState({ task: {} })}
          />
        );
      } else {
        return <SuccessPane onReset={(e) => this.setState({ task: {} })} />;
      }
    }

    return (
      <div>
        <MessageBox>
          {I18n.get("Favor preencher os campos abaixo.")}{" "}
          {I18n.get(
            "Após o envio, nosso time financeiro irá entrar em contato."
          )}{" "}
          <br />
          {I18n.get("Qualquer dúvida, favor enviar um e-mail para")}
          <strong>
            {" "}
            {I18n.get("suporte.financeiro@ollo.is")}
          </strong>
        </MessageBox>

        <FormBox onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Item label={I18n.get("Razão Social")}>
                {getFieldDecorator("beneficiaryName", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueBeneficiaryName,
                })(<Input disabled={disableFieldsExceptBankAccount} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("country", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueCountry,
                })(
                  <Select
                    style={{ width: "100%" }}
                    showSearch={true}
                    disabled={disableFieldsExceptBankAccount}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {COUNTRY_CODES.map((item) => {
                      let codeString = item.isO2.toLowerCase();
                      return (
                        <Option
                          value={item.code}
                          key={item.code}
                          prefix={item.prefix}
                        >
                          <img
                            src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                          />{" "}
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              {getFieldValue("country") !== "BRA" && (
                <Form.Item label={I18n.get("CNPJ")}>
                  {getFieldDecorator("beneficiaryId", {
                    rules: [
                      {
                        required: false,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: initialValueBeneficiaryId,
                  })(<Input disabled={disableFieldsExceptBankAccount} />)}
                </Form.Item>
              )}

              {getFieldValue("country") === "BRA" && (
                <Form.Item label={I18n.get("CNPJ")}>
                  {getFieldDecorator("beneficiaryId", {
                    rules: [
                      {
                        required: false,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: initialValueBeneficiaryId,
                  })(
                    <MaskedInput
                      mask={"00.000.000/0000-00"}
                      disabled={disableFieldsExceptBankAccount}
                    />
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Item label={I18n.get("Endereço")}>
                {getFieldDecorator("addressLine1", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueAddressLine1,
                })(<Input disabled={disableFieldsExceptBankAccount} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("Cidade")}>
                {getFieldDecorator("city", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueCity,
                })(<Input disabled={disableFieldsExceptBankAccount} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Item label={I18n.get("Estado")}>
                {getFieldDecorator("state", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueState,
                })(<Input disabled={disableFieldsExceptBankAccount} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("Cep")}>
                {getFieldDecorator("postcode", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValuePostcode,
                })(<Input disabled={disableFieldsExceptBankAccount} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Item label={I18n.get("Número da nota")}>
                {getFieldDecorator("number", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueInvoiceNumber,
                })(<Input disabled={disableFieldsExceptBankAccount} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("Valor da nota")}>
                {getFieldDecorator("invoiceValue", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: initialValueInvoiceValue,
                })(
                    <NumberFormat
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        prefix={`${symbolOnly} `}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={disableFieldsExceptBankAccount}
                        className="ant-input"
                        onValueChange={() => {}}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Form.Item label={I18n.get("Forma Recebimento")}>
                <p>
                  <strong>
                    {I18n.get(
                      "Atenção: A conta deve ser jurídica e ser da mesma titularidade da razão social"
                    )}
                  </strong>
                </p>{" "}
                <br />
                {getFieldDecorator("paymentMethod", {
                  rules: [
                    {
                      required: false,
                      message: I18n.get("Campo obrigatório."),
                    },
                  ],
                  initialValue: item.paymentMethod || "",
                })(
                  <Radio.Group>
                    <Radio value="TRANSFER">{I18n.get("Transferência")}</Radio>
                    <Radio value="PIX">PIX</Radio>
                    <Radio value="SWIFT_ABA">SWIFT</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>

          {getFieldValue("paymentMethod") == "TRANSFER" && (
            <>
              <Row>
                <Col sm={8}>
                  {getFieldValue("country") != "BRA" && (
                    <Form.Item label={I18n.get("Banco")}>
                      {getFieldDecorator("bankId", {
                        rules: [
                          {
                            required: false,
                            message: I18n.get("Campo obrigatório."),
                          },
                        ],
                        initialValue: item.bankId || "",
                      })(<Input />)}
                    </Form.Item>
                  )}
                  {getFieldValue("country") == "BRA" && (
                    <Form.Item label={I18n.get("Banco")}>
                      {getFieldDecorator("bankId", {
                        rules: [
                          {
                            required: false,
                            message: I18n.get("Campo obrigatório."),
                          },
                        ],
                        initialValue: item.bankId || "",
                      })(
                        <Select style={{ width: "100%" }} showSearch={true}>
                          {constants.BANK_IDS.map((item) => {
                            return (
                              <Option value={item?.label} key={item?.code}>
                                {item?.label}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Row>
                <Col sm={8}>
                  <Form.Item label={I18n.get("Agência")}>
                    {getFieldDecorator("bankBranch", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankBranch || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={8}>
                  <Form.Item label={I18n.get("Conta")}>
                    {getFieldDecorator("bankAccount", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankAccount || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {getFieldValue("paymentMethod") == "PIX" && (
            <>
              <Row>
                <Col sm={8}>
                  <Form.Item label={I18n.get("Chave Pix")}>
                    {getFieldDecorator("pix", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.pix || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {getFieldValue("paymentMethod") == "SWIFT_ABA" && (
            <>
              <Row>
                <Col sm={12}>
                  <Form.Item label={I18n.get("Nome do Banco")}>
                    {getFieldDecorator("bankName", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankName || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Item label={I18n.get("Cidade do Banco")}>
                    {getFieldDecorator("bankCity", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankCity || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item label={I18n.get("País do Banco")}>
                    {getFieldDecorator("bankCountryId", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankCountryId || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={12}>
                  <Form.Item label={I18n.get("Endereço do Banco")}>
                    {getFieldDecorator("bankAddress", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankAddress || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Item label="SWIFT">
                    {getFieldDecorator("bankSwift", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankSwift || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>

                <Col sm={6}>
                  <Form.Item label="ABA/ACH">
                    {getFieldDecorator("bankABA", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankABA || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Item label={I18n.get("Conta")}>
                    {getFieldDecorator("bankAccount", {
                      rules: [
                        {
                          required: false,
                          message: I18n.get("Campo obrigatório."),
                        },
                      ],
                      initialValue: item.bankAccount || "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {/* Devido o componente "FileUploadList" não  ter propriedade para desabilitar (readonly),
foi escondido o botão para este cenário de apenas informar dados de Conta Bancária. */}
          {!disableFieldsExceptBankAccount && (
            <Row>
              <Col sm={24}>
                <Form.Item label={I18n.get("Upload NF")}>
                  {getFieldDecorator("file", {
                    rules: [
                      {
                        required: false,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: item.file || "",
                  })(
                    <FileUploadList
                      getDataUpload={this.getDataUploadMult}
                      name="files"
                      get2={true}
                      fileListGet={filesNormalize || []}
                      disabledList={false}
                      limit={1}
                      output="object"
                      uploadId={this.props.match.params.id}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row>
            <Col sm={24}>
              <Form.Item className="labelClean" label="&nbsp;">
                <Button
                  className="button bt-bpool black"
                  shape="round"
                  htmlType="submit"
                  loading={task.busy}
                >
                  {I18n.get("Enviar")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </FormBox>
      </div>
    );
  }
}

export default Form.create({})(FormSupplierBilling);
