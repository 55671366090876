import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Tooltip } from "antd";
import {
  createQueryString,
  parseQueryString,
} from "../Filters/utils/resolvers";
import IconSteps from "../../../svgs/fi-rr-list-check.svg";
import IconEye from "../../../svgs/fi-rr-eye.svg";
import { DrawerStatusSteps, DrawerStatus1, DrawerStatus4 } from "./Drawers";
import { colorStatus, colorBgStatus } from "../../../utils/colorStatus";
import * as S from "./styles";

export const TableSupplier = ({ data, isLoading, refetch, isPartner }) => {
  let history = useHistory();
  let location = useLocation();
  const [drawerStatusSteps, setDrawerStatusSteps] = useState({
    visible: false,
  });
  const [drawerStatus1, setDrawerStatus1] = useState({ visible: false });
  const [drawerStatus4, setDrawerStatus4] = useState({ visible: false });
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);

  const renderText = (text) => {
    if (text || text === "0" || text === 0) {
      return text;
    } else {
      return "---";
    }
  };

  const handleCloseDrawer = () => {
    setDrawerStatusSteps({ visible: false });
    setDrawerStatus1({ visible: false });
    setDrawerStatus4({ visible: false });
  };

  const handleOpenDrawer = async ({ record }) => {
    setDrawerStatusSteps({
      visible: true,
      data: { ...record },
    });
  };

  const handlePagination = (page) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!page || page === 1) {
      delete objQueries.page;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, page });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const columns = [
    {
      title: I18n.get("Data da solicitação"),
      dataIndex: "createdOnFmt",
      width: 170,
      sorter: true,
      render: (type, record) => (
        <S.RowTab>{renderText(record?.createdOn)}</S.RowTab>
      ),
    },
    {
      title: I18n.get("Nome do Ollo Pay"),
      dataIndex: "bPayName",
      ellipsis: true,
      render: (type, record) => (
        <S.RowTab>
          <S.RowElipsis>{renderText(record?.bPayName)}</S.RowElipsis>
        </S.RowTab>
      ),
    },
    {
      title: I18n.get("Sku"),
      dataIndex: "sku",
      ellipsis: true,
      render: (type, record) => <S.RowTab>{renderText(record?.sku)}</S.RowTab>,
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "totalFmt",
      render: (type, record) => (
        <S.RowTab>{renderText(record?.totalFmt)}</S.RowTab>
      ),
    },
    {
      title: I18n.get("Status"),
      dataIndex: "statusDescription",
      width: 270,
      // render: (type, record) => (
      //   <S.RowTab>{renderText(record?.statusDescription)}</S.RowTab>
      // ),
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${I18n.get("Solicitação está em")} ${
            record?.statusDescription
          }`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.Status style={{ ...colorBgStatus(record?.status) }}>
            <span style={{ ...colorStatus(record?.status) }}>
              {renderText(record?.statusDescription)}
            </span>
          </S.Status>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Ações"),
      dataIndex: "id",
      width: 150,
      render: (type, record) => (
        <S.RowTab>
          <S.ButtonIcon
            style={{ marginLeft: 5 }}
            onClick={() => handleOpenDrawer({ record })}
          >
            <img src={IconSteps} alt="steps" />
          </S.ButtonIcon>
          {record?.status === 1 ? (
            <div style={{ marginLeft: 5 }}>
              <S.ButtonIcon
                style={{ marginLeft: 5 }}
                onClick={() =>
                  setDrawerStatus1({
                    visible: true,
                    bPaymentId: record?.id,
                    nfValue: record?.totalFmt,
                  })
                }
              >
                <img src={IconEye} alt="steps" />
              </S.ButtonIcon>
            </div>
          ) : null}
          {record?.status === 4 ? (
            <div style={{ marginLeft: 5 }}>
              <S.ButtonIcon
                style={{ marginLeft: 5 }}
                onClick={() =>
                  setDrawerStatus4({ visible: true, bPaymentId: record?.id })
                }
              >
                <img src={IconEye} alt="steps" />
              </S.ButtonIcon>
            </div>
          ) : null}
        </S.RowTab>
      ),
    },
  ];

  React.useEffect(() => {
    const bPaymentId = localStorage.getItem("@BPOOL-supplier-bPaymentId");
    const storageDataSubirNf = localStorage.getItem(
      "@BPOOL-accounts-dataSubirNf"
    );
    const dataSubirNf = JSON.parse(storageDataSubirNf);

    let id = "";
    if (bPaymentId) {
      id = bPaymentId;
    } else if (dataSubirNf?.id) {
      id = dataSubirNf?.id;
    }

    if (id && data?.data?.length) {
      const bPaymentData = data?.data?.find((bPayment) => bPayment?.id === id);

      if (bPaymentData && bPaymentData?.status === 1) {
        setDrawerStatus1({
          visible: true,
          bPaymentId: id,
          nfValue: bPaymentData?.totalFmt,
        });
      } else if (bPaymentData && bPaymentData?.status === 4) {
        setDrawerStatus4({
          visible: true,
          bPaymentId: id,
        });
      }
    }
  }, [data]);

  return (
    <>
      <S.Table
        columns={columns}
        dataSource={data?.data}
        pagination={{
          pageSize: 10,
          total: data?.metadata[0]?.TotalItems,
          defaultCurrent: parseQueryString(location?.search)?.page
            ? Number(parseQueryString(location?.search)?.page)
            : 1,
          onChange: (page) => {
            handlePagination(page);
          },
        }}
        loading={isLoading}
        locale={{
          emptyText: I18n.get("Nenhum BPayment cadastrado até o momento"),
        }}
        onRow={(record, rowIndex) => {
          if (record?.status === 1 || record?.status === 4) {
            return { className: "bpayment-action-need" };
          }
        }}
      />

      <DrawerStatusSteps
        drawer={drawerStatusSteps}
        handleCloseDrawer={handleCloseDrawer}
        isPartner={isPartner}
      />
      <DrawerStatus1
        drawer={drawerStatus1}
        handleCloseDrawer={handleCloseDrawer}
        refetch={refetch}
        isPartner={isPartner}
      />
      <DrawerStatus4
        drawer={drawerStatus4}
        handleCloseDrawer={handleCloseDrawer}
        refetch={refetch}
        isPartner={isPartner}
      />
    </>
  );
};
