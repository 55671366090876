import React from "react";

import { I18n } from "aws-amplify";

import { Task } from "../../utils/task";
import { FormUtils } from "../../utils/form";

import MaskedField from "../form/masked-field";
import SelectField from "../form/select-field";

import { SelectOne } from "../../v2/components/UI/SelectOne";
import { DatePicker } from "../../v2/components/UI/DatePicker";

class PartnerInfoForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      fields: {
        estimatedStartDate: {
          label: "",
          mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
          placeholder: I18n.get("DD/MM/AAAA"),
          onChange: this.onChangeForm.bind(this, "estimatedStartDate"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'estimatedStartDate'),
        },
        estimatedDecisionInWeeks: {
          label: "",
          onChange: this.onChangeForm.bind(this, "estimatedDecisionInWeeks"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'estimatedDecisionInWeeks')
        },
        estimatedDurationInMonths: {
          label: I18n.get("Meses"),
          onChange: this.onChangeForm.bind(this, "estimatedDurationInMonths"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'estimatedDurationInMonths'),
        },
        estimatedDurationInWeeks: {
          label: I18n.get("Semanas"),
          onChange: this.onChangeForm.bind(this, "estimatedDurationInWeeks"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'estimatedDurationInWeeks'),
        },
        nonCompeteInDays: {
          label: "",
          onChange: this.onChangeForm.bind(this, "nonCompeteInDays"),
          successIcon: false,
          // onBlur: this.onSave.bind(this, 'nonCompeteInDays')
        },
        isSubmit: false,
      },
      form: {},
      errors: {},
      task: {},
    };

    //this.onDrop = this.onDrop.bind(this)
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    const task = this.props.task || {};
    const prev = (prevProps && prevProps.task) || {};

    //console.dir({ task: task, prev: prev });

    if (task != prev) {
      var nextState = Object.assign({}, prevState, { task: task });

      Object.keys(this.state.fields).forEach((item) => {
        if (this.props[item]) {
          nextState.form[item] = this.props[item];
        }
      });

      this.setState(nextState);
    }
  }

  componentWillUnmount() {
    this.props.save(this.getData());
  }

  onChangeForm(prop, e) {
    const value = e.target ? e.target.value : e;

    this.setState(
      (prevState) => {
        var nextState = Object.assign({}, prevState);

        nextState.form[prop] = value;
        nextState.errors[prop] = null;

        return nextState;
      },
      () => this.onSaving()
    );
  }

  getData() {
    var data = {
      clientId: this.props.clientId,
      ...this.props.project,
    };

    Object.keys(this.state.form).forEach((item) => {
      if (FormUtils.isFormField(this.state.form[item])) {
        data[item] = this.state.form[item].value;
      } else {
        data[item] = this.state.form[item];
      }
    });

    return data;
  }

  onSaving() {
    clearTimeout(this.t);
    this.t = setTimeout(() => this.onSave(), 1000);
  }

  onSave() {
    if (this.props.isAdmin) return;

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  submitForm(e) {
    e.preventDefault();

    var task = new Task();

    task.start();

    this.setState({ task: task }, function () {
      this.props.save(this.getData());
    });
  }

  getWeekDurationValuesDecision() {
    var values = [];

    values.push({ code: 1, label: I18n.get("Até 1 semana") });
    values.push({ code: 2, label: I18n.get("2 semanas") });
    values.push({ code: 3, label: I18n.get("3 semanas") });
    values.push({ code: 4, label: I18n.get("4 semanas") });
    values.push({ code: 5, label: I18n.get("5 semanas") });
    values.push({ code: 6, label: I18n.get("6 semanas") });

    return values;
  }

  getWeekDurationValues() {
    var values = [];

    values.push({ code: 0, label: I18n.get("0 semana") });
    values.push({ code: 1, label: I18n.get("1 semana") });
    values.push({ code: 2, label: I18n.get("2 semanas") });
    values.push({ code: 3, label: I18n.get("3 semanas") });
    values.push({ code: 4, label: I18n.get("4 semanas") });
    values.push({ code: 5, label: I18n.get("5 semanas") });
    values.push({ code: 6, label: I18n.get("6 semanas") });
    values.push({ code: 7, label: I18n.get("7 semanas") });
    values.push({ code: 8, label: I18n.get("8 semanas") });

    return values;
  }

  getMonthDurationValues() {
    var values = [];

    values.push({ code: 0, label: I18n.get("0 mês") });
    values.push({ code: 1, label: I18n.get("1 mês") });
    values.push({ code: 2, label: I18n.get("2 meses") });
    values.push({ code: 3, label: I18n.get("3 meses") });
    values.push({ code: 4, label: I18n.get("4 meses") });
    values.push({ code: 5, label: I18n.get("5 meses") });
    values.push({ code: 6, label: I18n.get("6 meses") });
    values.push({ code: 7, label: I18n.get("7 meses") });
    values.push({ code: 8, label: I18n.get("8 meses") });
    values.push({ code: 9, label: I18n.get("9 meses") });
    values.push({ code: 10, label: I18n.get("10 meses") });
    values.push({ code: 11, label: I18n.get("11 meses") });
    values.push({ code: 12, label: I18n.get("12 meses") });

    return values;
  }

  getNonCompeteValues() {
    var values = [];

    values.push({ code: -1, label: I18n.get("Nenhum") });
    values.push({ code: 0, label: I18n.get("Durante o projeto") });
    values.push({ code: 30, label: I18n.get("Durante o projeto + 30 dias") });
    values.push({ code: 60, label: I18n.get("Durante o projeto + 60 dias") });
    values.push({ code: 90, label: I18n.get("Durante o projeto + 90 dias") });

    return values;
  }

  formatarData(value) {
    const dia = value.substring(0, 2);
    const mes = value.substring(2, 4);
    const ano = value.substring(4, 8);

    const data = new Date(`${ano}-${mes}-${dia}`);

    const dataFormatada = `${data.getDate()}/${
      data.getMonth() + 1
    }/${data.getFullYear()}`;

    return dataFormatada;
  }

  render() {
    const isDate = this.state.form.scheduleType == "DATE";
    const isPeriod = this.state.form.scheduleType == "PERIOD";

    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

    return (
      <form onSubmit={this.submitForm.bind(this)}>
        <div className="form-group">
          <h1 className="title-bpool medium">{I18n.get("Pagamento")}</h1>

          <br />

          <strong className="regular-control-text">
            {I18n.get("Prazo pagamento do cliente")}
          </strong>

          <p style={{ padding: "1rem 0" }}>
            {this.props.client &&
              this.props.client.company &&
              this.props.client.company.paymentDefaultTermDays}{" "}
            {I18n.get("dias após faturamento do Projeto.")}
          </p>

          <br />
        </div>
        <br />

        <div className="form-group">
          <h1 className="title-bpool medium">{I18n.get("Datas")}</h1>

          {!this.props.selectPartnerId && (
            <>
              <p style={{ padding: "1rem 0", fontWeight: "bold" }}>
                {I18n.get("Prazo para a seleção do parceiro")}
              </p>

              <p style={{ padding: "0.2rem 0" }}>
                {this.props.isBPitch === false && (
                  <>
                    {I18n.get(
                      "Lembre-se que te apresentaremos 3 parceiros e entendemos que muitas vezes você vai precisar envolver mais gente no processo de decisão. Indique aqui quanto tempo estima que este processo vai demorar."
                    )}
                  </>
                )}

                {this.props.isBPitch === true && (
                  <>
                    {I18n.get(
                      "Lembre-se que te apresentaremos parceiros e entendemos que muitas vezes você vai precisar envolver mais gente no processo de decisão. Indique aqui quanto tempo estima que este processo vai demorar."
                    )}
                  </>
                )}
              </p>

              {/* <SelectField
                {...this.state.fields.estimatedDecisionInWeeks}
                value={this.state.form.estimatedDecisionInWeeks}
                error={this.state.errors.estimatedDecisionInWeeks}
                values={this.getWeekDurationValuesDecision()}

              /> */}

              <SelectOne
                options={this.getWeekDurationValuesDecision()}
                value={this.state.form.estimatedDecisionInWeeks}
                onChange={(value) =>
                  this.onChangeForm("estimatedDecisionInWeeks", value)
                }
                placeholder={I18n.get("Semanas")}
                error=""
              />
            </>
          )}

          <div>
            <br />

            <p style={{ padding: "1rem 0", fontWeight: "bold" }}>
              {I18n.get("Previsão de início do projeto")}
            </p>

            <p style={{ padding: "0.2rem 0" }}>
              {I18n.get(
                "O projeto só poderá começar depois da emissão da SPA (número de pedido)"
              )}
            </p>
            <br />

            {/* <MaskedField
              {...this.state.fields.estimatedStartDate}
              value={this.state.form.estimatedStartDate}
              error={this.state.errors.estimatedStartDate}
            /> */}



            <DatePicker
              defaultValue={this.state.form.estimatedStartDate || null}
              format={dateFormatList}
              onChange={(e) => this.onChangeForm("estimatedStartDate", e)}
              placeholder="__/__/____"
              label={I18n.get("Data")}
              required
              error={this.state.errors.estimatedStartDate}
            />
            <br />

            <p style={{ padding: "1rem 0", fontWeight: "bold" }}>
              {I18n.get("Previsão para término do projeto")}
            </p>

            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field" style={{ width: 200 }}>
                  {/* <SelectField
                    {...this.state.fields.estimatedDurationInMonths}
                    value={this.state.form.estimatedDurationInMonths}
                    error={this.state.errors.estimatedDurationInMonths}
                    values={this.getMonthDurationValues()}
                  /> */}

                  <SelectOne
                    options={this.getMonthDurationValues()}
                    value={this.state.form.estimatedDurationInMonths}
                    onChange={(value) =>
                      this.onChangeForm("estimatedDurationInMonths", value)
                    }
                    placeholder={I18n.get("Meses")}
                    error=""
                  />
                </div>
                <div className="field" style={{ width: 200 }}>
                  {/* <SelectField
                    {...this.state.fields.estimatedDurationInWeeks}
                    value={this.state.form.estimatedDurationInWeeks}
                    error={this.state.errors.estimatedDurationInWeeks}
                    values={this.getWeekDurationValues()}
                  /> */}

                  <SelectOne
                    options={this.getWeekDurationValues()}
                    value={this.state.form.estimatedDurationInWeeks}
                    onChange={(value) =>
                      this.onChangeForm("estimatedDurationInWeeks", value)
                    }
                    placeholder={I18n.get("Semanas")}
                    error=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* {this.props.projectType !== ProjectType.Creative && <div>

                        <EventCheckoutForm {...this.props} isCheckout />

                    </div>} */}

          <br />
        </div>

        <div className="form-group">
          <br />
          <br />

          <h1 className="title-bpool medium">{I18n.get("Non-Compete")}</h1>

          <p style={{ padding: "0.2rem 0" }}>
            {I18n.get(
              "Precisa incluir cláusula de non-compete para este projeto? Por qual periodo (a partir do início do projeto)?"
            )}
          </p>

          {/* <SelectField
            {...this.state.fields.nonCompeteInDays}
            value={this.state.form.nonCompeteInDays}
            error={this.state.errors.nonCompeteInDays}
            values={this.getNonCompeteValues()}
          /> */}

          <SelectOne
            options={this.getNonCompeteValues()}
            value={this.state.form.nonCompeteInDays}
            onChange={(value) => this.onChangeForm("nonCompeteInDays", value)}
            placeholder={I18n.get("Selecione")}
            error=""
          />

          <br />
        </div>
        <br />
      </form>
    );
  }
}

export default PartnerInfoForm;
