import React, { useEffect } from "react";
import { I18n } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Content from "../../../../components/UI/Content";
import LoggedPage from "../../../../../components/page-templates/fillForm-logged";
import { Breadcrumb } from "../../../../components/UI/Breadcrumb";
import { ButtonBpool } from "../../../../components/UI/ButtonBpool";
import { BoxContent } from "../../components/BoxContent";
import { Totals } from "./Totals";
import { TableBpays } from "./TableBpays";
import { Filters } from "./Filters";

import * as S from "./styles";

export const BpayList = () => {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <LoggedPage>
      <Content style={{ paddingTop: 58 }}>
        <Breadcrumb
          title="Ollo Pay"
          breadcrumbs={[
            { name: "Home", route: "/" },
            { name: "Ollo Pay", route: "/cliente/bpay" },
          ]}
          buttons={
            <>
              <ButtonBpool
                text={I18n.get("Novo Ollo Pay")}
                theme="primary"
                onClick={() => history.push("/cliente/bpay/new/data")}
              />
            </>
          }
        />
        <div className="container">
          <Totals />
          <S.ContentBpay>
            <BoxContent>
              <Filters />
              <TableBpays />
            </BoxContent>
          </S.ContentBpay>
        </div>
      </Content>
    </LoggedPage>
  );
};
