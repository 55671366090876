import React, { Component } from "react";
import { I18n } from "aws-amplify";
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Radio,
  Button,
  Result,
} from "antd";

import styled from "styled-components";

import { Task } from "../../../utils/task";

const { Option } = Select;

const FormBox = styled(Form)``;

const SuccessPane = ({ onReset }) => {
  const BoxSuccess = styled.div`
    padding: 2rem;
  `;

  return (
    <BoxSuccess>
      <Result
        status="success"
        title={I18n.get("Faturamento Extraordinário cadastrado com sucesso!")}
        subTitle={I18n.get(
          "O Fornecedor irá receber o link para preenchimento."
        )}
        extra={[
          <Button key="create" type="primary" onClick={onReset}>
            {I18n.get("Cadastar outro Faturamento Extraordinário")}
          </Button>,
        ]}
      />
    </BoxSuccess>
  );
};

class FormSupplierBilling extends Component {
  constructor(props) {
    super();

    this.state = {
      brands: [],
      task: {},
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();


    this.props.form.validateFields((err, values) => {
      if (err) return;

      const brand = this.state.brands.length > 0 && this.state.brands.find(i => i.code == values.brandId);
      this.setState({
        formState: {
          ...values,
          brandName: brand?.label || null
        },
      });

      const data =  {
        ...values,
        brandName: brand?.label || null
      }

      var createTask = new Task();

      this.setState({ task: createTask.start() });

      this.props.action(data).then(({ task, errors }) => {
        this.setState({ task: task });

        if (errors) {
          Object.keys(errors).map((field) => {
            this.props.form.setFields({
              [field]: {
                value: values.field,
                errors: [new Error(errors[field].errorMessage)],
              },
            });
          });
        }
      });
    });
  };

  handleBrands = (value) => {

    this.props.get_brands(value).then((resp) => {

      this.setState({ brands: resp.data });
    });
  };


  render() {
    const languages = [
      { code: "pt", label: I18n.get("Português") },
      { code: 'en', label: I18n.get("Inglês") },
      { code: 'es', label: I18n.get("Espanhol") },
    ];

    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { clients = [], bp = {} } = this.props;

    const { item = {} } = this.props;

    const { task } = this.state;

    if (task.result) {
      return <SuccessPane onReset={(e) => this.setState({ task: {} })} />;
    }

    const currency =
      bp &&
      bp.currencies &&
      bp.currencies.find((c) => c.code == getFieldValue("currencyCode"));

    return (
      <FormBox onSubmit={this.handleSubmit}>
        <Row>
          <Col sm={24}>
            <Form.Item label={I18n.get("Nome Projeto")}>
              {getFieldDecorator("projectName", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.projectName || null,
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Form.Item label={I18n.get("Cliente")}>
              {getFieldDecorator("clientId", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.scopeId || null,
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={true}
                  onChange={(value) => this.handleBrands(value)}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {clients &&
                    clients.map((item, index) => {
                      return (
                        <Option className="" key={index} value={item.code}>
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={12}>
            <Form.Item label={I18n.get("Marca")}>
              {getFieldDecorator("brandId", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.scopeId || null,
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={true}
                  disabled={this.state.brands.length == 0}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {this.state.brands.length > 0 &&
                    this.state.brands.map((item, index) => {
                      return (
                        <Option className="" key={index} value={item.code}>
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={16}>
            <Form.Item label={I18n.get("Nome do Fornecedor")}>
              {getFieldDecorator("supplierName", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.supplierName || "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={8}>
            <Form.Item label={I18n.get("Idioma")}>
              {getFieldDecorator("supplierLocale", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.supplierLocale || "pt",
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={false}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {languages.map((item, index) => {
                    return (
                      <Option
                        className=""
                        key={index}
                        value={item.code}
                      >{`${item.label}`}</Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={24}>
            <Form.Item label={I18n.get("E-mail do Fornecedor")}>
              {getFieldDecorator("supplierEmail", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.supplierEmail || "",
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <Form.Item label={I18n.get("#SPA")}>
              {getFieldDecorator("pONumber", {
                rules: [
                  { required: false, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.poNumber || "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={12}>
            <Form.Item label={I18n.get("Moeda")}>
              {getFieldDecorator("currencyCode", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.currencyCode || "BRL",
              })(
                <Select
                  className=""
                  style={{ width: "100%" }}
                  showSearch={false}
                  filterOption={(input, option) => {
                    return (
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {bp &&
                    bp.currencies &&
                    bp.currencies.map((item, index) => {
                      return (
                        <Option
                          className=""
                          key={index}
                          value={item.code}
                        >{`${item.code} (${item.symbolOnly})`}</Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          {currency && (
            <>
              <Col sm={8}>
                <Form.Item label={I18n.get("Valor Total")}>
                  {getFieldDecorator("amountTotal", {
                    rules: [
                      {
                        required: true,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: item.amountTotal || "",
                  })(
                    <InputNumber
                      min={0}
                      step={0.01}
                      decimalSeparator={currency.decimalSeparator ?? ","}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item label={I18n.get("Valor BPool")}>
                  {getFieldDecorator("amountFee", {
                    rules: [
                      {
                        required: true,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: item.amountFee || "",
                  })(
                    <InputNumber
                      min={0}
                      step={0.01}
                      decimalSeparator={currency.decimalSeparator ?? ","}
                    />
                  )}
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item label={I18n.get("Valor Parceiro")}>
                  {getFieldDecorator("amountPartner", {
                    rules: [
                      {
                        required: true,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: item.amountPartner || "",
                  })(
                    <InputNumber
                      min={0}
                      step={0.01}
                      decimalSeparator={currency.decimalSeparator ?? ","}
                    />
                  )}
                </Form.Item>
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col sm={24}>
            <Form.Item label={I18n.get("Faturamento contra")}>
              {getFieldDecorator("billTo", {
                rules: [
                  { required: true, message: I18n.get("Campo obrigatório.") },
                ],
                initialValue: item.billingType,
              })(
                <Radio.Group>
                  <Radio value="Client">{I18n.get("Cliente")}</Radio>
                  <Radio value="BPool">{I18n.get("BPool")}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>

        {getFieldValue("billTo") == "BPool" && (
          <>
            <Row>
              <Col sm={24}>
                <Form.Item label={I18n.get("Escritório BPool")}>
                  {getFieldDecorator("bPoolRegistrationId", {
                    rules: [
                      {
                        required: true,
                        message: I18n.get("Campo obrigatório."),
                      },
                    ],
                    initialValue: item.bPoolOffice || null,
                  })(
                    <Select
                      className=""
                      style={{ width: "100%" }}
                      showSearch={true}
                      filterOption={(input, option) => {
                        return (
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {bp.bPoolOffices &&
                        bp.bPoolOffices.map((item, index) => {
                          return (
                            <Option
                              className=""
                              key={index}
                              value={item.id}
                            >{`${item.registrationName.toUpperCase()} [${item.country.toUpperCase()}] (${item.countryCode.join(
                              ", "
                            )})`}</Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col sm={24}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                className="button bt-bpool black"
                shape="round"
                htmlType="submit"
                loading={task.busy}
              >
                {I18n.get("Enviar")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </FormBox>
    );
  }
}

export default Form.create({})(FormSupplierBilling);
