import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { Link, Redirect } from "react-router-dom";

import NumberFormat from 'react-number-format';

import { formatMoney2, formatMoneyInput, formatMoneyGraph } from '../../../utils/helpers'
import {
    Table,
    Popconfirm,
    Tooltip,
    Icon
} from 'antd';

import { ProjectTypeNames, ProjectExtraTypeNames } from '../../../model';

import FormatCurrency from '../../../components/ui/format-currency';


const Btns = styled.p`
width: 100px;
display: block;
    a {
        margin-left: 10px;
    }
`

const FooterT = styled.div`
    font-weight: bold;
    text-align: right;
`

class tableProjects extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return '';

        return this.props.bp.clientProjectStatusesMap[value] && this.props.bp.clientProjectStatusesMap[value].label;
    }


    render() {


        const columns = [
            {
                title: I18n.get("País"),
                dataIndex: 'country',
                key: 'country',
                width: 70,
                render: (country) => {
                    if (country) {
                        return <img src={`https://assets-ollo.bpool.co/country-flags/png24px/${country}.png`} />
                    } else {
                        return "";
                    }
                }

            },

            {
                title: I18n.get("Tier / Market"),
                dataIndex: 'tier',
                key: 'tier',
                // render: (expertise) => this.getSector(expertise),
            },

            {
                title: I18n.get("Tipo"),
                dataIndex: 'type',
                key: 'type',
                // render: (expertise) => this.getSector(expertise),
            },
            {
                title: I18n.get("SKU"),
                dataIndex: 'sku',
                key: 'sku',
                // render: (expertise) => this.getSector(expertise),
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                width: 150,
                key: 'name',
                render: (name, record) => <Link to={`/bpool/projetos/${record.item.projectId}`}>{name || `S/N (${record.item.name} - ${record.item.email})`}</Link>,
            },
            {
                title: I18n.get("Status"),
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: I18n.get("Cliente"),
                dataIndex: 'client',
                key: 'client',
            },
            {
                title: I18n.get("Parceiro"),
                dataIndex: 'partner',
                key: 'partner',
                // render: (price,record) => <FormatCurrency amount={price} symbol={record.item.currency.symbolOnly} tSeparator={record.item.currency.thousandSeparator} dSeparator={record.item.currency.decimalSeparator} />,
            },

            // {
            //     title: I18n.get("BPPerc"),
            //     dataIndex: 'bp',
            //     key: 'bp',
            // },
            // {
            //     title: "",
            //     dataIndex: 'money',
            //     key: 'money',
            //     width: 30,
            //     render: (money, record) =>  <Tooltip title={money}>
            //                             {record.item.currency.symbolOnly}
            //                         </Tooltip>,
            // },
            {
                title: I18n.get("Valor"),
                dataIndex: 'price',
                key: 'price',
                width: 120,
                render: (price, record) => {
                    return (<FormatCurrency amount={price} currency={record.item.currency} />)
                }
            },
            // {
            //     title: I18n.get("Parc."),
            //     dataIndex: 'partnerPrice',
            //     key: 'partnerPrice',
            //     render: (price,record) => <FormatCurrency amount={price} symbol={record.item.currency.symbolOnly} tSeparator={record.item.currency.thousandSeparator} dSeparator={record.item.currency.decimalSeparator} />,
            // },
            // {
            //     title: I18n.get("BPFee"),
            //     dataIndex: 'bpf',
            //     key: 'bpf',
            //     render: (price,record) => <FormatCurrency amount={price} symbol={record.item.currency.symbolOnly} tSeparator={record.item.currency.thousandSeparator} dSeparator={record.item.currency.decimalSeparator} />,
            // },
            // {
            //     title: I18n.get("SPA"),
            //     dataIndex: 'po',
            //     key: 'po',
            //     render: (price,record) => <FormatCurrency amount={price} symbol={record.item.currency.symbolOnly} tSeparator={record.item.currency.thousandSeparator} dSeparator={record.item.currency.decimalSeparator} />,
            // },
            {
                title: I18n.get("Data"),
                dataIndex: 'date',
                width: 100,
                key: 'date',
            },
            {
                title: I18n.get("Extras"),
                dataIndex: 'extras',
                width: 80,
                key: 'extras',
                render: (extras, record) => {

                    const extraNames = extras.map((extra) => <div>{extra.extraName}</div>);

                    return (

                        <Fragment>
                            <Tooltip title={extraNames}>
                                {extras && extras.length}
                            </Tooltip>
                        </Fragment>
                    )
                }
            },
            {
                title: '',
                dataIndex: 'actions',
                key: 'item',
                width: 100,
                render: (text, record) => {
                    let data = {
                        id: record.item.id,
                        partnerId: this.props.partnerId,
                    }

                    if (!this.props.isView) {

                        // this.props.delete_project(item.projectId)
                        return (
                            <Btns>
                                <Popconfirm
                                    title={I18n.get("Deseja cancelar esse projeto?")}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => this.props.cancel(record.item.projectId)}
                                    okText={I18n.get("Sim")}
                                    cancelText={I18n.get("Não")}
                                >
                                    <a>
                                        <Tooltip title={I18n.get("Cancelar projeto")}>
                                            <Icon type="stop" theme="filled" />
                                        </Tooltip>
                                    </a>
                                </Popconfirm>
                                <Popconfirm
                                    title={I18n.get("Deseja deletar esse projeto?")}
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={() => this.props.delete(record.item.projectId)}
                                    okText={I18n.get("Sim")}
                                    cancelText={I18n.get("Não")}
                                >
                                    <a>
                                        <Tooltip title={I18n.get("Deletar projeto")}>
                                            <Icon type="delete" theme="filled" />
                                        </Tooltip>
                                    </a>
                                </Popconfirm>

                            </Btns>
                        )
                    }

                }
                // render: (record) => this.props.delMarketExpertise(record.item.id),
            },
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {

            const { extras = [] } = item;

            return {
                key: index,
                country: item.clientCountry && item.clientCountry.isO2 && item.clientCountry.isO2.toLowerCase(),
                type: ProjectTypeNames[item.projectType],
                sku: item.projectSKU,
                tier: `${item.tc}/${item.tMarket}`,
                name: item.projectName,
                money: item.currency.name,
                client: item.clientName,
                status: this.getStatus(item.status),
                price: item.projectTotal,
                partner: item.partnerName,
                bp: item.bpCommission && item.bpCommission.perc + "%",
                bpf: item.priceBPoolComission,
                partnerPrice: item.pricePartner,
                po: item.billingPO,
                date: item.createdOnFmt,
                extras: extras,
                item: item,
            }
        });


        return (
            <Table
                pagination={this.props.pagination ? true : false}
                columns={columns}
                dataSource={dataTable}
                locale={{ emptyText: I18n.get('Sem informação') }}
                scroll={{ x: 1200 }}
                // Nao é possível somar moedas diferentes
                // footer={() => {
                //     return (<FooterT>
                //         <h3>{I18n.get("Total:")}  <FormatCurrency  amount={this.props.totals.client} /></h3>
                //     </FooterT>)
                // }
                // }
            />
        );
    }
}

export default tableProjects;
