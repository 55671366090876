import React from "react";
import { Row, Col } from "antd";
import { I18n } from "aws-amplify";

import { Info } from "./info";
import * as S from "./styles";

export const InformationsDetails = ({ data, isLoading }) => {
  const billToValue = (billTo) => {
    let invoiceTo = "";

    if (billTo === 1) {
      invoiceTo = "CLIENT";
    } else {
      // invoiceTo = `BPOOL (${data.billToCompany.registrationName})`;
      invoiceTo = `BPOOL (---)`;
    }
    return invoiceTo;
  };

  return (
    <S.Wrapper>
      <Row>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Nome do projeto")}
            value={data?.name}
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Quantidade de BPayments solicitados")}
            value={data?.bPaymentsAmount}
            isLoading={isLoading}
          />
        </Col>
        <Col md={12} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Data da solicitação")}
            value={data?.createdOnFmt}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Valor total")}
            value={data?.totalFmt}
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Nº NF Ollo")}
            value={data?.bPoolNFSe}
            isLoading={isLoading}
          />
        </Col>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Faturar contra")}
            value={billToValue(data?.billTo)}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col md={6} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("SKU")}
            value={data?.sku}
            isLoading={isLoading}
          />
        </Col>
        <Col md={18} style={{ paddingLeft: 0 }}>
          <Info
            label={I18n.get("Descritivo")}
            value={data?.description}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};
