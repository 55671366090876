import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Icon, Tooltip, Popconfirm } from "antd";
import { useBpayAdmin } from "../../../../../hooks/useBpay/useBpayAdmin.hook";
import { Alert } from "../../../../../components/UI/Alert";
import { ButtonBpool } from "../../../../../components/UI/ButtonBpool";
import { Drawer } from "../../../../../components/UI/Drawer";
import { NotificationBPool } from "../../../../../components/UI/NotificationBPool";
import { createQueryString, parseQueryString } from "./utils/paramsResolvers";
import * as S from "./styles";
import { colorStatus, colorBgStatus } from "./utils/colorsStatus";
import IconEnvelope from "../../svgs/fi-rr-envelope.svg";
import IconEye from "../../svgs/fi-rr-eye.svg";
import IconTrash from "../../svgs/fi-rr-trash.svg";
import IconSteps from "../../svgs/fi-rr-list-check.svg";
import { DrawerSendMail, DrawerBillingInvoice, DrawerStatus } from "./Drawers";

export const TableBPayments = ({ handleSelectedsBpays }) => {
  let history = useHistory();
  let location = useLocation();
  let params = useParams();
  const { getBpayByIdTable } = useBpayAdmin();
  const [isLoadingInternal, setIsLoadingInternal] = useState(null);
  const [openDrawerSendMail, setOpenDrawerSendMail] = useState({ open: false });
  const [openDrawerBillingInvoice, setOpenDrawerBillingInvoice] = useState({
    open: false,
  });
  const [openDrawerStatus, setOpenDrawerStatus] = useState({ open: false });

  const { data, isLoading, error, refetch } = useQuery(
    [
      `bpaymentByIdAdminTable-${params?.bpayId}`,
      createQueryString(parseQueryString(location?.search)),
    ],
    () =>
      getBpayByIdTable({
        bpayId: params?.bpayId,
        query: createQueryString(parseQueryString(location?.search)),
      }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        NotificationBPool.open({
          type: "error",
          title: I18n.get("Erro!"),
          description: I18n.get("Erro ao carregar os dados do Ollo Pay"),
          duration: 3,
        });
      },
    }
  );

  const renderText = (text) => {
    if (text) {
      return text;
    } else if (text === 0) {
      return text;
    } else {
      return "---";
    }
  };

  const handleDeleteBPay = async (id) => {
    try {
      setIsLoadingInternal(id);
      refetch();
    } catch (error) {
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao deletar o Ollo Pay"),
        duration: 3,
      });
    } finally {
      setIsLoadingInternal(null);
    }
  };

  const columns = [
    {
      title: I18n.get("SKU"),
      dataIndex: "sku",
      width: 130,
      sorter: true,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.sku}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.sku)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Nome do Fornecedor"),
      dataIndex: "name",
      width: 160,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.name}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={160}>
            <S.RowElipsis>{renderText(record?.name)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor Fornecedor"),
      dataIndex: "totalFmt",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.totalFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.totalFmt)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor Fee"),
      dataIndex: "totalFeeFmt",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.totalFeeFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>{renderText(record?.totalFeeFmt)}</S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Valor Total"),
      dataIndex: "totalWithBPCommmissionFmt",
      width: 130,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${record?.totalWithBPCommmissionFmt}`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.RowTab widthRow={130}>
            <S.RowElipsis>
              {renderText(record?.totalWithBPCommmissionFmt)}
            </S.RowElipsis>
          </S.RowTab>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Status"),
      dataIndex: "statusDescription",
      width: 160,
      render: (type, record) => (
        <Tooltip
          placement="topRight"
          title={`${I18n.get("Solicitação está em")} ${
            record?.statusDescription
          }`}
          overlayClassName="tooltip-status-bpay"
        >
          <S.Status style={{ ...colorBgStatus(record?.status) }}>
            <span style={{ ...colorStatus(record?.status) }}>
              {renderText(record?.statusDescription)}
            </span>
          </S.Status>
        </Tooltip>
      ),
    },
    {
      title: I18n.get("Ações"),
      dataIndex: "id",
      width: 150,
      render: (type, record) => (
        <S.RowTab>
          <S.ButtonIcon
            onClick={() =>
              setOpenDrawerBillingInvoice({
                open: true,
                bpayId: params?.bpayId,
                bPaymentId: record.id,
                specificInformations: record.specificInformations,
              })
            }
          >
            <img src={IconEye} alt="billing invoice" />
          </S.ButtonIcon>
          {record?.status === 0 || record?.status === 1 ? (
            <S.ButtonIcon
              style={{ marginLeft: 5 }}
              onClick={() =>
                setOpenDrawerSendMail({
                  open: true,
                  bpayId: params?.bpayId,
                  bPaymentId: record.id,
                  createdOn: record.createdOn,
                })
              }
            >
              <img src={IconEnvelope} alt="send email" />
            </S.ButtonIcon>
          ) : null}
          {/* {isLoadingInternal === record.id ? (
            <S.LoadingIcon>
              <Icon type="loading" />
            </S.LoadingIcon>
          ) : (
            <Popconfirm
              placement="top"
              title={I18n.get("Deseja mesmo cancelar?")}
              onConfirm={() => handleDeleteBPay(record.id)}
              okText={I18n.get("Sim")}
              cancelText={I18n.get("Não")}
            >
              <S.ButtonIcon
                style={{ marginLeft: 5 }}
                // onClick={() => handleDeleteBPay(record.id)}
              >
                <img src={IconTrash} alt="send email" />
              </S.ButtonIcon>
            </Popconfirm>
          )} */}
          {/* <S.ButtonIcon
            style={{ marginLeft: 5 }}
            onClick={() =>
              setOpenDrawerStatus({
                open: true,
                bpayId: record.bPayId,
                bPaymentId: record.bPaymentId,
              })
            }
          >
            <img src={IconSteps} alt="steps" />
          </S.ButtonIcon> */}
        </S.RowTab>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      handleSelectedsBpays(selectedRows);
    },
  };

  const handlePagination = (page) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!page || page === 1) {
      delete objQueries.page;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, page });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleCloseDrawer = () => {
    setOpenDrawerSendMail({ open: false });
    setOpenDrawerBillingInvoice({ open: false });
    setOpenDrawerStatus({ open: false });
    refetch();
  };

  return (
    <>
      <S.Table
        columns={columns}
        dataSource={data?.data?.data || []}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 10,
          total: data?.data?.metadata[0]?.TotalItems,
          defaultCurrent: parseQueryString(location?.search)?.page
            ? Number(parseQueryString(location?.search)?.page)
            : 1,
          onChange: (page) => {
            handlePagination(page);
          },
        }}
        loading={isLoading}
        locale={{
          emptyText: I18n.get("Nenhum BPayment cadastrado até o momento"),
        }}
        scroll={{ x: 1300 }}
      />

      <DrawerSendMail
        drawer={openDrawerSendMail}
        handleCloseDrawer={handleCloseDrawer}
      />

      <DrawerBillingInvoice
        drawer={openDrawerBillingInvoice}
        handleCloseDrawer={handleCloseDrawer}
        refetch={refetch}
      />

      <DrawerStatus
        drawer={openDrawerStatus}
        handleCloseDrawer={handleCloseDrawer}
      />
    </>
  );
};
