import React, { useEffect, useState } from "react";
import { I18n } from "aws-amplify";

export const tabsDefinitions = async ({ isClient, projectId }) => {
  const listOfTabs = [
    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/briefing`,
      label: "Briefing",
      key: "briefing",
    },
    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/review`,
      label: "Review",
      key: "review",
    },
    isClient
      ? {
          url: `/cliente/projetos/v2/${projectId}/faturamento`,
          label: "SPA",
          key: "po",
        }
      : null,

    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/extras`,
      label: I18n.get("Extras"),
      key: "extras",
    },
  ];

  return listOfTabs.filter((item) => item !== null && typeof item === "object");
};
