import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';

import '../../App.css';
//import '../../Site.css';

import logo from '../../image/logoWOtext.svg';

import { AUTH_API } from '../../actions/auth/actions';
import Footer from './footer';

const mapStateToProps = state => {
    return {
        user: state.user,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(AUTH_API.logout());
        }
    };
};

class FillForm extends Component {

    constructor() {
        super();
        this.state = {};
    }

    onLogoutClick(e) {
        e.preventDefault();

        this.props.logout();
    }

    render() {

        const isLogged = (this.props.auth && this.props.auth.logged_user);

        return (
            <div>
                <section className="hero is-fullheight logged-page">
                <div className="hero-head is-fixed">
                    <nav className="navbar is-main">
                        <div className="container">
                            <div className="navbar-brand is-expanded">
                                <div className="navbar-item" >
                                    <img src={logo} alt="Ollo" />
                                </div>
                            </div>
                                <div id="navbarMenu" className="navbar-menu">
                                    <span className="navbar-menu--slogan">{I18n.get("Um novo caminho para a indústria criativa")}</span>
                                    <div className="navbar-start">
                                        <div className="navbar-item">
                                        </div>
                                    </div>

                                <div className="navbar-end" style={{ zIndex: 999 }}>
                                </div>
                            </div>
                        </div>
                    </nav>
            </div>
            <div >

                    {this.props.children}

            </div>

            </section>

            <Footer {...this.props} />
    </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FillForm);
