export const colorStatus = (status) => {
  switch (status) {
    case 1:
      // Rascunho
      return { color: "#64370C" };
    case 2:
      // Cadastro de fornecedor
      return { color: "#003F64" };
    case 3:
      // Aguardando SPA
      return { color: "#290772" };
    case 4:
      // Em andamento
      return { color: "#776400" };
    case 5:
      // Concluído
      return { color: "#36694E" };
    default:
      return { color: "#FFF" };
  }
};

export const colorBgStatus = (status) => {
  switch (status) {
    case 1:
      // Rascunho
      return { background: "#FCDEC1" };
    case 2:
      // Cadastro de fornecedor
      return { background: "#B6E3FD" };
    case 3:
      // Aguardando SPA
      return { background: "#D7E4FF" };
    case 4:
      // Em andamento
      return { background: "#FFD467" };
    case 5:
      // Concluído
      return { background: "#C6F1DA" };
    default:
      return { background: "#1883FF" };
  }
};
