import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';

import ContainerPage from './ContainerPage';

import {
    Form,
    Input,
    Select,
    Checkbox,
    TreeSelect,
    Row,
    Button,
    Col
} from 'antd';

const { Search } = Input;
const CheckboxGroup = Checkbox.Group;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;

const MainSearch = styled.div`
  background: #fff;
  padding: 60px 0;

  .FieldsSearch {
      margin-top: 0px;
  }

  h4 {
    color: #353535;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    border-bottom: 3px solid #000;
    padding-bottom: 12px;
    margin-bottom: 30px;

    &.LineBlue {
        border-color: #c7e5f2;
    }

    &.LinePurple {
        border-color: #bab1d1;
    }

    &.LinePink {
        border-color: #ffc5b9;
    }

    &.LineYellow {
        border-color: #f0e2a1;
    }
  }
`;

const estados = [
    {
        title: I18n.get("Norte"),
        value: 'Norte',
        key: 'norte',
        children: [
            {
                title: I18n.get("Manaus"),
                value: 'Manaus',
                key: 'manaus',
            },
            {
                title: I18n.get("Belem"),
                value: 'Belem',
                key: 'belem',
            },
        ],
    },
    {
        title: I18n.get("Nordeste"),
        value: 'Nordeste',
        key: 'nordeste',
        children: [
            {
                title: I18n.get("Recife"),
                value: 'Recife',
                key: 'recife',
            },
            {
                title: I18n.get("Fortaleza"),
                value: 'fortaleza',
                key: 'fortaleza',
            },
            {
                title: I18n.get("Maceio"),
                value: 'Maceio',
                key: 'maceio',
            },
        ],
    },
];

class MegaSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {


                const dataForm = {
                    ...values
                }



                return this.props.actionSearch(dataForm)
            }
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;



        const optionsBrands = this.props.products && this.props.products.map(product => {
            return { key: product.brandId, label: product.product, value: product.brandId }
        });

        // const optionsBrands = []

        const tProps = {
            treeData: estados,
            onChange: this.onChangeRegion,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            searchPlaceholder: 'Selecione...',
            style: {
                width: 300,
            },
        };

        const optionsSocial = [
            { label: I18n.get("Instagram"), value: 'Instagram' },
            { label: I18n.get("Instagram Stories"), value: 'Instagram Stories' },
            { label: I18n.get("Facebook"), value: 'Facebook' },
            { label: I18n.get("Twitter"), value: 'Twitter' }
        ];

        const OtherIdeasOptions = [
            { label: I18n.get("Evento"), value: 'Evento' },
            { label: I18n.get("Campanhas"), value: 'Campanhas' },
            { label: I18n.get("Social"), value: 'Social' },
            { label: I18n.get("Banners"), value: 'Banners' },
            { label: I18n.get("Experiência"), value: 'Experiência' }
        ];

        const optionsGenre = [
            { label: I18n.get("Mulher"), value: 'Mulher' },
            { label: I18n.get("Homem"), value: 'Homem' },
            { label: I18n.get("LGBT"), value: 'LGBT' },
        ];

        const optionsAge = [
            { label: I18n.get("18 - 24 anos"), value: '18 - 24 anos' },
            { label: I18n.get("25 - 30 anos"), value: '25 - 30 anos' },
            { label: I18n.get("Etc"), value: 'Etc' },
        ];

        return (
            <MainSearch>
                <ContainerPage>
                    <Form onSubmit={this.handleSubmit}>
                        <Row className="FieldsSearch">
                            <Col md={5}>
                                <h4 className="LineBlue">Marcas:</h4>

                                <Form.Item>
                                    {getFieldDecorator('products', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Checkbox.Group style={{ width: '100%' }} >
                                            {this.props.products && this.props.products.map((product, index) => {

                                                return <p><Checkbox key={index} value={product.productId}>{product.product}</Checkbox></p>
                                            })}
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col md={5}>
                                <h4 className="LinePurple">Ideias de:</h4>

                                <Form.Item label="Ideias de Social">
                                    {getFieldDecorator('ideaProposalSocial', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <CheckboxGroup
                                            options={optionsSocial}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="Outras ideias">
                                    {getFieldDecorator('ideaProposalOthers', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <CheckboxGroup
                                            options={OtherIdeasOptions}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col md={5}>
                                <h4 className="LinePink">Para quem:</h4>

                                <Form.Item label="Gênero">
                                    {getFieldDecorator('genre', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <CheckboxGroup
                                            options={optionsGenre}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="Faixa etária">
                                    {getFieldDecorator('ageRange', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <CheckboxGroup
                                            options={optionsAge}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col md={9}>
                                <h4 className="LineYellow">Busca Avançada:</h4>

                                <Form.Item label="Status">
                                    {getFieldDecorator('status', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Select
                                            mode="multiple"
                                            placeholder="Selecione..."
                                            style={{ width: '100%' }}
                                        >
                                            <Option value={2}>Novos</Option>
                                            <Option value={15}>Aguardando SPA</Option>
                                            <Option value={16}>Aprovado</Option>
                                            <Option value={18}>Recusado</Option>
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Plataformas de Atuação">
                                    {getFieldDecorator('ActuationPlatforms', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <Select
                                            mode="multiple"
                                            placeholder="Selecione..."
                                            style={{ width: '100%' }}
                                        >
                                            <Option key="Música">Música</Option>
                                            <Option key="Vídeo">Vídeo</Option>
                                            <Option key="ETC">ETC</Option>
                                        </Select>
                                    )}
                                </Form.Item>

                                <Form.Item label="Área Foco para Ideias & Conteudo (Região e/ou Estado)">
                                    {getFieldDecorator('StateOrRegion', {
                                        rules: [{ required: false, message: 'ops!' }],
                                    })(
                                        <TreeSelect {...tProps} />
                                    )}
                                </Form.Item>


                                <Button type="primary" htmlType="submit" shape="round" size="large">Procurar</Button>

                            </Col>
                        </Row>
                    </Form>
                </ContainerPage>
            </MainSearch>
        );
    }
}

const MegaSearchForm = Form.create({})(MegaSearch);

export default MegaSearchForm;
