import React, { useState } from "react";
import { Select } from "antd";

import * as S from "./styles";
import { I18n } from "aws-amplify";
const { Option } = Select;

export const SelectOne = ({
  options,
  value,
  defaultValue,
  onChange,
  required,
  placeholder,
  error,
  clearError,
  multiple,
  isCountry,
  disabled,
  defaultActiveFirstOption,
  loading,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const changeValue = (valueInternal) => {
    if (onChange) {
      onChange(valueInternal);
      if (valueInternal !== value) {
        if (clearError) {
          clearError();
        }
      }
    }
  };

  const checkValue = value != "" || value != undefined || value != null;

  const toggleFocus = () => {
    setIsFocused((prevIsFocused) => !prevIsFocused);
  };

  return (
    <>
      <S.Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{
          rows: 1,
          className: "skeleton-input",
          width: "100%",
        }}
      >
        <S.Wrapper errorSelect={error} isFocused={isFocused}>
          {placeholder ? (
            <S.PlaceHolder valueSelected={checkValue} isFocused={isFocused}>
              {required ? <span>*</span> : null}
              {placeholder}
            </S.PlaceHolder>
          ) : null}
          <Select
            defaultValue={defaultValue}
            style={{ width: "100%" }}
            onChange={(val) => changeValue(val)}
            value={value}
            mode={multiple ? "multiple" : "default"}
            disabled={disabled}
            defaultActiveFirstOption={defaultActiveFirstOption}
            data-test={`select-${placeholder
              ?.replace(/\s+/g, "-")
              ?.toLowerCase()}`}
            showSearch={true}
            filterOption={(input, option) =>
              option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onFocus={toggleFocus}
            onBlur={toggleFocus}
          >
            {options.map((item, index) => {
              let codeString = isCountry && item.isO2.toLowerCase();

              return (
                <Option
                  key={`${index}-${item?.code}`}
                  value={item?.code}
                  label={I18n.get(item?.label)}
                  data-value={item?.label || item?.name}
                >
                  {isCountry && (
                    <img
                      src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                    />
                  )}
                  {isCountry && " "}
                  {I18n.get(item?.label) || I18n.get(item?.name)}
                </Option>
              );
            })}
          </Select>
          {error ? <S.FieldError>{error}</S.FieldError> : null}
        </S.Wrapper>
      </S.Skeleton>
    </>
  );
};
