import React, { Component } from "react";
import { I18n } from "aws-amplify";
import {
  Row,
  Col,
  Form,
  Spin,
  Input,
  Select,
  Radio,
  Button,
  Tooltip,
} from "antd";

import styled from "styled-components";

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`;

class SupplierBillingAdmin extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.action(values);
      }
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldValue,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;
    const { filters = {} } = this.props;

    if (!filters.clients) {
      return (
        <BoxSpin>
          <Spin />
        </BoxSpin>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col sm={4}>
            <Form.Item label={I18n.get("Cliente")}>
              {getFieldDecorator("clientId", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  <Option value="">{I18n.get("Todos")}</Option>
                  {filters.clients?.map((item, index) => {
                    return (
                      <Option
                        className="SelectMin"
                        value={item.code}
                        key={index}
                      >
                        {item.label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item label={I18n.get("SPA")}>
              {getFieldDecorator("posNumbers", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item label={I18n.get("Fornecedor / E-mail")}>
              {getFieldDecorator("supplier", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Status")}>
              {getFieldDecorator("invoiceMissing", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "true",
              })(
                <Radio.Group>
                  <Tooltip
                    title={I18n.get(
                      "Para buscar por todos, selecione um cliente."
                    )}
                  >
                    <Radio value="">
                      {I18n.get("Todos")}
                    </Radio>
                  </Tooltip>
                  <Radio value="true">{I18n.get("Em Aberto")}</Radio>
                  <Radio value="false">{I18n.get("Com Nota Fiscal")}</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>

          <Col sm={2}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={!filters}
                loading={this.props.task.busy}
              >
                {I18n.get("Buscar")}
              </Button>
            </Form.Item>
          </Col>

          <Col sm={1}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                type="button"
                shape="round"
                htmlType="submit"
                onClick={this.props.createAction}
                loading={this.props.task.busy}
              >
                {I18n.get("+ Novo")}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col sm={4}>
            <Form.Item label={I18n.get("SKU")}>
              {getFieldDecorator("projectSku", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(<Input />)}
            </Form.Item>
            {/* TODO: Add some Tooltip here (to inform about separations by ";"). */}
          </Col>

          <Col sm={8}>{/* EMPTY */}</Col>
        </Row>
      </Form>
    );
  }
}

const FormFilterSupplierBillingAdmin = Form.create({})(SupplierBillingAdmin);

export default FormFilterSupplierBillingAdmin;
