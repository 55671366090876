import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { I18n } from 'aws-amplify';
import { Row, Col, message, Skeleton } from 'antd';
import Loading from '../../../../../components/pages/loading';
import { StepsVertical } from '../../../../components/UI/StepsVertical';
import Main from '../../../../components/UI/Main';
import Content from '../../../../components/UI/Content';

import {
  toflowToStorage,
  toGetFlowStorage,
  toRemoveFlowStorage,
} from '../../utils/flowToStorage';

import * as S from './styles';

import logo from '../../../../../image/logoWOtext.svg';

const ERROR_GET_STEPS_STATUSBAR = 'An error has occurred in the status bar.';

const statusToName = (status) => {
  console.log("statusToName status", status);
  if (status === 1) {
    return "process";
  } else if (status === 2) {
    return "error";
  } else if (status === 3) {
    return "wait";
  } else if (status === 4) {
    return "finish";
  }
};

export const UnloggedWithStepbar = ({
  children,
  pageIndex,
  statusBarStepsClickable,
  statusChangeInfo,
  stepbarId,
  hookGetItems,
  urls,
  contextStepBar,
}) => {
  let location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  const [statusBarSteps, setStatusBarSteps] = useState([]);
  const [isLoadingSteps, setIsLoadingSteps] = useState(true);
  const [tokenInUrl, setTokenInUrl] = useState('');

  const whatIsStatus = ({ step }) => {
    if (step.status === 'error') {
      return 'error';
    } else if (step.status === 'finish') {
      return 'finish';
    } else {
      if (step.url === location.pathname) {
        return 'process';
      } else {
        return statusToName(step.status);
      }
    }
  };

  const loadStepsStatusBar = async ({ token }) => {
    setIsLoadingSteps(true);

    const response = await hookGetItems(token);

    if (response?.success) {
      const stepsUpdateds = response.data.statusBarRegistration.map((step) => ({
        ...step,
        status:
          `${urls[step.step]}/${token}` === location.pathname
            ? 'process'
            : 'wait',
        visited: false,
        url: `${urls[step.step]}/${token}`,
      }));
      setStatusBarSteps(stepsUpdateds);

      const toStorage = {
        stepbarId,
        steps: stepsUpdateds,
      };

      await toflowToStorage({
        key: '@BPOOL-registration-flow-steps',
        stepbar: toStorage,
      });
    } else {
      message.error(I18n.get(ERROR_GET_STEPS_STATUSBAR));
    }

    setIsLoadingSteps(false);
  };

  const hasStepsInApp = async ({ flow }) => {
    const stepsUpdateds = flow.steps.map((step) => ({
      ...step,
      // status: step?.statusDescription?.toLowerCase(),
      status: whatIsStatus({ step }),
    }));
    const toStorage = {
      stepbarId,
      steps: stepsUpdateds,
    };
    await toflowToStorage({
      key: '@BPOOL-registration-flow-steps',
      stepbar: toStorage,
    });
    setStatusBarSteps(stepsUpdateds);
    setIsLoadingSteps(false);
  };

  const prepareToken = async (url) => {
    const arrayOfStrings = url.split('/');
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    return tkn;
  };

  const init = async () => {
    const tokenUrl = await prepareToken(location?.pathname);

    if (tokenUrl) {
      setTokenInUrl(tokenUrl);

      const flow = await toGetFlowStorage({
        key: '@BPOOL-registration-flow-steps',
      });

      if (flow && flow.stepbarId === stepbarId) {
        await hasStepsInApp({ flow });
      }

      if (flow && flow.stepbarId !== stepbarId) {
        await toRemoveFlowStorage({ key: '@BPOOL-registration-flow-steps' });
        await loadStepsStatusBar({ token: tokenUrl });
      }

      if (!flow) {
        await loadStepsStatusBar({ token: tokenUrl });
      }
    }
  };

  useEffect(() => {
    if (stepbarId) {
      init();
    }
  }, [urls]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderContent>
          <span>
            <img
              src={logo}
              alt="Ollo"
              class="logo"
            />
          </span>
        </S.HeaderContent>
      </S.Header>

      <Content paddingTop={60}>
        <div className="container">
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: stepbarId ? 16 : 24, offset: 0 }}
            >
              {isLoadingSteps && (
                <Main bgColor="#fff" padding="30px">
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </Main>
              )}
              {!isLoadingSteps && <>{children}</>}
            </Col>
            {stepbarId ? (
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }}>
                <Main bgColor="#fff" padding="30px">
                  {isLoadingSteps && (
                    <>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </>
                  )}

                  {!isLoadingSteps && (
                    <StepsVertical
                      isLoading={isLoadingSteps}
                      steps={statusBarSteps}
                      clickable={statusBarStepsClickable}
                      page={pageIndex}
                      urls={urls}
                      changedInfos={statusChangeInfo}
                      contextStepBar={contextStepBar}
                      token={tokenInUrl}
                    />
                  )}
                </Main>
              </Col>
            ) : null}
          </Row>
        </div>
      </Content>

      <S.Footer>
        <S.ContentFooter>
          <div className="container">
            <span>{`${new Date().getFullYear()} © Ollo`}</span>
          </div>
        </S.ContentFooter>
      </S.Footer>
    </S.Wrapper>
  );
};
