import React, { PureComponent, Fragment } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

import {
    Table,
    Icon,
    Tag
} from 'antd';

import { ItemBillingInstallment } from '../../../modules/Admin/components/itemBillingInstallment';

import FormatCurrency from '../../ui/format-currency';

const Btns = styled.p`
width: 50px;
displa: block;
    a {
        margin-left: 10px;
    }
`

const FooterT = styled.div`
    font-weight: bold;
    text-align: right;
`

class BillingTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getSector = (sectorId) => {
        const items = this.props.sectorsFlat && this.props.sectorsFlat.filter((item) => item.code == sectorId);


        if (items && items.length) {
            return items[0].label;
        }
        else {
            return {};
        }

    }

    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectStatusesMap) return '';

        return this.props.bp.clientProjectStatusesMap[value] && this.props.bp.clientProjectStatusesMap[value].label;
    }


    render() {
        const columns = [
            {
                key: 'exp',
                title: '',
                width: 50,
            },
            {
                title: I18n.get("Tipo"),
                dataIndex: 'type',
                key: 'type',
                width: 120,
                render: (type, record) => (<Tag>{type}</Tag>)
            },
            {
                title: I18n.get("Nome"),
                dataIndex: 'name',
                key: 'name',
                render: (name, record) => {

                    return (<NavLink to={`/projetos/${record.projectId}/resumo`}>{name || `S/N (${record.name} - ${record.email})`}</NavLink>);
                }

            },
            {
                title: I18n.get("Status"),
                dataIndex: 'status',
                key: 'status',
                width: 120
            },
            {
                title: I18n.get("Cliente"),
                dataIndex: 'clientName',
                key: 'clientName',
                render: (clientName, record) => (
                    <div>
                        <div>{clientName}</div>
                    </div>
                )
            },
            {
                title: I18n.get("Valor"),
                dataIndex: 'billingTotal',
                key: 'billingTotal',
                render: (billingTotal, record) => {

                    return (

                        <Fragment>
                            <FormatCurrency amount={billingTotal} currency={record.billingCurrency} />
                        </Fragment>
                    )
                }
            },
            {
                title: I18n.get("Data"),
                dataIndex: 'itemDate',
                key: 'itemDate',
                width: 120
            },
            {
                title: I18n.get("#SPA"),
                dataIndex: 'billings',
                key: 'billings',
                render: (billings, record) => {

                    return (billings && billings.map((billing, index) => {

                        var style = {
                            marginTop: (index > 0 ? '0.5rem' : ''),
                            display: 'flex',
                        };

                        return (<>
                            <div style={style}>
                                <div style={{ flex: '1 1 0%', paddingTop: '0.25rem', textAlign: 'right' }}>
                                    <FormatCurrency amount={billing.total} currency={record.billingCurrency} />
                                    <div style={{ paddingTop: '0.5rem', fontSize: '8pt', color: '#999999' }}>{I18n.get("Liberado")} {billing.balancePercFmt}</div>
                                </div>
                                <div style={{ flex: '1 1 0%', paddingLeft: '1rem' }}>

                                    {record.extraId && <>

                                        <NavLink className="button is-small is-black is-outlined is-fullwidth" to={`/parceiro/faturamento/${record.projectId}/extra/${record.extraId}/po/${billing.id}`}>{I18n.get("#SPA")} {billing.projectPO}</NavLink>

                                    </>}

                                    {!record.extraId && <>

                                        <NavLink className="button is-small is-black is-outlined is-fullwidth" to={`/parceiro/faturamento/${record.projectId}/po/${billing.id}`}>{I18n.get("#SPA")} {billing.projectPO}</NavLink>

                                    </>}

                                </div>
                            </div>
                            <div>

                                <div style={{ paddingTop: '0.5rem', fontSize: '9pt', color: '#999999' }}>

                                        <div style={{ padding: '0' }}>

                                            {billing.installments && billing.installments.map((installment, installmentIdx) => {

                                                return (<ItemBillingInstallment installment={installment} index={installmentIdx} billingCurrency={record.billingCurrency} />);

                                            })}

                                        </div>

                                </div>

                            </div>
                        </>);
                    })
                    );
                }
            }
        ]

        let dataTable = this.props.data && this.props.data.map((item, index) => {

            return {
                ...item
            };
        });

        return (
            <Table
                pagination={this.props.pagination ? true : false}
                columns={columns}
                dataSource={dataTable}
                locale={{ emptyText: I18n.get('Sem informação') }}
            // scroll={{ x: 1200 }}
            />
        );
    }
}

export default BillingTable;
