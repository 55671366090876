import React, { Component } from 'react';
import { I18n } from 'aws-amplify';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";

import '../../App.css';
import '../../Site.css';

import logo from '../../image/logoWOtext.svg';
import logoDesktop from '../../image/logo.svg';
import logoMobile from '../../image/logo-mob.png';
import Footer from './footer';

import { AUTH_API } from '../../actions/auth/actions';

const mapStateToProps = state => {
    return {
        user: state.user,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(AUTH_API.logout());
        }
    };
};

class LandingPageClean extends Component {

    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        this.props.logout();
    }

    render() {


        return (<div>
            <section>
                <header className="is-inline-block">
                    <div className="top-header">
                        <div className="container">
                            <div className="row">
                                <div className="logo-top-left">

                                    <a className="logo" >
                                        <img class="logo-lg" src={logoDesktop} alt="Ollo" style={{ marginBottom: "9px" }} />
                                        <img className="logo-sm" src={logoMobile} width="42" alt="Ollo" />
                                        {/* Atualizando */}
                                    </a>

                                </div>
                                <div className="top-right">
                                    <ul className="nav-link menuRight">


                                    </ul>

                                    <span className="menu-open" >&#9776; </span>
                                    <nav className="nav">

                                        <div id="myNav" className="overlay">

                                            <div className="overlay-content">

                                            </div>
                                        </div>

                                        <ul id="menu-bg">

                                        </ul>

                                    </nav>

                                </div>

                            </div>
                        </div>
                    </div>
                </header>
                {this.props.children}
            </section>

            <Footer {...this.props} />
        </div>

        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPageClean);
