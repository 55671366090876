import React from "react";
import { I18n } from "aws-amplify";
import { StatusInvoice } from "../../../../../components/StatusInvoice";
import * as S from "../styles";

export const InfosDrawer = ({ data, isLoading }) => {
  return (
    <div>
      <S.TitleDrawer>
        {I18n.get("Acompanhe por aqui todos os passos do seu pagamento")}
      </S.TitleDrawer>
      <S.Infos>
        <div>
          <label>{I18n.get("Ollo Pay")}</label>
          <p>{data?.bPayName}</p>
        </div>
        <div>
          <label>{I18n.get("Ollo Pay SKU")}</label>
          <p>{data?.sku}</p>
        </div>
        <div>
          <label>{I18n.get("Fornecedor")}</label>
          <p>{data?.name}</p>
        </div>
        <div>
          <label>{I18n.get("Valor")}</label>
          <p>{data?.totalFmt}</p>
        </div>
      </S.Infos>
      <StatusInvoice data={data?.status} isLoading={isLoading} />
    </div>
  );
};
