import React, { useState, useEffect } from "react";

import { I18n } from "aws-amplify";

import NumberFormat from "react-number-format";

import { Icon, Tooltip, Input, Button, Row, Col, Checkbox } from "antd";

import FormatCurrency from "../ui/format-currency";

// TODO: Ao ser refatorado a requisição o "updateBiTributation" deve ser removido estas referências.
import axios from "axios";
import { notification } from "antd";
import { Auth } from "aws-amplify";
import Config from "../../config";

const { Search } = Input;

const FormPo = ({
  billing,
  onDelete,
  currency = {},
  projectId,
  updatePo,
  extraId = null,
  handleNF,
  isAdmin,
  sendCommission,
  pricePartnerOtherTaxFmt,
  projectType,
}) => {
  const {
    projectPO,
    billingDate,
    total,
    code,
    bpCommissionAmount,
    availableAmount,
    hasBiTributation,
  } = billing;

  onDelete = false; //temporario, arriscado deixar pro cliente excluir

  const [EditCode, ViewCode] = useState(false);
  const [editViewComission, setEditComission] = useState(false);
  const [ValueCode, SetValueCode] = useState("");
  const [NfsBpValue, SetNf] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingComission, setLoadingComission] = useState(false);
  const [priceBPoolComission, setPriceBPoolComission] =
    useState(bpCommissionAmount);
  const [biTributation, setBiTributation] = useState(false);
  const [loadingBiTributation, setLoadingBiTributation] = useState(true);

  useEffect(() => {
    if (billing?.id) {
      setTimeout(() => {
        SetNf(billing?.bPoolNFSe);
        setBiTributation(hasBiTributation);
      }, 1000);
      setTimeout(() => {
        setLoadingBiTributation(false);
      }, 1010);
    }
  }, []);

  const BRL = ({ amount, symbol, tSeparator, dSeparator }) => (
    <NumberFormat
      value={amount ?? 0}
      displayType={"text"}
      thousandSeparator={tSeparator ?? "."}
      decimalSeparator={dSeparator ?? ","}
      prefix={`${symbol ?? "R$"} `}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );

  const sendNfs = (sendNfs) => {
    setLoading(true);
    const data = {
      bPoolNFSe: NfsBpValue,
      billingId: sendNfs.id,
    };

    handleNF(data).then((resp) => {
      if (resp) setLoading(false);
    });
  };

  const cleanValue = (value, symbolOnly) => {
    if (typeof value !== "string") {
      return value;
    }
    const cleanNumber = value.replace(symbolOnly, "");
    return cleanNumber;
  };

  const editComission = async (symbolOnly) => {
    setLoadingComission(true);
    const value = await cleanValue(priceBPoolComission.toString(), symbolOnly);

    const valueClean = value.replace(/[.,]/g, "");

    const numericValue = parseFloat(valueClean);

    if (!isNaN(numericValue)) {
      const valueFmt = (numericValue / 100).toFixed(2);

      const numericValueFmt = parseFloat(valueFmt);

      sendCommission(numericValueFmt).then((resp) => {
        if (resp.success) {
          setLoadingComission(false);
          setEditComission(false);
        } else {
          setLoadingComission(false);
        }
      });
    } else {
      setLoadingComission(false);
    }
  };

  const updateCode = (infos) => {
    let data = {
      projectId: projectId,
      id: infos.id,
      code: ValueCode,
    };

    if (extraId) {
      data.extraId = extraId;
      data.billingId = infos.id;
    }

    updatePo(data).then((resp) => {
      if (resp) {
        ViewCode(false);
      }
    });
  };

  const printMoney = (value, currency) => {
    if (currency?.code == "BRL") {
      return (
        <BRL
          amount={value}
          symbol={currency.symbolOnly}
          tSeparator={currency.thousandSeparator}
          dSeparator={currency.decimalSeparator}
        />
      );
    }

    if (currency?.code == "USD") {
      return (
        <BRL amount={value} symbol={"USD"} tSeparator={","} dSeparator={"."} />
      );
    }

    if (currency?.code == "EUR") {
      return (
        <BRL amount={value} symbol={"€"} tSeparator={","} dSeparator={"."} />
      );
    }

    if (currency?.code == "ARS") {
      return (
        <BRL amount={value} symbol={"ARS"} tSeparator={","} dSeparator={"."} />
      );
    }

    if (currency?.code == "COP") {
      return (
        <BRL amount={value} symbol={"COP"} tSeparator={","} dSeparator={"."} />
      );
    }
  };

  const formatarNumero = (numero) => {
    const teste = numero.toString();

    const valueClean = teste && teste.replace(/[.,]/g, "");

    const valueFmt = (valueClean / 100).toFixed(2);

    return valueFmt;
  };

  // TODO: Refatorar para tirar isso aqui, morando esta lógica de requisição para o arquivo adequado.
  const updateBiTributation = (e) => {
    setBiTributation(e.target.checked);
    Auth.currentSession().then((session) => {
      setLoading(true);

      const token = session && session.idToken.jwtToken;

      const config = { headers: { Authorization: "Bearer " + token } };

      const routeUrl = `${Config.API_BASE_URL}/AdminNotification/set-billing-bi-tributation`;

      axios.defaults.headers.common["Content-Type"] =
        "application/json;charset=UTF-8";

      const data = {
        projectId: projectId,
        billingId: billing?.id ?? "",
        billingHasBiTributation: e.target.checked,
      };

      //alert(JSON.stringify(data));

      axios
        .put(routeUrl, data, config)
        .then((response) => {
          setLoading(false);

          notification.open({
            message: I18n.get(
              `Nota Fiscal informada como ${
                e.target.checked === true ? "" : "não-"
              }Bi-Tributada atualizada com sucesso.`
            ),
            description: "",
            icon: <Icon type="check" style={{ color: "#000" }} />,
          });

          // setTimeout(function () {
          //   // Reload na página.
          //   window.location.reload();
          // }, 3000);
        })
        .catch((error) => {
          setLoading(false);

          notification.open({
            message: I18n.get("Ops! Algo deu errado."),
            description: I18n.get("Tente novamente mais tarde."),
            icon: <Icon type="check" style={{ color: "#000" }} />,
          });
        });
    });
  };

  return (
    <div
      className="card"
      style={{
        backgroundColor: "#fafafa",
        borderBottom: "solid 1px #cccccc",
        boxShadow: "none",
      }}
    >
      <div className="card-content">
        {onDelete && (
          <a
            onClick={onDelete}
            title={I18n.get("Excluir #SPA")}
            className="delete is-pulled-right"
          ></a>
        )}

        <div className="columns">
          <div className="column is-3">
            <p style={{ fontSize: "80%", fontWeight: "bold" }}>
              {I18n.get("#SPA")}
            </p>
            <p style={{ marginTop: "0.75rem" }}>
              {billing.projectPO && (
                <span style={{ fontSize: "20px" }}>{projectPO}</span>
              )}
              {billing.authorize && (
                <span style={{ fontSize: "90%" }}>
                  {I18n.get("Pagamento autorizado para")} {billingDate}
                </span>
              )}
            </p>

            {isAdmin && (
              <>
                <p></p>
                <p
                  style={{
                    marginTop: "1rem",
                    fontSize: "80%",
                    fontWeight: "bold",
                  }}
                >
                  {I18n.get("BPool NFSe")}
                </p>
                <p>
                  <Input
                    style={{
                      width: "150px",
                      float: "left",
                      marginRight: "10px",
                    }}
                    value={NfsBpValue}
                    onChange={(e) => SetNf(e.target.value)}
                  />{" "}
                  <Button
                    className="button bt-bpool black"
                    onClick={() => sendNfs(billing)}
                    loading={loading}
                  >
                    {I18n.get("Atualizar")}
                  </Button>
                </p>
                {loadingBiTributation ? (
                  <div style={{ fontSize: 14 }}>
                    <Icon type="loading" style={{ marginRight: 5 }} />
                    {I18n.get("Carregando...")}
                  </div>
                ) : (
                  <Checkbox
                    defaultChecked={biTributation}
                    value={biTributation}
                    onChange={updateBiTributation}
                  >
                    {I18n.get("SPA Bitributada")}
                  </Checkbox>
                )}
              </>
            )}
          </div>

          <div className="column is-3">
            <p style={{ fontSize: "80%", fontWeight: "bold" }}>
              {I18n.get("Valor")}
            </p>

            <p style={{ marginTop: "0.75rem", fontSize: "110%" }}>
              <FormatCurrency amount={total} currency={currency} />
              <br />

              <span style={{ fontSize: "8pt", color: "#666666" }}>
                {I18n.get("Fee BP")}:{" "}
                {!editViewComission && isAdmin && (
                  <Tooltip
                    placement="top"
                    title={I18n.get("Editar BPool Comission")}
                  >
                    <a onClick={() => setEditComission(true)}>
                      <Icon type="edit" />
                    </a>
                  </Tooltip>
                )}
                <div class="field" style={{ width: 200 }}>
                  <NumberFormat
                    value={priceBPoolComission}
                    thousandSeparator={currency?.thousandSeparator || "."}
                    decimalSeparator={currency?.decimalSeparator || "."}
                    prefix={`${
                      currency?.symbolOnly ? currency?.symbolOnly : "R$"
                    }`}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    onChange={(e) => setPriceBPoolComission(e.target.value)}
                    className="input has-text-right"
                    disabled={!editViewComission}
                  />

                  {editViewComission && (
                    <div
                      className="card-content break-word"
                      style={{ padding: 0 }}
                    >
                      <p className="btn-submit" style={{ marginTop: "7px" }}>
                        <Button
                          loading={loadingComission}
                          onClick={() => editComission(currency?.symbolOnly)}
                          disabled={priceBPoolComission == bpCommissionAmount}
                          className="button bt-bpool black pull-right"
                        >
                          {I18n.get("Alterar")}
                        </Button>
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  <></>
                </div>
              </span>
            </p>

            {projectType === 2 && billing?.otherTaxTotal > 0 && (
              <p>
                + {I18n.get("Outras Taxas")}:{" "}
                {/* <strong>{billing?.otherTaxTotal}</strong> */}
                <strong>
                  {" "}
                  <FormatCurrency
                    amount={billing?.otherTaxTotal}
                    currency={currency}
                  />
                </strong>
              </p>
            )}

            {projectType !== 2 && pricePartnerOtherTaxFmt && (
              <p>
                + {I18n.get("Outras Taxas")}:{" "}
                <strong>{pricePartnerOtherTaxFmt}</strong>
              </p>
            )}

            <p
              style={{ marginTop: "1rem", fontSize: "80%", fontWeight: "bold" }}
            >
              {I18n.get("Código")}
            </p>
            <p style={{ marginTop: "0.75rem" }}>
              {code != null ? code : "-"}{" "}
              {!EditCode && (
                <Tooltip placement="top" title={I18n.get("Editar código")}>
                  <a onClick={() => ViewCode(true)}>
                    <Icon type="edit" />
                  </a>
                </Tooltip>
              )}
              {EditCode && (
                <Tooltip placement="top" title={I18n.get("Editar código")}>
                  <a onClick={() => ViewCode(false)}>
                    <Icon type="close-circle" />
                  </a>
                </Tooltip>
              )}
            </p>

            {EditCode && (
              <Row gutter={6}>
                <Col sm={18}>
                  <Input
                    width={100}
                    onChange={(value) => SetValueCode(value.target.value)}
                  />
                </Col>
                <Col sm={6}>
                  <Button
                    onClick={() => updateCode(billing)}
                    className="button bt-bpool black"
                  >
                    {I18n.get("Salvar")}
                  </Button>
                </Col>
              </Row>
            )}
          </div>

          <div className="column is-3">
            <p style={{ fontSize: "80%", fontWeight: "bold" }}>
              {I18n.get("Emissão")}
            </p>

            <p
              style={{
                marginTop: "0.75rem",
                lineHeight: "1.3rem",
                fontSize: "75%",
              }}
            >
              {billing.registrationName} <br />
              {billing.registrationId}
            </p>

            <p
              style={{ marginTop: "1rem", fontSize: "80%", fontWeight: "bold" }}
            >
              {I18n.get("Endereço")}
            </p>

            <p
              style={{
                marginTop: "0.75rem",
                lineHeight: "1.3rem",
                fontSize: "75%",
              }}
            >
              {billing.addressLine1 && billing.city && billing.state && (
                <>
                  {billing.addressLine1}{" "}
                  {billing.addressLine2 && (
                    <> &middot; {billing.addressLine2} </>
                  )}{" "}
                  <br />
                  {billing.city} - {billing.state} &middot; {billing.postcode}{" "}
                  <br />
                </>
              )}
            </p>
          </div>

          <div className="column is-3">
            <p style={{ fontSize: "80%", fontWeight: "bold" }}>
              {I18n.get("Anexos")}
            </p>
            <br />
            <br />
            {billing.files &&
              billing.files.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="columns itemFile grey3"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <div className="column upload-item">
                      <a
                        href={item.url}
                        style={{ fontSize: "10px" }}
                        target="_blank"
                      >
                        <Icon type="file-text" theme="filled" /> &nbsp;{" "}
                        {item.name}
                      </a>
                    </div>
                  </div>
                );
              })}

            {billing.files && !billing.files.length && (
              <span
                className="has-text-gray"
                style={{ fontSize: "80%", color: "#cccccc" }}
              >
                {I18n.get("Nenhum arquivo.")}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPo;
