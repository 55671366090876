import React, { Component } from "react";
import { I18n } from "aws-amplify";
import { TitleMiddleLine } from "../../components/Titles";
import { CardBorder } from "../../components/Cards";

import FormSkeleton from "../../components/FormSkeleton";
import { useRegistrationFlowFull } from "../../../v2/hooks/useRegistrationFlow/useRegistrationFlowFull.hook";

import { Row, Col, Tooltip, Select, Icon, Input, Form } from "antd";

const { Option } = Select;

const { getTimezonesClient } = useRegistrationFlowFull();

class FormResponsibleClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialUrls: [],
      countrySeleted: "",
      fieldBlur: "",
      timezones: [],
    };
  }

  removeUrl = (str, url) => {
    const arr = this.state.socialUrls.filter((e) => e !== url);

    this.setState({
      socialUrls: arr,
    });

    setTimeout(() => {
      this.handleSubmit();
    }, 10);
  };

  addUrl = (arrStr) => {
    const valueInputSocial = this.props.form.getFieldValue("socialURLs");
    if (valueInputSocial) {
      this.setState({
        socialUrls: [...this.state.socialUrls, valueInputSocial],
      });

      this.props.form.resetFields("socialURLs");
    }

    setTimeout(() => {
      this.handleSubmit();
    }, 500);
  };

  sendDataApi = () => {
    const fieldsForm = this.props.form.getFieldsValue();

    const dataForm = {
      ...fieldsForm,
      socialURLs: this.state.socialUrls,
      country: this.state.countrySeleted || this.props.client.country,
      profilePicture:
        this.state.profilePicture || this.props.client.profilePicture,
      portfolioURLs: this.state.portfolioUrls,
      clientId: this.props.clientId,
    };

    return dataForm;
  };

  handleSubmit = (field) => {
    if (field) {
      this.setState({
        fieldBlur: field,
      });
    }

    this.props.action(this.sendDataApi());
  };

  handleSubmitSave = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.action(this.sendDataApi());
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps) return;
    if (!prevState) return;

    if (
      this.props.client &&
      this.props.client.socialURLs != prevProps.client.socialURLs
    ) {
      this.setState({
        socialUrls: this.props.client.socialURLs,
        portfolioUrls: this.props.client.portfolioURLs,
        profilePicture: this.props.client.profilePicture,
      });
    }

    if (
      this.props.errors &&
      this.props.errors != prevProps.errors &&
      Object.keys(this.props.errors).length > 0 &&
      this.props.errors[this.state.fieldBlur]
    ) {
      this.props.form.setFields({
        [this.state.fieldBlur]: {
          errors: [
            new Error(this.props.errors[this.state.fieldBlur].errorMessage),
          ],
        },
      });
    }
  }

  handleCountry = (e) => {
    if (e) {
      let obj = this.props.countryCodes.find((i) => i.code == e);

      let country = {
        ...obj
      };

      this.setState({
        prefix: obj.prefix,
        countrySeleted: country,
      });
    }

    // this.props.form.resetFields('companyCity');

    // this.props.form.setFieldsValue({
    //     companyCity: '',
    //     companyState: '',
    // });

    setTimeout(() => {
      this.handleSubmit("country");
    }, 10);
  };

  getPrefix = (code) => {
    let prefixCode =
      this.props.countryCodes &&
      this.props.countryCodes.find((i) => i.code == code);

    if (prefixCode) {
      return prefixCode.prefix || "";
    } else {
      return "";
    }
  };

  componentDidMount() {
    getTimezonesClient().then((res) => {
      this.setState({
        timezones: res.data,
      });
    });
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    if (!this.props.client) return <FormSkeleton />;

    const { client } = this.props;

    return (
      <Form onSubmit={this.handleSubmitSave}>
        <CardBorder>
          <Row>
            <Col sm={24}>
              <TitleMiddleLine>
                {I18n.get("Informações Gerais")}
              </TitleMiddleLine>
            </Col>
            <Col sm={12}>
              <Form.Item
                label={
                  <span>
                    {I18n.get("Nome completo do responsável")}
                    <Tooltip
                      title={I18n.get(
                        "Responsável pelo cadastro. Nos passos a frente você poderá adicionar outros usuários na plataforma."
                      )}
                    >
                      <Icon
                        className="iconLabel"
                        type="question-circle"
                        theme="filled"
                      />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("name", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: client.name ? client.name : null,
                })(<Input onBlur={() => this.handleSubmit("name")} />)}
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label={I18n.get("Como você quer ser chamado?")}>
                {getFieldDecorator("nameAlias", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: client.nameAlias ? client.nameAlias : null,
                })(<Input onBlur={() => this.handleSubmit("nameAlias")} />)}
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label={I18n.get("E-mail")}>
                {getFieldDecorator("email", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: client.email ? client.email : null,
                })(
                  <Input
                    onBlur={() => this.handleSubmit("email")}
                    disabled={true}
                  />
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item label={I18n.get("País")}>
                {getFieldDecorator("country", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    client && client.country && client.country.code
                      ? client.country.code
                      : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    onSelect={(e) => this.handleCountry(e)}
                    onBlur={(e) => this.handleCountry(e)}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //     option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.props.countryCodes &&
                      this.props.countryCodes.map((item) => {
                        let codeString = item.isO2.toLowerCase();

                        return (
                          <Option
                            value={item.code}
                            key={item.code}
                            prefix={item.prefix}
                          >
                            <img
                              src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
                            />{" "}
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col sm={8}>
              {getFieldValue("country") != "BRA" && (
                <Form.Item label={I18n.get("Celular")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue: client.phoneNumber
                      ? client.phoneNumber
                      : null,
                  })(
                    <Input
                      // addonBefore={client.company && client.company.country && this.getPrefix(client.company.country.prefix)}
                      addonBefore={
                        client && client.country && client.country.prefix
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                  )}
                </Form.Item>
              )}
              {getFieldValue("country") == "BRA" && (
                <Form.Item label={I18n.get("Celular")}>
                  {getFieldDecorator("phoneNumber", {
                    rules: [{ required: false, message: "Campo obrigatório!" }],
                    initialValue: client.phoneNumber
                      ? client.phoneNumber
                      : null,
                  })(
                    <Input
                      // addonBefore={client.company && client.company.country && this.getPrefix(client.company.country.prefix)}
                      addonBefore={
                        client && client.country && client.country.prefix
                      }
                      onBlur={() => this.handleSubmit("phoneNumber")}
                    />
                    // <MaskedInput
                    //     addonBefore={client && client.country && client.country.prefix}
                    //     mask="(11) 11111-1111" size="14"
                    //     onBlur={() => this.handleSubmit('phoneNumber')}
                    // />
                  )}
                </Form.Item>
              )}
            </Col>
            <Col sm={10}>
              <Form.Item label={I18n.get("Linkedin Pessoal")}>
                {getFieldDecorator("linkedin", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue: client.linkedin ? client.linkedin : null,
                })(<Input onBlur={() => this.handleSubmit("linkedin")} />)}
              </Form.Item>
            </Col>
            <Col sm={14}>
              <Form.Item label={I18n.get("Fuso horário")}>
                {getFieldDecorator("timeZone", {
                  rules: [{ required: false, message: "Campo obrigatório!" }],
                  initialValue:
                    client && client.timeZone ? client.timeZone : "",
                })(
                  <Select
                    style={{ width: "100%" }}
                    showSearch={true}
                    onSelect={() => this.handleSubmit("timeZone")}
                    filterOption={(input, option) =>
                      option.props.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state &&
                      this.state.timezones.length > 0 &&
                      this.state.timezones.map((item) => {
                        return (
                          <Option
                            value={item.value}
                            label={item?.text}
                            key={item.value}
                          >
                            {item?.text} - {item.value}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
              {this.props.edit && (
                <p style={{ fontSize: 12, color: "#7c7c7c", marginTop: 0 }}>
                  {I18n.get(
                    "Ao alterar o fuso horário, será necessário refazer o login"
                  )}
                </p>
              )}
            </Col>
          </Row>
        </CardBorder>

        <Row className="RowSection">
          <Col sm={8}></Col>
        </Row>
      </Form>
    );
  }
}

const MainFormResponsibleClient = Form.create({})(FormResponsibleClient);

export default MainFormResponsibleClient;
