import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { I18n } from "aws-amplify";
import { DatePicker, Radio } from "antd";
import { Input } from "../../../../../components/UI/Input";
import { SelectOne } from "../../../../../components/UI/SelectOne";

import { createQueryString, parseQueryString } from "./utils/resolvers";
import * as S from "./styles";

export const Filters = ({ data, isLoading }) => {
  let history = useHistory();
  let location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValueDebounced] = useDebounce(searchTerm, 1000);
  const [listStatus, setListStatus] = useState([]);
  const [filters, setFilters] = useState({});
  const [bpaySearchTerm, setBpaySearchTerm] = useState("");
  const [bpaySearchValueDebounced] = useDebounce(bpaySearchTerm, 1000);

  const handleChangeStatus = (status) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (status === 0) {
      delete objQueries.status;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, status });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearchSku = (sku) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!sku) {
      delete objQueries.sku;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, sku });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const handleSearchBpayName = (bpayName) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!bpayName) {
      delete objQueries.bpayName;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, bpayName });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  useEffect(() => {
    handleSearchSku(searchTerm);
  }, [searchValueDebounced]);

  useEffect(() => {
    handleSearchBpayName(bpaySearchTerm);
  }, [bpaySearchValueDebounced]);

  useEffect(() => {
    const objQueries = parseQueryString(location?.search);

    if (objQueries?.bpayName) {
      setBpaySearchTerm(objQueries?.bpayName);
    }

    if (objQueries?.sku) {
      setSearchTerm(objQueries?.sku);
    }

    setFilters(objQueries);
  }, [location]);

  useEffect(() => {
    const list = data?.status?.map((item) => ({
      code: Number(item?.code),
      label: item?.label,
    }));
    setListStatus(
      list?.length ? [{ code: 0, label: I18n.get("Todos") }, ...list] : []
    );
  }, [data]);

  return (
    <S.Wrapper>
      <div style={{}}>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 1,
            className: "skeleton-input",
            width: "100%",
          }}
        >
          <SelectOne
            placeholder={I18n.get("Status")}
            options={listStatus}
            value={filters?.status ? Number(filters?.status) : 0}
            onChange={(value) => handleChangeStatus(value)}
            error=""
          />
        </S.Skeleton>
      </div>
      <div style={{}}>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 1,
            className: "skeleton-input",
            width: "100%",
          }}
        >
          <Input
            placeholder={I18n.get("Nome do Ollo Pay")}
            label
            value={bpaySearchTerm}
            onChange={(value) => setBpaySearchTerm(value)}
            searchInput
          />
        </S.Skeleton>
      </div>
      <div style={{}}>
        <S.Skeleton
          loading={isLoading}
          active
          title={false}
          paragraph={{
            rows: 1,
            className: "skeleton-input",
            width: "100%",
          }}
        >
          <Input
            placeholder={I18n.get("Sku")}
            label
            value={searchTerm}
            onChange={(value) => setSearchTerm(value)}
            searchInput
          />
        </S.Skeleton>
      </div>
    </S.Wrapper>
  );
};
