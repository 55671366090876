import React from "react";
import { I18n } from "aws-amplify";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import icoAgenda from "../../image/ico-agenda.svg";
import icoContract from "../../image/ico-contract.svg";
import icoDash from "../../image/ico-dash.svg";
import icoExit from "../../image/ico-exit.svg";
import icoFaq from "../../image/ico-faq.svg";
import icoHome from "../../image/ico-home.svg";
import icoProfile from "../../image/ico-profile-menu.svg";
import icoProjects from "../../image/ico-projects.svg";
import icoRepoFiles from "../../image/ico-repo-files.svg";
import icoClose from "../../image/ico-close.svg";
import icoMenu from "../../image/ico-menu-white.svg";
import bLogo from "../../image/b-logo.svg";
import icoBpay from "../../image/ico-bpay.svg";

import { AUTH_API } from "../../actions/auth/actions";
import { getProfileType } from "../../v2/utils/getProfileType";

import Config from "../../config";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AUTH_API.logout());
    },
  };
};

class TopMenu extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isActive: null,
      typeUser: getProfileType(),
    };

    this.activeMenu = this.activeMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.props.get_knowledge("Partner");
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isActive: false,
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logout();
  }

  activeMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: true,
    });
  }

  deactivateMenu(event) {
    event.stopPropagation();
    this.setState({
      isActive: false,
    });
  }

  whatLinkProfileMenu() {
    const typeUser = this.state?.typeUser;
    let linkProfile = "/partner/edit-profile/company-profile";

    if (typeUser === "Leader") {
      linkProfile = "/leader/edit-profile/about-you";
    }

    if (typeUser === "PartnerUser") {
      linkProfile = "/partner/edit-profile/user-profile";
    }
    return linkProfile;
  }

  render() {
    //PARTNER

    // const name = (this.props.auth
    //     && this.props.auth.name) || this.props.auth.email;

    // var firstName = "";

    // if (name.indexOf("@") > -1)
    //     firstName = name.split('@')[0].toUpperCase();
    // else
    //     firstName = name.split(' ')[0].toUpperCase();

    const { moduloFaturamento } = Config;

    const urlDash = "/parceiro/dashboard";

    //Inicialmente somente pra Parceiros no Brasil
    let locale = this.props.auth.locale;
    let isPartner = this.props.auth.is_partner;

    const isPartnerBpay = "";

    const containsPartnerAndSupplier = this.props.auth?.profileGroups.includes('PartnerAndSupplier');


    let shouldShowFaqs =
      isPartner === true && (locale === "pt" || locale === "es");

    let urlWiki =
      this.props?.wikis?.knowledgeBase &&
      this.props?.wikis?.knowledgeBase.length > 0
        ? this.props?.wikis?.knowledgeBase[0]?.name
        : "all";





    return (
      <div
        ref={this.setWrapperRef}
        onClick={(event) => this.activeMenu(event)}
        className={`navbar-item has-dropdown ${
          this.state.isActive ? "is-active" : ""
        }`}
      >
        <a className="navbar-link has-text-white">
          <img src={icoMenu} alt="icone menu" />
        </a>

        {/* <div className="navbar-dropdown">
                    <div className="columns">
                        <div className="column is-6 is-mobile navbar-dropdown-left">{I18n.get("Olá,")} {firstName}!
                        </div>
                        <div className="column is-6 is-mobile navbar-dropdown-right">

                            <NavLink to={urlDash} className="navbar-item" activeClassName="is-active">{I18n.get("Dashboard")}</NavLink>
                            <NavLink to="/parceiro/central-de-pendencias" className="navbar-item" activeClassName="is-active">{I18n.get("Pendências")}</NavLink>


                            <NavLink to="/parceiro/contratos" className="navbar-item" activeClassName="is-active">{I18n.get("Contratos")}</NavLink>
                            <NavLink to="/parceiro/projetos" className="navbar-item" activeClassName="is-active">{I18n.get("Projetos")}</NavLink>
                            <NavLink to="/parceiro/extras" className="navbar-item" activeClassName="is-active">{I18n.get("Extras")}</NavLink>
                            <NavLink to="/parceiro/responsavel" className="navbar-item" activeClassName="is-active">{I18n.get("Cadastro")}</NavLink>
                            <NavLink to="/parceiro/preview" className="navbar-item" activeClassName="is-active">{I18n.get("Preview")}</NavLink>

                            {Config.moduloSuggestProject &&
                                <>
                                    {this.props.partner && this.props.partner.statistics && this.props.partner.statistics.clientWorkedWith.length > 0 &&
                                        <NavLink to="/parceiro/criar-projeto" className="navbar-item" activeClassName="is-active">{I18n.get("Criar Projeto")}</NavLink>
                                    }
                                </>
                            } */}

        {/* {Config.moduloSuggestAsset &&
                                <NavLink to="/parceiro/assets/criar" className="navbar-item" activeClassName="is-active">{I18n.get("Criar Asset")}</NavLink>
                            } */}

        {/* {moduloFaturamento && <NavLink to="/parceiro/faturamento" className="navbar-item" activeClassName="is-active">{I18n.get("Faturamento")}</NavLink>}

                            {shouldShowFaqs &&
                                <NavLink disabled={(this.props?.wikis?.knowledgeBase && this.props?.wikis?.knowledgeBase.length == 0)} to={`/parceiro/wikis/${urlWiki}`} className="navbar-item" activeClassName="is-active">{I18n.get("Processos")}</NavLink>
                            }

                            <a onClick={this.onLogoutClick.bind(this)} className="navbar-item navbar-last-item">{I18n.get("Sair")}</a>

                        </div>
                    </div>
                </div> */}

        <div className="navbar-menu-left">
          <div className="menu-left-header">
            <a href="/">
              <img src={bLogo} />
            </a>
            <img
              src={icoClose}
              onClick={(event) => this.deactivateMenu(event)}
            />
          </div>
          <div>
            <NavLink to="/" className="navbar-item" activeClassName="is-active">
              <img src={icoHome} />
              {I18n.get("Home")}
            </NavLink>
            {/* <NavLink to="/parceiro/news" className="navbar-item" activeClassName="is-active"><img src={icoNews}/>{I18n.get("News")}</NavLink>  */}
            <NavLink
              to={`/parceiro/wikis/${urlWiki}`}
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoFaq} />
              {I18n.get("Processos")}
            </NavLink>
            <NavLink
              to="/parceiro/projetos/v2"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProjects} />
              {I18n.get("Projetos")}
            </NavLink>
            {Config.moduloNewBpay &&  containsPartnerAndSupplier && (
              <NavLink
                to="/partners/inicio"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoBpay} />
                {I18n.get("Ollo Pay")}
              </NavLink>
            )}
            <NavLink
              to="/parceiro/repositorio-de-arquivos"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoRepoFiles} />
              {I18n.get("Repositório de Arquivos")}
            </NavLink>
            <NavLink
              to="/parceiro/projetos/v2?meetings"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoAgenda} />
              {I18n.get("Agenda de Videocalls")}
            </NavLink>
            <NavLink
              to="/parceiro/dashboard"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoDash} />
              {I18n.get("Central de Pendências e Dashboard")}
            </NavLink>
            <NavLink
              to="/parceiro/contratos"
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoContract} />
              {I18n.get("Contratos")}
            </NavLink>
            <NavLink
              to={this.whatLinkProfileMenu()}
              className="navbar-item"
              activeClassName="is-active"
            >
              <img src={icoProfile} />
              {I18n.get("Perfil")}
            </NavLink>

            {moduloFaturamento && (
              <NavLink
                to="/parceiro/faturamento"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoContract} />
                {I18n.get("Faturamento")}
              </NavLink>
            )}

            {Config.moduloFaqs && (
              <NavLink
                to="/parceiro/wikis/tutoriais"
                className="navbar-item"
                activeClassName="is-active"
              >
                <img src={icoFaq} />
                {I18n.get("Tutoriais e FAQs")}
              </NavLink>
            )}
            <a
              onClick={this.onLogoutClick.bind(this)}
              className="navbar-item navbar-last-item"
            >
              <img src={icoExit} />
              {I18n.get("Sair")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
