import React from 'react';
import { I18n } from 'aws-amplify';
import { Redirect, NavLink } from 'react-router-dom';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';

import StepsCreative from './steps-creative';
import StepsBidding from './steps-bidding';

import { scrollToTop } from '../../utils/ui';

class ProjectDefintionClientApproved extends React.Component {
    constructor(props) {
        super();
        this.state = {
            task: {}
        };
    }

    submitForm(e) {

    }

    componentDidMount() {

        scrollToTop();
    }

    render() {

        return (
            <FillForm>
                <div className="hero-body has-background-white project-definition-header">
                    <div className="container">
                        <div className="columns is-mobile">
                            <div className="column is-8">
                                <p className="title">{I18n.get("Definições do Projeto")}<br />
                                    <span className="tag is-warning">{this.props.project && this.props.project.projectName}</span>
                                </p>
                            </div>
                            <div className="column is-4">
                                {this.props.isCreative && <>
                                    <StepsCreative active={3} projectId={this.props.match.params.projectId} terms={this.props.clientTermsUpdatedOn} />
                                </>}
                                {this.props.isPartnerSelectionBidding && <>
                                    <StepsBidding active={2} projectId={this.props.match.params.projectId} terms={this.props.clientTermsUpdatedOn} />
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="client-step client-step-1 project-definition">

                    <div className="container client-container-1">

                        <br /><br /><br />

                        <h1 className="subtitle is-4 is-marginless">{I18n.get("Parabéns!")}</h1>
                        <br />
                        <p>{I18n.get("Você aceitou a proposta do seu parceiro.")}</p>
                        <br />
                        <p style={{ fontSize: '80%' }}>{I18n.get("Agora só falta você inserir a SPA para o projeto iniciar.")}</p>

                        <br /><br /><br />

                        {this.props.isCreative && <>

                            <NavLink to={`/projetos/${this.props.match.params.projectId}/detalhamento-passo-5`} className="button is-black">{I18n.get("inserir SPA")}</NavLink>

                        </>}
                        {this.props.isPartnerSelectionBidding && <>

                            <NavLink to={`/projetos/${this.props.match.params.projectId}/detalhamento-passo-4`} className="button is-black">{I18n.get("inserir SPA")}</NavLink>

                        </>}

                    </div>

                </div>

            </FillForm>
        );
    }
}

export default ProjectContainer(ProjectDefintionClientApproved);
