import React, { Component } from "react";
import { I18n } from "aws-amplify";
import {
  Row,
  Col,
  Form,
  Icon,
  Spin,
  Input,
  InputNumber,
  Select,
  Button,
  Tooltip,
} from "antd";

import styled from "styled-components";

const { Option } = Select;

const BoxSpin = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
`;

class FilterProjectAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.action(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form;
    const { filters } = this.props;

    if (!filters) {
      return (
        <BoxSpin>
          <Spin />
        </BoxSpin>
      );
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col sm={3}>
            <Form.Item label={I18n.get("Pais")}>
              {getFieldDecorator("country", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters?.countries &&
                    filters?.countries.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          {/* <Col sm={3}>
            <Form.Item label={I18n.get("Status")}>
              {getFieldDecorator("status", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: -1,
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters.status &&
                    filters.status.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col> */}

          <Col sm={3}>
            <Form.Item label={I18n.get("Tipo")}>
              {getFieldDecorator("type", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: -1,
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters.types.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Cliente")}>
              {getFieldDecorator("client", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters.clients.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col sm={6}>
            <Form.Item label={I18n.get("Parceiro")}>
              {getFieldDecorator("partner", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "-1",
              })(
                <Select
                  className="SelectMin"
                  style={{ width: "100%" }}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filters &&
                    filters.partners.map((item, index) => {
                      return (
                        <Option
                          className="SelectMin"
                          value={item.code}
                          key={index}
                        >
                          {item.label}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>

          </Row>

          <Row>

          <Col sm={6}>
            <Form.Item label={I18n.get("Buscar")}>
              {getFieldDecorator("name", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(<Input placeholder="Nome / SKU" />)}
            </Form.Item>
          </Col>

          <Col sm={4}>
            <Form.Item label={I18n.get("#SPA")}>
              {getFieldDecorator("poNumber", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(<Input placeholder="#SPA" />)}
            </Form.Item>
          </Col>

          {/* <Col sm={3}>
            <Form.Item
              label={
                <span>
                  {I18n.get("Valor")}&nbsp;
                  <Tooltip title={I18n.get("Valor maior ou igual")}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("billingTotal", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(<InputNumber decimalSeparator="," />)}
            </Form.Item>
          </Col>

          <Col sm={3}>
            <Form.Item
              label={
                <span>
                  {I18n.get("Liberado")}&nbsp;
                  <Tooltip title={I18n.get("Liberado % menor ou igual")}>
                    <Icon type="info-circle" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("billingBalancePerc", {
                rules: [{ required: false, message: "ops!" }],
                initialValue: "",
              })(
                <InputNumber
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                />
              )}
            </Form.Item>
          </Col> */}

          <Col sm={1}>
            <Form.Item className="labelClean" label="&nbsp;">
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={!filters}
                loading={this.props.task.busy}
              >
                OK
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
// TESTE

const FormFilterProjectAdmin = Form.create({})(FilterProjectAdmin);

export default FormFilterProjectAdmin;
