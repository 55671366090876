import React, { useState } from "react";
import { useBp } from "../../../v2/hooks/useBp/useBp.hook";
import { I18n } from "aws-amplify";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Icon,
  Skeleton,
  Popconfirm,
  notification,
  Texarea,
  Modal,
} from "antd";

import { FormatCurrency } from "../../../v2/helpers/utils";
import styled from "styled-components";
import { SupplierBillingStatusType } from "../../../model";

const { TextArea } = Input;

const Field = styled.p`
  span: first-child {
    font-weight: 700;
  }
`;

const BtDelete = styled.a`
  margin-left: 20px;
  font-size: 14px;
`;

const SupplierBillingDetails = ({ data, setValue, reloadPage }) => {
  const [nf, setNf] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [information, setInformation] = useState(
    data?.invoice?.specificInformations || ""
  );
  const [reasonDeleteInvoice, setReasonDeleteInvoice] = useState("");
  const [visibleModalDeleteInvoice, setVisibleModalDeleteInvoice] =
    useState(false);

  const { deleteInvoiceSupplier, updateSpecificInformation } = useBp();

  const handleDelete = async () => {
    setLoading(true);
    const response = await deleteInvoiceSupplier({
      id: data.id,
      reasonDeleteInvoice: reasonDeleteInvoice,
    });

    if (response?.data?.success) {
      setLoading(false);
      notification.success({
        message: I18n.get("Nota excluída com sucesso."),
        description: I18n.get("Esse modal será fechado."),
      });

      reloadPage();
    } else {
      notification.error({
        message: I18n.get("Erro ao excluir nota."),
        // description: I18n.get("Tente novamente mais tarde."),
        description:
          response?.data?.errors && response?.data?.errors.length > 0 &&
          response?.data?.errors.map((error) => error.errorMessage).join(", "),
      });
    }
  };

  const HandleUpdateSpecificInformation = async () => {
    setLoadingInfo(true);
    const response = await updateSpecificInformation({
      id: data.id,
      specificInformations: information,
    });

    if (response?.data?.data?.success) {
      notification.success({
        message: I18n.get("Atualizado com sucesso."),
        description: I18n.get("Esse modal será fechado."),
      });

      // reloadPage();
      setLoadingInfo(false);
    } else {
      notification.error({
        message: I18n.get("Ops! Algo deu errado."),
        description:
          response?.data?.errors[0]?.errorMessage ??
          I18n.get("Tente novamente mais tarde."),
      });
      setLoadingInfo(false);
    }
  };

  return (
    <div>
      <h6>{I18n.get("Detalhes")}</h6>

      <br />

      <p
        style={{
          marginTop: "1rem",
          fontSize: "80%",
          fontWeight: "bold",
        }}
      >
        {I18n.get("NFSe")}:
      </p>
      <p>
        <Input
          style={{
            width: "150px",
            float: "left",
            marginRight: "10px",
          }}
          onChange={(e) => setNf(e.target.value)}
          disabled={data.bPoolNFSe}
          defaultValue={data?.bPoolNFSe}
        />{" "}
        <Button
          className="button bt-bpool black"
          onClick={() => setValue(nf)}
          disabled={nf === "" || data?.bPoolNFSe}
        >
          {I18n.get("Atualizar")}
        </Button>
      </p>

      <SupplierBillingCard data={data} />

      <p
        style={{
          marginTop: "1rem",
          fontSize: "80%",
          fontWeight: "bold",
        }}
      >
        {I18n.get("Informações específicas")}:
      </p>
      <p>
        <TextArea
          value={information || data?.invoice?.specificInformations}
          onChange={(e) => setInformation(e.target.value)}
          style={{ height: 150 }}
        />
        <br />

        <Button
          disabled={information == data?.specificInformation}
          type="primary"
          onClick={() => HandleUpdateSpecificInformation()}
          loading={loadingInfo}
        >
          {I18n.get("Atualizar")}
        </Button>
      </p>
      <br />
      <br />

      <h6>{I18n.get("Faturamento")}</h6>

      <SupplierBillingBillToCompany data={data.billToCompany} />

      <h6>
        {I18n.get("Pagamento")}
        {data.invoice && (
          <BtDelete onClick={() => setVisibleModalDeleteInvoice(true)}>
            <Icon type="delete" theme="filled" /> {I18n.get("Excluir nota")}
          </BtDelete>
        )}{" "}
      </h6>

      {loading && <Skeleton active />}

      {!loading && (
        <SupplierBillingInvoice
          data={data.invoice}
          currency={data.currency}
          id={data.id}
          status={data.status}
          reloadPage={reloadPage}
        />
      )}

      <Modal
        title={I18n.get("Notificar parceiro sobre nota deletada")}
        visible={visibleModalDeleteInvoice}
        onCancel={() => setVisibleModalDeleteInvoice(false)}
        footer={[
          <>
            <Button
              disabled={!reasonDeleteInvoice}
              type="primary"
              key="send"
              onClick={() => handleDelete()}
            >
              {I18n.get("Enviar")}
            </Button>

            <Button
              key="back"
              onClick={() => setVisibleModalDeleteInvoice(false)}
            >
              {I18n.get("Cancelar")}
            </Button>
          </>,
        ]}
      >
        <TextArea
          value={reasonDeleteInvoice}
          onChange={(e) => setReasonDeleteInvoice(e.target.value)}
          style={{ height: 150 }}
        />
      </Modal>
    </div>
  );
};

export const SupplierBillingCard = ({ data }) => {
  let invoiceTo = "";

  if (data.billTo === 1) {
    invoiceTo = "CLIENT";
  } else {
    invoiceTo = `BPOOL (${data.billToCompany.registrationName})`;
  }

  return (
    <div>
      <Card bordered={false}>
        <Field>
          <span>{I18n.get("Cliente")}</span>: {data.clientName}
        </Field>

        <Field>
          <span>{I18n.get("Fornecedor")}</span>: {data.supplierName} (
          {data.supplierEmail})
        </Field>

        <Field>
          <span>{I18n.get("Projeto")}</span>:{data.projectName}
        </Field>

        <Field>
          <span>{I18n.get("Marca")}</span>: {data?.brandName}
        </Field>

        <Field>
          <span>{I18n.get("SKU")}</span>: {data?.projectSKU}
        </Field>

        <Field>
          <span>{I18n.get("#SPA")}</span>: {data.poNumber}
        </Field>

        <Field>
          <span>{I18n.get("Valor Total")}</span>: {data.currencyCode}{" "}
          {data.amountTotalFmt}
        </Field>

        <Field>
          <span>{I18n.get("Valor Fee")}</span>: {data.currencyCode}{" "}
          {data.amountFeeFmt}
        </Field>

        <Field>
          <span>{I18n.get("Valor Parceiro")}</span>: {data.currencyCode}{" "}
          {data.amountPartnerFmt}
        </Field>

        <Field>
          <span>{I18n.get("Faturar Contra")}</span>: {invoiceTo}
        </Field>
        <Field>
          <span>{I18n.get("Criado em")}</span>: {data.createdOn}
        </Field>

        <Field>
          <span></span>
        </Field>
      </Card>
    </div>
  );
};

export const SupplierBillingBillToCompany = ({ data }) => {


  if (!data) {
    return <div>-</div>;
  }

  return (
    <div>
      <Card bordered={false}>
        <Field>
          <span>{I18n.get("Razão Social")}</span>: {data.registrationName}
        </Field>

        <Field>
          <span>{I18n.get("CNPJ")}</span>: {data.registrationId}
        </Field>

        <Field>
          <span>{I18n.get("Endereço")}</span>: {data.addressLine1} -{" "}
          {data.addressLine2}
        </Field>

        <Field>
          <span>{I18n.get("CEP")}</span>: {data.postcode}
        </Field>

        <Field>
          <span>{I18n.get("Cidade/Estado")}</span>: {data.city} {data.state}
        </Field>

        <Field>
          <span>{I18n.get("País")}</span>: {data.country}
        </Field>
      </Card>
    </div>
  );
};

export const SupplierBillingInvoice = ({
  data,
  currency,
  id,
  status,
  reloadPage,
}) => {
  const { sendNotificationEmailBankAccountWrongAsync } = useBp();
  const [visibleModal, setVisibleModal] = useState(false);

  const [bankAccountWrongReason, setBankAccountWrongReason] = useState("");

  // const [idNota, setIdNota] = useState(null);

  if (!data) {
    return <div>-</div>;
  }

  const handleNotifyBankAccountIsWrong = async () => {
    const response = await sendNotificationEmailBankAccountWrongAsync({
      id: id,
      bankAccountWrongReason: bankAccountWrongReason,
    });

    if (response?.data?.data?.success) {
      notification.success({
        message: I18n.get(
          "Foi enviado uma notificação ao parceiro sobre a conta bancária estar errada."
        ),
        description: I18n.get("Esse modal será fechado."),
      });

      reloadPage();
    } else {
      notification.error({
        message: I18n.get(
          "Erro ao enviar notificação sobre a conta bancária estar errada."
        ),
        description:
          response?.data?.errors[0]?.errorMessage ??
          I18n.get("Tente novamente mais tarde."),
      });
    }

    // if (
    //   !response ||
    //   !response?.data?.success ||
    //   response?.data?.status == 400
    // ) {
    //   notification.error({
    //     message: I18n.get(
    //       "Erro ao enviar notificação sobre a conta bancária estar errada."
    //     ),
    //     description: I18n.get("Tente novamente mais tarde."),
    //   });
    // } else {
    //   notification.success({
    //     message: I18n.get(
    //       "Foi enviado uma notificação ao parceiro sobre a conta bancária estar errada."
    //     ),
    //     description: I18n.get("Esse modal será fechado."),
    //   });

    //   reloadPage();
    // }
  };

  const renderActionBankAccountWrong = (
    messageToConfirm,
    customTitleButton = null
  ) => {


    return (
      <>
        <br />

        <Button
          className="button bt-bpool black"
          onClick={() => setVisibleModal(true)}
          disabled={false}
        >
          <strong>{I18n.get(customTitleButton ?? "Conta errada")}</strong>
        </Button>
      </>
    );
  };

  const valueBankAccountEmpty = "-";

  return (
    <div>
      <Row>
        <Col sm={12}>
          <Card bordered={false}>
            <Field>
              <span>{I18n.get("Razão Social")}</span>: {data.beneficiaryName}
            </Field>

            <Field>
              <span>{I18n.get("CNPJ")}</span>: {data.beneficiaryId}
            </Field>

            <Field>
              <span>{I18n.get("Endereço")}</span>: {data.addressLine1}
            </Field>

            <Field>
              <span>{I18n.get("CEP")}</span>: {data.postcode}
            </Field>

            <Field>
              <span>{I18n.get("Cidade/Estado")}</span>: {data.city} {data.state}
            </Field>

            <Field>
              <span>{I18n.get("País")}</span>: {data?.country?.name}
            </Field>

            <Field>
              <span>{I18n.get("Número nota")}</span>: {data?.number}
            </Field>
            <Field>
              <span>{I18n.get("Valor Nota")}</span>:{" "}
              <FormatCurrency amount={data?.invoiceValue} currency={currency} />
            </Field>

            <Field>
              <span>{I18n.get("Forma Pagamento")}</span>:{" "}
              {data.paymentMethod ?? valueBankAccountEmpty}
            </Field>
            <br />

            <Field>
              <span>{I18n.get("NF")}</span>:{" "}
              <a
                href={data.file.url}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {I18n.get("Clique para fazer download")}
              </a>
            </Field>
          </Card>
        </Col>
        <Col sm={12}>
          <Card bordered={false}>
            {status == SupplierBillingStatusType.BankAccountIsWrong && (
              <>
                <Field style={{ color: "red" }}>
                  <span>
                    {I18n.get("Aguardando a correção pelo fornecedor")}
                  </span>
                </Field>

                {renderActionBankAccountWrong(
                  "Tem certeza que deseja re-enviar sobre conta bancária estar errada?",
                  "Re-enviar"
                )}
              </>
            )}

            {status !== SupplierBillingStatusType.BankAccountIsWrong &&
              !data.paymentMethod && (
                <>
                  {renderActionBankAccountWrong(
                    "Tem certeza que deseja notificar o parceiro sobre preenchimento de dados bancários?",
                    "Solicitar Dados Bancários"
                  )}
                </>
              )}

            {data.paymentMethod == "TRANSFER" && (
              <>
                <Field>
                  <span>{I18n.get("Banco")}</span>:{" "}
                  {data.bankId ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>{I18n.get("Agência")}</span>:{" "}
                  {data.bankBranch ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>{I18n.get("Conta")}</span>:{" "}
                  {data.bankAccount ?? valueBankAccountEmpty}
                </Field>

                {renderActionBankAccountWrong(
                  "Tem certeza que deseja notificar o parceiro sobre a conta bancária estar errada?"
                )}
              </>
            )}

            {data.paymentMethod == "PIX" && (
              <>
                <Field>
                  <span>{I18n.get("Chave Pix")}</span>:{" "}
                  {data.pix ?? valueBankAccountEmpty}
                </Field>

                {renderActionBankAccountWrong(
                  "Tem certeza que deseja notificar o parceiro sobre a chave PIX estar errada?"
                )}
              </>
            )}

            {data.paymentMethod == "SWIFT_ABA" && (
              <>
                <Field>
                  <span>{I18n.get("Nome do Banco")}</span>:{" "}
                  {data.bankName ?? "-"}
                </Field>

                <Field>
                  <span>{I18n.get("Cidade do Banco")}</span>:{" "}
                  {data.bankCity ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>{I18n.get("País do Banco")}</span>:{" "}
                  {data.bankCountryId ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>{I18n.get("Endereço do Banco")}</span>:{" "}
                  {data.bankAddress ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>SWIFT</span>: {data.bankSwift ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>ABA/ACH</span>: {data.bankABA ?? valueBankAccountEmpty}
                </Field>

                <Field>
                  <span>{I18n.get("Conta")}</span>:{" "}
                  {data.bankAccount ?? valueBankAccountEmpty}
                </Field>

                {renderActionBankAccountWrong(
                  "Tem certeza que deseja notificar o parceiro sobre a conta de SWIFT estar errada?"
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Modal
        title={I18n.get("Notificar parceiro sobre conta bancária errada")}
        visible={visibleModal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <>
            <Button
              disabled={!bankAccountWrongReason}
              type="primary"
              key="send"
              onClick={() => handleNotifyBankAccountIsWrong()}
            >
              {I18n.get("Enviar")}
            </Button>

            <Button key="back" onClick={() => setVisibleModal(false)}>
              {I18n.get("Cancelar")}
            </Button>
          </>,
        ]}
      >
        <TextArea
          value={bankAccountWrongReason}
          onChange={(e) => setBankAccountWrongReason(e.target.value)}
          style={{ height: 150 }}
        />
      </Modal>
    </div>
  );
};

export default SupplierBillingDetails;
