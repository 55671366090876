import React from "react";
import { I18n } from "aws-amplify";
import { Input } from "../../UI/Input";
import { SelectOne } from "../../UI/SelectOne";
import { hasError, clearErrorInput } from "../../../utils/errorsInput";
import constants from "../../../../constants";

import * as S from "./styles";

export const DrawerInfos = ({
  paymentMethod,
  inputsForm,
  handleChangeInput,
  clearing,
  errorsInputs,
  handleSetErrorsInputs,
}) => {
  if (paymentMethod === "PIX") {
    return (
      <S.Wrapper>
        {clearing ? (
          <div />
        ) : (
          <>
            <Input
              required
              placeholder={I18n.get("Nome do Beneficiário/Razão Social")}
              label
              value={inputsForm?.beneficiaryName}
              onChange={(value) => handleChangeInput("beneficiaryName", value)}
              error={hasError({
                arr: errorsInputs,
                field: "beneficiaryName",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "beneficiaryName",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <Input
              required
              placeholder={I18n.get("Documento do Beneficiário/CNPJ")}
              label
              value={inputsForm?.beneficiaryId}
              onChange={(value) => handleChangeInput("beneficiaryId", value)}
              error={hasError({
                arr: errorsInputs,
                field: "beneficiaryId",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "beneficiaryId",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <Input
              required
              placeholder={I18n.get("Chave PIX")}
              label
              value={inputsForm?.pix}
              onChange={(value) => handleChangeInput("pix", value)}
              error={hasError({
                arr: errorsInputs,
                field: "pix",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "pix",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
          </>
        )}
      </S.Wrapper>
    );
  } else if (paymentMethod === "SWIFT") {
    return (
      <S.Wrapper>
        {clearing ? (
          <div />
        ) : (
          <>
            <h3>{I18n.get("Beneficiário")}</h3>
            <Input
              placeholder={I18n.get("Nome do Beneficiário/Razão Social")}
              label
              required
              value={inputsForm.beneficiaryName}
              onChange={(value) => handleChangeInput("beneficiaryName", value)}
              error={hasError({
                arr: errorsInputs,
                field: "beneficiaryName",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "beneficiaryName",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <Input
              placeholder={I18n.get("Documento do Beneficiário/CNPJ")}
              label
              required
              value={inputsForm.beneficiaryId}
              onChange={(value) => handleChangeInput("beneficiaryId", value)}
              error={hasError({
                arr: errorsInputs,
                field: "beneficiaryId",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "beneficiaryId",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <div
              style={{
                display: "grid",
                columnGap: 16,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Input
                placeholder={I18n.get("Dirección de Beneficiário")}
                label
                required
                value={inputsForm.beneficiaryDirectionBeneficiary}
                onChange={(value) =>
                  handleChangeInput("beneficiaryDirectionBeneficiary", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "beneficiaryDirectionBeneficiary",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "beneficiaryDirectionBeneficiary",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
              <Input
                placeholder={I18n.get("Número de cuenta")}
                label
                required
                value={inputsForm.beneficiaryAccountNumber}
                onChange={(value) =>
                  handleChangeInput("beneficiaryAccountNumber", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "beneficiaryAccountNumber",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "beneficiaryAccountNumber",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                columnGap: 16,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Input
                placeholder={I18n.get("Clave interbancaria")}
                label
                required
                value={inputsForm.beneficiaryInterbankKey}
                onChange={(value) =>
                  handleChangeInput("beneficiaryInterbankKey", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "beneficiaryInterbankKey",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "beneficiaryInterbankKey",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
              <Input
                placeholder={I18n.get("SWIFT/ABA/BIC/IBAN")}
                label
                required
                value={inputsForm.beneficiarySWIFTABABICIBAN}
                onChange={(value) =>
                  handleChangeInput("beneficiarySWIFTABABICIBAN", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "beneficiarySWIFTABABICIBAN",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "beneficiarySWIFTABABICIBAN",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                columnGap: 16,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Input
                placeholder={I18n.get("Nombre de Banco")}
                label
                required
                value={inputsForm.beneficiaryBank}
                onChange={(value) =>
                  handleChangeInput("beneficiaryBank", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "beneficiaryBank",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "beneficiaryBank",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
              <Input
                placeholder={I18n.get("Dirección del Banco")}
                label
                required
                value={inputsForm.beneficiaryBankDirection}
                onChange={(value) =>
                  handleChangeInput("beneficiaryBankDirection", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "beneficiaryBankDirection",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "beneficiaryBankDirection",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
            <h3>{I18n.get("Intermediário")}</h3>
            <div
              style={{
                display: "grid",
                columnGap: 16,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Input
                placeholder={I18n.get("Dirección de Intermediário")}
                label
                value={inputsForm.intermediateDirectionBeneficiary}
                onChange={(value) =>
                  handleChangeInput("intermediateDirectionBeneficiary", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "intermediateDirectionBeneficiary",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "intermediateDirectionBeneficiary",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
              <Input
                placeholder={I18n.get("Número de cuenta")}
                label
                value={inputsForm.intermediateAccountNumber}
                onChange={(value) =>
                  handleChangeInput("intermediateAccountNumber", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "intermediateAccountNumber",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "intermediateAccountNumber",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                columnGap: 16,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Input
                placeholder={I18n.get("Clave interbancaria")}
                label
                value={inputsForm.intermediateInterbankKey}
                onChange={(value) =>
                  handleChangeInput("intermediateInterbankKey", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "intermediateInterbankKey",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "intermediateInterbankKey",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
              <Input
                placeholder={I18n.get("SWIFT/ABA/BIC/IBAN")}
                label
                value={inputsForm.intermediateSWIFTABABICIBAN}
                onChange={(value) =>
                  handleChangeInput("intermediateSWIFTABABICIBAN", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "intermediateSWIFTABABICIBAN",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "intermediateSWIFTABABICIBAN",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
            <div
              style={{
                display: "grid",
                columnGap: 16,
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <Input
                placeholder={I18n.get("Nombre de Banco")}
                label
                value={inputsForm.intermediateBank}
                onChange={(value) =>
                  handleChangeInput("intermediateBank", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "intermediateBank",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "intermediateBank",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
              <Input
                placeholder={I18n.get("Dirección del Banco")}
                label
                value={inputsForm.intermediateBankDirection}
                onChange={(value) =>
                  handleChangeInput("intermediateBankDirection", value)
                }
                error={hasError({
                  arr: errorsInputs,
                  field: "intermediateBankDirection",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "intermediateBankDirection",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
          </>
        )}
      </S.Wrapper>
    );
  } else {
    return (
      <S.Wrapper>
        {clearing ? (
          <div />
        ) : (
          <>
            <Input
              required
              placeholder={I18n.get("Nome do Beneficiário/Razão Social")}
              label
              value={inputsForm?.beneficiaryName}
              onChange={(value) => handleChangeInput("beneficiaryName", value)}
              error={hasError({
                arr: errorsInputs,
                field: "beneficiaryName",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "beneficiaryName",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <Input
              required
              placeholder={I18n.get("Documento do Beneficiário/CNPJ")}
              label
              value={inputsForm?.beneficiaryId}
              onChange={(value) => handleChangeInput("beneficiaryId", value)}
              error={hasError({
                arr: errorsInputs,
                field: "beneficiaryId",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "beneficiaryId",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <div style={{ height: 80 }}>
              <SelectOne
                options={constants.BANK_IDS}
                value={inputsForm?.bankId}
                defaultValue={inputsForm?.bankId}
                //   loading={isLoadingGet}
                onChange={(value) => handleChangeInput("bankId", value)}
                required
                placeholder={I18n.get("Banco")}
                error={hasError({
                  arr: errorsInputs,
                  field: "bankId",
                })}
                clearError={() => {
                  const errorsArr = clearErrorInput({
                    field: "bankId",
                    arr: errorsInputs,
                  });
                  handleSetErrorsInputs(errorsArr);
                }}
              />
            </div>
            <Input
              required
              placeholder={I18n.get("Agência")}
              label
              value={inputsForm?.bankBranch}
              onChange={(value) => handleChangeInput("bankBranch", value)}
              error={hasError({
                arr: errorsInputs,
                field: "bankBranch",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "bankBranch",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
            <Input
              required
              placeholder={I18n.get("Conta com dígito")}
              label
              value={inputsForm?.bankAccount}
              onChange={(value) => handleChangeInput("bankAccount", value)}
              error={hasError({
                arr: errorsInputs,
                field: "bankAccount",
              })}
              clearError={() => {
                const errorsArr = clearErrorInput({
                  field: "bankAccount",
                  arr: errorsInputs,
                });
                handleSetErrorsInputs(errorsArr);
              }}
            />
          </>
        )}
      </S.Wrapper>
    );
  }
};
