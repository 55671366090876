import React, { Fragment, PureComponent } from 'react';
import { I18n } from 'aws-amplify';
import styled from 'styled-components';
import numeral from "numeral";
import { Table } from 'antd';

numeral.register('locale', 'brasil', {
    delimiters: {
        thousands: ',',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        var b = number % 10;
        return (~~(number % 100 / 10) === 1) ? 'th' :
            (b === 1) ? 'st' :
                (b === 2) ? 'nd' :
                    (b === 3) ? 'rd' : 'th';
    },
    currency: {
        symbol: 'R$'
    }
});

numeral.locale('brasil');

const TextP = styled.p`
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    margin: 10px 0;
`;


class ResumeTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const columns = [
            {
                title: I18n.get("Valor Ideia"),
                dataIndex: 'valorIdeia',
                key: 'valorIdeia',
            },
            {
                title: I18n.get("Valor Produção"),
                dataIndex: 'valorProducao',
                key: 'valorProducao'
            },
            {
                title: I18n.get("Total Cliente 3"),
                dataIndex: 'totalCliente',
                key: 'totalCliente'
            },
            {
                title: I18n.get("Retenção Ollo (10%)"),
                dataIndex: 'retencaoBpool',
                key: 'retencaoBpool'
            },
            {
                title: I18n.get("Valor Parceiro"),
                dataIndex: 'valorParceiro',
                key: 'valorParceiro'
            }
        ];



        let totalClientValue = this.props.valorIdeia + this.props.valorProducao;
        let retencaoBpoolValue = totalClientValue * (10 / 100);
        let valorParceiro = totalClientValue - retencaoBpoolValue;

        let data = [
            {
                key: '1',
                valorIdeia: 'R$ ' + numeral(this.props.valorIdeia).format('0,0.00'),
                valorProducao: 'R$ ' + numeral(this.props.valorProducao).format('0,0.00'),
                totalCliente: 'R$ ' + numeral(totalClientValue).format('0,0.00'),
                retencaoBpool: 'R$ ' + numeral(retencaoBpoolValue).format('0,0.00'),
                valorParceiro: 'R$ ' + numeral(valorParceiro).format('0,0.00'),
            }
        ];




        return (
            <Fragment>
                <TextP>Disclaimer Valores Brutos ((R$), com NF + % plataforma BPool</TextP>
                <Table
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                    scroll={{ x: 640 }}
                    bordered
                />
                <TextP>*Sujeto a retenção de ISS conforme tipo de empresa / municipio</TextP>
            </Fragment>
        );
    }
}

export default ResumeTable;
