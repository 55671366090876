import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { I18n } from 'aws-amplify';
import { Row, Col, message, Dropdown, Menu, Typography } from 'antd';
import { useRegistrationFlow } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlow.hook';
import { useRegistrationFlowFull } from '../../../../hooks/useRegistrationFlow/useRegistrationFlowFull.hook';
import { AUTH_API } from '../../../../../actions/auth/actions';
import Loading from '../../../../../components/pages/loading';
import { StepsVertical } from '../../../../components/UI/StepsVertical';
import Main from '../../../../components/UI/Main';
import Content from '../../../../components/UI/Content';
import { handleClearLocalStorage } from '../../../../utils/clearStorage';
import {
  flowToStorage,
  getFlowStorage,
  updateFlowStorage,
  removeFlowStorage,
} from '../../utils/flowToStorage';
import * as S from './styles';

import logo from '../../../../../image/logoWOtext.svg';

const ERROR_GET_STEPS_STATUSBAR = 'An error has occurred in the status bar.';

export const Logged = ({
  children,
  pageIndex,
  statusBarStepsClickable,
  statusChangeInfo,
  showStepBar = true,
  full = false,
  urls,
}) => {
  const { StatusBarRegistrationFlow } = useRegistrationFlow();
  const { getStatusBarFull } = useRegistrationFlowFull();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  const [statusBarSteps, setStatusBarSteps] = useState([]);
  const [isLoadingSteps, setIsLoadingSteps] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // STEPBAR Vertical
  const loadStepsStatusBar = async () => {
    setIsLoadingSteps(true);

    let response = [];

    if (full) {
      response = await getStatusBarFull();
    } else {
      response = await StatusBarRegistrationFlow();
    }

    if (response?.length) {
      let flowStorageTemp = await getFlowStorage();

      if (
        flowStorageTemp &&
        flowStorageTemp[0].title === 'Company Profile' &&
        !full
      ) {
        await removeFlowStorage();
      }

      if (
        flowStorageTemp &&
        flowStorageTemp[0].title === 'Application' &&
        full
      ) {
        await removeFlowStorage();
      }

      let flowStorage = await getFlowStorage();

      let toStorage = [];

      if (!full) {
        toStorage = response.map((item, index) => ({
          ...item,
          url: urls[item.key],
          visited:
            item.title === 'Application'
              ? true
              : flowStorage
              ? flowStorage[index].visited
              : false,
          status: item.title === 'Application' ? 'finish' : item.status,
        }));
      }

      if (full) {
        toStorage = response.map((item, index) => ({
          ...item,
          url: urls[item.key],
          visited: flowStorage ? flowStorage[index].visited || false : false,
          status: item.title === 'Application' ? 'finish' : item.status,
        }));
        toStorage.sort((a, b) => (a.key > b.key) - (a.key < b.key));
      }

      await flowToStorage(toStorage);
      setStatusBarSteps(toStorage);
    } else {
      message.error(ERROR_GET_STEPS_STATUSBAR);
    }
    setIsLoadingSteps(false);
  };

  useEffect(() => {
    if (showStepBar) {
      loadStepsStatusBar();
    }
  }, [urls]);

  //AUTH
  const isNotLogged = auth?.init === false && !auth.logged_user;

  if (isNotLogged) {
    history.push({
      pathname: '/registration-flow/login',
    });
  }

  const handleLogout = async () => {
    dispatch(AUTH_API.logout());
    handleClearLocalStorage();
    history.push({
      pathname: '/registration-flow/login',
    });
  };

  const handleClickDropdown = () => {
    setDropdownOpen(dropdownOpen ? false : true);
  };

  if (auth?.init) {
    return <Loading />;
  }

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderContent>
          <div className="container" style={{ display: 'flex' }}>
            <S.ContentUser />
            <S.ContentLogo>
              <span>
                <img
                  src={logo}
                  alt="Ollo"
                  class="logo"
                />
              </span>
            </S.ContentLogo>
            <S.ContentUser>
              {auth?.init ? (
                <strong>{I18n.get('Loading...')}</strong>
              ) : (
                <S.Dropdown onClick={handleClickDropdown}>
                  <strong>
                    <span>{auth?.name}</span>
                    {dropdownOpen ? <UpOutlined /> : <DownOutlined />}
                  </strong>
                  <S.DropdownOpened active={dropdownOpen}>
                    <li>
                      <button onClick={handleLogout}>
                        {I18n.get('Logout')}
                      </button>
                    </li>
                  </S.DropdownOpened>
                </S.Dropdown>
              )}
            </S.ContentUser>
          </div>
        </S.HeaderContent>
      </S.Header>

      <Content paddingTop={60}>
        <div className="container">
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: showStepBar ? 16 : 24, offset: 0 }}
            >
              {children}
            </Col>
            {showStepBar ? (
              <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }}>
                <Main bgColor="#fff" padding="30px">
                  <StepsVertical
                    isLoading={isLoadingSteps}
                    steps={statusBarSteps}
                    clickable={statusBarStepsClickable}
                    page={pageIndex}
                    urls={urls}
                    changedInfos={statusChangeInfo}
                    full={full}
                  />
                </Main>
              </Col>
            ) : null}
          </Row>
        </div>
      </Content>

      <S.Footer>
        <S.ContentFooter>
          <div className="container">
            <span>{`${new Date().getFullYear()} © Ollo`}</span>
          </div>
        </S.ContentFooter>
      </S.Footer>
    </S.Wrapper>
  );
};
