import React from 'react';
import { I18n } from 'aws-amplify';

import { Table, Tag, Icon, Popconfirm } from 'antd';

import FormatCurrency from '../../ui/format-currency';

import { ClientProjectStatus, ProjectExtraTypeNames } from '../../../model';

import { getQuotationStatus } from '../../../utils/extras';

import ExtraDetails from './_extra-details';

export default class ExtrasList extends React.Component {

    constructor(props) {
        super();

        this.state = {
        };
    }

    componentDidMount() {
    }


    getStatus(value) {
        if (!this.props.bp || !this.props.bp.clientProjectExtraStatusesMap) return '';

        return this.props.bp.clientProjectExtraStatusesMap[value].label;
    }

    render() {

        const currency = this.props.currency || {};

        const columns = [
            {
                key: 1,
                title: I18n.get('Tipo de Extra'),
                dataIndex: 'extraType',
                render: (extraType) => <Tag>{I18n.get(ProjectExtraTypeNames[extraType])}</Tag>
            },
            {
                key: 2,
                title: I18n.get('Nome do Extra'),
                dataIndex: 'extraName',
            },
            {
                key: 3,
                title: `${I18n.get('Budget')} / ${I18n.get('Valor')}`,
                dataIndex: 'budget',
                render: (budget,record) => {
                    if(record?.extraPriceClientWithDiscountFmt) {
                        return record?.extraPriceClientWithDiscountFmt
                    } else {
                        return record?.budget;
                    }
                }
            },
            {
                key: 4,
                title: I18n.get('Orçamento'),
                dataIndex: 'quotationStatus',
            },
            {
                key: 5,
                title: I18n.get('#SPA'),
                dataIndex: 'billings',
                render: (billings) => { return billings && billings.map((billing, index) => (billing.projectPO || `Pagamento para ${billing.billingDate}`)).join(', ') }
            },
            {
                key: 6,
                title: I18n.get('PDF'),
                dataIndex: 'quotationPdf',
                render: (quotationPdf) => { return quotationPdf && <a href={quotationPdf} target="_blank"><Icon type="file-pdf"></Icon> </a> }
            },
            {
                key: 7,
                title: "",
                dataIndex: '',
                render: (record) => {

                    const data = {
                        extraId: record.extraId,
                        projectId: this.props.projectId
                    }
                    return (
                        <Popconfirm
                            title={I18n.get("Deseja realmente excluir esse Extra bem como qualquer SPA relacionada a ele?")}
                            onConfirm={() => this.props.deleteExtra(data)}
                            okText={I18n.get("SIM")}
                            cancelText={I18n.get("NÃO")}
                            placement="right"
                        >
                            <a className="info-invite-icon pull-right"><Icon type="delete" theme="filled" /></a>
                        </Popconfirm>
                    )
                }
            },
        ];

        const data = Array.isArray(this.props.extras) && this.props.extras.map((item, index) => {

            return {
                ...item,
                quotationStatus: getQuotationStatus(this.props.auth, item.quotation)
            };

        });

        return (
            <Table
                columns={columns}
                expandedRowRender={record => <ExtraDetails {...this.props} {...record} />}
                dataSource={data}
                pagination={false}
            ></Table>
        );
    }
}
