import React, { useState, useEffect } from 'react';
import Modal from '../../../components/UI/Modal';

import * as S from './styles';

import Logo from '../../../media/img/logo.png';
import IcoAlert from '../../../media/svg/ico-alert-modal.svg';
import IcoConfirm from '../../../media/svg/ico-confirm-modal.svg';

export const ModalCta = ({
  openModal,
  message,
  titleButtonBack,
  titleButtonConfirm,
  bgColorButtonConfirm,
  cbButtonConfirm,
  onCloseModal,
  loading,
  success,
}) => {
  return (
    <>
      {openModal && (
        <Modal onClickCloseModal={onCloseModal} header={false} closeInBg>
          <S.WrapperModal>
            <S.ContentModal>
              <S.HeadModal>
                <img src={Logo} alt="Ollo" />
              </S.HeadModal>
              {!success.success ? (
                <>
                  <S.MessageModal>
                    <img src={IcoAlert} alt="Alert modal" />
                    <p>{message}</p>
                  </S.MessageModal>
                  <S.CtaModal>
                    <S.ButtonBack onClick={onCloseModal} type="button">
                      {titleButtonBack}
                    </S.ButtonBack>
                    <S.ButtonConfirm
                      onClick={cbButtonConfirm}
                      type="button"
                      bgColor={bgColorButtonConfirm}
                    >
                      {titleButtonConfirm}
                    </S.ButtonConfirm>
                  </S.CtaModal>
                </>
              ) : (
                <>
                  <S.MessageModal>
                    <img src={IcoConfirm} alt="Confirm modal" />
                    <p>{success.message}</p>
                  </S.MessageModal>
                  <S.CtaModal>
                    <S.ButtonConfirmSuccess
                      onClick={onCloseModal}
                      type="button"
                    >
                      OK!
                    </S.ButtonConfirmSuccess>
                  </S.CtaModal>
                </>
              )}
            </S.ContentModal>
          </S.WrapperModal>
        </Modal>
      )}
    </>
  );
};
