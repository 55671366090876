import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f8f8f8;
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-areas: 'header' 'content' 'footer';
  grid-template-rows: 60px calc(100% - 160px) 100px;
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const HeaderContent = styled.div`
  position: relative;
  bottom: 0;
  background-color: #007aff;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 30px;
  }
`;

export const Content = styled.div`
  grid-area: content;
`;

export const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export const ContentFooter = styled.div`
  position: relative;
  bottom: 0;
  background-color: black;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  span {
    color: #ffffff;
    font-weight: 600;
    padding: 0px 20px;
    margin: 0px;
  }
`;
