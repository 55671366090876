import React, { useState, useEffect } from 'react';
import { I18n } from 'aws-amplify';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { Row, Col, message, Select, DatePicker } from 'antd';
import Main from '../../../../components/UI/Main';
import { ButtonBpool } from '../../../../components/UI/ButtonBpool';
import Loading from '../../../../components/UI/Loading';
import { TabsSelect } from '../../../../components/UI/TabsSelect';
import { BoxWithCheckboxes } from '../../../../components/UI/BoxWithCheckboxes';
import { Input } from '../../../../components/UI/Input';
import { SelectOne } from '../../../../components/UI/SelectOne';
import { useRegistrationFlowLeader } from '../../../../hooks/useRegistrationFlow/useRegistrtionFlowLeader.hook';
import {
  toUpdateFlowStorageSteps,
  toUpdateStatusInStep,
} from '../../utils/flowToStorage';
import { statusIdToName } from '../../components/Loggedv2/statusIdToName';
import { clearErrorInput, hasError } from '../../utils/errorsInput';

import { ItemCase } from './itemCase';
import { ItemAward } from './itemAward';
import COUNTRY_CODES from './countryCodes.json';

import { urlsLeader as urls } from '../../utils/stepsUrls';
import * as S from './styles';

const MESSAGE_ERROR_GET_PAGE = 'There was an error, try again.';
const MESSAGE_ERROR_BACK_PAGE = 'Error in back page.';
const MESSAGE_ERROR = 'There was an error, try again.';
const PAGE_INDEX = 20;
const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

export const ContentPage = ({ contextPage, onChangeInfo, sendOk }) => {
  const {
    getStepBarLeader,
    getCasesAndAwards,
    getCasesAndAwardsLogged,
    getIndustriesToDropdownCases,
    getIndustriesDropDownLogged,
    putCasesAndAwards,
    putCasesAndAwardsLogged
  } = useRegistrationFlowLeader();
  const location = useLocation();
  let history = useHistory();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [token, setToken] = useState('');
  const [tabSelect, setTabSelect] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [inputsForm, setInputsForm] = useState({});
  const [categoriesDrop, setCategoriesDrop] = useState([]);
  const [checkboxIdTab, setCheckboxIdTab] = useState('id-0');
  const [dateCaseLeader, setDateCaseLeader] = useState(null);
  const [onEditing, setOnEditing] = useState(false);
  const [selectCountry, setSelectCountry] = useState({
    countryLabel: 'Brazil',
    phoneMask: '(00)00000-0000',
    countryCode: 'BRA',
  });
  const [errrorsInternalForm, setErrrorsInternalForm] = useState({});
  //company (sua empresa)
  const [companyCases, setCompanyCases] = useState([]);
  const [companyAwards, setCompanyAwards] = useState([]);
  const [companyCasesTemp, setCompanyCasesTemp] = useState([]);
  const [companyAwardsTemp, setCompanyAwardsTemp] = useState([]);
  const [participedCasesIds, setParticipedCasesIds] = useState([]);
  //leader (sua carreira)
  const [leaderCases, setLeaderCases] = useState([]);
  const [leaderAwards, setLeaderAwards] = useState([]);
  const [leaderAwardsTemp, setLeaderAwardsTemp] = useState([]);
  const [leaderCasesTemp, setLeaderCasesTemp] = useState([]);
  const [participedAwardsIds, setParticipedAwardsIds] = useState([]);

  const { Option } = Select;

  const onChangeCheck = ({ id }) => {
    setCheckboxIdTab(id);
    setInputsForm({});
    setOnEditing(false);
    setErrrorsInternalForm({});
  };

  const handleChangeCategory = (value) => {
    onChangeInputForm('industry', value);
    setErrrorsInternalForm({
      ...errrorsInternalForm,
      industry: null,
    });
  };

  const onChangeSelectCountry = (value) => {
    const country = COUNTRY_CODES.find((item) => item.name === value);
    setInputsForm({
      ...inputsForm,
      countryCode: country.code,
    });
    setSelectCountry({
      countryLabel: value,
      phoneMask: country.mask,
      countryCode: country.code,
    });
  };

  const createDateArray = () => {
    var currentDate = new Date();
    var startDate = new Date(1900, 0, 1);
    var yearArray = [];

    while (startDate <= currentDate) {
      yearArray.push({
        code: startDate.getFullYear().toString(),
        label: startDate.getFullYear().toString(),
      });
      startDate.setFullYear(startDate.getFullYear() + 1);
    }

    const sortedArray = yearArray.sort((a, b) => {
      if (b.code > a.code) {
        return 1;
      } else if (b.code < a.code) {
        return -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  };

  const selectBefore = (
    <Select
      defaultValue={selectCountry.countryLabel}
      className="select-before"
      onSelect={(value) => onChangeSelectCountry(value)}
      value={selectCountry.countryLabel}
    >
      {COUNTRY_CODES.map((item) => {
        let codeString = item.isO2.toLowerCase();
        return (
          <Option key={item.code} value={item.name} prefix={item.prefix}>
            <img
              src={`https://assets-ollo.bpool.co/country-flags/png24px/${codeString}.png`}
            />
          </Option>
        );
      })}
    </Select>
  );

  const onChangeDate = (date, dateString) => {
    setDateCaseLeader(dateString);
  };

  const formCase = () => {
    return (
      <>
        <Row style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Case name')}
              label
              required
              value={inputsForm.name}
              error={errrorsInternalForm?.name}
              onChange={(value) => {
                onChangeInputForm('name', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  name: null,
                });
              }}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Company name')}
              label
              required
              value={inputsForm.companyName}
              onChange={(value) => {
                onChangeInputForm('companyName', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  companyName: null,
                });
              }}
              error={errrorsInternalForm?.companyName}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Link')}
              label
              required
              value={inputsForm.link}
              onChange={(value) => {
                onChangeInputForm('link', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  link: null,
                });
              }}
              error={errrorsInternalForm?.link}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <SelectOne
              options={categoriesDrop}
              value={inputsForm.industry}
              defaultValue=""
              onChange={handleChangeCategory}
              required
              placeholder={I18n.get('Category')}
              error={errrorsInternalForm?.industry}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <SelectOne
              options={COUNTRY_CODES || []}
              value={inputsForm?.countryCode}
              defaultValue={inputsForm?.countryCode}
              isCountry
              onChange={(value) => {
                onChangeInputForm('countryCode', value, true);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  countryCode: null,
                });
              }}
              required
              placeholder={I18n.get('Country')}
              error={errrorsInternalForm?.countryCode}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Brand')}
              label
              required
              value={inputsForm.brand}
              onChange={(value) => {
                onChangeInputForm('brand', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  brand: null,
                });
              }}
              error={errrorsInternalForm?.brand}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <SelectOne
              options={createDateArray()}
              value={inputsForm.year}
              defaultValue=""
              required
              placeholder={I18n.get('Select year')}
              onChange={(value) => {
                onChangeInputForm('year', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  year: null,
                });
              }}
              error={errrorsInternalForm?.year}
            />
            {/* <S.TitleCheckboxes>{I18n.get("Date")}</S.TitleCheckboxes>
            <S.ContentDatePicker>
              <DatePicker
                defaultValue={dateCaseLeader}
                format={dateFormatList}
                onChange={onChangeDate}
                placeholder="__/__/____"
                value={
                  dateCaseLeader ? moment(dateCaseLeader, 'DD-MM-YYYY') : null
                }
                disabledDate={(current) => {
                  let customDate = moment().format('YYYY-MM-DD');
                  return current && current > moment(customDate, 'YYYY-MM-DD');
                }}
              />


            </S.ContentDatePicker> */}
          </Col>
        </Row>
      </>
    );
  };

  const formAward = () => {
    return (
      <>
        <Row style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Award name')}
              label
              required
              value={inputsForm.name}
              onChange={(value) => {
                onChangeInputForm('name', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  name: null,
                });
              }}
              error={errrorsInternalForm?.name}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <Input
              placeholder={I18n.get('Brand')}
              label
              required
              value={inputsForm.brand}
              onChange={(value) => {
                onChangeInputForm('brand', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  brand: null,
                });
              }}
              error={errrorsInternalForm?.brand}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <SelectOne
              options={createDateArray()}
              value={inputsForm.year}
              defaultValue=""
              required
              placeholder={I18n.get('Select year')}
              onChange={(value) => {
                onChangeInputForm('year', value);
                setErrrorsInternalForm({
                  ...errrorsInternalForm,
                  year: null,
                });
              }}
              error={errrorsInternalForm?.year}
            />
          </Col>
          <Col xs={24} sm={12} style={{ height: 80 }}>
            <SelectOne
              options={categoriesDrop}
              value={inputsForm.industry}
              defaultValue=""
              onChange={handleChangeCategory}
              required
              placeholder={I18n.get('Category')}
              error={errrorsInternalForm?.industry}
            />
          </Col>
        </Row>
      </>
    );
  };

  const addItemForm = async () => {
    if (checkboxIdTab === 'id-0') {
      if (
        inputsForm.name &&
        inputsForm.companyName &&
        inputsForm.industry &&
        inputsForm.link &&
        inputsForm.countryCode &&
        inputsForm.brand &&
        inputsForm.year
      ) {
        const id = uuid();
        const objAdd = {
          id,
          name: inputsForm.name,
          companyName: inputsForm.companyName,
          industry: inputsForm.industry,
          brand: inputsForm.brand,
          countryCode: inputsForm.countryCode,
          year: inputsForm.year,
          link: inputsForm.link,
          type: 1,
        };

        setLeaderCasesTemp((leaderCasesTemp) => [...leaderCasesTemp, objAdd]);
      } else {
        setErrrorsInternalForm({
          name: inputsForm.name ? '' : I18n.get('Fill in the name field'),
          companyName: inputsForm.companyName
            ? ''
            : I18n.get('Fill in the company name field'),
          industry: inputsForm.industry ? '' : I18n.get('Select one category'),
          link: inputsForm.link ? '' : I18n.get('Fill in the link field'),
          brand: inputsForm.brand ? '' : I18n.get('Fill in the brand field'),
          year: inputsForm.year ? '' : I18n.get('Fill in the year field'),
          countryCode: inputsForm.countryCode
            ? ''
            : I18n.get('Fill in the country field'),
        });
      }
    }

    if (checkboxIdTab === 'id-1') {
      if (
        inputsForm.name &&
        inputsForm.brand &&
        inputsForm.industry &&
        inputsForm.year
      ) {
        const id = uuid();
        const objAdd = {
          id,
          name: inputsForm.name,
          brand: inputsForm.brand,
          industry: inputsForm.industry,
          year: inputsForm.year,
          type: 2,
        };

        setLeaderAwardsTemp((leaderAwardsTemp) => [
          ...leaderAwardsTemp,
          objAdd,
        ]);
      } else {
        setErrrorsInternalForm({
          name: inputsForm.name ? '' : I18n.get('Fill in the award name field'),
          brand: inputsForm.brand ? '' : I18n.get('Fill in the brand field'),
          industry: inputsForm.industry ? '' : I18n.get('Select one category'),
          year: inputsForm.year ? '' : I18n.get('Fill in the year field'),
        });
      }
    }

    setInputsForm({});
    onChangeInfo(true);
    setDateCaseLeader(null);
  };

  const updateItemForm = async () => {
    const arr1 = companyCases.find((item) => item.id === inputsForm.id);
    const arr2 = companyAwards.find((item) => item.id === inputsForm.id);
    const arr3 = companyCasesTemp.find((item) => item.id === inputsForm.id);
    const arr4 = companyAwardsTemp.find((item) => item.id === inputsForm.id);
    const arr5 = leaderCases.find((item) => item.id === inputsForm.id);
    const arr6 = leaderAwards.find((item) => item.id === inputsForm.id);
    const arr7 = leaderAwardsTemp.find((item) => item.id === inputsForm.id);
    const arr8 = leaderCasesTemp.find((item) => item.id === inputsForm.id);

    if (arr1) {
      const companyCasesTemp = [...companyCases];
      const indexCompanyCases = companyCases.findIndex(
        (item) => item.id === inputsForm.id
      );
      companyCasesTemp[indexCompanyCases] = inputsForm;
      setCompanyCases(companyCasesTemp);
    }
    if (arr2) {
      const companyAwardsTemp = [...companyAwards];
      const indexCompanyAwards = companyAwards.findIndex(
        (item) => item.id === inputsForm.id
      );
      companyAwardsTemp[indexCompanyAwards] = inputsForm;
      setCompanyAwards(companyAwardsTemp);
    }
    if (arr3) {
      const companyCasesTempTemp = [...companyCasesTemp];
      const indexCompanyCasesTemp = companyCasesTemp.findIndex(
        (item) => item.id === inputsForm.id
      );
      companyCasesTempTemp[indexCompanyCasesTemp] = inputsForm;
      setCompanyCasesTemp(companyCasesTempTemp);
    }
    if (arr4) {
      const companyAwardsTempTemp = [...companyAwardsTemp];
      const indexCompanyAwardsTemp = companyAwardsTemp.findIndex(
        (item) => item.id === inputsForm.id
      );
      companyAwardsTempTemp[indexCompanyAwardsTemp] = inputsForm;
      setCompanyAwardsTemp(companyAwardsTempTemp);
    }
    if (arr5) {
      const leaderCasesTemp = [...leaderCases];
      const indexLeaderCases = leaderCases.findIndex(
        (item) => item.id === inputsForm.id
      );
      leaderCasesTemp[indexLeaderCases] = inputsForm;
      setLeaderCases(leaderCasesTemp);
    }
    if (arr6) {
      const leaderAwardsTemp = [...leaderAwards];
      const indexLeaderAwards = leaderAwards.findIndex(
        (item) => item.id === inputsForm.id
      );
      leaderAwardsTemp[indexLeaderAwards] = inputsForm;
      setLeaderAwards(leaderAwardsTemp);
    }
    if (arr7) {
      const leaderAwardsTempTemp = [...leaderAwardsTemp];
      const indexleaderAwardsTemp = leaderAwardsTemp.findIndex(
        (item) => item.id === inputsForm.id
      );
      leaderAwardsTempTemp[indexleaderAwardsTemp] = inputsForm;
      setLeaderAwardsTemp(leaderAwardsTempTemp);
    }
    if (arr8) {
      const leaderCasesTempTemp = [...leaderCasesTemp];
      const indexLeaderCasesTemp = leaderCasesTemp.findIndex(
        (item) => item.id === inputsForm.id
      );
      leaderCasesTempTemp[indexLeaderCasesTemp] = inputsForm;
      setLeaderCasesTemp(leaderCasesTempTemp);
    }

    setInputsForm({});
    setOnEditing(false);
    onChangeInfo(true);
    setErrrorsInternalForm({});
    setDateCaseLeader(null);
    setSelectCountry({
      countryLabel: 'Brazil',
      phoneMask: '(00)00000-0000',
      countryCode: 'BRA',
    });
  };

  const handleDeleteItem = (id) => {
    const arr1 = companyCases.find((item) => item.id === id);
    const arr2 = companyAwards.find((item) => item.id === id);
    const arr3 = companyCasesTemp.find((item) => item.id === id);
    const arr4 = companyAwardsTemp.find((item) => item.id === id);
    const arr5 = leaderCases.find((item) => item.id === id);
    const arr6 = leaderAwards.find((item) => item.id === id);
    const arr7 = leaderAwardsTemp.find((item) => item.id === id);
    const arr8 = leaderCasesTemp.find((item) => item.id === id);

    if (arr1) {
      const arrUpdated1 = companyCases.filter((item) => item.id !== id);
      setCompanyCases(arrUpdated1);
      setErrrorsInternalForm({});
    }

    if (arr2) {
      const arrUpdated2 = companyAwards.filter((item) => item.id !== id);
      setCompanyAwards(arrUpdated2);
      setErrrorsInternalForm({});
    }

    if (arr3) {
      const arrUpdated3 = companyCasesTemp.filter((item) => item.id !== id);
      setCompanyCasesTemp(arrUpdated3);
      setErrrorsInternalForm({});
    }

    if (arr4) {
      const arrUpdated4 = companyAwardsTemp.filter((item) => item.id !== id);
      setCompanyAwardsTemp(arrUpdated4);
      setErrrorsInternalForm({});
    }

    if (arr5) {
      const arrUpdated5 = leaderCases.filter((item) => item.id !== id);
      setLeaderCases(arrUpdated5);
      setErrrorsInternalForm({});
    }

    if (arr6) {
      const arrUpdated6 = leaderAwards.filter((item) => item.id !== id);
      setLeaderAwards(arrUpdated6);
      setErrrorsInternalForm({});
    }

    if (arr7) {
      const arrUpdated7 = leaderAwardsTemp.filter((item) => item.id !== id);
      setLeaderAwardsTemp(arrUpdated7);
      setErrrorsInternalForm({});
    }

    if (arr8) {
      const arrUpdated8 = leaderCasesTemp.filter((item) => item.id !== id);
      setLeaderCasesTemp(arrUpdated8);
      setErrrorsInternalForm({});
    }
  };

  const handleEditItem = async ({ type, id }) => {
    setOnEditing(true);
    setCheckboxIdTab(type);
    setErrrorsInternalForm({});

    const arrOfCasesOrAwards = [
      ...companyCases,
      ...companyCasesTemp,
      ...companyAwards,
      ...companyAwardsTemp,

      ...leaderAwards,
      ...leaderAwardsTemp,
      ...leaderCases,
      ...leaderCasesTemp,
    ];

    const editCaseOrAward = arrOfCasesOrAwards.find((item) => item.id === id);

    let objToEdit = {};
    if (type === 'id-0') {
      objToEdit = {
        id: editCaseOrAward.id,
        name: editCaseOrAward.name,
        brand: editCaseOrAward.brand,
        companyName: editCaseOrAward.companyName,
        countryCode: editCaseOrAward.countryCode,
        date: editCaseOrAward.date,
        industry: editCaseOrAward.industry,
        link: editCaseOrAward.link,
        participantsUsersEmail: editCaseOrAward.participantsUsersEmail,
        participated: editCaseOrAward.participated,
        type: editCaseOrAward.type,
      };

      onChangeDate('', editCaseOrAward.date);

      const country = COUNTRY_CODES.find(
        (item) => item.code === editCaseOrAward.countryCode
      );
      setSelectCountry({
        countryLabel: country ? country?.name : selectCountry.countryLabel,
        phoneMask: country ? country.mask : selectCountry.phoneMask,
        countryCode: country ? country?.code : selectCountry.countryCode,
      });
    }

    if (type === 'id-1') {
      objToEdit = {
        id: editCaseOrAward.id,
        brand: editCaseOrAward.brand,
        industry: editCaseOrAward.industry,
        link: editCaseOrAward.link,
        name: editCaseOrAward.name,
        participantsUsersEmail: editCaseOrAward.participantsUsersEmail,
        participated: editCaseOrAward.participated,
        type: editCaseOrAward.type,
        year: editCaseOrAward.year,
      };

      onChangeDate('', null);
    }

    setInputsForm(objToEdit);
  };

  const tabsMock = [
    {
      key: 0,
      label: I18n.get('Your company'),
      component: <div />,
    },
    {
      key: 1,
      label: I18n.get('Your career'),
      component: (
        <div>
          <BoxWithCheckboxes
            checkboxes={[I18n.get('Case'), I18n.get('Award')]}
            checkboxIdTab={checkboxIdTab}
            onChangeCheck={(id) => onChangeCheck({ id })}
            components={[formCase(), formAward()]}
            actions={[
              <ButtonBpool
                text={I18n.get(!onEditing ? 'Add' : 'Save')}
                theme="primary"
                onClick={
                  !onEditing ? () => addItemForm() : () => updateItemForm()
                }
              />,
            ]}
          />
        </div>
      ),
    },
  ];

  const onChangeInputForm = (id, value) => {
    setInputsForm({
      ...inputsForm,
      [id]: value ? value : null,
    });
  };

  const prepareToken = async (url) => {
    const arrayOfStrings = url.split('/');
    const tkn = arrayOfStrings[arrayOfStrings.length - 1];
    return tkn;
  };

  const newGetStepbar = async () => {
    const responseStepbar = await getStepBarLeader(token);
    const step = responseStepbar?.data?.statusBarRegistration?.find(
      (item) => item.step === PAGE_INDEX
    );
    const statusStep = await statusIdToName({ status: step.status });
    await toUpdateStatusInStep({ screen: PAGE_INDEX, status: statusStep });
    return statusStep;
  };

  const send = async () => {
    setIsSending(true);

    const objToSend = {
      partnerId: dataUser.partnerId,
      id: dataUser.id,
      verificationToken: dataUser.verificationToken,
      participedCasesIds,
      participedAwardsIds,
      cases: [...leaderCases, ...leaderCasesTemp],
      awards: [...leaderAwards, ...leaderAwardsTemp],
    };

    if (contextPage === "edit") {
      const responseLogged = await putCasesAndAwardsLogged(objToSend);

      if (responseLogged.success) {
        sendOk(true);
      } else {
        sendOk(false)
      }
      setIsSending(false);

    } else {
      const response = await putCasesAndAwards(objToSend);
      await newGetStepbar();

      if (response.success) {
        await toUpdateFlowStorageSteps({ screen: PAGE_INDEX });
        history.push({
          pathname: `${urls[PAGE_INDEX + 1]}/${token}`,
        });
      } else {
        message.error(I18n.get(MESSAGE_ERROR));
      }

      setIsSending(false);
    }

  };

  const setInfos = async ({ response, tokenUrl }) => {
    setDataUser({
      partnerId: response.data.partnerId,
      id: response.data.id,
      verificationToken: tokenUrl,
    });

    setLeaderCases(
      response?.data?.leaderCases ? response?.data?.leaderCases : []
    );
    setLeaderAwards(
      response?.data?.leaderAwards ? response?.data?.leaderAwards : []
    );
    setCompanyCases(
      response?.data?.companyCases ? response?.data?.companyCases : []
    );
    setCompanyAwards(
      response?.data?.companyAwards ? response?.data?.companyAwards : []
    );
  };

  const loadInit = async () => {
    const tokenUrl = await prepareToken(location?.pathname);
    if (tokenUrl) {
      setToken(tokenUrl);
      setIsLoadingPage(true);

      if (contextPage === "edit") {
        const responseLogged = await getCasesAndAwardsLogged();

        if (responseLogged.success) {
          await setInfos({ response: responseLogged, tokenUrl: null });
        } else {
          message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
        }

        const responseCategories = await getIndustriesDropDownLogged({ admin: false });
        if (responseCategories.success) {
          const categories = responseCategories.data.map((category) => ({
            code: category.id,
            label: category.description,
          }));
          setCategoriesDrop(categories);
        }
        setIsLoadingPage(false);
      } else {
        const response = await getCasesAndAwards(tokenUrl);

        if (response.success) {
          await setInfos({ response, tokenUrl });
        } else {
          message.error(I18n.get(MESSAGE_ERROR_GET_PAGE));
        }

        const responseCategories = await getIndustriesToDropdownCases(tokenUrl);
        if (responseCategories.success) {
          const categories = responseCategories.data.map((category) => ({
            code: category.id,
            label: category.description,
          }));
          setCategoriesDrop(categories);
        }

        setIsLoadingPage(false);
      }
    }
  };

  const handleBackPage = () => {
    const url = `${urls[PAGE_INDEX - 1]}/${token}`;

    if (url) {
      history.push({
        pathname: url,
      });
    } else {
      message.error(I18n.get(MESSAGE_ERROR_BACK_PAGE));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadInit();
  }, []);

  return (
    <>
      <Main bgColor="#fff" padding="30px">
        <Row>
          <Col xs={24}>
            <S.RowCenter>
              <S.TitleWithIcon>
                <S.H4>{I18n.get('Cases and Awards')}</S.H4>
              </S.TitleWithIcon>
              <S.Paragraph>
                {I18n.get(
                  'Our algorithm wants to know more about you, who are in the lead. Remember that each information is valuable for him to find projects that have to do with your company.'
                )}
              </S.Paragraph>
            </S.RowCenter>
          </Col>
        </Row>

        <Row>
          <Col xs={24} style={{ marginTop: 30 }}>
            <S.TitleSectionForm>
              {I18n.get('Cases and Awards')}
            </S.TitleSectionForm>
          </Col>
        </Row>

        {isLoadingPage ? (
          <Row>
            <Col xs={24}>
              <S.ContentIsLoading>
                <Loading
                  text={I18n.get('Loading informations...')}
                  sizeText={14}
                  sizeIcon={16}
                />
              </S.ContentIsLoading>
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <TabsSelect
                  tabs={tabsMock}
                  tabDefault={tabSelect}
                  onChange={(keyTab) => setTabSelect(keyTab)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col xs={24}>
                <ItemCase
                  textEmpty=""
                  data={
                    tabSelect === 0
                      ? [...companyCases, ...companyCasesTemp]
                      : [...leaderCases, ...leaderCasesTemp]
                  }
                  onDelete={(id) => handleDeleteItem(id)}
                  onEdit={(id) => handleEditItem({ type: 'id-0', id })}
                  typeOfTab={tabSelect}
                  addOrRemoveParticipedCasesIds={(ids) =>
                    setParticipedCasesIds(ids)
                  }
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 15, marginBottom: 60 }}>
              <Col xs={24}>
                <ItemAward
                  textEmpty=""
                  data={
                    tabSelect === 0
                      ? [...companyAwards, ...companyAwardsTemp]
                      : [...leaderAwards, ...leaderAwardsTemp]
                  }
                  onDelete={(id) => handleDeleteItem(id)}
                  onEdit={(id) => handleEditItem({ type: 'id-1', id })}
                  typeOfTab={tabSelect}
                  addOrRemoveParticipedAwardsIds={(ids) =>
                    setParticipedAwardsIds(ids)
                  }
                />
              </Col>
            </Row>
          </>
        )}

        <Row style={{ marginTop: 30 }}>
          {contextPage === 'edit' ? null : (
            <Col xs={24} sm={12}>
              <ButtonBpool
                text={I18n.get('Back')}
                theme="secondary"
                full
                loading={isSending}
                onClick={handleBackPage}
              />
            </Col>
          )}
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={
              contextPage === "edit"
                ? { span: 24, offset: 0 }
                : { span: 12, offset: 0 }
            }>
            <ButtonBpool
              text={contextPage === "edit" ? I18n.get('Save') : I18n.get('Save and Next')}
              theme="primary"
              full
              loading={isSending}
              onClick={send}
            />
          </Col>
        </Row>
      </Main>
    </>
  );
};
