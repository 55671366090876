import React from 'react';

import { I18n } from 'aws-amplify';

import Logo from '../../svg/logo.svg';

class LogoButton extends React.Component {
    constructor(props) {

        super();
        this.state = {
        }
    }

    static defaultProps = {
        className: 'button is-black is-fullwidth',
        style: {}
    }

    render() {

        const style = {
            height: '4.5rem',
            fontSize: '120%',
            fontWeight: 'bold',
            minWidth: '250px',
            ...this.props.style
        };

        const is_busy = this.props.task && this.props.task.busy;

        const className = is_busy ? [this.props.className, "is-loading"].join(' ') : this.props.className;

        return (<button onClick={this.props.onClick} type="button" className={className} disabled={this.props.disabled} style={style}>
            <div style={{ width: '70px', marginRight: '1rem', paddingRight: '1rem', borderRight: 'solid 1px #444444' }}><img src={Logo} alt="Ollo" /></div>
            <span>{this.props.label}</span>
        </button>);
    }
}

export default LogoButton;
