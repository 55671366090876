import { I18n } from "aws-amplify";

export class Profile {
  static get BPool() {
    return 0;
  }

  static get Partner() {
    return 1;
  }

  static get Client() {
    return 2;
  }

  static get Leader() {
    return 3;
  }

  static get Master() {
    return 4;
  }

  static get ClientUserGlobalContract() {
    return 5;
  }

  static get ClientUserContract() {
    return 6;
  }

  static get ClientUserPayment() {
    return 7;
  }

  static get ClientUserMarketing() {
    return 8;
  }

  static get ClientUserPurchasing() {
    return 9;
  }

  static get ClientUserLegal() {
    return 10;
  }

  static get PartnerUserLeader() {
    return 11;
  }
}

export class PartnerStatus {
  static get None() {
    return 0;
  }

  static get Incomplete() {
    return 1;
  }

  static get Created() {
    return 2;
  }

  static get Referenced() {
    return 3;
  }

  // static get BackgroundChecked() {
  //     return 4;
  // }

  static get PreApproved() {
    return 5;
  }

  // static get Evaluation() {
  //     return 6;
  // }

  static get Approved() {
    return 7;
  }

  static get Rejected() {
    return 90;
  }

  static get Inactive() {
    return 91;
  }
}

export class IdeaWhatIfStatus {
  static get None() {
    return 0;
  }
  static get Draft() {
    return 1;
  }
  static get Created() {
    return 2;
  }
  static get Approved() {
    return 15;
  }
  static get ApprovedWithPO() {
    return 16;
  }
  static get Disapproved() {
    return 18;
  }
  static get Finished() {
    return 20;
  }
  static get Canceled() {
    return 90;
  }
}

export class PartnerWhatIfStatus {
  static get None() {
    return 0;
  }

  static get Incomplete() {
    return 1;
  }

  static get CompletedStep1() {
    return 2;
  }

  static get PreApproved() {
    return 3;
  }

  static get Approved() {
    return 4;
  }

  static get Rejected() {
    return 90;
  }

  static get Inactive() {
    return 91;
  }
}

export class ClientStatus {
  static get None() {
    return 0;
  }

  static get Incomplete() {
    return 1;
  }

  static get Created() {
    return 2;
  }

  static get BackgroundChecked() {
    return 4;
  }

  static get Approved() {
    return 7;
  }

  static get Rejected() {
    return 90;
  }

  static get Inactive() {
    return 91;
  }
}

export class ClientProjectStatus {
  /**
   * Nenhum.
   */
  static get None() {
    return 0;
  }

  /**
   * Cliente começou colocar o projeto mas não terminou. Modo de Rascunho.
   */
  static get Draft() {
    return 1;
  }

  /**
   * Completado pelo cliente.
   */
  static get Created() {
    return 2;
  }

  /**
   * BPool seleciona 3 parceiros, e aguarda eles aceitarem.
   */
  static get PreMatched() {
    return 3;
  }

  /**
   * 3 parceiros aceitaram participar.
   */
  static get Matched() {
    return 4;
  }

  /**
   * Cliente escolhe o parceiro que quer trabalhar. Status depende do usuário que criou o projeto.
   */
  static get PartnerSelected() {
    return 5;
  }

  /**
   * Cliente aprova proposta do parceiro (fase definições). Informar SPA#.
   */
  static get DefinitionsSet() {
    return 6;
  }

  /**
   * Projeto começou. Cliente que já tem #SPA. Em Andamento.
   */
  static get Started() {
    return 7;
  }
  /**
   * Projeto em andamento e o parceiro solicitou conclusão e enviou arquivos de finalização de projeto.
   */
  static get RequestComplete() {
    return 9;
  }

  /**
   * Projeto finalizado.
   */
  static get Finished() {
    return 20;
  }

  /**
   * Projeto cancelado.
   */
  static get Canceled() {
    return 90;
  }
}

export class ClientProjectProductionStatus {
  static get None() {
    return 0;
  }

  static get Draft() {
    return 1;
  }

  static get Created() {
    return 2;
  }

  static get Quoted() {
    return 3;
  }

  static get Finished() {
    return 20;
  }

  static get Canceled() {
    return 90;
  }
}

export class ProjectType {
  static get Creative() {
    return 0;
  }

  static get EventBundle() {
    return 1;
  }

  static get EventCustom() {
    return 2;
  }

  static get Ideas() {
    return 3;
  }

  static get BPitch() {
    return 4;
  }
}

export const ProjectTypeNames = {
  [ProjectType.Creative]: I18n.get("BPool Marketplace"),
  [ProjectType.EventBundle]: I18n.get("BPool Proposal (Bundle)"),
  [ProjectType.EventCustom]: I18n.get("BPool Proposal"),
  [ProjectType.Ideas]: I18n.get("Idéias"),
  [ProjectType.BPitch]: I18n.get("BPitch"),
};

export const ClientProjectStatusNames = {
  [ClientProjectStatus.Draft]: I18n.get("Rascunho"),
  [ClientProjectStatus.Created]: I18n.get("Matching..."),
  [ClientProjectStatus.PreMatched]: I18n.get("Seleção Parceiro"),
  [ClientProjectStatus.Matched]: I18n.get("Seleção Parceiro"),
  [ClientProjectStatus.PartnerSelected]: I18n.get("Briefing & Cronograma"),
  [ClientProjectStatus.DefinitionSet]: I18n.get("Informando SPA"),
  [ClientProjectStatus.Started]: I18n.get("Em andamento"),
  [ClientProjectStatus.RequestComplete]: I18n.get("Em andamento"),
  [ClientProjectStatus.Finished]: I18n.get("Finalizado"),
  [ClientProjectStatus.Canceled]: I18n.get("Cancelado"),
};

export class CustomEventBriefingType {
  static get Event() {
    return 0;
  }

  static get Shopper() {
    return 1;
  }

  static get Incentive() {
    return 2;
  }

  static get Digital() {
    return 3;
  }

  static get Communication() {
    return 4;
  }

  static get Promotional() {
    return 5;
  }
}

export const CustomEventBriefingTypeNames = {
  [CustomEventBriefingType.Communication]: I18n.get("Comunicação/Digital"),
  [CustomEventBriefingType.Event]: I18n.get("Evento/Ativação de Marca"),
  [CustomEventBriefingType.Shopper]: I18n.get("Shopper Marketing"),
  [CustomEventBriefingType.Incentive]: I18n.get("Campanhas de Incentivo"),
  [CustomEventBriefingType.Digital]: I18n.get("Evento Digital"),
  [CustomEventBriefingType.Promotional]: I18n.get("Brindes/materiais gráficos"),
};
//
export const CustomEventBriefingRules = {
  [CustomEventBriefingType.Communication]:
    "bpCommissionTaxCustomEventCommunicationRules",
  [CustomEventBriefingType.Event]: "bpCommissionTaxCustomEventEventRules",
  [CustomEventBriefingType.Shopper]: "bpCommissionTaxCustomEventShopperRules",
  [CustomEventBriefingType.Incentive]:
    "bpCommissionTaxCustomEventIncetiveRules",
  [CustomEventBriefingType.Digital]: "bpCommissionTaxCustomEventDigitalRules",
  [CustomEventBriefingType.Promotional]:
    "bpCommissionTaxCustomEventPromotionalRules",
};

export class ClientProjectExtraStatus {
  static get None() {
    return 0;
  }

  static get Created() {
    return 1;
  }

  static get Finished() {
    return 20;
  }

  static get Canceled() {
    return 90;
  }
}

export class ClientProjectEventQuotationInternalType {
  static get Asset() {
    return 0;
  }

  static get Perc() {
    return 1;
  }

  static get Piece() {
    return 2;
  }
}

export class BPitchType {
  static get PerProject() {
    return 0;
  }

  static get PerYear() {
    return 1;
  }
}

export const BPitchTypeNames = {
  [BPitchType.PerProject]: I18n.get("Por Projeto"),
  [BPitchType.PerYear]: I18n.get("Parceiro Fixo & Projeto Anual"),
};

export class BPitchScopeType {
  static get CommunicationIntegrated() {
    return 0;
  }

  static get CommunicationDigital() {
    return 1;
  }

  static get LiveMarketingEvent() {
    return 10;
  }

  static get LiveMarketingShopper() {
    return 11;
  }

  static get LiveMarketingIncentive() {
    return 12;
  }

  static get LiveMarketingActivation() {
    return 13;
  }
}

export const BPitchScopeTypeNames = {
  [BPitchScopeType.CommunicationIntegrated]: I18n.get("Integrada"),
  [BPitchScopeType.CommunicationDigital]: I18n.get("Digital"),
  [BPitchScopeType.LiveMarketingEvent]: I18n.get("Evento"),
  [BPitchScopeType.LiveMarketingShopper]: I18n.get("Shopper Marketing"),
  [BPitchScopeType.LiveMarketingIncentive]: I18n.get("Campanhas de Incentivo"),
  [BPitchScopeType.LiveMarketingActivation]: I18n.get("Ativação"),
};

export class ProjectExtraType {
  static get Production() {
    return 0;
  }

  static get Media() {
    return 1;
  }

  static get Tools() {
    return 2;
  }

  static get Finance() {
    return 3;
  }

  static get InternalCosts() {
    return 4;
  }

  static get ExternalCosts() {
    return 5;
  }
}

export const ProjectExtraTypeNames = {
  [ProjectExtraType.Production]: I18n.get("Produção"),
  [ProjectExtraType.Media]: I18n.get("Compra Mídia/Influencers"),
  [ProjectExtraType.Tools]: I18n.get("Ferramentas"),
  [ProjectExtraType.Finance]: I18n.get("Transações Financeiras"),
  [ProjectExtraType.InternalCosts]: I18n.get("Custos Internos"),
  [ProjectExtraType.ExternalCosts]: I18n.get("Custos Externos"),
};

export const CurrencySymbols = {
  BRL: "R$",
  USD: "$",
  EUR: "€",
  COP: "$",
  MXN: "$",
};

export class BPCommissionType {
  static get None() {
    return 0;
  }

  static get Perc() {
    return 1;
  }

  static get Flat() {
    return 2;
  }
}

export const BPCommissionTypeNames = {
  [BPCommissionType.None]: I18n.get("Nenhum(a)"),
  [BPCommissionType.Perc]: I18n.get("Percentual"),
  [BPCommissionType.Flat]: I18n.get("Fixa"),
};

export class BPCompensationType {
  static get None() {
    return 0;
  }

  static get Perc() {
    return 1;
  }

  static get Flat() {
    return 2;
  }
}

export const BPCompensationTypeNames = {
  [BPCompensationType.None]: I18n.get("Nenhum(a)"),
  [BPCompensationType.Perc]: I18n.get("Percentual"),
  [BPCompensationType.Flat]: I18n.get("Fixa"),
};

export class BiddingPhase {
  static get None() {
    return 0;
  }

  static get PartnerInvite() {
    return 1;
  }

  static get Quotation() {
    return 2;
  }
}

export class ClientProjectUniversalQuotationSectionType {
  static get None() {
    return 0;
  }

  static get Assets() {
    return 1;
  }

  static get Items() {
    return 2;
  }

  static get GenericItems() {
    return 3;
  }

  static get PercItems() {
    return 4;
  }

  static get HourItems() {
    return 5;
  }
}

export const ClientProjectUniversalQuotationSectionTypeNames = {
  ["None"]: I18n.get("Nenhum(a)"),
  ["Assets"]: I18n.get("Entregáveis"),
  ["Items"]: I18n.get("Itens Externos"),
  ["GenericItems"]: I18n.get("Itens Livres"),
  ["PercItems"]: I18n.get("Itens Percentuais"),
  ["HourItems"]: I18n.get("Itens Hora"),
};

export const ClientProjectUniversalQuotationSectionTypeStrings = {
  [ClientProjectUniversalQuotationSectionType.None]: "None",
  [ClientProjectUniversalQuotationSectionType.Assets]: "Assets",
  [ClientProjectUniversalQuotationSectionType.Items]: "Items",
  [ClientProjectUniversalQuotationSectionType.GenericItems]: "GenericItems",
  [ClientProjectUniversalQuotationSectionType.PercItems]: "PercItems",
  [ClientProjectUniversalQuotationSectionType.HourItems]: "HourItems",
};

export class WarningType {
  /**
   * Cliente precisa informar SPA.
   */
  static get ClientPo() {
    return 1;
  }

  /**
   * Cliente Precisa aprovar escopo.
   */
  static get ClientApproveScope() {
    return 2;
  }

  /**
   * Selecionar Parceiro.
   */
  static get ClientSelectPartner() {
    return 4;
  }

  /**
   * Parceiro precisa aceitar convite.
   */
  static get PartnerInvite() {
    return 5;
  }

  /**
   * TEP Cliente precisa assinar.
   */
  static get TEPClient() {
    return 6;
  }

  /**
   * TEP parceiro precisa assinar.
   */
  static get TEPPartner() {
    return 7;
  }

  /**
   * Parceiro precisa enviar escopo para aprovação
   */
  static get PartnerNeedSubmitScope() {
    return 8;
  }

  /**
   * Parceiro precisa fazer upload da NF do valor liberado.
   */
  static get PartnerNeedUploadInstallment() {
    return 9;
  }

  /**
   * Cliente precisa aprovar/reprocar cotação enviada pelo parceiro.
   */
  static get ClientNeedApproveExtraQuotation() {
    return 10;
  }

  /**
   * Cliente precisa coloar SPA da cotação que ele aprovou.
   */
  static get ClientNeedAddPOExtraQuotation() {
    return 11;
  }

  /**
   * Parceiro precisa responder ao convite (Aceitar ou Declinar).
   */
  static get PartnerNeedToRespondToAProjectInvitation() {
    return 12;
  }
}

export class SupplierBillingStatusType {
  static get Created() {
    return 0;
  }

  static get SupplierNotified() {
    return 1;
  }

  static get InvoiceUploaded() {
    return 2;
  }

  static get PaymentConfirmedByBank() {
    return 3;
  }

  /// <summary>
  /// Conta Bancária foi informado errada.
  /// </summary>
  static get BankAccountIsWrong() {
    return 4;
  }
}
