import { I18n } from "aws-amplify";

export const tabsInProgress = async ({ isClient, projectId }) => {
  const listOfTabs = [
    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/in-progress/review`,
      label: I18n.get("Review"),
      key: "review",
    },
    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/in-progress/files`,
      label: I18n.get("Arquivos"),
      key: "files",
    },
    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/in-progress/briefing`,
      label: I18n.get("Briefing"),
      key: "briefing",
    },
    isClient
      ? {
          url: `/cliente/projetos/v2/${projectId}/in-progress/po`,
          label: "SPA",
          key: "po",
        }
      : null,
    {
      url: `/${
        isClient ? "cliente" : "parceiro"
      }/projetos/v2/${projectId}/in-progress/extras`,
      label: I18n.get("Extras"),
      key: "extras",
    },
  ];

  return listOfTabs.filter((item) => item !== null && typeof item === "object");
};
