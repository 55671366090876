import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Icon } from "antd";
import { useBpay } from "../../../../../../hooks/useBpay/useBpay.hook";
import { Alert } from "../../../../../../components/UI/Alert";
import { ModalBp } from "../../../../../../components/UI/ModalBp/ModalBp";
import { NotificationBPool } from "../../../../../../components/UI/NotificationBPool";
import BpayContext from "../../../../context/BpayContext";
import {
  createQueryString,
  parseQueryString,
} from "../../../List/Filters/utils/resolvers";

import * as S from "./styles";

export const TableSuppliers = ({
  data,
  isLoading,
  alertInfo,
  onCloseAlert,
  valuesDivergets,
}) => {
  let params = useParams();
  let history = useHistory();
  let location = useLocation();
  const { deleteSuppliers } = useBpay();
  const { fetchDataBpayUpdate } = useContext(BpayContext);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const [alertInfoInternal, setAlertInfoInternal] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, id: null });
  const [hasSupplierDuplicated, setHasSupplierDuplicated] = useState(false);

  const handleDeleteSupplier = async ({ id }) => {
    setIsLoadingInternal(true);
    try {
      await deleteSuppliers({ bpayId: params?.bpayId, id });
      // setAlertInfoInternal({
      //   type: "success",
      //   message: "Pagamento Fornecedor foi excluído com sucesso",
      // });
      await fetchDataBpayUpdate({ id: params?.bpayId });
      NotificationBPool.open({
        type: "success",
        title: I18n.get("Sucesso!"),
        description: I18n.get("Pagamento Fornecedor foi excluído com sucesso"),
        duration: 3,
      });
    } catch (error) {
      // setAlertInfoInternal({
      //   type: "error",
      //   message: "Erro ao deletar fornecedor",
      // });
      NotificationBPool.open({
        type: "error",
        title: I18n.get("Erro!"),
        description: I18n.get("Erro ao deletar pagamento de fornecedor"),
        duration: 3,
      });
    }
    setIsLoadingInternal(false);
    setOpenModal({ open: false, id: null });
  };

  const columns = [
    {
      title: I18n.get("Fornecedor"),
      dataIndex: "name",
      width: 140,
    },
    {
      title: I18n.get("Sku"),
      dataIndex: "sku",
      width: 133,
    },
    {
      title: I18n.get("Email 1"),
      dataIndex: "emailPrimary",
      width: 133,
    },
    {
      title: I18n.get("Email 2"),
      dataIndex: "emailSecondary",
      width: 133,
    },
    {
      title: I18n.get("Telefone"),
      dataIndex: "phoneNumber",
      width: 133,
    },
    {
      title: I18n.get("Valor"),
      dataIndex: "totalFmt",
      width: 133,
    },
    {
      title: "",
      dataIndex: "id",
      width: 60,
      render: (type, record) => (
        <S.ButtonDelete
          onClick={() => setOpenModal({ open: true, id: record.id })}
        >
          <Icon type="delete" />
        </S.ButtonDelete>
      ),
    },
  ];

  const handlePagination = (page) => {
    let objQueries = parseQueryString(location?.search);

    let queryString = "";

    if (!page || page === 1) {
      delete objQueries.page;
      queryString = createQueryString({ ...objQueries });
    } else {
      queryString = createQueryString({ ...objQueries, page });
    }
    history.push(`${location.pathname}${queryString}`);
  };

  const FooterTable = () => {
    return (
      <S.FooterTable>
        <p style={{ marginRight: 46 }}>
          <span>{I18n.get("Total Ollo Pay")}</span>
          <strong style={{ fontWeight: 400 }}>{data?.totalBpay}</strong>
        </p>
        <p>
          <span style={{ fontWeight: 600 }}>{I18n.get("Total BPayments")}</span>
          <strong>{data?.totalBpayments}</strong>
        </p>
      </S.FooterTable>
    );
  };

  useEffect(() => {
    if (data?.list?.length) {
      const hasDuplicateds = data?.list?.some(
        (item) => item?.isDuplicated === true
      );
      setHasSupplierDuplicated(hasDuplicateds);
    }
  }, [data]);

  return (
    <>
      <S.Wrapper>
        <div>
          {/* {alertInfoInternal && (
            <Alert
              message={alertInfoInternal?.message}
              type={alertInfoInternal?.type}
              autoClose={false}
              notClosable
              onClose={() => setAlertInfoInternal(null)}
            />
          )} */}
          {alertInfo && (
            <Alert
              message={alertInfo?.message}
              type={alertInfo?.type}
              autoClose={false}
              notClosable
              onClose={onCloseAlert}
            />
          )}
        </div>

        {hasSupplierDuplicated ? (
          <div style={{ marginTop: 10 }}>
            <Alert
              message={I18n.get("Dados duplicados")}
              description={I18n.get(
                "Revise abaixo os dados em negrito, existem fornecedores com dados duplicados"
              )}
              type="error"
              autoClose={false}
              notClosable
            />
          </div>
        ) : null}

        {valuesDivergets?.isDivergent ? (
          <div style={{ height: 95, marginTop: 16 }}>
            <Alert
              message={I18n.get("Valor dos BPayments difere do valor total")}
              description={valuesDivergets?.message}
              type={"warning"}
              notClosable
              onClose={() => true}
            />
          </div>
        ) : null}

        {data && data?.list?.length ? (
          <>
            <S.Table
              columns={columns}
              dataSource={data?.list}
              pagination={{
                pageSize: 10,
                total: data?.totalRecords,
                defaultCurrent: parseQueryString(location?.search)?.page
                  ? Number(parseQueryString(location?.search)?.page)
                  : 1,
                onChange: (page) => {
                  handlePagination(page);
                },
              }}
              loading={isLoading || isLoadingInternal}
              style={{ marginTop: 15 }}
              onRow={(record, rowIndex) => {
                if (record?.isDuplicated) {
                  return { className: "supplier-duplicated" };
                }
              }}
              footer={FooterTable}
            />

            {/* <S.Totals>
              <div />
              <S.TotalInfo isLoading={isLoading || isLoadingInternal}>
                <S.Skeleton
                  loading={isLoading || isLoadingInternal}
                  active
                  title={false}
                  paragraph={{
                    rows: 2,
                    className: "skeleton-totals",
                  }}
                >
                  <div className="text-total">
                    {I18n.get("Valor total BPayments")}
                  </div>
                  <div className="value-total">{data?.totalBpayments}</div>
                </S.Skeleton>
              </S.TotalInfo>
              <S.TotalInfo
                isLoading={isLoading || isLoadingInternal}
                style={{ alignItems: "flex-end", paddingRight: 30 }}
              >
                <S.Skeleton
                  loading={isLoading || isLoadingInternal}
                  active
                  title={false}
                  paragraph={{
                    rows: 2,
                    className: "skeleton-totals right",
                  }}
                >
                  <div>
                    <div className="text-total">
                      {I18n.get("Valor total Bpay")}
                    </div>
                    <div className="value-total">{data?.totalBpay}</div>
                  </div>
                </S.Skeleton>
              </S.TotalInfo>
            </S.Totals> */}
          </>
        ) : (
          <div />
        )}
      </S.Wrapper>
      <ModalBp
        visible={openModal.open}
        bodyText={I18n.get("Você gostaria de excluir este pagamento?")}
        subBodyText={I18n.get("Você vai perder este item.")}
        okText={I18n.get("Deletar")}
        cancelText={I18n.get("Cancelar")}
        handleClickOk={() => handleDeleteSupplier({ id: openModal.id })}
        onCancel={() => setOpenModal({ open: false, id: null })}
        typeModal="isConfirm"
        isLoading={isLoadingInternal}
        isDanger
        isAlert
      />
    </>
  );
};
