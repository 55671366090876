import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f8f8f8;
  position: relative;
  min-height: 100vh;
  display: grid;
  grid-template-areas: 'header' 'content' 'footer';
  grid-template-rows: 60px calc(100% - 160px) 100px;
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

export const HeaderContent = styled.div`
  position: relative;
  bottom: 0;
  background-color: #007aff;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
`;

export const ContentLogo = styled.div`
  width: 50%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 30px;
  }
`;

export const ContentUser = styled.div`
  width: 25%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  strong {
    font-weight: 400;
    color: #ffffff;

    span {
      padding-right: 5px;
      text-transform: uppercase;
    }
  }
`;

export const Dropdown = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

export const DropdownOpened = styled.ul`
  width: auto;
  min-width: 130px;
  min-height: 60px;
  padding: 10px 0;
  background: #fff;
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 0;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};

  li {
    list-style: none;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;

    button {
      cursor: pointer;
      padding: 5px;
      width: 100%;
      border: none;
      background: transparent;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const Footer = styled.div`
  grid-area: footer;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`;

export const ContentFooter = styled.div`
  position: relative;
  bottom: 0;
  background-color: #007aff;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  span {
    color: #ffffff;
    font-weight: 600;
    padding: 0px 20px;
    margin: 0px;
  }
`;
