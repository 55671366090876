import { I18n } from "aws-amplify";

import { LOGIN_SUCCESS } from "./actions";
import { CHAT_API } from "../chat/actions";

import { BP_API } from "../bp/actions";

const api_login_success = (logged_user) => {

  return (dispatch) => {
    const groups =
      logged_user.signInUserSession.getIdToken().payload["cognito:groups"];
    const email = logged_user.signInUserSession.getIdToken().payload["email"];
    const name = logged_user.signInUserSession.getIdToken().payload["name"];

    // add profileGroups to storage
    localStorage.setItem("@BPOOL-profileGroups", JSON.stringify(groups));

    const clientId =
      logged_user.signInUserSession.getIdToken().payload["custom:clientId"];
    const partnerId =
      logged_user.signInUserSession.getIdToken().payload["custom:partnerId"];
    const supplierId =
      logged_user.signInUserSession.getIdToken().payload["custom:supplierId"];
    const locale =
      logged_user.signInUserSession.getIdToken().payload["locale"] || "pt";

    const clonedFromUsername =
      logged_user.signInUserSession.getIdToken().payload["custom:bpl"];
    const clonedFromEmail =
      logged_user.signInUserSession.getIdToken().payload["custom:bple"];

    //chama domain no idioma da pessoa
    dispatch(BP_API.get_domain(locale));

    const profile = groups ? groups[0] : "";

    let prof = "";
    let is_client = clientId && clientId.length > 0; //(profile == 'Client') || (profile.substring(0, 10) == 'ClientUser');
    let is_partner = partnerId && partnerId.length > 0; //(profile == 'Partner' || profile == 'Leader' || profile == 'PartnerUserLeader');

    let hasGroup_bpool = groups.includes("BPool");
    let hasGroup_master = groups.includes("Master");
    let hasGroup_master_or_bpool =
      groups.includes("BPool") || groups.includes("Master");

    let hasGroup_partner =
      groups.includes("Partner") ||
      groups.includes("PartnerLeader") ||
      groups.includes("PartnerOperation") ||
      groups.includes("PartnerFinancial") || groups.includes("PartnerAndSupplier");
    let hasGroup_partner_operation =
      groups.includes("PartnerOperation") || groups.includes("Partner");
    let hasGroup_partner_financial = groups.includes("PartnerFinancial");
    let hasGroup_partner_user =
      groups.includes("PartnerOperation") ||
      groups.includes("PartnerFinancial");
    let hasGroup_partner_leader = groups.includes("PartnerLeader");
    let hasGroup_partner_supplier = hasGroup_partner && groups.includes("PartnerAndSupplier");


    let hasGroup_client = groups.includes("Client");
    let hasGroup_client_user =
      groups.includes("ClientUserMarketing") ||
      groups.includes("ClientUserPurchasing");
    let hasGroup_client_user_marketing = groups.includes("ClientUserMarketing");
    let hasGroup_client_user_purchasing = groups.includes(
      "ClientUserPurchasing"
    );
    let hasGroup_supplier = groups.includes("Supplier");
    let hasGroup_client_or_client_user =
      groups.includes("Client") ||
      groups.includes("ClientUserMarketing") ||
      groups.includes("ClientUserPurchasing");

    // Seta o idioma do usuario
    I18n.setLanguage(locale);

    // if (logged_user && logged_user.username) {
    //   window.openWs(
    //     clonedFromUsername || logged_user.username,
    //     logged_user.signInUserSession.idToken.jwtToken,
    //     hasGroup_client_or_client_user
    //   );
    // }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        init: false,
        logged_user: logged_user,
        jwtToken:
          logged_user &&
          logged_user.signInUserSession &&
          logged_user.signInUserSession.idToken.jwtToken,
        email: clonedFromEmail || email,
        name: name,
        username: clonedFromUsername || logged_user.username,
        locale: locale,

        profile: profile,
        profileGroups: groups,

        clientId: clientId,
        partnerId: partnerId,
        supplierId: supplierId,

        is_bpool: hasGroup_bpool,
        is_master: hasGroup_master,
        is_master_or_bpool: hasGroup_master_or_bpool,

        is_partner: hasGroup_partner,
        is_partner_operation: hasGroup_partner_operation,
        is_partner_leader: hasGroup_partner_leader,
        is_partner_financial: hasGroup_partner_financial,
        is_partner_user: hasGroup_partner_user,

        is_client: hasGroup_client,
        is_client_user: hasGroup_client_user,
        is_client_user_marketing: hasGroup_client_user_marketing,
        is_client_user_purchasing: hasGroup_client_user_purchasing,
        is_supplier: hasGroup_supplier,
        is_client_or_client_user: hasGroup_client_or_client_user,
        is_partner_and_supplier: hasGroup_partner_supplier,

        task: { result: true },
      },
    });
  };
};

export default api_login_success;
