import React from 'react';

import { I18n } from 'aws-amplify';

import FormatCurrency from '../ui/format-currency';

export default class ProjectDetails extends React.Component {

  constructor(props) {
    super();

    this.state = {
      detailsView: null,
      task: {}
    };
  }

  componentDidMount() {
    this.setState({ task: {} });
  }


  render() {

    const billing = this.props.billing || {};
    const currency = this.props.currency || {};


    return (
      <div className="content">

        <div className="card">
          <div className="card-content">

            <div className="columns">
               <div className="column is-3">
                <p>
                  <strong>{I18n.get("Número SPA:")}</strong> &nbsp; {billing.projectPO}
                </p>
                <p>
                  <strong>{I18n.get("Tipo:")}</strong>&nbsp;
                  {this.props.isCreative && <span>{I18n.get("Criativo")}</span>}
                  {this.props.isEvent && <span>{I18n.get("Evento")}</span>}
                  {this.props.isWhatif && <span>{I18n.get("Idéias/Conteúdo")}</span>}
                  {this.props.isBPitch && <span>{I18n.get("BPitch")}</span>}

                </p>
              </div>
              <div className="column is-3">
                <p>
                  <strong>{I18n.get("Valor Total:")}</strong> &nbsp; <FormatCurrency amount={billing.total} currency={currency} />
                </p>
                <strong>{I18n.get("Docs:")}</strong> <br />
                {billing.files && billing.files.map((item, index) => {
                  return (<div key={index} title={item.name} style={{ width: '5rem', display: 'inline-block' }}>

                    <a href={item.url} target="_blank" style={{ color: '#ccc' }}>
                      <span className="icon is-large" style={{ marginTop: '1rem' }}>
                        <i className="fas fa-file fa-3x" aria-hidden="true"></i>
                      </span>
                    </a>

                    <div style={{ width: '5rem', padding: '0.5rem', fontSize: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</div>
                  </div>
                  );
                })}
              </div>
            </div>

            {/* <p className="title-bpool medium">Dados Emissão</p>

            <p style={{ lineHeight: '1.3rem' }}>

              <strong>Razão Social:</strong> &nbsp; {billing.registrationName} <br />
              <strong>CNPJ:</strong> &nbsp; {billing.registrationId} <br />
              <strong>Endereço:</strong> &nbsp; {billing.addressLine1} <br />
              <strong>Complemento:</strong> &nbsp; {billing.addressLine2} <br />
              <strong>Cidade:</strong> &nbsp; {billing.city} <br />
              <strong>Estado:</strong> &nbsp; {billing.state} <br />
              <strong>CEP:</strong> &nbsp; {billing.postcode} <br />

            </p> */}



          </div>
        </div>

      </div>
    );
  }
}
