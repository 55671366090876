import React from 'react';

import { I18n } from 'aws-amplify';

import ProjectContainer from '../../containers/project-container';

import FillForm from '../page-templates/fillForm-logged';
import { Link } from 'react-router-dom';

import bannerImage3 from '../../image/banner-image3.png';

import { scrollToTop } from '../../utils/ui';

class ThankYou extends React.Component {

    constructor(props) {

        super();

        this.state = {
        };
    }

    componentDidMount() {

        scrollToTop();
    }

    render() {
        return (
            <FillForm noFixed={true}>

                <div className="hero-body">
                    <div className="container">

                        <div className="columns">

                            <div className="column">
                                <div className="sucesso-select">
                                    <h1 className="title-bpool medium">{I18n.get("Obrigado, parceiro selecionado.")}</h1>
                                    <br></br>
                                    <p>{I18n.get("O processo de escolha chegou ao final!")}</p>
                                    <br></br>
                                    <p>{I18n.get("A partir de agora, o seu projeto pode ser acessado na área de projetos, que será compartilhada com o parceiro. É onde você deverá detalhar o briefing e fazer upload de todos os materiais de apoio para que o parceiro possa revisar o escopo final do projeto, assim como submeter para sua aprovação um cronograma de checkpoints e entregas.")}</p>
                                    <br />
                                    <p>
                                        <Link to={`/projetos/${this.props.projectId}/detalhamento-passo-1`}>{I18n.get("Clique aqui!")}</Link>
                                    </p>
                                </div>
                            </div>

                            <div className="column">

                                <p className="title-bpool medium">{I18n.get("PRÓXIMOS PASSOS")}</p>

                                <div className="timeline">
                                    <header className="timeline-header">
                                        <span className="tag is-primary">{I18n.get("Briefing")}</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker"></div>
                                        <div className="timeline-content">
                                            <p><strong>{I18n.get("Cliente:")}</strong> {I18n.get("Detalhamento do briefing na plataforma.")}</p>
                                            <p><strong>{I18n.get("Parceiro:")}</strong> {I18n.get("Revisão do briefing.")}</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag">{I18n.get("Review do Projeto")}</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker"></div>
                                        <div className="timeline-content">
                                            <p><strong>{I18n.get("Parceiro:")}</strong> {I18n.get("Se necessário, edição do escopo do projeto de acordo com o briefing.")}</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag">{I18n.get("Cronograma")}</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker"></div>
                                        <div className="timeline-content">
                                            <p><strong>{I18n.get("Parceiro:")}</strong> {I18n.get("Definição de macros de entrega.")}</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag">{I18n.get("Aprovação / Gerar SPA")}</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker"></div>
                                        <div className="timeline-content">
                                            <p><strong>{I18n.get("Cliente:")}</strong> {I18n.get("Revisão do escopo final do projeto, valores e aprovação final. Aprovação do escopo final, cronograma de entregas, valores e aprovação final. Assinatura TEP e inserção do número de SPA (ordem ou pedido de compra).")}</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag">{I18n.get("Jobs OnGoing")}</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker"></div>
                                        <div className="timeline-content">
                                            <p><strong>{I18n.get("Parceiro:")}</strong> {I18n.get("Início do Projeto.")}</p>
                                        </div>
                                    </div>
                                    <div className="timeline-header">
                                        <span className="tag is-primary">{I18n.get("Final")}</span>
                                    </div>
                                </div>



                            </div>

                        </div>

                        <br /><br /><br />

                    </div>

                </div>

            </FillForm>
        );
    }
}


export default ProjectContainer(ThankYou);
