import React from "react";

import { I18n } from "aws-amplify";

import FormatCurrency from "../ui/format-currency";

class Heading extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    const currency = this.props.currency || {};

    const quotation =
      this.props.eventQuotations &&
      this.props.eventQuotations.find(
        (item) => item.id == this.props.partner.id
      );

    return (
      <div className="heading-mobile">
        <p className="textInvite">
          {I18n.get(
            "Você foi convidado para participar de um processo de propostas. Verifique o briefing, datas e escopo e veja se topa."
          )}
        </p>

        <br />
        <br />

        <h1 className="title">{I18n.get("Briefing")}</h1>

        <br />
        <br />

        <p>
          <strong>{I18n.get("Nome do Projeto:")}</strong> &nbsp;{" "}
          {this.props.projectName}
        </p>
        <p>
          <strong>{I18n.get("Marca / Categoria:")}</strong> &nbsp;{" "}
          {this.props.brandName} - {this.props.categoryName}
        </p>
        <p>
          <strong> {I18n.get("Budget máximo autorizado pelo cliente")}:</strong>{" "}
          <FormatCurrency amount={quotation?.budget} currency={currency} />
        </p>

        <p>
          <strong>{I18n.get("Retenção máxima Ollo")}:</strong>{" "}
          <FormatCurrency
            amount={
              quotation?.bpCommission && quotation?.bpCommission?.maxAmount
            }
            currency={currency}
          />
        </p>

        <p>
          <strong>
            {" "}
            {I18n.get("Budget máximo disponível para orçamento")}:
          </strong>{" "}
          <FormatCurrency
            amount={quotation?.workingBudgetMax}
            currency={currency}
          />
        </p>

        <p>
          <strong>
            {I18n.get("Data de entrega da proposta criativa-comercial:")}
          </strong>{" "}
          &nbsp;{" "}
          {this.props.customEvent && this.props.customEvent.quotationDate}
        </p>

        <p>
          <strong>{I18n.get("Prazo pagamento:")}</strong>{" "}
          {this.props.paymentTermDays} {I18n.get("dias")}
        </p>

        <br />
        <br />
      </div>
    );
  }
}

export default Heading;
