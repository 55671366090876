import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { I18n } from "aws-amplify";
import { Row, Col } from "antd";
import { Unlogged } from "../../../components/UI/Unlogged";
import ErrorPane from "../../../../components/error-pane";
import Main from "../../../components/UI/Main";
import { Input } from "../../../components/UI/Input";
import { ButtonBpool } from "../../../components/UI/ButtonBpool";
import { PartnerStatus } from "../../../../model";
import {
  handleRedirectIfDeslogged,
  getRedirectIfDeslogged,
} from "../../../utils/redirectPageIfDeslogged";

import { useSelector, useDispatch } from "react-redux";
import { AUTH_API } from "../../../../actions/auth/actions";

import Config from "../../../../config";
import { ClientStatus } from "../../../../model";

import * as S from "./styles";

export const Login = () => {
  let location = useLocation();
  const state = useSelector((state) => state);
  const authUser = useSelector((state) => state.auth);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const [redirectUrlSaved, setRedirectUrlSaved] = useState("");

  const dispatch = useDispatch();
  let history = useHistory();

  const getParamsLocale = () => {
    let language = "";
    if (location.search) {
      const locationSplit = location.search.split("=");
      language = locationSplit[1];
    } else {
      language = "pt";
    }
    return language;
  };

  const saveUrlIfDeslogged = async (url) => {
    await handleRedirectIfDeslogged(url);
  };

  useEffect(() => {
    const language = getParamsLocale();

    I18n.setLanguage(language);
  }, [location]);

  const handleSignIn = async () => {
    const data = {
      email: login.trim(),
      password: password.trim(),
    };
    dispatch(AUTH_API.login(data));
  };

  const signin = async () => {
    setIsLoadingAuth(true);
    await handleSignIn();
  };

  const prevSignin = async () => {
    const redirectSaved = await getRedirectIfDeslogged();
    if (redirectSaved) {
      setRedirectUrlSaved(redirectSaved);
      return;
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await signin();
    }
  };

  const received = async () => {
    history.push("/registration-flow/application/received/login");
  };

  useEffect(() => {
    if (authUser) {
      setIsLogged(Boolean(authUser.logged_user));
      setIsLoadingAuth(false);
    } else {
      setIsLogged(false);
    }
  }, [authUser]);

  useEffect(() => {
    if (state?.auth?.jwtToken) {
      if (redirectUrlSaved) {
        // history.push(redirectUrlSaved);
        window.location.replace(redirectUrlSaved);
        localStorage.removeItem("@BPOOL-redirect-url-after-login");
        return;
      }

      if (authUser.is_partner) {
        if (
          state.partner &&
          state.partner.status === PartnerStatus.Incomplete
        ) {
          //Pre cadastro incompleto - Status 1
          history.push("/registration-flow/about-the-company");
          return;
        }

        if (state.partner && state.partner.status === 9) {
          received();
          return;
        }

        if (state.partner && state.partner.status === PartnerStatus.Created) {
          //Pre cadastro finalizado - faltando referencias - Status 2
          history.push("/references-partner/list");
          return;
        }

        if (
          state.partner &&
          state.partner.status === PartnerStatus.Referenced
        ) {
          //Pre cadastro - 3 avaliaram - Status 3
          history.push("/references-partner/list");
          return;
        }

        if (
          state.partner &&
          state.partner.status === PartnerStatus.PreApproved
        ) {
          //Pré selecionado com cadastro full incompleto - Status 5
          history.push("/registration-flow/full/company-profile");
          return;
        }

        // Status 6 = antigo Evaluation desativado

        if (state.partner && state.partner.status === PartnerStatus.Approved) {
          history.push("/parceiro/inicio");
          return;
        }
      }

      if (state?.auth?.is_client_or_client_user || state?.auth.is_client_user) {
        if (
          state.client &&
          state.client.status < ClientStatus.Approved &&
          Config.moduloNewRegisterClient
        ) {
          //Pré selecionado com cadastro full incompleto - Status 5
          history.push(
            `/cliente/cadastre-se/responsavel/${state.client.clientId}`
          );
          return;
        }

        if (state.client && state.client.status < ClientStatus.Approved) {
          history.push(`/cliente/passo-${state.client.step}`);
          return;
        }

        if (state.client && state.client.status === ClientStatus.Approved) {
          history.push("/cliente/inicio");
          return;
        }
      }

      if (state?.auth.is_master || state?.auth.is_bpool) {
        history.push("/bpool/dashboard");
        return;
      }

      if (state?.auth.is_supplier) {
        history.push("/fornecedor/inicio");
        return;
      }
    }
  }, [state, redirectUrlSaved]);

  useEffect(() => {
    window.scrollTo(0, 0);
    prevSignin();
  }, []);

  return (
    <Unlogged>
      <div className="container">
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12, offset: 6 }}>
            <Main bgColor="#fff" padding="30px" style={{ marginTop: 80 }}>
              <Row>
                <Col xs={24}>
                  <S.RowCenter>
                    <S.TitleWithIcon>
                      <S.H4>{I18n.get("Login")}</S.H4>
                    </S.TitleWithIcon>
                  </S.RowCenter>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <S.Form>
                    <Input
                      placeholder={I18n.get("Login")}
                      label
                      error=""
                      value={login}
                      onChange={(value) => setLogin(value)}
                      onKeyDown={handleKeyDown}
                    />
                    <div style={{ marginTop: 5 }}>
                      <Input
                        placeholder={I18n.get("Password")}
                        label
                        error=""
                        value={password}
                        password
                        onChange={(value) => setPassword(value)}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <S.Forgot>
                      <Link to="/esqueci-minha-senha">
                        {I18n.get("Forgot my password")}
                      </Link>
                    </S.Forgot>
                    <div style={{ marginTop: 30 }}>
                      <ErrorPane error={state?.auth?.task?.error}></ErrorPane>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <ButtonBpool
                        full
                        text={I18n.get("Sign In")}
                        theme="primary"
                        onClick={signin}
                        onKeyDown={handleKeyDown}
                        loading={isLoadingAuth}
                      />
                    </div>
                  </S.Form>
                </Col>
              </Row>
              {/* <Row style={{ marginTop: 30 }}>
                <Col xs={24}>
                  <div className="footer-from" style={{ marginBottom: 16 }}>
                    <span className="is-pulled-left">
                      {I18n.get("Quer conhecer mais da BPool?")}{" "}
                      <a href="https://bpool.co/" style={{ color: "#1883FF" }}>
                        {I18n.get("Visite nosso site")}
                      </a>
                    </span>
                  </div>
                </Col>
              </Row> */}
            </Main>
          </Col>
        </Row>
      </div>
    </Unlogged>
  );
};
