import styled from "styled-components";

export const TitleWithIcon = styled.div`
  display: flex;

  img {
    width: 25px;
    height: 25px;
    margin-left: 30px;
  }
`;

export const H4 = styled.h4`
  font-weight: 700;
  font-size: 30px;
  color: #000000;
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  p {
    margin: 20px 0 0 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.51px;
  }
`;

export const SubTitle = styled.div`
  margin: 15px 0 30px 0;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  span {
    width: 70px;
    height: 1px;
    background-color: #007aff;
    display: block;
  }
`;

export const Text = styled.div`
  font-weight: ${(props) => (props.strongText ? "700;" : "400;")};
  font-size: ${(props) => (props.strongText ? "16px;" : "14px;")};
`;

export const ContentIsLoading = styled.div`
  margin: 30px 0 60px 0;
`;

export const Content = styled.div`
  padding: 30px 0 0 0;
`;

export const ContentRate = styled.div`
  box-shadow: 0px 4px 0px rgb(0 0 0 / 10%);
  border-radius: 20px;
  border: 1px solid;
  border-color: #f2f2f2;
  width: 215px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;

  svg {
    font-size: 26px;
  }
`;

export const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
`;

export const ExtraFinalized = styled.div`
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #09c38a;
  }
`;
